<template>
    <v-container class="add-customer-page has-shadow has-rounded-corners">
        <v-row>
            <v-col>
                <div class="create-addon-subscription">
                    <div>
                        <div v-if="hasAvailableAddonSubscriptions" class="choose-subscription-block">
                            <div>
                                <div class="subscriptions-type-selection item-block">
                                    <p class="title">
                                        {{ $t('Add-on subscription type') }}
                                    </p>
                                    <div class="field">
                                        <div class="has-float-label">
                                            <v-select v-model="addonSubscription"
                                                :items="availableAddonSubscriptions" :item-text="addonSubscriptionText" item-value="sku"
                                                dense :placeholder="$t('Select add-on subscription type')" @change="addonSubscriptionChanged"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="number-of-cameras-block item-block">
                                    <p class="title">
                                        {{ $t('Number of cameras') }}
                                    </p>
                                    <number-spinner id="change-number-of-cameras-button" :init-value="numberOfCameras"
                                        :min="1" :max="maxCamerasNumber" @value-changed="cameraNumberChanged" :disabled="!canAddMoreCameras"/>
                                </div>
                            </div>
                            <div>
                              <p class="text-center pt-4" v-if="!canAddMoreCameras">{{ $t('Cannot add more add-on subscriptions of this type. You have reached the maximum number') }}</p>
                            </div>
                            <div>
                                <v-btn
                                    large
                                    class="float-left mt-2"
                                    color="primary"
                                    outlined
                                    @click="cancelWizard()"
                                >
                                    {{ $t('Cancel') }}
                                </v-btn>
                                <v-btn
                                    large
                                    class="float-right mt-2"
                                    color="primary"
                                    :disabled="!addonSubscription || !numberOfCameras || !canAddMoreCameras"
                                    @click="createAddonSubscription()"
                                >
                                    {{ $t('Done') }}
                                </v-btn>
                            </div>
                        </div>
                        <div v-else class="has-text-centered">
                            <p class="has-text-error">
                                {{ $t('There are no available add-on subscriptions to choose from') }}
                            </p>
                            <p>{{ $t('Please contact support') }}</p>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script charset="utf-8">
import NumberSpinner from '@/components/base/NumberSpinner'

const restapi = require('@/service/restapi')

export default {
    name: 'CreateAddonSubscription',
    components: {
        NumberSpinner
    },
    props: ['maxAmountOfCameras', 'availableAddonSubscriptions', 'addonSubscriptions'],
    data () {
        return {
            addonSubscription: null,
            numberOfCameras: null,
            maxCamerasNumber: this.maxAmountOfCameras,
            numberOfEmptySlots: null
        }
    },
    computed: {
        resellerId () {
            return this.$route.params.resellerid
        },
        accountId () {
            return this.$route.params.accountid
        },
        userId () {
            return this.$route.params.userid
        },
        hasAvailableAddonSubscriptions () {
            return this.availableAddonSubscriptions ? this.availableAddonSubscriptions.length > 0 : null
        },
        canAddMoreCameras() {
            return this.numberOfEmptySlots - this.numberOfCameras >= 0
        }
    },
    methods: {
        getSKUWithoutMonthSuffix (sku) {
            return sku.replace(/-\d+$/, '')
        },
        addonSubscriptionText: item => `${item.name} (${item.sku})`,
        cameraNumberChanged (value) {
            this.numberOfCameras = value ? parseInt(value) : 0
        },
        addonSubscriptionChanged (value) {
            this.calculateEmptySlots(value)
        },
        calculateEmptySlots(value) {
            const skuWithoutMonthSuffix = this.getSKUWithoutMonthSuffix(value)            
            const numberOfAddedSubscriptions = this.addonSubscriptions.filter(subscription => this.getSKUWithoutMonthSuffix(subscription.sku) === skuWithoutMonthSuffix).length
            this.numberOfEmptySlots = this.maxAmountOfCameras - numberOfAddedSubscriptions
        },
        cancelWizard () {
            this.$emit('onCancel')
        },
        async createAddonSubscription() {
            this.isLoading(true)
            try {
                const subscriptions = Array.from({ length: this.numberOfCameras }, () => ({
                    sku: this.addonSubscription,
                    groupType: 'addOn'
                }))
                await Promise.all(subscriptions.map(subscription => restapi.createAccountSubscription(this.resellerId, this.accountId, subscription)))
                this.$emit('onCreated')
            } catch (error) {
                console.error(error)
                this.$store.commit('toastMessage', {
                    showing: true,
                    text: this.getMessageFromError(error, 'ADD_ACCOUNT_SUBSCRIPTION'),
                    timeout: -1,
                    color: 'error',
                    support: true
                })
                this.$emit('onCancel')
            } finally {
                this.isLoading(false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/forms';
@import '../../../../assets/styles/custom';

.add-customer-page {
    background-color: #ffffff;
    max-width: 700px;
    .columns {
      margin: 0;
      padding: 0;
      background-color: transparent;
      .column {
        padding: 0 40px;
        //margin: 20px 0;
        @media screen and (max-width: 767px) {
          border-left: 8px solid $primary;
          padding-left: 32px;
        }
        @media screen and (min-width: 768px) {
          &:first-child {
            border-left: 8px solid $primary;
            padding-left: 32px;
          }
        }
        &.password-column {
          min-height: 100px;
        }
        .field {
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
.choose-subscription-block {
  min-height: 250px;
  .item-block {
    padding-top:10px;
    text-align: center;
  }
  flex-direction: column;
}
.choose-subscription-block ::v-deep .switch input[type=checkbox] + .check {
  border-radius: 4px;
  background-color: #CCCCCC;
}
.choose-subscription-block ::v-deep .switch input[type=checkbox] + .check:before {
  border-radius: 4px;
}
.choose-subscription-block ::v-deep .switch .control-label {
  padding-left: 0;
  padding-right: 0.5em;
}
.choose-subscription-block ::v-deep .switch .control-label.is-large {
  font-size: large;
  font-weight: bold;

}
.choose-subscription-block ::v-deep .switch:focus input[type=checkbox]:checked + .check,
.choose-subscription-block ::v-deep .switch:focus input[type=checkbox] + .check {
  box-shadow: none;
}

p.title {
  color: #b9b9b9!important;
  text-transform: uppercase;
  margin-bottom: 10px;
   font-size: 85%!important;
}

.subscriptions-type-selection {
  justify-content: center;
}

button {
  margin-right: 10px;
  margin-bottom: 10px;
  color: $color-action;
  background-color: white;
}

.choose-subscription-block {
  display: flex;
  justify-content: center;
  padding: 0px 10px;
}

#change-number-of-cameras-button.field {
  max-height: 48px;
  margin: 0  auto 0 auto;
  width: 180px;
}

#change-number-of-cameras-button ::v-deep .field {
  max-height: 48px;
  margin: 10px 0;
}
</style>
