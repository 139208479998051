<template>
  <div class="mobile-subscriptions">
    <h2 class="component-title">
      {{ $t('Mobile Subscriptions') }}
    </h2>
    <p class="component-description">
      {{ $t('Mobile Subscriptions Description') }}
    </p>
    <view-mobile-subscriptions
      v-if="shouldBeShown('view')"
      :account="account"
      :subscriptions="subscriptions"
      @onAddNewSubscription="addNewSubscription()"
      @onEditSubscription="editSubscription"
      @onRemoveSubscription="removeSubscription"
    />
    <create-mobile-subscription
      v-if="shouldBeShown('create')"
      :available-subscriptions="availableSubscriptions"
      @onCancel="createSubscriptionCancelled"
      @onCreated="subscriptionCreated"
    />
    <edit-mobile-subscription
      v-if="shouldBeShown('edit')"
      :available-subscriptions="availableSubscriptions"
      :subscription="subscriptionToEdit"
      @onCancel="editSubscriptionCancelled"
      @onUpdated="subscriptionUpdated"
    />
    <delete-mobile-subscription
      v-if="shouldBeShown('delete')"
      :subscription="subscriptionToDelete"
      :is-modal-active="shouldBeShown('delete')"
      @onDeleted="subscriptionDeleted"
      @onCancel="deleteSubscriptionCancelled"
    />
  </div>
</template>

<script charset="utf-8">
import ViewMobileSubscriptions from '@/components/account/subscription/mobile/ViewMobileSubscriptions'
import CreateMobileSubscription from '@/components/account/subscription/mobile/CreateMobileSubscription'
import EditMobileSubscription from '@/components/account/subscription/mobile/EditMobileSubscription'
import DeleteMobileSubscription from '@/components/account/subscription/mobile/DeleteMobileSubscription'

const restapi = require('@/service/restapi')

// states which can have the page (showing subscriptions, creating new ones, editing or deleting existing ones
const actionState = {
    VIEW: 'view',
    CREATE: 'create',
    EDIT: 'edit',
    DELETE: 'delete'
}

export default {
    name: 'MobileSubscriptions',
    components: {
        DeleteMobileSubscription,
        EditMobileSubscription,
        ViewMobileSubscriptions,
        CreateMobileSubscription
    },
    props: ['account'],
    data () {
        return {
            subscriptions: [],
            availableSubscriptions: [],
            filter: null,
            state: actionState.VIEW,
            subscriptionToDelete: null,
            subscriptionToEdit: null
        }
    },
    computed: {
        resellerId () {
            return this.$route.params.resellerid
        },
        accountId () {
            return this.$route.params.accountid
        },
        userId () {
            return this.$route.params.userid
        }
    },
    mounted () {
        this.loadSubscriptions()
    },
    methods: {
        shouldBeShown (state) {
            return this.state === state
        },
        changeState (state) {
            this.state = state
        },
        addNewSubscription () {
            if (!this.hasPermission('CustomerAccount')) return
            this.changeState(actionState.CREATE)
        },
        editSubscription (subscription) {
            if (!this.hasPermission('CustomerAccount')) return
            this.subscriptionToEdit = subscription
            this.changeState(actionState.EDIT)
        },
        removeSubscription (subscription) {
            if (!this.hasPermission('CustomerAccount')) return
            this.subscriptionToDelete = subscription
            this.changeState(actionState.DELETE)
        },
        createSubscriptionCancelled () {
            this.changeState(actionState.VIEW)
        },
        subscriptionCreated () {
            this.changeState(actionState.VIEW)
            this.loadSubscriptions()
        },
        subscriptionUpdated (subscription) {
            this.subscriptionToEdit = null
            this.changeState(actionState.VIEW)
            this.loadSubscriptions()
        },
        editSubscriptionCancelled () {
            this.subscriptionToEdit = null
            this.changeState(actionState.VIEW)
        },
        deleteSubscriptionCancelled () {
            this.subscriptionToDelete = null
            this.changeState(actionState.VIEW)
        },
        subscriptionDeleted () {
            this.subscriptionToDelete = null
            this.changeState(actionState.VIEW)
            this.loadSubscriptions()
        },
        loadSubscriptions () {
            this.subscriptions = []
            const that = this
            if (this.resellerId && this.accountId) {
                that.isLoading(true)
                restapi.getAccountSubscriptions(that.resellerId, that.accountId, 'mobile')
                    .then(function (mobileSubscriptions) {
                        restapi.getAccountAvailableSubscriptions(that.resellerId, that.accountId, 'mobile')
                            .then(function (result) {
                                that.availableSubscriptions = result
                                restapi.getAccountMobileSubscriptionsSummary(that.resellerId, that.accountId)
                                    .then(function (result) {
                                        result.forEach(value => {
                                            let mobileSubscriptionFound = mobileSubscriptions.filter(function (subscription) {
                                                return subscription.subscriptionId === value.subscriptionId
                                            })
                                            if (mobileSubscriptionFound && mobileSubscriptionFound.length === 1) {
                                                let subscriptionObject = {
                                                    iccid: value.iccid,
                                                    sku: mobileSubscriptionFound[0].sku,
                                                    cameraName: value.cameraName,
                                                    cameraId: value.cameraId,
                                                    imei: value.imei,
                                                    subscriptionId: value.subscriptionId
                                                }
                                                let mdsWithSameSKUFound = that.availableSubscriptions.filter(function (availableSubs) {
                                                    return availableSubs.sku === mobileSubscriptionFound[0].sku
                                                })
                                                if (mdsWithSameSKUFound && mdsWithSameSKUFound.length === 1) {
                                                    subscriptionObject.dataCap = mdsWithSameSKUFound[0] && mdsWithSameSKUFound[0].subscriptionTypeDetails ? mdsWithSameSKUFound[0].subscriptionTypeDetails.dataCap : null
                                                }
                                                that.subscriptions.push(subscriptionObject)
                                            } else if (mobileSubscriptionFound.length > 1) {
                                                console.log('[FATAL] More that one matching mobile subscriptions were found, id = ', mobileSubscriptionFound.subscriptionId)
                                            } else {
                                                console.log('[FATAL] Mobile subscription was not found, object = ', value)
                                            }
                                        })
                                    })
                                    .catch(function (error) {
                                        console.error(error)
                                        that.$store.commit('toastMessage', {
                                            showing: true,
                                            text: that.getMessageFromError(error, 'GET_MOBILE_SUBSCRIPTIONS'),
                                            timeout : -1,
                                            color: 'error',
                                            support: true
                                        })
                                    })
                            })
                    })
                    .catch(function (error) {
                        console.error(error)
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.getMessageFromError(error, 'GET_MOBILE_SUBSCRIPTIONS'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                    })
                    .then(function () {
                        that.isLoading(false)
                    })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../../../assets/styles/forms';
  @import '../../../../assets/styles/custom';

  .mobile-subscriptions {
    padding-top: 50px;
  }

  .content-block {
    margin-top: 30px;
  }

  .columns {
    margin-top: 20px;
    padding: 5px 0;
  }

</style>
