/* eslint-disable no-undef */

import { getConfiguration } from '@/service/main'

const config = getConfiguration()

let locale = {}
let messages = {}
let info = {}

let names = {
    en: 'English',
    nl: 'Nederlands',
    de: 'Deutsch',
    es: 'Español',
    pt: 'Português',
    pl: 'Polski',
    tr: 'Türkçe'
    // ja: '日本語'
}

if (process.env.NODE_ENV !== 'testing') {
    /* eslint-disable no-undef */
    if (config.crowdinIntegration) {
        names['aa'] = 'Crowdin'
    }
}
let codes = Object.keys(names)

for (let i = 0, len = codes.length; i < len; i++) {
    const abbr = codes[i]
    let x = require('@/assets/lang/dd_' + abbr + '.json')
    messages[abbr] = x
    info[abbr] = {
        code: abbr,
        name: names[abbr] || abbr
    }
}

locale = {
    messages: messages,
    info: info
}

export default locale
