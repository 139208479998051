/* eslint-disable no-undef */
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import ClientOAuth2 from 'client-oauth2'
import { store } from '@/store/index'
import axios from 'axios'
import {getConfiguration} from '@/service/main'
import { findTimeZone, getUTCOffset } from 'timezone-support'

const restapi = require('@/service/restapi')

Vue.use(VueCookies)

const config = getConfiguration()

const APP_ID = config.appId
const APP_SWITCHER_ID = 'dealer.v2'
const COOKIE_SESSION_DATA = 'sessionData'
const COOKIE_MY_ACCOUNT = 'myAccount'
const COOKIE_MY_PARTNER = 'myPartner'
const UID_KEY = 'uid'
const CLIENT_ID = clientId()


/* eslint-disable no-undef */
let client = initClient()

function initClient() {
    return new ClientOAuth2({
        clientId: CLIENT_ID,
        authorizationUri: config.authServer + '/oauth/authorize',
        redirectUri: getRedirectUri(),
        scopes: ['read', 'write'],
        query: {
            appId: APP_SWITCHER_ID
        }
    })
}

export function resetClient() {
    client = initClient()
}  

export function oauthUri () {
    if (client.token) {
        return client.token.getUri({
            redirectUri: getRedirectUri()
        })
    }
    return null
}

function clientId () {
    let uid = window.$cookies.get(UID_KEY) || false
    if (!uid) {
        uid = Math.floor(Math.random() * 0x10000).toString(16)
        window.$cookies.set(UID_KEY, uid, '1y')
    }
    return APP_ID + '-' + uid
}

export function checkAuth () {
    return this.getToken()
}

export function isAuthorized () {
    const token = this.getToken()
    const appId = window.$cookies.get(this.cookieApp())
    return !!token && appId === APP_ID
}

export function getSessionData () {
    return window.$cookies.get(COOKIE_SESSION_DATA)
}

export function getSessionId () {
    if (store.getters.session && store.getters.session.session) {
        return store.getters.session.session.sessionId
    }
    return null
}

export function getPartnerId () {
    return store.getters.myAccount ? store.getters.myAccount.accountId : null
}

export function getAccountId () {
    if (store.getters.session && store.getters.session.session) {
        return store.getters.session.session.partnerSession.accountId
    }
    return null
}

export function getSuperPartnerId () {
    if (store.getters.session && store.getters.session.session) {
        return store.getters.session.session.superPartnerId
    }
    return null
}

export function isAdmin () {
    if (store.getters.session && store.getters.session.session && store.getters.session.session.partnerSession) {
        return !!(store.getters.session.session.partnerSession.partnerId === 0)
    }
    return false
}

export function isDistributor () {
    if (store.getters.session && store.getters.session.session && store.getters.session.session.partnerSession) {
        return !!(store.getters.session.session.partnerSession.superPartnerId === -1)
    }
    return false
}

export function isReseller () {
    if (store.getters.session && store.getters.session.session && store.getters.session.session.partnerSession) {
        return !!(store.getters.session.session.partnerSession.superPartnerId !== -1)
    }
    return false
}

export function getToken () {
    return window.$cookies.get(cookieAuth(), '/', cookieDomain())
}

export function deleteToken () {
    let deleteOauthToken = true
    const token = window.$cookies.get(this.cookieAuth(), '/', this.cookieDomain())
    if (token) {
        if (deleteOauthToken) {
            axios.delete(config.authServer + '/rest/v2.0/users/self/tokens/current', {
                headers: {'Authorization': 'Bearer ' + token}
            })
        }
        window.$cookies.remove(this.cookieAuth(), '/', this.cookieDomain())
    }
}

// @todo: This needs work to use the OAuth session for re-login
export function reLogin (returnToUrl = true) {
    console.log('Now doing a re-login')
    // window.location = oauthUri()
    // This does not work since popups get blocked and iframes also
    window.open(oauthUri() + '&redirect_uri=' + (returnToUrl ? window.location.href : window.location.origin), '_blank')
}

export function login (token, expiresIn) {
    const validExpiresIn = !!expiresIn
    const that = this
    if (validExpiresIn) {
        window.$cookies.set(this.cookieApp(), APP_ID, parseInt(expiresIn), '/', this.cookieDomain())
        window.$cookies.set(this.cookieAuth(), token, parseInt(expiresIn), '/', this.cookieDomain())
        setTimeout(() => {
            that.logout()
        }, expiresIn * 1000)
    } else {
        window.$cookies.remove(this.cookieApp(), '/', this.cookieDomain())
        window.$cookies.remove(this.cookieAuth(), '/', this.cookieDomain())
    }
}

export function doLogout (returnToUrl = true) {
    const token = getToken()
    if (token) {
        cleanOnLogout()
        const location = returnToUrl ? window.location.href.split('?')[0] : window.location.origin
        console.log(`${config.authServer}/auth/logout?token=${token}&redirect_uri=${location}`)
        window.location.href = `${config.authServer}/auth/logout?token=${token}&redirect_uri=${location}`
    } else {
        window.location.href = oauthUri() + '&redirect_uri=' + (returnToUrl ? window.location.href.split('?')[0] : window.location.origin)
    }
}

export function logout () {
    return restapi.createSession()
        .then(function (sessionData) {
            store.commit('session', sessionData)
            window.$cookies.set(COOKIE_SESSION_DATA, sessionData, '24h')
            return Promise.resolve(sessionData)
        })
        .catch(function (error) {
            console.error(error)
            doLogout()
            return Promise.reject(error)
        })
}

export function getRedirectUri () {
    let uriLocation = window.location.href

    if (!uriLocation) {
        uriLocation = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
    }
    console.log(`Redirect uri: ${uriLocation}`)
    return uriLocation + '?a=0'
}

export function cleanOnLogout () {
    window.$cookies.remove(cookieApp(), '/', cookieDomain())
    window.$cookies.remove(COOKIE_SESSION_DATA)
    window.$cookies.remove(COOKIE_MY_ACCOUNT)
    window.$cookies.remove(COOKIE_MY_PARTNER)
}

export function getServer () {
    if (!store.getters.session) {
        const data = getSessionData()
        store.commit('session', data)
    }
    const hasPort = !!store.getters.session.server.httpsPort
    return 'https://' + store.getters.session.server.serverIp + (hasPort ? ':' + store.getters.session.server.httpsPort.toString() : '')
}

export function isActive () {
    return store.getters.myPartner ? store.getters.myPartner.active : null
}

export function getMyAccount () {
    const myAccount = window.$cookies.get(COOKIE_MY_ACCOUNT)
    if (myAccount) {
        store.commit('myAccount', myAccount)
        return getMyPartner()
            .then(function () {})
            .catch(function (error) {
                console.error(error)
            })
            .then(function () {
                return Promise.resolve(myAccount)
            })
    } else {
        return restapi.getCurrentUser()
            .then(function (user) {
                return restapi.getUserProfile(user.userId)
                    .then(function (profileId) {
                        return restapi.getAccountProfiles(user.accountId, profileId)
                            .then(function (profile) {
                                user.profile = profile
                            })
                            .catch(function (error) {
                                console.error(error)
                            })
                            .then(function () {
                                store.commit('myAccount', user)
                                window.$cookies.set(COOKIE_MY_ACCOUNT, user, '24h')
                                return getMyPartner()
                                    .then(function () {})
                                    .catch(function (error) {
                                        console.error(error)
                                    })
                                    .then(function () {
                                        return Promise.resolve(user)
                                    })
                            })
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
                    .then(function () {
                        return Promise.resolve(user)
                    })
            })
            .catch(function (error) {
                throw error
            })
    }
}

export function getMyPartner () {
    const myPartner = window.$cookies.get(COOKIE_MY_PARTNER)
    if (myPartner) {
        store.commit('myPartner', myPartner)
        return Promise.resolve(myPartner)
    } else {
        return restapi.getResellerDetails(store.getters.myAccount.accountId)
            .then(function (partner) {
                partner.timeZoneOffset = calculateTimeOffset(partner.timeZone)
                if (!partner.active) doLogout()
                store.commit('myPartner', partner)
                window.$cookies.set(COOKIE_MY_PARTNER, partner, '24h')
                return Promise.resolve(partner)
            })
            .catch(function (error) {
                if (error.response && error.response.status === 403) {
                    console.error('No permission to get my partner details')
                } else if (error.response && error.response.status === 404) {
                    restapi.getDistributorDetails(store.getters.myAccount.accountId)
                        .then(function (partner) {
                            partner.timeZoneOffset = calculateTimeOffset(partner.timeZone)
                            if (!partner.active) doLogout()
                            store.commit('myPartner', partner)
                            window.$cookies.set(COOKIE_MY_PARTNER, partner, '24h')
                            return Promise.resolve(partner)
                        })
                        .catch(function (error) {
                            throw error
                        })
                } else {
                    throw error
                }
            })
    }
}

function calculateTimeOffset(timezone) {
    const foundTimeZone = findTimeZone(timezone)
    return getUTCOffset(new Date(), foundTimeZone).offset
}

export function cookieDomain () {
    let ipv4Url = RegExp([
        '^https?://([a-z0-9\\.\\-_%]+:([a-z0-9\\.\\-_%])+?@)?',
        '((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(25[0-5]|2[0-4',
        '][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])?',
        '(:[0-9]+)?(/[^\\s]*)?$'
    ].join(''), 'i')
    const fqd = window.location.hostname.split('.')
    if (ipv4Url.test(window.location.href)) {
        return window.location.hostname
    } else if (fqd.length === 1) {
        return fqd[0]
    } else {
        const fqdr = fqd.reverse()
        return `${fqdr[1]}.${fqdr[0]}`
    }
}

export function cookieApp () {
    return 'app-' + getHostName(config.authServer)
}

export function cookieAuth () {
    return 'auth-' + getHostName(config.authServer)
}

export function isLoggedIntoOtherApp () {
    const cookieApp = window.$cookies.get(this.cookieApp())
    return cookieApp && cookieApp !== APP_ID
}

function getHostName (url) {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2]
    }
    return null
}

export default {
    oauthUri,
    reLogin,
    login,
    logout,
    doLogout,
    resetClient,
    checkAuth,
    getSessionData,
    getSessionId,
    getPartnerId,
    getAccountId,
    getSuperPartnerId,
    isAdmin,
    isDistributor,
    isReseller,
    isAuthorized,
    getToken,
    clientId,
    cleanOnLogout,
    getServer,
    isActive,
    getMyAccount,
    getMyPartner,
    cookieDomain,
    cookieApp,
    isLoggedIntoOtherApp
}
