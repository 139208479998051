import restapi from '@/service/restapi'
import { store } from '@/store'
import { getMessageFromError } from '@eencloud/core-components/src/service/errors'

const state = {
    resellerAccounts: {
        activeLoaded: false,
        inactiveLoaded: false,
        resellerId: null,
        currentActivePage: 1,
        currentInactivePage: 1,
        accounts: []
    },
    endUserAccount: {
        userId: null,
        resellerId: null,
        helpdesk: false
    },
    currentCustomersActiveState: true,
    selectedReseller: null // we keep track of the selected reseller outside of the resellerAccounts state in order to decide if we should clear the state and fetch new accounts.
}

const getters = {
    activeAccountsLoaded: state => {
        return state.resellerAccounts.activeLoaded
    },
    inactiveAccountsLoaded: state => {
        return state.resellerAccounts.inactiveLoaded
    },
    resellerAccounts: state => {
        return state.resellerAccounts
    },
    currentActivePage: state => {
        return state.resellerAccounts.currentActivePage
    },
    currentInactivePage: state => {
        return state.resellerAccounts.currentInactivePage
    },
    currentActiveState: state => {
        return state.currentCustomersActiveState
    },
    helpdesk: state => {
        return state.endUserAccount.helpdesk
    },
    selectedReseller: state => {
        return state.selectedReseller
    }
}

const mutations = {
    resellerAccounts: (state, resellerAccounts) => {
        if (!resellerAccounts) {
            state.resellerAccounts = {
                activeLoaded: false,
                inactiveLoaded: false,
                resellerId: null,
                currentActivePage: 1,
                currentInactivePage: 1,
                accounts: [],
            }
        } else if (resellerAccounts.resellerId !== state.resellerAccounts.resellerId) {
            state.resellerAccounts = resellerAccounts
            state.resellerAccounts.currentActivePage = 1
            state.resellerAccounts.currentInactivePage = 1
            state.resellerAccounts.activeLoaded = true // table always starts with loading active accounts
            state.resellerAccounts.inactiveLoaded = false
            state.currentCustomersActiveState = true // reset active state for different reseller
        } else {
            state.resellerAccounts.resellerId = resellerAccounts.resellerId
            state.resellerAccounts.accounts = state.resellerAccounts.accounts.concat(resellerAccounts.accounts)
        }
    },
    activeAccountsLoaded: (state, loaded) => {
        state.resellerAccounts.activeLoaded = loaded
    },
    inactiveAccountsLoaded: (state, loaded) => {
        state.resellerAccounts.inactiveLoaded = loaded
    },
    accountsCurrentPage: (state, page) => {
        if (page.active) {
            state.resellerAccounts.currentActivePage = page.page
        } else {
            state.resellerAccounts.currentInactivePage = page.page
        }
    },
    currentActiveState: (state, active) => {
        state.currentCustomersActiveState = active
    },
    addAccount: (state, account) => {
        if (account.resellerId === parseInt(state.resellerAccounts.resellerId)) {
            state.resellerAccounts.accounts.unshift(account)
        }
    },
    updateAccount: (state, account) => {
        if (account.resellerId === parseInt(state.resellerAccounts.resellerId)) {
            const accountId = account.accountId
            const accountIndex = state.resellerAccounts.accounts.findIndex(function (a) { return a.accountId === accountId })
            if (accountIndex !== -1) {
                state.resellerAccounts.accounts[accountIndex] = account
            }
        }
    },
    userId: (state, userId) => {
        state.endUserAccount.userId = userId
    },
    resellerId: (state, userId) => {
        state.endUserAccount.resellerId = userId
    },
    selectedReseller: (state, value) => {
        state.selectedReseller = value
    },
    helpdesk: (state, value) => {
        state.endUserAccount.helpdesk = value
    }
}

const actions = {
    setHelpdesk({ commit }, data) {
        return restapi.setAccount(state.endUserAccount.resellerId, state.endUserAccount.userId, { allowsHelpdeskExpirationDate: data.allowsHelpdeskExpirationDate})
            .then(commit('helpdesk', data.allowsHelpdesk))
            .catch( error => {
                store.dispatch('toastMessage', {
                    showing: true,
                    text: getMessageFromError(error, 'SET_ACCOUNT'),
                    timeout: -1,
                    color: 'error',
                    support: true
                })
                commit('helpdesk', !data.allowsHelpdesk)
                throw error
            })
    },
    selectReseller({ commit }, partnerId) {
        commit('selectedReseller', partnerId)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
