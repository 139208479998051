<template>
  <div id="new-partner-login">
    <v-card>
      <v-form @submit.prevent="addAccount">
        <v-card-title>
          {{ $t('New operator') }}
        </v-card-title>
        <v-card-text class="new-partner-login-background">
          <div class="field">
            <v-text-field
              id="add-account-email-input"
              v-model="email"
              :label="$t('Email address')"
              type="text"
              dense
              autocomplete="no"
              @input="$v.email.$touch()"
            />
            <p
              v-if="$v.email.$error && !$v.email.required"
              class="error-message"
            >
              {{ $t('This is a required field') }}
            </p>
            <p
              v-else-if="$v.email.$error && !$v.email.email"
              class="error-message"
            >
              {{ $t('Please enter a valid email address') }}
            </p>
          </div>
          <div class="field">
            <v-text-field
              id="add-account-password-input"
              v-model="password"
              :label="$t('Password')"
              type="password"
              dense
              autocomplete="no"
              @input="$v.password.$touch()"
            />
            <p
              v-if="$v.password.$error && !$v.password.required"
              class="error-message"
            >
              {{ $t('This is a required field') }}
            </p>
            <p
              v-else-if="$v.password.$error && !$v.password.validPassword"
              class="error-message"
            >
              {{ $t('Please enter a valid password') }}
            </p>
            <p
              v-else-if="$v.password.$error && !$v.password.minLength"
              class="error-message"
            >
              {{ $t('Please enter a minimum of X characters', { min: $v.password.$params.minLength.min }) }}
            </p>
            <p
              v-else-if="$v.password.$error && !$v.password.maxLength"
              class="error-message"
            >
              {{ $t('Please enter a maximum of X characters', { max: $v.password.$params.maxLength.max }) }}
            </p>
          </div>
          <div class="field">
            <v-select
              id="add-account-profile-select"
              v-model="roleId"
              :label="$t('Role')"
              item-value="profileId"
              :items="profiles"
              item-text="name"
              dense
              :placeholder="$t('Role')"
              @change="$v.roleId.$touch()"
            />
            <p
              v-if="$v.roleId.$error && !$v.roleId.required"
              class="error-message"
            >
              {{ $t('This is a required field') }}
            </p>
          </div>
          <v-btn
            large
            class="float-right"
            color="primary"
            type="submit"
          >
            {{ $t('Add operator') }}
          </v-btn>
          <v-btn
            large
            outlined
            class="float-right mr-2"
            color="primary"
            type="button"
            @click="closeDialog"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { validPassword } from '@/service/main'

const restapi = require('@/service/restapi')

export default {
    name: 'NewPartnerLogin',
    props: ['partnerId', 'profiles'],
    data () {
        return {
            email: null,
            password: null,
            roleId: null
        }
    },
    computed: {
        newAccount () {
            return {
                active: true,
                email: this.email,
                password: this.password,
                roleId: this.roleId
            }
        }
    },
    methods: {
        addAccount () {
            console.log('Adding account...')
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }
            let that = this
            this.$v.$reset()
            this.isLoading(true)
            restapi.addPartnerAccount(this.partnerId, this.newAccount)
                .then(function () {
                    that.email = null
                    that.password = null
                    that.roleId = null
                    that.$emit('add')
                })
                .catch(function (error) {
                    console.error(error)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.getMessageFromError(error, 'ADD_PARTNER_ACCOUNT'),
                        timeout : -1,
                        color: 'error',
                        support: true
                    })
                })
                .then(function () {
                    that.isLoading(false)
                })
        },
        closeDialog()
        {this.$emit('close-dialog')
        },
    },
    validations: {
        email: {
            required,
            email
        },
        password: {
            required,
            validPassword,
            minLength: minLength(6),
            maxLength: maxLength(32)
        },
        roleId: {
            required
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';
  .field {
    &:first-child {
      margin-top: 0;
    }
  }
  .new-partner-login-background{
    height: 225px;
  }
</style>
