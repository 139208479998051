<template>
  <div class="columns content-block has-shadow has-rounded-corners">
    <div class="column">
      <v-data-table
        :headers="headers"
        :items="filteredSubscriptions"
        item-key="iccid"
        hide-default-header
        :footer-props="{ 'items-per-page-text': '', 'items-per-page-options': []}"
        dense
      >
        <template #header="{ props, on }">
          <thead>
            <tr>
              <th
                v-for="(header, index) in props.headers"
                :key="index"
                class="primary--text table-head"
              >
                <a @click="on.sort(header.value)">
                  {{ $t(header.text) }}
                </a>
              </th>
            </tr>
          </thead>
        </template>
        <template #item="{ item, isExpanded, expand }">
          <tr
            :key="item.cameraId"
            :class="{ 'table-row': true, 'expanded': isExpanded }"
            @click="() => { expand(!isExpanded); if(!isExpanded) openDetails(item); }"
          >
            <td class="table-cell text-right">
              <v-icon
                v-if="!isExpanded"
                small
              >
                fas fa-chevron-down
              </v-icon>
              <v-icon
                v-if="isExpanded"
                small
              >
                fas fa-chevron-up
              </v-icon>
            </td>
            <td class="table-cell">
              {{ iccid(item) }}
            </td>
            <td class="table-cell">
              {{ sku(item) }}
            </td>
            <td class="table-cell">
              <a
                v-if="hasCameraAttached(item)"
                class="is-text camera-name-link"
                @click="showCameraDetails(item)"
              >
                {{ cameraName(item) }}
              </a>
              <span v-else>
                {{ cameraName(item) }}
              </span>
            </td>
            <td class="table-cell">
              {{ cameraIMEI(item) }}
            </td>
            <td class="table-cell">
              <v-menu
                v-if="hasPermission('CustomerAccount')"
                offset-y
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    @click.stop="on.click"
                  >
                    <v-icon
                      size="1em"
                      color="primary"
                    >
                      fa fa-ellipsis-h
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editSubscription(item)">
                    <v-list-item-title>{{ $t('Edit subscription') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="removeSubscription(item)">
                    <v-list-item-title>{{ $t('Remove subscription') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template #expanded-item="{ headers, item }">
          <td
            v-if="isMobileSubscriptionDetailsLoading(item.iccid)"
            class="table-cell expanded-area py-6 text-center"
            :colspan="headers.length"
          >
            {{ $t('Loading') }}
          </td>
          <td
            v-else
            class="table-cell expanded-area pa-12 py-6"
            :colspan="headers.length"
          >
            <v-row v-if="!hasDataToShow(item)">
              {{ $t('Could not show details for this subscription because the selected SKU is Inactive') }}
            </v-row>
            <v-row>
              <v-col>
                <span>{{ $t("Total data") }}</span>
                <p class="content">
                  {{ totalData(item) }}
                </p>
              </v-col>
              <v-col>
                <span>{{ $t("Used data") }}</span>
                <p class="content">
                  {{ usedData(item) }}
                </p>
              </v-col>
              <v-col>
                <span>{{ $t("Available data") }}</span>
                <p class="content">
                  {{ availableData(item) }}
                </p>
              </v-col>
              <v-col>
                <span>{{ $t("Billing cycle") }}</span>
                <p class="content">
                  {{ billingCycleDay(item) }}
                </p>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script charset="utf-8">

const restapi = require('@/service/restapi')

export default {
    name: 'MobileSubscriptionsTable',
    props: ['account', 'subscriptions', 'filter'],
    data () {
        return {
            loadingDetails: [],
            headers: [
                { text: '', value: '', align:'end' },
                { text: 'ICCID', value: 'iccid', align:'end' },
                { text: 'SKU', value: 'sku'},
                { text: 'Camera name', value: 'cameraName' },
                { text: 'Camera IMEI', value: 'imei' },
                { text: '', value: '' },
            ],
        }
    },
    computed: {
        filteredSubscriptions () {
            let subscriptions = this.subscriptions || []
            if (this.filter) {
                let _this = this
                subscriptions = subscriptions.filter(function (mds) {
                    return (mds.iccid && mds.iccid.toLowerCase().indexOf(_this.filter.toLowerCase()) !== -1) ||
              (mds.sku && mds.sku.toLowerCase().indexOf(_this.filter.toLowerCase()) !== -1) ||
              (mds.cameraName && mds.cameraName.toLowerCase().indexOf(_this.filter.toLowerCase()) !== -1) ||
              (mds.imei && mds.imei.toLowerCase().indexOf(_this.filter.toLowerCase()) !== -1)
                })
            }
            return subscriptions
        },
        resellerId () {
            return this.$route.params.resellerid
        },
        accountId () {
            return this.$route.params.accountid
        },
        userId () {
            return this.$route.params.userid
        }
    },
    methods: {
        editSubscription (subscription) {
            this.$emit('onEditSubscription', subscription)
        },
        removeSubscription (subscription) {
            this.$emit('onRemoveSubscription', subscription)
        },
        showCameraDetails (subscription) {
            this.$router.push({ path: `/user/${this.resellerId}/${this.accountId}/${this.userId}/cameras/${subscription.cameraId}` })
        },
        cameraName (subscription) {
            return subscription.cameraName ? subscription.cameraName : '-'
        },
        cameraIMEI (subscription) {
            return subscription.imei ? subscription.imei : '-'
        },
        sku (subscription) {
            return subscription.sku ? subscription.sku : this.$t('Inactive')
        },
        iccid (subscription) {
            return subscription.iccid ? subscription.iccid : '-'
        },
        hasCameraAttached (subscription) {
            return (subscription.imei)
        },
        bytesToHumanReadable (bytes) {
            let neg = bytes < 0
            let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

            if (neg) {
                bytes = -bytes
            }

            if (bytes < 1) {
                return (neg ? '-' : '') + bytes + ' B'
            }

            let exponent = Math.min(Math.floor(Math.log(bytes) / Math.log(1000)), units.length - 1)
            bytes = Number((bytes / Math.pow(1024, exponent)).toFixed(2))

            let unit = units[exponent]
            return (neg ? '-' : '') + bytes + ' ' + unit
        },
        totalData: function (subscription) {
            return (subscription.dataUsage && !this.isEmpty(subscription.dataUsage.maxBytes)) ? this.bytesToHumanReadable(subscription.dataUsage.maxBytes) : 'n/a'
        },
        usedData: function (subscription) {
            return (subscription.dataUsage && !this.isEmpty(subscription.dataUsage.usedBytes)) ? this.bytesToHumanReadable(subscription.dataUsage.usedBytes) : 'n/a'
        },
        availableData: function (subscription) {
            return (subscription.dataUsage && !this.isEmpty(subscription.dataUsage.usedBytes) && !this.isEmpty(subscription.dataUsage.maxBytes)) ? this.bytesToHumanReadable(subscription.dataUsage.maxBytes - subscription.dataUsage.usedBytes) : 'n/a'
        },
        billingCycleDay: function (subscription) {
            if (subscription.dataUsage && subscription.dataUsage.billingCycleDay) {
                return this.$t('Day X of the month', { day: subscription.dataUsage.billingCycleDay })
            } else {
                return 'n/a'
            }
        },
        hasAvailableData (subscription) {
            return (subscription.dataUsage && subscription.dataUsage.usedBytes && subscription.dataUsage.maxBytes) ? subscription.dataUsage.maxBytes - subscription.dataUsage.usedBytes > 0 : false
        },
        showWarningOnAvailableData (subscription) {
            if (subscription.dataUsage && subscription.dataUsage.usedBytes && subscription.dataUsage.maxBytes) {
                return (subscription.dataUsage.maxBytes - subscription.dataUsage.usedBytes) === 0
            } else {
                return false
            }
        },
        hasDataToShow (subscription) {
            return !!subscription.sku
        },
        setMobileSubscriptionDetailsLoading (loading, iccid) {
            if (loading) {
                if (this.isMobileSubscriptionDetailsLoading(iccid)) {
                    console.log('[Warn] Mobile subscriptions details are already loading for iccid = ', iccid)
                } else {
                    this.loadingDetails.push(iccid)
                }
            } else {
                let indexOfIccid = this.loadingDetails.indexOf(iccid)
                this.loadingDetails.splice(indexOfIccid, 1)
            }
        },
        isMobileSubscriptionDetailsLoading (iccid) {
            let foundIccid = this.loadingDetails.find(function (element) {
                return element === iccid
            })
            return foundIccid
        },
        openDetails (row, index) {
            if (!row.details && this.hasDataToShow(row)) {
                let that = this
                that.setMobileSubscriptionDetailsLoading(true, row.iccid)
                restapi.getMobileSubscriptionDataUsage(that.account.resellerId, that.account.accountId, row.iccid)
                    .then(function (result) {
                        let subscriptionFound = that.subscriptions.filter(function (item) {
                            return item.iccid === row.iccid
                        })
                        if (subscriptionFound && subscriptionFound.length === 1) {
                            subscriptionFound = subscriptionFound[0]
                            delete result.iccid
                            subscriptionFound.dataUsage = result
                            let indexOfIccid = that.subscriptions.indexOf(subscriptionFound)
                            that.$set(that.subscriptions, indexOfIccid, subscriptionFound)
                        }
                        that.setMobileSubscriptionDetailsLoading(false, row.cameraId)
                    })
                    .catch(function (error) {
                        console.error(error)
                        that.setMobileSubscriptionDetailsLoading(false, row.cameraId)
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.getMessageFromError(error, 'GET_MOBILE_SUBSCRIPTION_DATA_USAGE'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                    })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../../../assets/styles/forms';
  @import '../../../../assets/styles/custom';

  .table-head {
    border-bottom-color: var(--v-primary-lighten1) !important;
    border-bottom-width: 2px !important;
  }

  .table-row {
    &:nth-child(even) {
      background-color: #f8f8f8;
    }
    &.expanded {
      background-color: #f5f5f5;
    }
    .table-cell {
      padding: 10px;
      vertical-align: middle;
      font-size: 0.9em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: none !important;
      &:hover {
        cursor: pointer;
      }
      &.camera-status {
        text-align: center;
        color: white;
        .online {
          background-color: $primary;
          padding: 5px;
          border-radius: $border-radius;
        }
        .offline {
          background-color: $color-error-dark;
          padding: 5px;
          border-radius: $border-radius;
        }
      }
    }
  }

  .expanded-area {
    cursor: default;
    .row {
      .col {
        padding: 0;
        span {
          font-size: 12px;
          color: rgb(204, 204, 204);
          img {
            height: 14px;
          }
          &.connectionIcon {
            color: rgb(74, 74, 74);
          }
        }
        p {
          margin-bottom: 5px;
        }
      }
    }
  }

  .grayed-out {
    opacity: .3;
  }

  div.spinner-container {
    position: absolute;
    width: 100%;
    left: 50%;
    opacity: .5;
  }

  .run-out-data p, .run-out-data span {
    color: $color-warning;
  }

  .iccid-icon, .iccid-label {
    display: inline-block;
    vertical-align: middle;
  }

  .iccid-label {
    padding-left: 5px;
  }

  .columns {
    padding: 5px 0;
  }

  .sim-card-image-small {
    width: 16px;
  }

  .no-data-to-show {
    font-size: 12px;
  }

  #mobile-subscriptions-table ::v-deep {
      table {
        &.table {
          tbody > tr {
            box-shadow: none;
            cursor: default;
            td {
              vertical-align: inherit;
              a.dropdown-item {
                padding: 0.75rem 1rem;
                text-align: center;
              }
              a.dropdown-item.dropdown-remove-subscription {
                color: $color-warning;
              }
            }
          }
          td {
            border: none;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          thead th {
            border-bottom: 2px solid $border-blue-light;
            padding: 6px 4px!important;
            .th-wrap {
              color: $primary;
              text-transform: uppercase;
            }
            &.is-current-sort {
              border-bottom-color: $border-blue;
              span.icon {
                i.fa.fa-arrow-up {
                  &:before {
                    content: "\f0d8";
                  }
                }
              }
            }
          }
          th.is-sortable:hover {
            border-color: $primary;
          }

          tbody td {
            border: none;
          }
        }
        &.is-striped {
          tbody > tr {
            &.detail {
              .detail-container {
                margin: 0 0 0 30px;
                .field {
                  margin: 0;
                  position: relative;
                  .has-float-label {
                    p {
                      &.content {
                        font-size: 12px;
                        padding: 5px 0;
                        margin: 0;
                        height: 22px;
                      }
                    }
                    span {
                      position: absolute;
                      top: -10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  #mobile-subscriptions-table .item-edit-options-button {
    background-color: transparent;
    border: none;
    font-weight: bold;
    color: $primary;
  }

  a.dropdown-item {
    color: $primary;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
  }

  td.context-menu-column {
    text-align: right;
  }

  .camera-name-link {
    text-decoration: underline;
  }

</style>
