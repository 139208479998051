'use strict'

function Theme (application) {
    this.application = application || 'Cameramanager'

    this.mapping = {
        'Cameramanager': 'een',
        'Civitas': 'civitas',
        'Pronet Kameram': 'pronet',
        'Live TIM': 'livetim',
        'Zain Camera': 'zaincamera',
        'KC': 'kc',
        'Wilhelmina BabyLive!': 'wilhelminababylive',
        'Deventer Ziekenhuis': 'deventerziekenhuis',
        'Visiocam': 'visiocam',
        'Een Veilig Gevoel': 'eenveiliggevoel',
        'IntelliSurveillance': 'intellisurveillance',
        'Prosegur Cloud Video': 'prosegur',
        'Yourstore': 'yourstore',
        'Easy2Begin': 'easy2begin',
        'Hogeschool Utrecht': 'hogeschoolutrecht',
        'Amphia Babycam': 'amphiababycam',
        'Babycam Diakonessenhuis': 'babycamdiakonnessenhuis',
        'Erasmus MC Sophia Babywatch': 'erasmusmcsophiababywatch',
        'GizmoSmart Cam': 'gizmosmartcam',
        'igoview': 'igoview',
        'Telepet': 'telepet',
        'Alibi Cloud VS': 'alibi',
        'Twelve Camera': 'twelve',
        'NuboCam': 'nubocam',
        'ionodes': 'ionodes',
        'capture': 'capture',
        'invid': 'invid'
    }
    this.themes = {
        een: {
            colors: {
                primary: '#207CAD',
                secondary: '#CCCCCC',
                accent: '#207CAD',
                info: '#207CAD',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#F6F6F6',
                shadow: '#EEEEEE'
            },
            logo: 'een-logo-white.png',
            logoNavigation: 'een-logo.png',
            favicon: 'favicon.ico',
            terms: 'een.txt',
            links: {
                phone: '+31202610461',
                privacyPolicy: 'https://www.eagleeyenetworks.com/privacy-policy/',
                cookies: '/#/cookies',
                terms: '/#/terms',
                apiterms: 'https://developer.cameramanager.com/dp/terms-and-conditions',
                newsletter: true,
                webappSupportAccess: true,
                copyright:true
            }
        },
        pronet: {
            colors: {
                primary: '#EE7413',
                secondary: '#CCCCCC',
                accent: '#EE7413',
                info: '#0B77B4',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#333',
                title: '#EE7413',
                shadow: '#EEEEEE'
            },
            logo: 'pronet-logo.svg',
            logoNavigation: 'pronet-logo.svg',
            favicon: 'favicon-pronet.ico',
            terms: false,
            links: {
                phone: '',
                privacyPolicy: '',
                cookies: '',
                terms: '',
                apiterms: 'https://developer.cameramanager.com/dp/terms-and-conditions',
                newsletter: true,
                webappSupportAccess: true,
                copyright:false
            }
        },
        prosegur: {
            colors: {
                primary: '#EDC201',
                secondary: '#CCCCCC',
                accent: '#EDC201',
                info: '#0B77B4',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#333',
                title: '#EDC201',
                shadow: '#EEEEEE'
            },
            logo: 'Prosegur.svg',
            logoNavigation: 'Prosegur.svg',
            favicon: 'favicon-prosegur.ico',
            terms: false,
            links: {
                phone: '',
                privacyPolicy: '',
                cookies: '',
                terms: '',
                apiterms: 'https://developer.cameramanager.com/dp/terms-and-conditions',
                newsletter: true,
                webappSupportAccess: true,
                copyright:false
            }
        },
        kochar: {
            colors: {
                primary: '#109CCE',
                secondary: '#CCCCCC',
                accent: '#109CCE',
                info: '#0B77B4',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#333',
                title: '#109CCE',
                shadow: '#EEEEEE'
            }
        },
        civitas: {
            colors: {
                primary: '#093371',
                secondary: '#CCCCCC',
                accent: '#093371',
                info: '#093371',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#093371',
                shadow: '#EEEEEE'
            }
        },
        livetim: {
            colors: {
                primary: '#477AAD',
                secondary: '#CCCCCC',
                accent: '#477AAD',
                info: '#477AAD',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#477AAD',
                shadow: '#EEEEEE'
            }
        },
        zaincamera: {
            colors: {
                primary: '#8FD2C5',
                secondary: '#CCCCCC',
                accent: '#8FD2C5',
                info: '#8FD2C5',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#8FD2C5',
                shadow: '#EEEEEE'
            }
        },
        kc: {
            colors: {
                primary: '#C1022C',
                secondary: '#CCCCCC',
                accent: '#C1022C',
                info: '#207CAD',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#C1022C',
                shadow: '#EEEEEE'
            }
        },
        wilhelminababylive: {
            colors: {
                primary: '#00539F',
                secondary: '#CCCCCC',
                accent: '#00539F',
                info: '#00539F',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#00539F',
                shadow: '#EEEEEE'
            }
        },
        deventerziekenhuis: {
            colors: {
                primary: '#CC2773',
                secondary: '#CCCCCC',
                accent: '#CC2773',
                info: '#207CAD',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#CC2773',
                shadow: '#EEEEEE'
            }
        },
        visiocam: {
            colors: {
                primary: '#9D0313',
                secondary: '#CCCCCC',
                accent: '#9D0313',
                info: '#207CAD',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#9D0313',
                shadow: '#EEEEEE'
            }
        },
        eenveiliggevoel: {
            colors: {
                primary: '#65B417',
                secondary: '#CCCCCC',
                accent: '#65B417',
                info: '#207CAD',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#65B417',
                shadow: '#EEEEEE'
            }
        },
        intellisurveillance: {
            colors: {
                primary: '#3BB8FF',
                secondary: '#CCCCCC',
                accent: '#3BB8FF',
                info: '#3BB8FF',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#3BB8FF',
                shadow: '#EEEEEE'
            }
        },
        yourstore: {
            colors: {
                primary: '#9DD4E9',
                secondary: '#CCCCCC',
                accent: '#9DD4E9',
                info: '#9DD4E9',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#9DD4E9',
                shadow: '#EEEEEE'
            }
        },
        easy2begin: {
            colors: {
                primary: '#AFD253',
                secondary: '#CCCCCC',
                accent: '#AFD253',
                info: '#207CAD',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#AFD253',
                shadow: '#EEEEEE'
            }
        },
        hogeschoolutrecht: {
            colors: {
                primary: '#ED0010',
                secondary: '#CCCCCC',
                accent: '#ED0010',
                info: '#207CAD',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#ED0010',
                shadow: '#EEEEEE'
            }
        },
        amphiababycam: {
            colors: {
                primary: '#225D8A',
                secondary: '#CCCCCC',
                accent: '#225D8A',
                info: '#225D8A',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#225D8A',
                shadow: '#EEEEEE'
            }
        },
        babycamdiakonnesenhuis: {
            colors: {
                primary: '#083773',
                secondary: '#CCCCCC',
                accent: '#083773',
                info: '#083773',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#083773',
                shadow: '#EEEEEE'
            }
        },
        erasmusmcsophiababywatch: {
            colors: {
                primary: '#0C2074',
                secondary: '#CCCCCC',
                accent: '#0C2074',
                info: '#0C2074',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#0C2074',
                shadow: '#EEEEEE'
            }
        },
        gizmosmartcam: {
            colors: {
                primary: '#109CCE',
                secondary: '#CCCCCC',
                accent: '#109CCE',
                info: '#109CCE',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#109CCE',
                shadow: '#EEEEEE'
            }
        },
        igoview: {
            colors: {
                primary: '#207CAD',
                secondary: '#CCCCCC',
                accent: '#207CAD',
                info: '#207CAD',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#A1C84F',
                shadow: '#EEEEEE'
            }
        },
        telepet: {
            colors: {
                primary: '#791D72',
                secondary: '#CCCCCC',
                accent: '#791D72',
                info: '#791D72',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#791D72',
                shadow: '#EEEEEE'
            }
        },
        alibi: {
            colors: {
                primary: '#00A7CF',
                secondary: '#CCCCCC',
                accent: '#00A7CF',
                info: '#00A7CF',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#00A7CF',
                shadow: '#EEEEEE'
            },
            logo: 'alibi-logo-white.png',
            logoNavigation: 'alibi-logo-white.png',
            favicon: 'favicon-alibi.ico',
            terms: false,
            links: {
                phone: '+18888581442',
                privacyPolicy: '',
                cookies: '',
                terms: '',
                webapp: 'https://cloudvslogin.alibisecurity.com',
                downloadportal:'https://alibi.downloadportal.cameramanager.com',
                myaccount:'https://cloudvsaccount.alibisecurity.com',
                dealerdashboard:'http://cloudvsdashboard.alibisecurity.com/',
                newsletter: false,
                webappSupportAccess: true,
                apiterms: 'https://developer.cameramanager.com/dp/terms-and-conditions',
                AppSwitcher:false,
                copyright:false
            }
        },
        twelve: {
            colors: {
                primary: '#190078',
                secondary: '#CCCCCC',
                accent: '#190078',
                info: '#190078',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#190078',
                shadow: '#EEEEEE'
            },
            logo: 'twelve-logo-white.svg',
            logoNavigation: 'twelve-logo.svg',
            favicon: 'favicon-twelve.ico',
            terms: false,
            links: {
                phone: '',
                privacyPolicy: '',
                cookies: '',
                terms: '',
                apiterms: 'https://developer.cameramanager.com/dp/terms-and-conditions',
                newsletter: true,
                webappSupportAccess: true,
                copyright:false
            }
        },
        nubocam: {
            colors: {
                primary: '#3a3d51',
                secondary: '#CCCCCC',
                accent: '#3a3d51',
                info: '#0B77B4',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#333',
                title: '#3a3d51',
                shadow: '#EEEEEE',
            },
            logo: 'nubo-logo-white.svg',
            logoNavigation: 'nubo-logo-purple.svg',
            favicon: 'favicon-nubocam.ico',
            terms: false,
            links: {
                phone: '',
                privacyPolicy: '',
                cookies: '',
                terms: '',
                newsletter: true,
                webappSupportAccess: true,
                copyright: false
            }
        },
        capture: {
            colors: {
                primary: '#207CAD',
                secondary: '#CCCCCC',
                accent: '#207CAD',
                info: '#207CAD',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#F6F6F6',
                shadow: '#EEEEEE'
            },
            logo: 'capture.png',
            logoNavigation: 'capture.png',
            favicon: 'capture.ico',
            terms: false,
            links: {
                phone: '+1 (512)301-9710',
                privacyPolicy: 'https://www.eagleeyenetworks.com/privacy-policy/',
                cookies: '',
                terms: 'https://myaccount.capturecloudcm.com/#/aditerms',
                apiterms: 'https://developer.cameramanager.com/dp/terms-and-conditions',
                newsletter: true,
                webappSupportAccess: true,
                AppSwitcher:false,
                copyright: false
            }
        },
        ionodes: {
            colors: {
                primary: '#0a7890',
                secondary: '#CCCCCC',
                accent: '#0a7890',
                info: '#0B77B4',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#333',
                title: '#0a7890',
                shadow: '#EEEEEE',
            },
            logo: 'ionodes.png',
            logoNavigation: 'ionodes.png',
            favicon: 'ionodes-favicon.ico',
            terms: true,
            links: {
                phone: '',
                privacyPolicy: 'https://www.ionodes.com/main/privacy/',
                cookies: 'https://www.ionodes.com/main/cookies/',
                terms: 'https://www.ionodes.com/main/terms/',
                apiterms: 'https://developer.cameramanager.com/dp/terms-and-conditions',
                newsletter: true,
                webappSupportAccess: true,
                copyright: false,
                AppSwitcher:false,
            }
        },
        invid: {
            colors: {
                primary: '#061f39',
                secondary: '#190078',
                accent: '#F79422',
                info: '#F79422',
                success: '#4CAF50',
                warning: '#D43536',
                error: '#D43536',
                text: '#555',
                title: '#F6F6F6',
                shadow: '#EEEEEE',
            },
            companyName: 'InVid Tech',
            logo: 'invid-logo.png',
            logoNavigation: 'invid-logo.png',
            logoLogin: 'invid-logo.png',
            favicon: 'favicon-invid.ico',
            links: {
                phone: '',
                privacyPolicy: 'https://info.invidtech.com/privacy-policy',
                cookies: 'https://info.invidtech.com/cookie-website-policy',
                terms: 'https://info.invidtech.com/terms-conditions-invid-cloud-view',
                apiterms: 'https://developer.cameramanager.com/dp/terms-and-conditions',
                newsletter: true,
                webappSupportAccess: true,
                copyright: false,
                AppSwitcher:false,
            },
        },
    }
}

Theme.prototype.shadeColor = function (color, percent) {
    var R = parseInt(color.substring(1, 3), 16)
    var G = parseInt(color.substring(3, 5), 16)
    var B = parseInt(color.substring(5, 7), 16)

    R = parseInt(R * (100 + percent) / 100)
    G = parseInt(G * (100 + percent) / 100)
    B = parseInt(B * (100 + percent) / 100)

    R = (R < 255) ? R : 255
    G = (G < 255) ? G : 255
    B = (B < 255) ? B : 255

    var RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16))
    var GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16))
    var BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16))

    return '#' + RR + GG + BB
}

Theme.prototype.colors = function () {
    const mapping = this.mapping.hasOwnProperty(this.application) ? this.mapping[this.application] : 'een'
    let colors = this.themes.hasOwnProperty(mapping) ? this.themes[mapping].colors : this.themes.een.colors
    colors.lightprimary = this.shadeColor(colors.primary, 21)
    return { themes: { light: colors }, options: { customProperties: true }  }
}

Theme.prototype.logo = function (type = '') {
    const mapping = this.mapping.hasOwnProperty(this.application) ? this.mapping[this.application] : 'een'
    return this.themes.hasOwnProperty(mapping) ? (this.themes[mapping].hasOwnProperty(`logo${type}`) ? this.themes[mapping][`logo${type}`] : null) : null
}

Theme.prototype.favicon = function (type = '') {
    const mapping = this.mapping.hasOwnProperty(this.application) ? this.mapping[this.application] : 'een'
    return this.themes.hasOwnProperty(mapping) ? (this.themes[mapping].hasOwnProperty(`favicon${type}`) ? this.themes[mapping][`favicon${type}`] : null) : null
}

Theme.prototype.terms = function (type = '') {
    const mapping = this.mapping.hasOwnProperty(this.application) ? this.mapping[this.application] : 'een'
    return this.themes.hasOwnProperty(mapping) ? (this.themes[mapping].hasOwnProperty(`terms${type}`) ? this.themes[mapping][`terms${type}`] : null) : null
}

Theme.prototype.links = function (type = '') {
    const mapping = this.mapping.hasOwnProperty(this.application) ? this.mapping[this.application] : 'een'
    return this.themes.hasOwnProperty(mapping) ? (this.themes[mapping].hasOwnProperty(`links${type}`) ? this.themes[mapping][`links${type}`] : null) : null
}

export default Theme
