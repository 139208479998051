<template>
  <div>
    <v-app-bar
      fixed
      extended
      height="64px"
      :extension-height="isEEN ? 12 : 0"
      color="primary"
      elevation="0"
      class="z-200"
      dark
    >
      <router-link
        v-if="!(isSearching && smallWidth)"
        to="/"
        class="mr-md-5 mr-sm-0"
      >
        <v-img
          id="eagle-eye-networks-logo"
          contain
          max-width="180"
          max-height="50px"
          style="box-sizing: border-box; padding: 5px;"
          class="img-responsive block"
          :src="logo"
          alt="Eagle Eye Networks logo"
        />
      </router-link>
      <search
        v-if="isAuthorized"
        :placeholder="smallWidth ? '' : $t('Search')"
        class="search-bar"
        :class="isEEN ? 'pt-10' : 'pt-5'"
        @focus="(val) => isSearching = val"
      />
      <v-spacer class="hidden-sm-and-down" />
      <v-toolbar-items
        v-if="isAuthorized"
        class="hidden-sm-and-down"
      >
        <div :class="isEEN ? 'pt-5' : 'pt-4'">
          <router-link
            id="management-menu-item"
            class="is-link"
            :class="{'is-selected': isSelected('management')}"
            :to="{path: '/'}"
          >
            <v-btn
              :ripple="false"
              text
            >
              <span
                class="font-weight-bold"
                :class="{ 'isSelected' : isSelected('management') }"
              >{{ $t('Management') }}</span>
            </v-btn>
          </router-link>
        </div>
        <div
          v-if="hasInsightsRights()"
          :class="isEEN ? 'pt-5' : 'pt-4'"
        >
          <v-menu
            bottom
            offset-y
          >
            <template #activator="{ on }">
              <v-btn
                text
                :ripple="false"
                v-on="on"
              >
                <span
                  class="font-weight-bold"
                  :class="{ 'isSelected' : isSelected('insights') }"
                >{{ $t('Insights') }}</span>
                <v-icon
                  class="ml-2"
                  size="1em"
                  color="white"
                  aria-hidden="true"
                >
                  fa fa-caret-down
                </v-icon>
              </v-btn>
            </template>
            <v-list class="blue-menu">
              <router-link
                id="insights-reports-menu-item"
                class="hover-highlight"
                :to="{path: '/insights/reports'}"
              >
                <v-list-item class="hover-highlight">
                  <v-list-item-title class="white--text">
                    {{ $t('Reports') }}
                  </v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list>
          </v-menu>
        </div>
        <div
          v-if="hasPartnerRights()"
          :class="isEEN ? 'pt-5' : 'pt-4'"
        >
          <v-menu
            bottom
            offset-y
          >
            <template #activator="{ on }">
              <v-btn
                text
                :ripple="false"
                v-on="on"
              >
                <span
                  class="font-weight-bold"
                  :class="{ 'isSelected' : isSelected('my-account') }"
                >{{ $t('Partner account') }}</span>
                <v-icon
                  class="ml-2"
                  size="1em"
                  color="white"
                  aria-hidden="true"
                >
                  fa fa-caret-down
                </v-icon>
              </v-btn>
            </template>
            <v-list class="blue-menu">
              <router-link
                v-if="hasPermission('AccountEdit')"
                id="my-details-popup-menu-item"
                class="hover-highlight"
                :to="{path: '/my-account/my-details'}"
              >
                <v-list-item class="hover-highlight">
                  <v-list-item-title class="white--text">
                    {{ $t('My details') }}
                  </v-list-item-title>
                </v-list-item>
              </router-link>
              <router-link
                v-if="hasPermission('AccountLogins')"
                id="partner-admins-popup-menu-item"
                class="hover-highlight"
                :to="{path: '/my-account/partner-admins'}"
              >
                <v-list-item class="hover-highlight">
                  <v-list-item-title class="white--text">
                    {{ $t('Operators') }}
                  </v-list-item-title>
                </v-list-item>
              </router-link>
              <router-link
                v-if="hasPermission('AccountProfiles')"
                id="partner-roles-popup-menu-item"
                class="hover-highlight"
                :to="{path: '/my-account/partner-roles'}"
              >
                <v-list-item class="hover-highlight">
                  <v-list-item-title class="white--text">
                    {{ $t('Roles') }}
                  </v-list-item-title>
                </v-list-item>
              </router-link>
              <router-link
                v-if="hasPermission('ManageOauthClient')"
                id="api-keys-popup-menu-item"
                class="hover-highlight"
                :to="{path: '/my-account/api-keys'}"
              >
                <v-list-item class="hover-highlight">
                  <v-list-item-title class="white--text">
                    {{ $t('Api Keys') }}
                  </v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list>
          </v-menu>
        </div>
        <div
          v-if="isEEN"
          :class="isEEN ? 'pt-4' : 'pt-2'"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                class="mx-2"
                icon
                v-on="on"
                @click="openChat"
              >
                <v-icon dark>
                  fa-headset
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Chat with support") }}</span>
          </v-tooltip>
        </div>
        <div :class="isEEN ? 'pt-4' : 'pt-2'">
       
        </div>
        <div :class="isEEN ? 'pt-4' : 'pt-2'">
          <my-account-menu />
        </div>
      </v-toolbar-items>
      <div
        v-if="isAuthorized"
        class="hidden-md-and-up"
      >
        <v-menu
          v-model="isMenuOpen"
          class="mobile-menu"
          nudge-bottom="10px"
          max-width="100%"
          max-height="90%"
          bottom
          offset-y
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-btn
              :class="{ 'mt-4': isEEN }"
              :ripple="false"
              text
              icon
              v-on="on"
            >
              <v-icon>fas fa-bars</v-icon>
            </v-btn>
          </template>
          <v-list class="mobile-menu">
            <v-list-item>
              <v-list-item-title>
                <router-link
                  id="management-menu-item"
                  class="is-link"
                  :class="{'is-selected': isSelected('management')}"
                  :to="{path: '/'}"
                >
                  <v-btn
                    :ripple="false"
                    text
                    @click="isMenuOpen = false"
                  >
                    <span
                      class="primary--text font-weight-bold"
                      :class="{ 'isSelected' : isSelected('management') }"
                    >{{ $t('Management') }}</span>
                  </v-btn>
                </router-link>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="hasInsightsRights()">
              <v-list-item-title>
                <v-menu
                  :close-on-content-click="true"
                  bottom
                  offset-y
                >
                  <template #activator="{ on }">
                    <v-btn
                      text
                      :ripple="false"
                      v-on="on"
                    >
                      <span
                        class="primary--text font-weight-bold"
                        :class="{ 'isSelected' : isSelected('insights') }"
                      >{{ $t('Insights') }}</span>
                      <v-icon
                        class="ml-2"
                        size="1em"
                        color="white"
                        aria-hidden="true"
                      >
                        fa fa-caret-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list class="blue-menu">
                    <router-link
                      id="insights-reports-menu-item"
                      class="hover-highlight"
                      :to="{path: '/insights/reports'}"
                    >
                      <v-list-item
                        class="hover-highlight"
                        @click="isMenuOpen = false"
                      >
                        <v-list-item-title class="white--text">
                          {{ $t('Reports') }}
                        </v-list-item-title>
                      </v-list-item>
                    </router-link>
                  </v-list>
                </v-menu>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="hasPartnerRights()">
              <v-list-item-title>
                <v-menu
                  :close-on-content-click="true"
                  bottom
                  offset-y
                >
                  <template #activator="{ on }">
                    <v-btn
                      text
                      :ripple="false"
                      v-on="on"
                    >
                      <span
                        class="primary--text font-weight-bold"
                        :class="{ 'isSelected' : isSelected('my-account') }"
                      >{{ $t('Partner account') }}</span>
                      <v-icon
                        class="ml-2"
                        size="1em"
                        color="white"
                        aria-hidden="true"
                      >
                        fa fa-caret-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list class="blue-menu">
                    <router-link
                      v-if="hasPermission('AccountEdit')"
                      id="my-details-popup-menu-item"
                      class="hover-highlight"
                      :to="{path: '/my-account/my-details'}"
                    >
                      <v-list-item
                        class="hover-highlight"
                        @click="isMenuOpen = false"
                      >
                        <v-list-item-title class="white--text">
                          {{ $t('My details') }}
                        </v-list-item-title>
                      </v-list-item>
                    </router-link>
                    <router-link
                      v-if="hasPermission('AccountLogins')"
                      id="partner-admins-popup-menu-item"
                      class="hover-highlight"
                      :to="{path: '/my-account/partner-admins'}"
                    >
                      <v-list-item
                        class="hover-highlight"
                        @click="isMenuOpen = false"
                      >
                        <v-list-item-title class="white--text">
                          {{ $t('Operators') }}
                        </v-list-item-title>
                      </v-list-item>
                    </router-link>
                    <router-link
                      v-if="hasPermission('AccountProfiles')"
                      id="partner-roles-popup-menu-item"
                      class="hover-highlight"
                      :to="{path: '/my-account/partner-roles'}"
                    >
                      <v-list-item
                        class="hover-highlight"
                        @click="isMenuOpen = false"
                      >
                        <v-list-item-title class="white--text">
                          {{ $t('Roles') }}
                        </v-list-item-title>
                      </v-list-item>
                    </router-link>
                    <router-link
                      v-if="hasPermission('ManageOauthClient')"
                      id="api-keys-popup-menu-item"
                      class="hover-highlight"
                      :to="{path: '/my-account/api-keys'}"
                    >
                      <v-list-item
                        class="hover-highlight"
                        @click="isMenuOpen = false"
                      >
                        <v-list-item-title class="white--text">
                          {{ $t('Api Keys') }}
                        </v-list-item-title>
                      </v-list-item>
                    </router-link>
                  </v-list>
                </v-menu>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  text
                  :ripple="false"
                  @click="isMenuOpen = false; logout()"
                >
                  <span class="primary--text font-weight-bold">{{ $t('logout') }}</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import Search from '@/components/base/Search'
import MyAccountMenu from '@/components/menu/MyAccountMenu'
import { getConfiguration } from '@/service/main'
import Theme from '@/service/themes'

const auth = require('@/service/auth')

export default {
    name: 'Navigation',
    components: {
        Search,
        MyAccountMenu,
    },
    props: {
        logo: {
            type: String
        },
    },
    data () {
        return {
            showSearch: false,
            name: 'Your name',
            email: 'someone@cameramanager.com',
            isMenuOpen: false,
            isSearching: false,
            windowWidth: 0,
        }
    },
    computed: {
        isEEN () {
            return config.theme === 'Cameramanager'
        },
        showSubMenu () {
            return this.isSelected('insights') || this.isSelected('my-account')
        },
        isAuthorized () {
            return this.$store.getters.isAuthorized
        },
        isReseller () {
            return auth.isReseller()
        },
        isDistributor () {
            return auth.isDistributor()
        },
        isAdmin () {
            return auth.isAdmin()
        },
        burgerMenuActive: {
            get () {
                return this.$store.getters.burgerMenuActive
            },
            set (active) {
                this.$store.commit('burgerMenuActive', !!active)
            }
        },
        smallWidth () {
            return this.windowWidth < 960
        }
    },
    watch: {
        showSearch () {
            this.burgerMenuActive = false
            document.documentElement.classList.toggle('is-clipped', this.showSearch)
            if (this.showSearch) window.setTimeout(function () { document.getElementById('searchInputField').focus() }, 0)
        }
    },
    mounted () {
        this.windowWidth = window.innerWidth
        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }
        const config = getConfiguration()
        const themeName = config.hasOwnProperty('theme') ? config.theme : 'Cameramanager'
        const theme = new Theme(themeName)
    },
    methods: {
        isSelected (item) {
            return this.$route.meta.topMenu && this.$route.meta.topMenu.indexOf(item) !== -1
        },
        hasInsightsRights () {
            return auth.isReseller() || this.hasPermission('InsightsReports')
        },
        hasPartnerRights () {
            return this.hasPermission('AccountEdit') || this.hasPermission('AccountLogins') || this.hasPermission('AccountProfiles') || this.hasPermission('ManageOauthClient')
        },
        openChat() {
            $zoho.salesiq.visitor.name(this.$store.getters.myAccount ? this.$store.getters.myAccount.email.split('@')[0] : null)
            $zoho.salesiq.visitor.email(this.$store.getters.myAccount ? this.$store.getters.myAccount.email : null)   
            $zoho.salesiq.floatwindow.open()
        },
        logout () {
            this.$router.push('/logout')
        },
    }
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";
  @import "../assets/styles/custom";

  .mobile-menu {
    left: 0 !important;
    height: 100% !important;
    width: 100vw !important;
  }

  .hover-highlight {
    &:hover {
      background-color: white !important;
      .v-list-item__title {
        color: var(--v-primary-base) !important;
      }
    }
  }

  .isSelected {
    padding-bottom: 2px;
    border-bottom: 2px solid white;
  }

  .v-app-bar {
    background: -webkit-linear-gradient(to left, var(--v-primary-lighten2),  var(--v-primary-base));
    background: linear-gradient(to left, var(--v-primary-lighten2),  var(--v-primary-base));
  }

  a {
    text-decoration: none;
  }

  .z-200 {
    z-index: 200 !important;
  }

</style>
