<template>
  <section
    id="breadcrumb"
    class="section breadcrumb"
  >
    <div
      v-show="isVisible"
      class="mt-0"
    >
      <span
        v-for="(crumb, index) in crumbs"
        :key="`crumb-${index}`"
        class="crumb"
        :title="crumb.name"
      >
        <router-link :to="{ path: crumb.route }">{{ crumb.name }}</router-link>
      </span>
    </div>
  </section>
</template>

<script>
export default {
    name: 'Breadcrumb',
    computed: {
        isVisible () {
            return this.$store.getters.crumbs instanceof Array && this.$store.getters.crumbs.length > 1
        },
        crumbs () {
            return this.$store.getters.crumbs
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/main';

  .breadcrumb {
    padding: 0;
    font-size: 11px;
    position: fixed;
    margin: 0 auto;
    left: 220px;
    right: 0;
    z-index: 10;
    div {
      width: fit-content;
      margin: 5px auto;
      padding: 5px;
      border: 0;
      border-radius: 0 0 4px 4px;
      background-color: white;
      color: $primary;
      text-transform: uppercase;
      span {
        a {
          font-weight: normal;
          text-align: center;
          justify-content: inherit;
          align-items: inherit;
          display: inline-block;
          max-width: 350px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 5px 0 10px;
        }
        &:not(:first-child):before {
          content: '>';
          position: fixed;
        }
        &:last-child {
          a {
            font-weight: 600;
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      display: none;
      margin-bottom: -40px;
    }
  }
</style>
