<template>
  <div class="content-wrapper">
    <div class="content">
      <div class="container">
        <h2>{{ $t('You are already logged in with an end user account. To sign out as end user and sign in to the Dealer Dashboard, click ') }} <a @click="logout">{{ $t('here') }}</a>.</h2>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/service/auth'

export default {
    beforeMount () {
        if (!auth.isLoggedIntoOtherApp()) {
            this.$router.push('/')
        }
    },
    methods: {
        logout() {
            auth.logout()
        }
    },
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";

  .content-wrapper {
    .content {
      width: 100%;
      background-color: $color-shadow-light;
      .container {
        position: relative;
        padding-top: 40px;
        padding-bottom: 20px;
        width: 90%;
        min-width: 90%;
      }
    }
  }
</style>
