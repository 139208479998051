<template>
  <v-row
    class="px-10"
    align="center"
  >
    <v-col>
      <p class="mb-0 font-weight-bold">
        {{ $t(permission.name) }}
      </p>
      <p class="mb-0">
        {{ $t(permission.name + 'Description') }}
      </p>
    </v-col>
    <v-col
      class="ma-0 pa-0"
      cols="auto"
    >
      <v-switch
        v-model="right"
        color="primary"
        :name="permission.name"
        :disabled="disabled"
        inset
        dense
      >
        {{ $t(permission.name) }}>
      </v-switch>
    </v-col>
  </v-row>
</template>

<script>
export default {
    name: 'ProfilePermission',
    props: ['permissions', 'permission', 'partnerId', 'profileId'],
    computed: {
        right: {
            get () {
                return this.permission.value
                // return this.permissions.hasOwnProperty(`Prov.${this.permission}`) ? this.permissions[`Prov.${this.permission}`] : false
                // if (!this.permissions || this.permissions.length === 0) return false
                // return this.permissions.indexOf(this.permission) >= 0
            },
            set () {
                this.$emit('change')
            }
        },
        disabled () {
            return this.$store.getters.myAccount.profile.profileId === this.profileId
        },
        description () {
            return this.permission + 'Description'
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
