<template>
  <div class="content-wrapper">
    <side-bar>
      <side-bar-item
        v-if="isAdmin() && !isBillableEntity"
        name="back"
        class="pt-1 pb-1"
        :text="$t('Back')"
        icon="fa fa-arrow-circle-left"
        icon-size="32"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="isPartnerActive && isBillableEntity && hasPermission('PartnerAdd')"
        name="add-reseller"
        class="pt-1 pb-1"
        :text="$t('Add reseller')"
        icon-size="32"
        icon="fa fa-plus-circle"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="isPartnerActive && hasPermission('PartnerOverview')"
        name="resellers"
        :text="$t('Resellers')"
        icon="fa fa-th"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="!isMine"
        name="partner-details"
        :text="$t('Details')"
        icon="fa fa-id-card"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="isPartnerActive && !isMine && hasPermission('AccountLogins')"
        name="partner-admins"
        :text="$t('Operators')"
        icon="fa fa-user-plus"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="isPartnerActive && !isMine && hasPermission('AccountProfiles')"
        name="partner-roles"
        :text="$t('Roles')"
        icon="fa fa-align-left"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="isPartnerActive && hasPermission('ManageOauthClient') && !isMine"
        name="api-keys"
        :text="$t('Api keys')"
        icon="fa fa-key"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
    </side-bar>
    <div class="content">
      <breadcrumb />
      <div
        class="container"
        :class="selectedMenu"
      >
        <section
          v-if="distributor && !distributor.active"
          class="section notification-section"
        >
          <div v-if="menuSelected('partner-details')">
            <div
              class="user-notification"
              v-html="$t('This partner is inactive', {link: getLinkToDetailsPage() })"
            />
          </div>
          <div v-else>
            <div
              class="user-notification"
              v-html="$t('This partner is inactive - link', {link: getLinkToDetailsPage() })"
            />
          </div>
        </section>
        <add-reseller
          v-if="hasPermission('PartnerAdd') && menuSelected('add-reseller')"
          :partner-id="partnerId"
          :zoho-id="zohoId"
          @created="createdReseller"
        />
        <partners
          v-if="hasPermission('PartnerOverview') && menuSelected('resellers')"
          id="reseller-list"
          :title="$t('Choose a reseller')"
          :partners="resellers"
          partner-type="Reseller"
          :initial-sorting="partnerSorting.sorting"
          :initial-sort-order="partnerSorting.order"
          @sort="changePartnerSorting"
        />
        <partner-details
          v-if="hasPermission('PartnerOverview') && menuSelected('partner-details')"
          id="distributor-details"
          :partner="distributor"
          partner-type="Distributor"
          :reseller-count="resellerCount"
          @partnerDeactivated="partnerDeactivated"
          @partnerReactivated="partnerReactivated"
        />
        <partner-logins
          v-if="hasPermission('AccountLogins') && menuSelected('partner-admins')"
          id="partner-logins"
          :partner-id="partnerId"
        />
        <partner-profiles
          v-if="hasPermission('AccountProfiles') && menuSelected('partner-roles')"
          id="partner-profiles"
          :partner-id="partnerId"
        />
        <api-keys
          v-if="hasPermission('ManageOauthClient') && menuSelected('api-keys')"
          id="api-keys"
          :partner="distributor"
          :partner-id="partnerId"
        />
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
import AddReseller from '@/components/partner/AddReseller'
import Partners from '@/components/Partners'
import PartnerDetails from '@/components/partner/PartnerDetails'
import PartnerLogins from '@/components/partner/PartnerLogins'
import PartnerProfiles from '@/components/partner/PartnerProfiles'
import ApiKeys from '@/components/partner/ApiKeys'
import SideBar from '@/components/base/SideBar'
import SideBarItem from '@/components/base/SideBarItem'

const auth = require('@/service/auth')
const restapi = require('@/service/restapi')

export default {
    name: 'Distributor',
    components: {
        AddReseller,
        Partners,
        PartnerDetails,
        PartnerLogins,
        PartnerProfiles,
        ApiKeys,
        SideBar,
        SideBarItem
    },
    data () {
        return {
            selectedMenu: null,
            defaultMenu: 'resellers',
            customersCount: null,
            count: null,
            resellers: [],
            distributor: null,
            partnerSorting: {
                sorting: null,
                order: null
            }
        }
    },
    computed: {
        partnerId () {
            return this.$route.params.id
        },
        zohoId () {
            return this.$route.params.zohoid
        },
        isMine () {
            return this.partnerId === this.$store.getters.myAccount.accountId.toString()
        },
        isPartnerActive () {
            return this.distributor && this.distributor.active
        },
        isBillableEntity () {
            return this.distributor ? this.distributor.crmId !== null : null
        },
        resellerCount () {
            if (this.count) {
                return this.count.resellerCount
            }
            return null
        }
    },
    watch: {
        partnerId () {
            this.checkPermission()
            this.loadDistributor()
            this.loadResellers()
        },
        resellers () {
            let _resellers = this.resellers
            if (_resellers && Array.isArray(_resellers)) {
                for (let i = 0; i < _resellers.length; i++) {
                    let reseller = _resellers[i]
                    if (this.$store.getters.resellerCount[reseller.resellerId] &&
              this.$store.getters.resellerCount[reseller.resellerId].expires > Date.now()) {
                        reseller.count = this.$store.getters.resellerCount[reseller.resellerId].count
                    } else {
                        const that = this
                        restapi.getResellerCount(reseller.resellerId)
                            .then(function (count) {
                                reseller.count = count
                                that.$store.commit('resellerCount', {id: reseller.resellerId, count: count})
                            })
                            .catch(function (error) {
                                console.error(error)
                            })
                    }
                }
            }
        },
        distributor () {
            if (this.distributor && !this.distributor.active) {
                this.selectMenu('partner-details')
            }
            this.setBreadCrumbs()
        },
        '$route' (to, from) {
            this.selectedMenu = this.$route.params.menu || this.defaultMenu
            if (this.distributor && !this.distributor.active && this.selectedMenu !== 'partner-details') {
                this.selectMenu('partner-details')
            }
        },
        selectedMenu () {
            if (this.partnerId) {
                this.checkPermission()
                this.loadDistributor()
                this.loadResellers()
            }
        }
    },
    mounted () {
        if (!this.$route.params.menu) {
            this.$router.replace({path: '/distributor/' + this.partnerId + '/' + this.defaultMenu})
        } else {
            this.selectedMenu = this.$route.params.menu
        }
        this.partnerSorting = this.$cookies.get('partnerSorting') || { sorting: null, order: null }
        this.$cookies.set('partnerSorting', this.partnerSorting, '1y')
    },
    methods: {
        partnerDeactivated () {
        },
        partnerReactivated () {
        },
        getLinkToDetailsPage () {
            return `/#/distributor/${this.partnerId}/partner-details`
        },
        selectMenu (item) {
            if (item === 'back') {
                this.$router.push({path: '/admin/distributors'})
            } else {
                this.$router.push({path: `/distributor/${this.partnerId}/${item}`})
            }
        },
        menuSelected (item) {
            return item === this.selectedMenu
        },
        isAdmin () {
            return auth.isAdmin()
        },
        loadResellers () {
            let _resellers = []
            const that = this
            restapi.getResellers(that.partnerId)
                .then(function (result) {
                    let resellers = result
                    for (let i = 0; i < resellers.length; i++) {
                        let reseller = resellers[i]
                        reseller.count = {
                            cameraCount: 0,
                            customerCount: 0
                        }
                        _resellers.push(reseller)
                    }
                    that.resellers = _resellers
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        loadDistributor () {
            const that = this
            if (that.partnerId) {
                that.distributor = null
                restapi.getDistributor(that.partnerId)
                    .then(function (result) {
                        that.distributor = result
                        if (result.active) {
                            restapi.getDistributorCount(that.partnerId)
                                .then(function (count) {
                                    that.count = count
                                })
                                .catch(function (error) {
                                    console.error(error)
                                })
                        }
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
            }
        },
        createdReseller () {
            this.selectMenu(this.defaultMenu)
        },
        checkPermission () {
            if (!auth.isAdmin()) {
                if (auth.isDistributor() && parseInt(this.partnerId) !== auth.getPartnerId()) {
                    this.$router.push({path: '/distributor/' + auth.getPartnerId()})
                } else if (auth.isReseller()) {
                    this.$router.push({path: '/reseller/' + auth.getPartnerId()})
                }
            }
        },
        setBreadCrumbs () {
            let crumbs = []
            if (auth.isAdmin()) {
                let crumb = {route: '/', name: 'Distributors'}
                crumbs.push(crumb)
            }
            if (this.distributor) {
                let crumb = {route: '/distributor/' + this.distributor.distributorId, name: this.distributor.companyName}
                crumbs.push(crumb)
            }
            this.$store.commit('crumbs', crumbs)
        },
        changePartnerSorting (sorting, order) {
            this.partnerSorting.sorting = sorting
            this.partnerSorting.order = order
            this.$cookies.set('partnerSorting', this.partnerSorting, '1y')
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";

  .content-wrapper {
    .content {
      width: 100%;
      background-color: $color-shadow-light;
      .container {
        width: 90%;
        min-width: 90%;
        .notification-section {
          margin: 0;
          padding: 0;
          text-align: center;
          .user-notification {
            color: #fff;
            background: #D43536;
            border-radius: 2em;
            align-self: center;
            display: inline-block;
            margin: 0;
            text-align: center;
            padding: 0.75em 1.5em;
            pointer-events: auto;
            ::v-deep a {
              color: #ffffff;
              text-decoration: underline;
            }
          }
        }
        @media screen and (min-width: 769px) {
          &.add-reseller {
            width: 900px;
            min-width: 900px;
          }
          &.resellers {
            width: 90%;
            min-width: 90%;
          }
          &.partner-details {
            width: 900px;
            min-width: 900px;
          }
          &.partner-admins {
            width: 90%;
            min-width: 90%;
          }
          &.partner-roles {
            width: 90%;
            min-width: 90%;
          }
        }
      }
    }
  }
</style>
