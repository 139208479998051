<template>
  <div
    v-if="hasPermission('CustomerBlock')"
    id="customer-block"
    class="customer-block"
    :data-is-blocked="blocked"
  >
    <h2 class="component-title">
      {{ $t('Block login') }}<span v-if="blocked"> - {{ $t('Blocked') }}</span>
    </h2>
    <p class="component-description">
      {{ $t('Block login description') }}
    </p>
    <v-btn
      v-if="blocked"
      large
      color="primary"
      :disabled="!active"
      @click="showUnblockUserLoginPopup()"
    >
      {{ $t('Unblock user login') }}
    </v-btn>
    <v-btn
      v-else
      large
      color="primary"
      :disabled="!active"
      @click="showBlockUserLoginPopup()"
    >
      {{ $t('Block user login') }}
    </v-btn>
    <v-dialog
      id="block-user-modal"
      v-model="isBlockUserLoginPopupActive"
      class="support-element-popup"
      width="600px"
      :active.sync="isBlockUserLoginPopupActive"
      @close="blockUserLoginPopupClosed()"
    >
      <v-card>
        <v-form @submit.prevent="block">
          <v-card-title>
            {{ $t('Block user login') }}
          </v-card-title>
          <v-card-text>
            <p> {{ $t('Are you sure you want to block user', { email: email }) }} </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              large
              outlined
              class="mr-2"
              color="primary"
              type="button"
              @click="blockUserLoginPopupClosed()"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              id="block-user-button"
              large
              color="primary"
              type="submit"
            >
              {{ $t('Block user') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      id="unblock-user-modal"
      v-model="isUnblockUserLoginPopupActive"
      class="support-element-popup"
      width="600px"
      :active.sync="isUnblockUserLoginPopupActive"
      @close="unblockUserLoginPopupClosed()"
    >
      <v-card>
        <v-form @submit.prevent="unblock">
          <v-card-title>
            {{ $t('Un-block user login') }}
          </v-card-title>
          <v-card-text>
            <p> {{ $t('Are you sure you want to unblock user', { email: email }) }} </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              large
              outlined
              class="mr-2"
              color="primary"
              type="button"
              @click="unblockUserLoginPopupClosed()"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              large
              color="primary"
              type="submit"
            >
              {{ $t('Unblock user') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const restapi = require('@/service/restapi')

export default {
    name: 'CustomerBlock',
    props: ['account', 'user'],
    data () {
        return {
            isBlockUserLoginPopupActive: false,
            isUnblockUserLoginPopupActive: false
        }
    },
    computed: {
        active () {
            return this.account ? this.account.active : null
        },
        email () {
            return this.user ? this.user.email : null
        },
        blocked: {
            get: function () {
                return this.user && this.user.blocked
            },
            set: function (value) {
                this.user.blocked = !!value
            }
        }
    },
    methods: {
        blockUserLoginPopupClosed () {
            this.isBlockUserLoginPopupActive = false
        },
        unblockUserLoginPopupClosed () {
            this.isUnblockUserLoginPopupActive = false
        },
        showBlockUserLoginPopup () {
            if (this.active) {
                this.isBlockUserLoginPopupActive = true
            }
        },
        showUnblockUserLoginPopup () {
            if (this.active) {
                this.isUnblockUserLoginPopupActive = true
            }
        },
        block () {
            this.blocked = true
            this.isLoading(true)
            const that = this
            restapi.setAccountUser(this.user.resellerId, this.user.accountId, this.user.userId, {blocked: true})
                .then(function (result) {
                    that.$emit('blocked')
                    that.blockUserLoginPopupClosed()
                    that.isLoading(false)
                })
                .catch(function (error) {
                    console.error(error)
                    that.isLoading(false)
                })
        },
        unblock () {
            this.blocked = false
            this.isLoading(true)
            const that = this
            restapi.setAccountUser(this.user.resellerId, this.user.accountId, this.user.userId, {blocked: false})
                .then(function (result) {
                    that.$emit('unblocked')
                    that.unblockUserLoginPopupClosed()
                    that.isLoading(false)
                })
                .catch(function (error) {
                    console.error(error)
                    that.isLoading(false)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/custom';

  #block-user-button {
    background-color: $color-error-dark;
  }

  .customer-block {
    padding: 20px 0;
    .component-title span {
      color: $color-error-dark;
    }
  }

</style>
