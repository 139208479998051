<template>
  <div class="partner-reports">
    <div class="head">
      <h2>{{ title }}</h2>
      <p>{{ description }}</p>
      <div class="card-container">
        <v-card
          v-if="isAdmin"
          class="card"
        >
          <v-autocomplete
            id="select-distributor-input"
            v-model="distributor"
            tile
            :label="$t('SelectDistributor')"
            :items="distributors"
            item-text="companyName"
            return-object
            dense
            @change="onSelectDistributor"
          />
        </v-card>
       
        <v-card
          v-if="isDistributor"
          class="card ml-2"
        >
          <v-autocomplete
            id="select-reseller-input"
            v-model="reseller"
            tile
            :label="$t('Select Reseller')"
            :items="resellers"
            item-text="companyName"
            return-object
            dense
            @change="onSelectReseller"
          />
        </v-card>
      </div>
    </div>
    <div
      v-if="isResellerSelected"
      class="reports-container"
    >
      <span
        v-for="(r, index) in reports"
        :key="`report-${index}`"
        class="report-card"
      >
        <report
          :report-type="r.reportType"
          :download-name="r.downloadName"
          :reseller-id="resellerId"
          :title="$t(r.title)"
          :description="$t(r.description)"
          :button-label="$t(r.buttonLabel)"
        />
      </span>
    </div>
  </div>
</template>

<script charset="utf-8">
import Report from '@/components/insights/Report'

const auth = require('@/service/auth')
const restapi = require('@/service/restapi')

export default {
    name: 'Reports',
    components: {
        Report
    },
    data () {
        return {
            distributors: [],
            distributor: null,
            resellers: [],
            reseller: null,
            reports: [
                {
                    reportType: 'offlineCamera',
                    downloadName: 'OfflineCamerasReport',
                    title: 'Offline cameras report',
                    description: 'Contains all offline cameras, number of days offline and the date last seen online, generated once per day.',
                    buttonLabel: 'Download CSV'
                },
                {
                    reportType: 'emptyReport',
                    downloadName: 'EmptyAccountsReport',
                    title: 'Empty accounts report',
                    description: 'Contains all empty accounts, generated once per day.',
                    buttonLabel: 'Download CSV'
                },
                {
                    reportType: 'userSubscriptions',
                    downloadName: 'UserSubscriptionsReport',
                    title: 'Customer overview report',
                    description: 'Overview of all the customers and their subscription, generated once per day.',
                    buttonLabel: 'Download CSV'
                },
                {
                    reportType: 'subscriptionOverview',
                    downloadName: 'SubscriptionOverviewReport',
                    title: 'Frequently used subscriptions report',
                    description: 'Overview of the most frequently used subscriptions, generated once per day.',
                    buttonLabel: 'Download CSV'
                },
                {
                    reportType: 'addedCameras',
                    downloadName: 'AddedCamerasReport',
                    title: 'Added cameras report',
                    description: 'Contains the newly added cameras, generated once per day.',
                    buttonLabel: 'Download CSV'
                },
                {
                    reportType: 'cameraOverview',
                    downloadName: 'CameraOverviewReport',
                    title: 'Camera overview report',
                    description: 'Overview of cameras in all accounts, generated once per day.',
                    buttonLabel: 'Download CSV'
                }
            ]
        }
    },
    computed: {
        session () {
            return this.$store.getters.session ? this.$store.getters.session.session : null
        },
        singleReseller () {
            return false && this.resellers.length === 1
        },
        partnerId () {
            return this.$store.getters.myAccount.accountId
        },
        title () {
            return this.$t('Reports')
        },
        description () {
            return auth.isReseller() ? this.$t('Reports description reseller') : this.$t('Reports description distributor')
        },
        resellerId () {
            return this.reseller ? this.reseller.resellerId : null
        },
        distributorId () {
            return this.distributor ? this.distributor.distributorId : null
        },
        isAdmin () {
            return auth.isAdmin()
        },
        isDistributor () {
            return auth.isDistributor()
        },
        isReseller () {
            return auth.isReseller()
        },
        isBrowserIE () {
            return this.$store.getters.isIE
        },
        isResellerSelected () {
            return this.resellerId !== null && this.resellerId !== undefined
        },
    },
    watch: {
        session: function () {
            this.loadData()
        },
        distributor: async function (newVal) {
            if (newVal) {
                this.resellers = []
                this.reseller = null
                try {
                    const result = await restapi.getResellers(this.distributor.distributorId)
                    this.resellers = result.filter(function (r) { return r.active }).sort(function (a, b) {
                        return a.companyName.localeCompare(b.companyName, [], {sensitivity: 'base'})
                    })
                    if(this.resellers.length > 1) {
                        this.resellers.unshift({ resellerId: this.distributorId, companyName: this.$t('All Resellers') })
                    }
                } catch (error) {
                    console.error(error)
                }    
            }
        }
    },
    created () {
        console.log('created', this.isAdmin, this.isDistributor, this.isReseller)
        this.loadData()
    },
    methods: {
        areMyPartnerDetailsAdded (partners) {
            let myDetails = this.$store.getters.myPartnerDetails
            let found = partners.filter(function (p) {
                return p.PartnerId === myDetails.PartnerId
            })
            return (found && Array.isArray(found) && found.length > 0) // check if returned data is empty
        },
        onSelectDistributor (distributor) {
            this.distributor = distributor
        },
        onSelectReseller (reseller) {
            this.reseller = reseller
        },
        async loadData () {
            try {
                if (this.isAdmin) {
                    const result = await restapi.getDistributors(auth.getPartnerId())
                    this.distributors = result.filter(function (d) { return d.active }).sort(function (a, b) {
                        return a.companyName.localeCompare(b.companyName, [], {sensitivity: 'base'})
                    })
                } else if (this.isDistributor) {
                    const result = await restapi.getResellers(auth.getPartnerId())
                    this.resellers = result.filter(function (r) { return r.active }).sort(function (a, b) {
                        return a.companyName.localeCompare(b.companyName, [], {sensitivity: 'base'})
                    })
                    if(this.resellers.length > 1) {
                        this.resellers.unshift({ resellerId: auth.getPartnerId(), companyName: this.$t('All Resellers') })
                    }
                } else if (this.isReseller) {
                    const result = await restapi.getReseller(auth.getPartnerId())
                    this.resellers = [result]
                    this.reseller = result
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/styles/forms";
  @import '../../assets/styles/custom';

  .reports-container {
        width: 92%;
        padding-left: 15.5%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
        @media screen and (min-width: 1820px) {
        .reports-container {
          width: 80%;
          padding-left: 22.5%;
        }
    }
  .partner-reports {
    .head {
      width: 900px;
      min-width: 900px;
      margin: 0 auto;
      @media screen and (max-width: 767px) {
        width: 90%;
        min-width: 90%;
        margin-bottom: 50px;
      }
      h2 {
        color: $primary;
        text-transform: uppercase;
        margin: 10px 0 0.5714em 0;
        font-size: 14px;
      }
      p {
        margin-bottom: 20px;
      }
      .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .card {
          &.has-partner-select {
            width: 250px;
            height: 55px;
            padding: 0;
            .card-content {
              padding: 0.5rem;
              .field {
                margin: 0;
              }
            }
            &:first-child {
              margin-right: 20px;
            }
          }
        }
      }
    }
    .card {
      width: 350px;
      height: 75px;
      padding: 18px;
      border-radius: 4px;
      border: none;
      text-align: left;
      &:hover, &:focus, &:active {
        transform: none;
        box-shadow: 0 4px 16px 0px rgba(10, 10, 10, 0.1), 0 0 0 0 rgba(10, 10, 10, 0.1);
      }
      .card-content {
        padding: 0.5rem 1.5rem;
        p {
          font-size: 11px;
          &.title {
            color: $primary;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .last-generated-report-info {
          background-color: $color-shadow-light;
          margin: 20px -31px;
          padding: 10px 31px;
          p {
            &.title {
              font-size: 11px;
              color: $color-line;
              text-transform: uppercase;
              margin-bottom: 0;
            }
          }
        }
        a {
          color: $primary;
          font-weight: 600;
        }
      }
    }
     .report-card{
      padding: 10px 5px;
    }
  }
</style>
