<template>
  <div
    v-if="hasPermission('CustomerAdd')"
    class="add-customer"
  >
    <h2>{{ $t('Add new customer') }}</h2>
    <p v-if="exclusiveThirdPartyAuthentication">
      {{ $t('Password for this user is managed by a third party.') }}
    </p>
    <v-container
      v-show="step === 1"
      class="add-customer-page has-shadow has-rounded-corners"
    >
      <v-row>
        <v-col cols="6">
          <div class="field">
            <v-text-field
              id="add-user-company-input"
              v-model="company"
              :label="$t('Company')"
              dense
              autocomplete="no"
              @input="$v.company.$touch()"
            />
            <p
              v-if="$v.company.$error && !$v.company.minLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a minimum of X characters', { min: $v.company.$params.minLength.min }) }}
            </p>
            <p
              v-else-if="$v.company.$error && !$v.company.maxLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a maximum of X characters', { max: $v.company.$params.maxLength.max }) }}
            </p>
            <p
              v-else-if="$v.company.$error && !$v.company.validName"
              class="error-message pt-1"
            >
              {{ $t('Please enter a valid name') }}
            </p>
          </div>

          <div class="field">
            <v-text-field
              id="add-user-reference-input"
              v-model="reference"
              :label="$t('Reference')+'*'"
              dense
              autocomplete="no"
              @input="$v.reference.$touch()"
            />
            <p
              v-if="$v.reference.$error && !$v.reference.required"
              class="error-message pt-1"
            >
              {{ $t('This is a required field') }}
            </p>
            <p
              v-else-if="$v.reference.$error && !$v.reference.minLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a minimum of X characters', { min: $v.reference.$params.minLength.min }) }}
            </p>
            <p
              v-else-if="$v.reference.$error && !$v.reference.maxLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a maximum of X characters', { max: $v.reference.$params.maxLength.max }) }}
            </p>
            <p
              v-else-if="$v.reference.$error && !$v.reference.validReference"
              class="error-message pt-1"
            >
              {{ $t('Please enter a valid value') }}
            </p>
          </div>
          <div class="field">
            <v-text-field
              id="add-user-custom-reference-input"
              v-model="customReference"
              :label="$t('Custom Reference')"
              dense
              autocomplete="no"
              @input="$v.customReference.$touch()"
            />
            <p
              v-if="$v.customReference.$error && !$v.customReference.maxLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a maximum of X characters', { max: $v.customReference.$params.maxLength.max }) }}
            </p>
          </div>
          <div class="field">
            <v-text-field
              id="add-user-first-name-input"
              v-model="firstName"
              :label="$t('First name')+'*'"
              dense
              autocomplete="no"
              @input="$v.firstName.$touch()"
            />
            <p
              v-if="$v.firstName.$error && !$v.firstName.required"
              class="error-message pt-1"
            >
              {{ $t('This is a required field') }}
            </p>
            <p
              v-if="$v.firstName.$error && !$v.firstName.minLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a minimum of X characters', { min: $v.firstName.$params.minLength.min }) }}
            </p>
            <p
              v-else-if="$v.firstName.$error && !$v.firstName.maxLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a maximum of X characters', { max: $v.firstName.$params.maxLength.max }) }}
            </p>
            <p
              v-else-if="$v.firstName.$error && !$v.firstName.validName"
              class="error-message pt-1"
            >
              {{ $t('Please enter a valid name') }}
            </p>
          </div>
          <div class="field">
            <v-text-field
              id="add-user-last-name-input"
              v-model="lastName"
              :label="$t('Last name')+'*'"
              dense
              autocomplete="no"
              @input="$v.lastName.$touch()"
            />
            <p
              v-if="$v.lastName.$error && !$v.lastName.required"
              class="error-message pt-1"
            >
              {{ $t('This is a required field') }}
            </p>
            <p
              v-else-if="$v.lastName.$error && !$v.lastName.minLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a minimum of X characters', { min: $v.lastName.$params.minLength.min }) }}
            </p>
            <p
              v-else-if="$v.lastName.$error && !$v.lastName.maxLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a maximum of X characters', { max: $v.lastName.$params.maxLength.max }) }}
            </p>
            <p
              v-else-if="$v.lastName.$error && !$v.lastName.validName"
              class="error-message pt-1"
            >
              {{ $t('Please enter a valid name') }}
            </p>
          </div>
          <div class="field">
            <v-text-field
              id="add-user-phone-input"
              v-model="phone"
              :label="$t('Phone')"
              dense
              autocomplete="no"
              @input="$v.phone.$touch()"
            />
            <p
              v-if="$v.phone.$error && !$v.phone.phoneNumberCustomer"
              class="error-message pt-1"
            >
              {{ $t('Please enter a valid phone number') }}
            </p>
            <p
              v-else-if="$v.phone.$error && !$v.phone.minLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a minimum of X characters', { min: $v.phone.$params.minLength.min }) }}
            </p>
            <p
              v-else-if="$v.phone.$error && !$v.phone.maxLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a maximum of X characters', { max: $v.phone.$params.maxLength.max }) }}
            </p>
          </div>
          <div class="field">
            <v-text-field
              id="add-user-email-input"
              v-model="email"
              :label="$t('Email')+'*'"
              dense
              autocomplete="no"
              @input="$v.email.$touch()"
            />
            <p
              v-if="$v.email.$error && !$v.email.required"
              class="error-message pt-1"
            >
              {{ $t('This is a required field') }}
            </p>
            <p
              v-else-if="$v.email.$error && !$v.email.fullEmail"
              class="error-message pt-1"
            >
              {{ $t('Please enter a valid email address') }}
            </p>
            <p
              v-else-if="$v.email.$error && !$v.email.minLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a minimum of X characters', { min: $v.email.$params.minLength.min }) }}
            </p>
            <p
              v-else-if="$v.email.$error && !$v.email.maxLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a maximum of X characters', { max: $v.email.$params.maxLength.max }) }}
            </p>
          </div>
          <div
            v-if="zohoId"
            class="field"
          >
            <v-text-field
              id="add-zoho-id-input"
              v-model="zohoId"
              :label="$t('Zoho ID')"
              dense
              readonly
              autocomplete="no"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="field">
            <v-text-field
              id="add-user-address-input"
              v-model="address"
              :label="$t('Address')"
              dense
              autocomplete="no"
              @input="$v.address.$touch()"
            />
            <p
              v-if="$v.address.$error && !$v.address.minLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a minimum of X characters', { min: $v.address.$params.minLength.min }) }}
            </p>
            <p
              v-else-if="$v.address.$error && !$v.address.maxLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a maximum of X characters', { max: $v.address.$params.maxLength.max }) }}
            </p>
            <p
              v-else-if="$v.address.$error"
              class="error-message pt-1"
            >
              {{ $t('Please enter only valid characters') }}
            </p>
          </div>
          <div class="field">
            <v-text-field
              id="add-user-zip-code-input"
              v-model="zip"
              :label="$t('Zip code')"
              dense
              autocomplete="no"
              @input="$v.zip.$touch()"
            />
            <p
              v-if="$v.zip.$error && !$v.zip.minLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a minimum of X characters', { min: $v.zip.$params.minLength.min }) }}
            </p>
            <p
              v-else-if="$v.zip.$error && !$v.zip.maxLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a maximum of X characters', { max: $v.zip.$params.maxLength.max }) }}
            </p>
            <p
              v-else-if="$v.zip.$error && !$v.zip.alphaNumericSpace"
              class="error-message pt-1"
            >
              {{ $t('Please enter only valid characters') }}
            </p>
          </div>
          <div class="field">
            <v-text-field
              id="add-user-city-input"
              v-model="city"
              :label="$t('City')"
              dense
              autocomplete="no"
              @input="$v.city.$touch()"
            />
            <p
              v-if="$v.city.$error && !$v.city.minLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a minimum of X characters', { min: $v.city.$params.minLength.min }) }}
            </p>
            <p
              v-else-if="$v.city.$error && !$v.city.maxLength"
              class="error-message pt-1"
            >
              {{ $t('Please enter a maximum of X characters', { max: $v.city.$params.maxLength.max }) }}
            </p>
            <p
              v-else-if="$v.city.$error && !$v.city.alphaNumericSpace"
              class="error-message pt-1"
            >
              {{ $t('Please enter only valid characters') }}
            </p>
          </div>
          <div class="field">
            <v-autocomplete
              id="add-user-country-input"
              v-model="country"
              :label="$t('Country')"
              :items="countries"
              dense
              class="zIndex100"
              :placeholder="$t('Select country')"
              autocomplete="no"
              type="search"
            />
          </div>
          <div class="field">
            <v-autocomplete
              id="add-user-languages-input"
              v-model="language"
              :label="$t('Language')+'*'"
              :items="languages"
              dense
              class="zIndex100"
              :placeholder="$t('Select language')"
              autocomplete="no"
              type="search"
            />
            <p
              v-if="$v.language.$error && !$v.language.required"
              class="error-message"
            >
              {{ $t('This is a required field') }}
            </p>
          </div>
          <div class="field">
            <v-autocomplete
              id="add-user-timezone-input"
              v-model="timezone"
              :label="$t('Timezone')+'*'"
              :items="timezones"
              dense
              class="zIndex100"
              :placeholder="$t('Select timezone')"
              autocomplete="no"
              type="search"
            >
              <template #item="data">
                {{ data.item + addOffset(data.item) }}
              </template>
            </v-autocomplete>
            <p
              v-if="$v.timezone.$error && !$v.timezone.required"
              class="error-message"
            >
              {{ $t('This is a required field') }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-show="step === 1"
        v-if="!exclusiveThirdPartyAuthentication"
      >
        <v-col>
          <transition name="fade">
            <div
              v-show="!isPasswordGenerated"
              class="field"
            >
              <v-text-field
                id="add-user-password-input"
                v-model="password"
                :label="$t('Password')"
                dense
                :type="showPassword ? 'text' : 'password'"
                autocomplete="no"
                :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                @input="$v.password.$touch()"
                @click:append="showPassword = !showPassword"
              />
              <p
                v-if="$v.password && $v.password.$error && !$v.password.required"
                class="error-message pt-1"
              >
                {{ $t('This is a required field') }}
              </p>
              <p
                v-else-if="$v.password && $v.password.$error && !$v.password.validPassword"
                class="error-message pt-1"
              >
                {{ $t('Please enter a valid password') }}
              </p>
              <p
                v-else-if="$v.password && $v.password.$error && !($v.password.minLength && $v.password.maxLength)"
                class="error-message pt-1"
              >
                {{ $t('Password should be X characters', { min: $v.password.$params.minLength.min, max: $v.password.$params.maxLength.max }) }}
              </p>
            </div>
          </transition>
          <transition name="fade">
            <div
              v-show="!isPasswordGenerated"
              class="field"
            >
              <v-text-field
                id="add-user-confirm-password-input"
                v-model="confirmPassword"
                :label="$t('Confirm Password')"
                dense
                :type="showPasswordConfirm ? 'text' : 'password'"
                autocomplete="no"
                :append-icon="showPasswordConfirm ? 'fas fa-eye' : 'fas fa-eye-slash'"
                @input="$v.confirmPassword.$touch()"
                @click:append="showPasswordConfirm = !showPasswordConfirm"
              />
              <p
                v-if="$v.confirmPassword && $v.confirmPassword.$error && !$v.confirmPassword.sameAsPassword"
                class="error-message pt-1"
              >
                {{ $t('Password does not match') }}
              </p>
            </div>
          </transition>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="9">
              <p class="generate-password-label">
                {{ $t('Automatically generate password') }}
              </p>
              <p class="generate-password-send-by-email">
                {{ $t('Send by email') }}
              </p>
            </v-col>
            <v-col cols="3">
              <v-switch
                v-model="isPasswordGenerated"
                size="is-large"
                inset
                dense
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-show="hasDemoOrTrial">
        <v-col cols="6">
          <v-select
            id="add-user-account-type"
            v-model="accountType"
            :label="$t('User type')"
            :items="accountTypes"
            :selected-option="selectedAccountType"
            dense
            class="zIndex100"
            :placeholder="$t('User type')"
            @change="onSelectAccountType"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-show="step === 2"
      class="add-customer-page has-shadow has-rounded-corners"
    >
      <v-row>
        <v-col>
          <choose-subscription
            :subscriptions="subscriptions"
            @subscriptionSelected="onSubscriptionSelected($event)"
          />
        </v-col>
      </v-row>
    </v-container>
    <div class="button-bar">
      <v-btn
        v-show="step === 1 && !subscription"
        large
        class="float-right mt-2"
        color="primary"
        :disabled="$v.$invalid"
        @click="chooseSubscription"
      >
        {{ $t('Choose subscription') }}
      </v-btn>
      <v-btn
        v-show="step === 2"
        large
        class="float-left mt-2"
        color="primary"
        @click="changeDetails"
      >
        {{ $t('Change details') }}
      </v-btn>
      <v-btn
        v-show="step === 2 || subscription"
        large
        class="float-right mt-2"
        color="primary"
        :disabled="$v.$invalid || !subscription"
        @click="addCustomer"
      >
        {{ $t('Add customer') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import ChooseSubscription from '@/components/account/ChooseSubscription'
import { required, minLength, maxLength, sameAs} from 'vuelidate/lib/validators'
import { phoneNumberCustomer, validName, validReference, alphaNumericSpace, validPassword, fullEmail } from '@/service/main'
import { getLanguageLabel } from '@/service/guiUtils'
import { findTimeZone, getUTCOffset } from 'timezone-support'

const auth = require('@/service/auth')
const restapi = require('@/service/restapi')

export default {
    name: 'AddCustomer',
    components: {
        ChooseSubscription
    },
    props: ['partnerId', 'subscriptions', 'exclusiveThirdPartyAuthentication', 'zohoId'],
    data () {
        return {
            countries: [],
            languages: [],
            company: null,
            reference: null,
            customReference: null,
            email: null,
            firstName: null,
            lastName: null,
            phone: null,
            address: null,
            zip: null,
            city: null,
            language: null,
            timezone: null,
            country: null,
            password: null,
            confirmPassword: null,
            subscription: null,
            isPasswordGenerated: false,
            accountType: 'regular',
            step: 1,
            showPassword: false,
            showPasswordConfirm: false,
        }
    },
    computed: {
        availableSubscriptions () {
            if (this.subscriptions) {
                return this.subscriptions.filter(function (s) { return s.available })
            }
            return null
        },
        newCustomerData () {
            return {
                firstName: this.firstName,
                lastName: this.lastName,
                contactEmail: this.email,
                address: this.address,
                zipCode: this.zip,
                city: this.city,
                country: this.country,
                companyName: this.company,
                reference: this.reference,
                customReference: this.customReference,
                phone: this.phone,
                vatNumber: null,
                language: this.language,
                currency: null,
                timeZone: this.timezone,
                subscriptionId: this.subscription ? this.subscription.subscriptionId : null,
                password: this.isPasswordGenerated ? null : this.password
            }
        },
        timezones () {
            return this.$store.getters.timeZones
        },
        selectedCountry () {
            if (this.country && this.countries) {
                let currentCountry = this.country
                return this.countries.find(function (c) { return c.value === currentCountry })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        selectedLanguage () {
            if (this.language && this.languages) {
                let currentLanguage = this.language
                return this.languages.find(function (l) { return l.value === currentLanguage })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        selectedTimezone () {
            if (this.timezone && this.timezones) {
                let currentZone = this.timezone
                return this.timezones.find(function (l) { return l.value === currentZone })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        selectedAccountType () {
            let currentAccountType = this.accountType
            return this.accountTypes.find(function (t) { return t.value === currentAccountType })
        },
        trialAccountType () {
            if (this.availableSubscriptions) {
                return this.availableSubscriptions.find(function (s) { return s.name.toLowerCase().indexOf('trial') !== -1 })
            }
            return null
        },
        demoAccountType () {
            if (this.availableSubscriptions) {
                return this.availableSubscriptions.find(function (s) { return s.name.toLowerCase().indexOf('demo') !== -1 })
            }
            return null
        },
        hasDemoOrTrial () {
            return !!this.demoAccountType || !!this.trialAccountType
        },
        accountTypes () {
            let types = [
                {
                    value: 'regular',
                    text: this.$t('Regular')
                }
            ]
            if (this.trialAccountType) {
                types.push({
                    value: 'trial',
                    text: this.$t('Trial')
                })
            }
            if (this.demoAccountType) {
                types.push({
                    value: 'demo',
                    text: this.$t('Demo')
                })
            }
            return types
        },
        isBrowserIE () {
            return this.$store.getters.isIE
        }
    },
    watch: {
        'isPasswordGenerated': function (newVal, oldVal) {
            if (newVal) {
                this.password = null
                this.confirmNewPassword = null
            }
        }
    },
    mounted () {
        const that = this
        this.initNewCustomerData()
        this.step = 1
        this.countries = []
        this.languages = []
        restapi.getCountries()
            .then(function (result) {
                that.countries = result
            })
            .catch(function (e) {
                console.error(e)
            })
            .then(function () {
                restapi.getLanguages()
                    .then(function (result) {
                        that.languages = result
                    })
                    .catch(function (e) {
                        console.error(e)
                    })
            })
            .then(function () {
                if (that.zohoId) {
                    restapi.getZohoAccountById(that.zohoId)
                        .then(function (result) {
                            that.company = result.name
                            that.phone = result.phone.replace(/ /g, '')
                            that.address = result.billingStreet
                            that.city = result.billingCity
                            that.country = result.billingCountry
                        })
                        .catch(function (error) {
                            console.error(error)
                        })
                }
            })
    },
    methods: {
        initNewCustomerData () {
            this.company = null
            this.reference = null
            this.customReference = null
            this.email = null
            this.firstName = null
            this.lastName = null
            this.telephone = null
            this.address = null
            this.zip = null
            this.city = null
            this.language = null
            this.timezone = null
            this.country = null
            this.password = ''
            this.subscription = null
            this.isPasswordGenerated = false
        },
        onSubscriptionSelected (subscription) {
            this.subscription = subscription
        },
        onSelectLanguage (l) {
            this.language = l.value
        },
        onSelectCountry (c) {
            this.country = c.value
        },
        onSelectTimezone (tz) {
            this.timezone = tz.value
        },
        onSelectAccountType (t) {
            this.accountType = t
            if (this.accountType === 'demo') {
                this.subscription = this.demoAccountType
            } else if (this.accountType === 'trial') {
                this.subscription = this.trialAccountType
            } else {
                this.subscription = null
            }
        },
        getLanguageLabel (code) {
            return getLanguageLabel(this.languages, code)
        },
        chooseSubscription () {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.step = 2
            }
        },
        changeDetails () {
            this.step = 1
        },
        addCustomer() {
            this.$v.$touch()
            if (!this.$v.$invalid && this.subscription) {
                const that = this
                that.isLoading(true)
                // Create new account on rest api.
                // Password is optional, if not sent in, it will be generated.
                // Welcome email will be sent.
                // Exception is a case when partner has `exclusiveThirdPartyAuthentication`. Then if the password is empty then we skip it.
                if (this.exclusiveThirdPartyAuthentication) {
                    this.newCustomerData.password = null
                } else if (this.zohoId) {
                    this.newCustomerData.crmId = this.zohoId
                }
                restapi.addAccount(this.partnerId, this.newCustomerData)
                    .then(function (result) {
                        that.$emit('created', result.accountOwnerId)
                        that.isLoading(false)
                        that.state = 'success'
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.$t('A user was successfully created'),
                            timeout : 4000,
                            color: 'primary'
                        })
                    })
                    .catch(function (error) {
                        that.isLoading(false)
                        console.error(error)
                        if (parseInt(error.code) === 305) {
                            console.log('Session expired. Create new one and retry.')
                            auth.logout()
                                .then(function (result) {
                                    restapi.addAccount(that.partnerId, that.newCustomerData)
                                        .then(function (result) {
                                            that.isLoading(false)
                                            that.state = 'success'
                                            that.$store.commit('toastMessage', {
                                                showing: true,
                                                text: this.$t('A user was successfully created'),
                                                timeout: 4000,
                                                color: 'primary'
                                            })
                                        })
                                        .catch(function (error) {
                                            that.isLoading(false)
                                            console.error(error)
                                            that.$store.commit('toastMessage', {
                                                showing: true,
                                                text: that.getMessageFromError(error, 'ADD_ACCOUNT'),
                                                timeout: -1,
                                                color: 'error',
                                                support: true
                                            })
                                        })
                                })
                                .catch(function (error) {
                                    that.isLoading(false)
                                    console.error(error)
                                    that.$store.commit('toastMessage', {
                                        showing: true,
                                        text: that.getMessageFromError(error, 'ADD_ACCOUNT'),
                                        timeout: -1,
                                        color: 'error',
                                        support: true
                                    })
                                })
                        } else {
                            that.$store.commit('toastMessage', {
                                showing: true,
                                text: that.getMessageFromError(error, 'ADD_ACCOUNT'),
                                timeout: -1,
                                color: 'error',
                                support: true
                            })
                        }
                    })
            } else {
                console.error('Not a valid customer!')
            }
        },
        addOffset(timezone) {
            const offset = this.getOffset(timezone)
            const hours = offset / 60
            const minutes = offset % 60
            if (hours > 0) return ' ( -' + hours + (minutes ? `:${Math.abs(minutes)}` : '') + ' )'
            else return ' ( +' + Math.floor(Math.abs(hours)) + (minutes ? `:${Math.abs(minutes)}` : '') + ' )'
        },
        getOffset(timeZone) {
            const foundTimeZone = findTimeZone(timeZone)
            const date = new Date()
            const timeZoneOffset = getUTCOffset(date, foundTimeZone)
            return timeZoneOffset.offset
        }
    },
    validations () {
        if (this.isPasswordGenerated || this.exclusiveThirdPartyAuthentication) {
            return {
                company: {
                    minLength: minLength(2),
                    maxLength: maxLength(255),
                    validName
                },
                reference: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(250),
                    validReference
                },
                customReference: {
                    maxLength: maxLength(255)
                },
                phone: {
                    phoneNumberCustomer,
                    minLength: minLength(10),
                    maxLength: maxLength(20)
                },
                address: {
                    minLength: minLength(2),
                    maxLength: maxLength(250),
                },
                zip: {
                    minLength: minLength(2),
                    maxLength: maxLength(16),
                    alphaNumericSpace
                },
                city: {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    alphaNumericSpace
                },
                email: {
                    required,
                    fullEmail,
                    minLength: minLength(8),
                    maxLength: maxLength(128)
                },
                firstName: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(32),
                    validName
                },
                lastName: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(32),
                    validName
                },
                language: {
                    required
                },
                timezone: {
                    required
                },
                password: {
                },
                confirmPassword: {
                }
            }
        } else {
            return {
                company: {
                    minLength: minLength(2),
                    maxLength: maxLength(255),
                    validName
                },
                reference: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(250),
                    validReference
                },
                customReference: {
                    maxLength: maxLength(255)
                },
                phone: {
                    phoneNumberCustomer,
                    minLength: minLength(10),
                    maxLength: maxLength(20)
                },
                address: {
                    minLength: minLength(2),
                    maxLength: maxLength(250),
                },
                zip: {
                    minLength: minLength(2),
                    maxLength: maxLength(16),
                    alphaNumericSpace
                },
                city: {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    alphaNumericSpace
                },
                email: {
                    required,
                    fullEmail,
                    minLength: minLength(8),
                    maxLength: maxLength(128)
                },
                firstName: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(32),
                    validName
                },
                lastName: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(32),
                    validName
                },
                language: {
                    required
                },
                timezone: {
                    required
                },
                password: {
                    required,
                    validPassword,
                    minLength: minLength(8),
                    maxLength: maxLength(32)
                },
                confirmPassword: {
                    sameAsPassword: sameAs('password')
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/styles/forms";

  .add-customer {
    h2 {
      color: $primary;
      text-transform: uppercase;
      margin: 50px 0 calc(20px + 0.75rem) 0;
      font-size: 14px;
    }
    .button-bar {
      .button {
        margin: 20px 0;
      }
    }
    .add-customer-page {
      background-color: #ffffff;
      .columns {
        margin: 0;
        padding: 0;
        &:first-child {
          padding-top: 20px;
        }
        &:last-child {
          padding-bottom: 20px;
        }
        background-color: transparent;
        .column {
          padding: 0 40px;
          margin: 20px 0;
          @media screen and (max-width: 767px) {
            border-left: 8px solid $primary;
            padding-left: 32px;
          }
          @media screen and (min-width: 768px) {
            &:first-child {
              border-left: 8px solid $primary;
              padding-left: 32px;
            }
          }
          &.password-column {
            min-height: 100px;
          }
          .field {
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
    .generate-password-send-by-email {
      color: $color-title-light;
    }
  }
  .zIndex100{
    z-index: 100;
  }
</style>
