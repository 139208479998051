/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { store } from '@/store/index'

export function getImgUrl (img) {
    var pixelRatio = getPixelRatio()
    var images = getImagesContext(pixelRatio)
    return images('./' + img)
}

export function getConfiguration () {
    /* eslint-disable no-use-before-define */
    return config || store.getters.configuration
}

export function title () {
    let config = getConfiguration()
    return config['title']
}

export function version () {
    let config = getConfiguration()
    return config['version']
}

export function getPixelRatio () {
    return window.devicePixelRatio
}

export function getImagesContext (pixelRatio) {
    // fallback is to scale 1.0
    var images = require.context('../assets/images', false, /\.jpg$|\.png$|.gif$/)

    if (pixelRatio > 1 && pixelRatio < 2) {
        images = require.context('../assets/images/hdpi150', false, /\.jpg$|\.png$|.gif$/)
    } else if (pixelRatio >= 2 && pixelRatio < 3) {
        images = require.context('../assets/images/hdpi200', false, /\.jpg$|\.png$|.gif$/)
    } else if (pixelRatio >= 3) {
        images = require.context('../assets/images/hdpi300', false, /\.jpg$|\.png$|.gif$/)
    }

    return images
}

export function phoneNumber (value) {
    if (typeof value === 'undefined' || value === null || value === '') {
        return true
    }
    // In the future we should support more generic phone number patterns
    return (/^[+]?[0-9]{1,}/).test(value)
    // return (/^\d{7,}$/).test(value.replace(/[\s()+-.]|ext/gi, ''))
}

export function phoneNumberCustomer (value) {
    if (typeof value === 'undefined' || value === null || value === '') {
        return true
    }
    // In the future we should support the generic phone number validation for customers
    return /^\+?[0-9]*$/.test(value)
}

export function dateString (cmDate) {
    const dt = cmDate.split('-')
    return dt[2] + '-' + dt[1] + '-' + dt[0]
}

export function fullEmail (value) {
    const XRegExp = require('xregexp')
    const expr = XRegExp.build('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-\\+]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$')
    return expr.test(value)

}
export function validPassword (value) {
    return /^[\w-.,!?@$&*^=%:/\\~+#"]*$/.test(value)
}

export function goodPassword (value) {
    const zxcvbn = require('zxcvbn')
    if (value) return zxcvbn(value).score >= 3
    return true
}

export function validName (value) {
    const XRegExp = require('xregexp')
    const expr = XRegExp.build('^[\\p{L}0-9_\\- .&+:,;/]*$')
    return expr.test(value)
}

export function validReference (value) {
    const XRegExp = require('xregexp')
    const expr = XRegExp.build('^[0-9_\\- \\&\\.\\p{L}]*$')
    return expr.test(value)
}

export function alphaNumericSpace (value) {
    const XRegExp = require('xregexp')
    const expr = XRegExp.build('^[0-9_\\- \\&\\p{L}]*$')
    return expr.test(value)
}

export function validApiKey (value) {
    const XRegExp = require('xregexp')
    const expr = XRegExp.build('^[0-9_ \\p{L}]*$')
    return expr.test(value)
}

export function validVATNumber (value) {
    if (typeof value === 'undefined' || value === null || value === '') {
        return true
    }
    return /^[A-Z]{2}[0-9A-Z]{2,13}$/.test(value)
}

export function changeFavicon (link) {
    let favicon = document.getElementById('favicon')
    if (favicon !== null) {
        favicon.href = link
    }
}


export function getUrl (path, extraQuery) {
    const href = window.location.href
    const hashPos = href.indexOf('#')
    let base = hashPos > -1 ? href.slice(0, hashPos) : href
  
    const searchPos = base.indexOf('?')
    const query = searchPos > -1 ? base.slice(searchPos) : ''
    base = query ? base.slice(0, searchPos) : base
    let extra = ''
    Object.keys(extraQuery).forEach(key => {
        extra += `&${key}=${extraQuery[key]}`
    })
  
    return `${base}#${path}${query}${extra}`
}