<template>
  <div class="api-keys-root">
    <div class="head">
      <h2>{{ $t('Api Keys') }}</h2>
      <div class="d-flex justify-space-between">
        <p class="mr-3 mb-0">
          {{ $t('Api Keys Description') }}
        </p>
        <v-btn
          large
          color="primary"
          @click="setGenerateApiKeyPopupVisible(true)"
        >
          {{ $t('Generate new Api Key') }}
        </v-btn>
      </div>
    </div>
    <v-card v-if="apiKeys.length > 0">
      <v-data-table
        :headers="headers"
        :items="apiKeys"
        :items-per-page="10"
        :footer-props="{ 'items-per-page-text': '', 'items-per-page-options': []}"
        hide-default-header
        calculate-widths
        dense
      >
        <template #header="{ props }">
          <thead>
            <tr>
              <th
                v-for="(header, index) in props.headers"
                :key="index"
                class="primary--text table-head"
              >
                {{ $t(header.text) }}
              </th>
            </tr>
          </thead>
        </template>
        <template #body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.clientSecret"
              class="table-row"
            >
              <td class="table-cell">
                {{ item.clientId }}
              </td>
              <td class="table-cell secret-key">
                {{ item.clientSecret }}
              </td>
              <td class="table-cell">
                {{ dateFormatted(item.datetimeCreation) }}
              </td>
              <td class="table-cell">
                <v-menu offset-y>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon
                        size="1em"
                        color="primary"
                      >
                        fa fa-ellipsis-h
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="setDeleteApiKeyPopupVisible(true, item.clientId)">
                      <v-list-item-title>{{ $t('Delete Key') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <v-card v-else>
      <v-card-text align="center">
        {{ $t('No keys found') }}
      </v-card-text>
    </v-card>
    <div>
      <div>
        <v-dialog
          id="generate-api-key-modal"
          v-model="isGenerateKeyModalActive"
          width="600px"
          :active.sync="isGenerateKeyModalActive"
          @close="createKeyPopupClosed()"
        >
          <v-card>
            <v-form @submit.prevent="generateApiKey">
              <v-card-title>
                {{ $t('Create API Key') }}
              </v-card-title>
              <v-card-text>
                <p>
                  {{ $t('Enter ID for the Api Key. By generating an API key you agree with the ') }} <a
                    href="https://developer.cameramanager.com/dp/terms-and-conditions"
                    style="text-decoration:none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >{{ $t('API Terms and Conditions.') }}</a>
                </p>
                <div class="field">
                  <v-text-field
                    id="account-details-company-input"
                    v-model="keyIdToCreate"
                    :label="$t('id')"
                    dense
                    autocomplete="no"
                    @input="$v.keyIdToCreate.$touch()"
                  />
                  <p
                    v-if="$v.keyIdToCreate.$error && !$v.keyIdToCreate.required"
                    class="error-message"
                  >
                    {{ $t('This is a required field') }}
                  </p>
                  <p
                    v-else-if="$v.keyIdToCreate.$error && !$v.keyIdToCreate.minLength"
                    class="error-message"
                  >
                    {{ $t('Please enter a minimum of X characters', { min: $v.keyIdToCreate.$params.minLength.min }) }}
                  </p>
                  <p
                    v-else-if="$v.keyIdToCreate.$error && !$v.keyIdToCreate.maxLength"
                    class="error-message"
                  >
                    {{ $t('Please enter a maximum of X characters', { max: $v.keyIdToCreate.$params.maxLength.max }) }}
                  </p>
                  <p
                    v-else-if="$v.keyIdToCreate.$error && !$v.keyIdToCreate.validApiKey"
                    class="error-message"
                  >
                    {{ $t('Please only use letters, numbers spaces and underscores in your ID') }}
                  </p>
                </div>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    large
                    class="mr-2"
                    outlined
                    color="primary"
                    type="button"
                    @click="createKeyPopupClosed()"
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                  <v-btn
                    large
                    color="primary"
                    type="submit"
                  >
                    {{ $t('Add Key') }}
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog
          id="delete-api-key-modal"
          v-model="isDeleteKeyModalActive"
          width="600px"
          :active.sync="isDeleteKeyModalActive"
          @close="deleteKeyPopupClosed()"
        >
          <v-card>
            <v-form @submit.prevent="deleteApiKey">
              <v-card-title>
                {{ $t('Delete API Key') }}
              </v-card-title>
              <v-card-text>
                <p> {{ $t('Are you sure you want delete API Key') }} </p>
                <v-container fluid>
                  <v-checkbox
                    v-model="checkboxGeneratingApiKeyNotPossible"
                    class="mt-0 pt-0 mb-0"
                    dense
                    :label="$t('Generating this API Key again is not possible')"
                  />
                  <v-checkbox
                    v-model="checkboxAllAppsStopWorking"
                    class="mt-0 pt-0 mb-0"
                    dense
                    :label="$t('All applications using this API Key will stop working')"
                  />
                  <p color="error">
                    {{ $t('If you accidentally removed your API Key') }}
                  </p>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  large
                  outlined
                  class="mr-2"
                  color="primary"
                  type="button"
                  @click="deleteKeyPopupClosed()"
                >
                  {{ $t('Cancel') }}
                </v-btn>
                <v-btn
                  large
                  color="error"
                  type="submit"
                  :disabled="!(checkboxGeneratingApiKeyNotPossible && checkboxAllAppsStopWorking)"
                >
                  {{ $t('Delete Key') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <!-- div class="terms">
      <p>{{ $t('Api keys terms title') }}</p>
      <p><span class="icon"><i class="fa fa-circle"></i></span>{{ $t('Api keys terms first') }}</p>
      <p><span class="icon"><i class="fa fa-circle"></i></span>{{ $t('Api keys terms second') }}</p>
      <p><span class="icon"><i class="fa fa-circle"></i></span>{{ $t('Api keys terms third') }}</p>
      <p><span class="icon"><i class="fa fa-circle"></i></span>{{ $t('Api keys terms fourth') }}</p>
    </div -->
  </div>
</template>

<script charset="utf-8">
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validApiKey } from '@/service/main'
import { getTimeStamp } from '@eencloud/core-components/src/service/time'

const restapi = require('@/service/restapi')

export default {
    name: 'ApiKeys',
    components: {
    },
    props: ['partnerId'],
    data () {
        return {
            isGenerateKeyModalActive: false,
            isDeleteKeyModalActive: false,
            keyIdToCreate: '',
            keyIdToDelete: null,
            apiKeys: [],
            checkboxGeneratingApiKeyNotPossible: false,
            checkboxAllAppsStopWorking: false,
            headers: [
                { text: 'ID', value: 'clientId', width: '200px', fixed: true },
                { text: 'Secret', value: 'clientSecret', width: '200px', fixed: true },
                { text: 'Creation Date', value: 'datetimeCreation', width: '200px', fixed: true },
                { text: '', value: '', width: '1%', fixed: true },
            ],
        }
    },
    methods: {
        setGenerateApiKeyPopupVisible (visible) {
            this.isGenerateKeyModalActive = visible
        },
        generateApiKey () {
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }
            const that = this
            restapi.createApiKey(this.partnerId, this.keyIdToCreate)
                .then(function (result) {
                    that.apiKeys.push(result)
                    that.createKeyPopupClosed()
                })
                .catch(function (error) {
                    console.error(error)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.getMessageFromError(error, 'ADD_API_KEY'),
                        timeout : -1,
                        color: 'error',
                        support: true
                    })
                })
        },
        setDeleteApiKeyPopupVisible (visible, keyId = null) {
            this.isDeleteKeyModalActive = visible
            this.keyIdToDelete = keyId
            this.checkboxGeneratingApiKeyNotPossible = false
            this.checkboxAllAppsStopWorking = false
        },
        deleteApiKey () {
            const that = this
            restapi.deleteApiKey(this.partnerId, this.keyIdToDelete)
                .then(function (result) {
                    let newResults = that.apiKeys.filter(function (item) {
                        return item.clientId !== that.keyIdToDelete
                    })
                    that.apiKeys = newResults
                    that.setDeleteApiKeyPopupVisible(false)
                })
                .catch(function (error) {
                    console.error(error)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.getMessageFromError(error, 'DELETE_API_KEY'),
                        timeout : -1,
                        color: 'error',
                        support: true
                    })
                })
        },
        createKeyPopupClosed () {
            this.$v.$reset()
            this.keyIdToCreate = null
            this.setGenerateApiKeyPopupVisible(false)
        },
        deleteKeyPopupClosed () {
            this.setDeleteApiKeyPopupVisible(false)
        },
        dateFormatted(timestamp) {
            if (isNaN(this.$store.getters.myPartner.timeZoneOffset))  
                return timestamp + ' (UTC)'
            //extracting the ofset to system time from the timezoneOffset becuase we don't get a ISOString from the backend. Therefore it's not clear it's a UTC date.
            return getTimeStamp(this.$store.getters.myPartner.timeZoneOffset + new Date().getTimezoneOffset(), new Date(timestamp.replace(' ', 'T'))).slice(0,17)
        }
    },
    validations: {
        keyIdToCreate: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
            validApiKey
        }
    },
    mounted () {
        const that = this
        restapi.getApiKeys(this.partnerId)
            .then(function (result) {
                that.apiKeys = result
            })
            .catch(function (error) {
                console.error(error)
                that.$store.commit('toastMessage', {
                    showing: true,
                    text: that.getMessageFromError(error, 'GET_API_KEYS'),
                    timeout : -1,
                    color: 'error',
                    support: true
                })
            })
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';

  .table-head {
        border-bottom-color: var(--v-primary-lighten1) !important;
        border-bottom-width: 2px !important;
    }

    .table-row {
        &:nth-child(even) {
            background-color: #f8f8f8;
        }
        &.expanded {
            background-color: #f5f5f5;
        }
        .table-cell {
            &:nth-child(1) {
               padding-left: 20px;
            }
            padding: 10px;
            vertical-align: middle;
            font-size: 0.9em;
            max-width:320px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border: none !important;
        }
        .email {
            max-width: 300px;
            width: 300px;
        }
    }

  .secret-key {
    max-width: 350px;
    width: 350px;
  }

  .api-keys-root {
    padding-bottom: 40px;
    width: 900px;
    min-width: 900px;
    margin: 0 auto;
    .head {
      h2 {
        color: $primary;
        text-transform: uppercase;
        margin: 50px 0 calc(20px + 0.75rem) 0;
        font-size: 14px;
      }
      p {
        display: inline-block;
        flex-grow: 1;
      }
      div {
        margin: 20px 0 30px;
      }
    }
    ::v-deep {
      table {
        &.table {
          tbody > tr {
            cursor: default;
            td > span {
              font-size: 12px;
            }
          }
          td {
            border: none;
          }
          thead th {
            border-bottom: 2px solid $border-blue-light;
            padding: 6px 4px!important;
            .th-wrap {
              color: $primary;
              text-transform: uppercase;
              font-size: 12px;
            }
            &.is-current-sort {
              border-bottom-color: $border-blue;
              span.icon {
                i.fa.fa-arrow-up {
                  &:before {
                    content: "\f0d8";
                  }
                }
              }
            }
          }
          th.is-sortable:hover {
            border-color: $primary;
          }
        }
      }
    }
  }

  .columns.title-block {
    background-color: transparent;
    p {
      font-size: 12px;
    }
  }

  .terms {
    margin-top: 40px;
    p {
      font-size: 12px;
      margin-bottom: 5px;
    }
    .icon {
      font-size: 5px;
      vertical-align: middle;
    }
  }

  .content-block {
    margin-top: 30px;
  }

  .modal-card-head {
    border-bottom: none;
  }

  .modal-card-foot {
    border-top: none;
    justify-content: flex-end;
    .button.is-primary {
      min-width: 170px;
    }
  }

  .modal-card-title {
    font-size: 20px;
    font-weight: 600;
  }

  .modal-card-body {
    padding: 30px 20px;
  }

  #generate-api-key-modal {
    .field {
      margin-bottom: 10px;
    }
    .add-key-title {
      // font-weight: 600;
    }
  }

  #delete-api-key-modal {
    .icon {
      color: $color-error-dark;
    }
    button.is-primary {
      background-color: $color-error-dark;
    }
    .modal-card-body {
      p:not(:first-child) {
        margin-bottom: 5px;
      }
      p:last-child {
        margin-top: 20px;
      }
    }
    ::v-deep .b-checkbox.checkbox input[type=checkbox]:checked + .check {
      background-color: $color-error-dark;
      border-color: $color-error-dark;
    }
    ::v-deep label.b-checkbox.checkbox:hover .check {
      border-color: $color-error-dark;
    }
    ::v-deep .b-checkbox.checkbox .check:hover {
      border-color: $color-error-dark;
    }
    ::v-deep .b-checkbox.checkbox .control-label {
      font-size: 14px;
    }
    ::v-deep .b-checkbox.checkbox {
      margin-bottom: 5px;
    }
    ::v-deep .b-checkbox.checkbox + .checkbox {
      margin-left: 0;
    }
    ::v-deep .b-checkbox.checkbox:focus input[type=checkbox] + .check {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    ::v-deep .b-checkbox.checkbox input[type=checkbox] + .check {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .cancel-button {
    background-color: transparent;
    color: $secondary-invert;
    &:hover {
      color: $secondary-invert;
    }
  }

  #api-keys-table .delete-key-button {
    background-color: transparent;
    border: none;
    font-weight: bold;
    color: #207CAD;
  }

  a.dropdown-item {
    color: $primary;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
  }

  td.contextMenuColumn {
    text-align: right;
  }

</style>
