const state = {
    modalShown: false
}

const getters = {
    isModalShown: state => {
        return state.modalShown
    }
}

const mutations = {
    modalShown: (state, opened) => {
        state.modalShown = opened
    }
}

export default {
    state,
    getters,
    mutations
}
