<template>
  <div
    class="partner-profile"
    :class="{ 'edit-mode': editMode }"
  >
    <div
      v-if="editMode"
      class="head"
    >
      <h2>{{ profile.name }}</h2>
      <p>{{ $t('To add or remove a permission for this role use the corresponding switch.') }}</p>
    </div>
    <v-card min-height="230">
      <v-card-title
        v-if="!editMode"
        class="primary--text"
      >
        <span><span class="role-title">{{ profile.name }}</span>&nbsp;<span class="help"> - <v-icon
          size="1em"
          color="primary"
        >fa fa-user</v-icon> {{ relatedAccounts.length }}</span></span>
      </v-card-title>
      <v-card-text class="pa-0">
        <div
          :data-id="profile.profileId"
          :data-profile="profile.name"
        >
          <div
            v-if="!editMode"
            ref="dropDownEditMenu"
            :class="{ 'is-active': isActive }"
            class="dropdown"
          >
            <v-menu
              offset-y
              auto
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon
                    size="1em"
                    color="primary"
                  >
                    fa fa-ellipsis-h
                  </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="edit">
                  <v-list-item-title class="pr-5">
                    {{ $t('Edit') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!hasRelatedAccounts"
                  @click="deleteConfirm"
                >
                  <v-list-item-title class="warning--text pr-5">
                    {{ $t('Delete') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div
            v-if="!editMode"
            class="card-content"
          >
            <v-data-table
              class="partner-roles"
              :headers="[{ text: '', value: 'email'}]"
              :items="sortedAccounts"
              :footer-props="{ 'items-per-page-text': '', 'items-per-page-options': [4]}"
              :items-per-page="4"
              hide-default-header
              calculate-widths
              dense
            >
              <template #body="{ items }">
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.email"
                    class="table-row"
                  >
                    <td class="table-cell">
                      {{ item.email }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
          <div class="control-buttons">
            <div
              v-if="editMode"
              class="is-pulled-right"
            >
              <v-btn
                fab
                small
                color="primary"
                @click="cancelEditProfile"
              >
                <v-icon dark>
                  fas fa-times
                </v-icon>
              </v-btn>
              <v-btn
                id="save-partner-button"
                fab
                small
                color="primary"
                :disabled="!isDirty"
                @click="savePartnerProfile"
              >
                <v-icon dark>
                  fas fa-save
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div
            v-if="editMode"
            class="rights"
          >
            <v-row>
              <v-col>
                <profile-permission
                  v-for="(permission, index) in permissions"
                  :key="permission.name"
                  class="right"
                  :permissions="newPermissions"
                  :permission="permission"
                  :partner-id="partnerId"
                  :profile-id="profile.profileId"
                  :index="index"
                  @change="togglePermission(permission)"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <div class="text-center">
      <v-dialog
        v-model="showDeleteDialog"
        width="500"
      >
        <v-card>
          <v-card-title
            class="headline lighten-2"
            primary-title
          />

          <v-card-text v-html="$t('Are you sure you want to delete profile \'PROFILE NAME\'? This action cannot be undone.', {name: profile.name})" />

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              large
              outlined
              @click="showDeleteDialog = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              large
              color="error"
              @click="deleteProfile"
            >
              {{ $t('Delete') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ProfilePermission from '@/components/partner/ProfilePermission'
import {ADMIN_ACCOUNT_PERMISSIONS, ALLOWED_ACCOUNT_PERMISSIONS} from '../../main'

const auth = require('@/service/auth')
const restapi = require('@/service/restapi')
const hash = require('object-hash')

export default {
    name: 'PartnerProfile',
    components: {
        ProfilePermission
    },
    props: ['partnerId', 'profile', 'relatedAccounts'],
    data () {
        return {
            isActive: false,
            editMode: false,
            newPermissions: null,
            showDeleteDialog: false
        }
    },
    computed: {
        provisioningPermissions () {
            return Object.keys(this.filteredPermissions).filter(function (p) { return p.indexOf('Prov.') !== -1 })
        },
        permissions () {
            let permissions = []
            for (let i = 0; i < this.provisioningPermissions.length; i++) {
                const key = this.provisioningPermissions[i].replace(/Prov./, '')
                const value = this.filteredPermissions[`Prov.${key}`]
                if(this.canShowPermission(key)) {
                    permissions.push({ name: key, value: value })
                }
            }
            permissions.sort((a, b) => {
                return ALLOWED_ACCOUNT_PERMISSIONS.indexOf(`Prov.${a.name}`) - ALLOWED_ACCOUNT_PERMISSIONS.indexOf(`Prov.${b.name}`)
            })
            return permissions
        },
        dropdownMenuId () {
            return 'dropdown-menu-' + this.profile.profileId
        },
        hasRelatedAccounts () {
            return this.relatedAccounts.length > 0
        },
        myAccount () {
            return this.$store.getters.myAccount
        },
        isDirty () {
            return hash(this.newPermissions) !== hash(this.filteredPermissions)
        },
        sortedAccounts () {
            let copyOfAccounts = this.relatedAccounts.slice(0)
            return copyOfAccounts.sort(function (a, b) { return (a.email > b.email) ? 1 : ((a.email < b.email) ? -1 : 0) })
        },
        filteredPermissions () {
            let filteredPermissions = {...this.profile.permissions}
            if(!auth.isAdmin()) {
                ADMIN_ACCOUNT_PERMISSIONS.forEach(element => {
                    delete filteredPermissions[element]
                })
            }
            return filteredPermissions
        }
    },
    created () {
        document.addEventListener('click', this.documentClick)
    },
    destroyed () {
        document.removeEventListener('click', this.documentClick)
    },
    mounted () {
        this.newPermissions = Object.assign({}, this.filteredPermissions)
    },
    methods: {
        canShowPermission (key) {
            return this.$t(key) !== 'DELETE'
        },
        toggleDropdown () {
            this.isActive = !this.isActive
            if (!this.isActive) this.editMode = false
        },
        edit () {
            this.editMode = true
            this.isActive = false
            this.$emit('edit', this.profile.profileId)
        },
        togglePermission (permission) {
            this.newPermissions[`Prov.${permission.name}`] = !this.newPermissions[`Prov.${permission.name}`]
        },
        cancelEditProfile () {
            this.editMode = false
            this.$emit('cancel')
            this.newPermissions = Object.assign({}, this.filteredPermissions)
        },
        savePartnerProfile () {
            if (this.isDirty) {
                const that = this
                restapi.updateAccountProfilePermissions(this.partnerId, this.profile.profileId, this.newPermissions)
                    .then(function () {
                        that.editMode = false
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.$t('Updated permissions for ROLENAME role', {name: that.profile.name}),
                            timeout : 4000,
                            color: 'primary'
                        })
                        that.$emit('updated')
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
            } else {
                this.cancelEditProfile()
            }
        },
        deleteProfile () {
            const that = this
            restapi.deleteAccountProfile(this.partnerId, this.profile.profileId)
                .then(function () {
                    that.$emit('remove', that.profile.profileId)
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        deleteConfirm () {
            this.isActive = false
            this.showDeleteDialog = true
        },
        documentClick (e) {
            let el = this.$refs.dropDownEditMenu
            let target = e.target
            if (el && el !== target && !el.contains(target)) {
                this.isActive = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';
  @import '../../assets/styles/vuetify-custom.scss';

  .table-row {
        &:nth-child(odd) {
            background-color: #f8f8f8;
        }
        &.expanded {
            background-color: #f5f5f5;
        }
        .table-cell {
            &:nth-child(1) {
               padding-left: 20px;
            }
            padding: 5px;
            vertical-align: middle;
            font-size: 0.9em;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border: none !important;
            &:hover {
                cursor: pointer;
            }
        }
        .email {
            max-width: 300px;
            width: 300px;
        }
    }

  .role-title {
    max-width: 220px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .help {
    vertical-align: middle;
    display: inline-block;
  }

  .partner-profile {
    max-width: 320px;
    width: 320px;
    margin: 10px;
    h2 {
      color: $primary;
      text-transform: uppercase;
      font-size: 14px;
    }
    .rights {
      .columns {
        &.title-block {
          padding: 20px 20px 0 20px;
          background-color: transparent;
          p {
            font-size: 12px;
          }
        }
        &.rights-label {
          padding: 0 20px;
          p {
            color: $secondary;
            font-size: 12px;
            text-transform: uppercase;
          }
          .column {
            padding-bottom: 0;
          }
        }
        &.rights {
          padding: 20px 20px 20px 0;
          .column {
            .right {
              &:not(:last-child) {
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid $secondary;
              }
            }
          }
        }
      }
    }
    &.edit-mode {
      position: relative;
      padding-top: 60px;
      padding-bottom: 40px;
      max-width: 900px;
      width: 900px;
      margin: 0 auto;
    }
    .edit-icons {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .edit-icon {
      cursor: pointer;
      color: $primary;
      font-size: large;
    }
    .card {
      height: 210px;
      padding: 10px 10px 30px 10px;
      border-radius: $border-radius;
      border: none;
      text-align: left;
      &.has-dropdown {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &.edit-mode {
        height: auto;
        width: 900px;
        max-width: 900px;
      }
      .field {
        .control {
          border: none;
        }
        .select {
          border: none;
        }
      }
      .menu-overlay-background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(10, 10, 10, 0.75);
      }
      .card-title {
        font-weight: bold;
        text-transform: uppercase;
        border-top: none;
        text-align: left;
        &.admin {
          color: $primary;
        }
      }
      .card-content {
        padding: 0.5rem;
        .columns {
          max-height: 160px;
          overflow-x: hidden;
          overflow-y: scroll;
          .column {
            padding: 5px;
            min-width: 100%;
            font-size: small;
            &:nth-child(odd) {
              background-color: $color-shadow-light;
            }
          }
        }
        .pagination {
          position: absolute;
          bottom: 2px;
          right: 10px;
        }
      }
      .rights {
        margin: 20px;
        h3 {
          color: $primary;
          text-transform: uppercase;
          font-size: 1.2em;
          font-weight: bold;        }
      }
      &.inactive {
        background-color: $secondary;
        opacity: 0.75;
        .card-title {
          color: $color-error-dark;
        }
      }
      .select {
        border: none;
      }
    }
    .dropdown {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 20;
      .dropdown-trigger {
        color: $primary;
        font-weight: bold;
        position: absolute;
        top: 0;
        right: 10px;
        cursor: pointer;
        span {
          font-weight: 600;
          font-size: 18px;
        }
      }
      .dropdown-menu {
        position: relative;
        margin-top: 20px;
        .dropdown-content {
          border-radius: $border-radius;
          .dropdown-item {
            text-align: center;
            padding: 0.375rem 1rem;
            text-transform: uppercase;
            color: $primary;
            &.is-danger {
              color: $color-error-dark;
            }
          }
          .dropdown hr {
            height: 1px;
          }
        }
      }
    }
    .control-buttons {
      padding: 0;
      width: 50px;
      display: inline-block;
      position: absolute;
      right: -35px;
      top: 60px;
      button {
        float: right;
        margin-bottom: 10px;
        font-size: 18px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        vertical-align: middle;
        &.cancel-button {
          color: $primary;
          background-color: #FFFFFF;
          border: 2px solid $primary;
        }
        &.save-button {
          color: #FFFFFF;
          background-color: $primary;
        }
      }
    }
    p.error-message {
      bottom: 0;
    }
    .password-strength {
      height: 3px;
      &.worst {
        background: darkred;
        width: 20%;
      }
      &.bad {
        background: orangered;
        width: 40%;
      }
      &.weak {
        background: orange;
        width: 60%;
      }
      &.good {
        background: yellowgreen;
        width: 80%;
      }
      &.strong {
        background: green;
        width: 100%;
      }
    }
    .child.dialog.modal {
      .modal-content {
        width: 90%;
        height: 90%;
        margin: 0;
        background-color: #ffffff;
        padding: 20px;
        border-radius: $border-radius;
        p {
          text-align: center;
          &.title {
            font-size: large!important;
          }
        }
        .button {
          text-transform: none!important;
          &.cancel {
            position: absolute;
            bottom: 10px;
            left: 10px;
            background-color: $color-shadow-light;
          }
          &.confirm {
            position: absolute;
            bottom: 10px;
            right: 10px;
          }
        }
      }
    }
  }
  .partner-login ::v-deep input, .partner-login ::v-deep .input, .partner-login ::v-deep .control {
    height: 25px;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: $color-general-text;
    border-bottom: solid 1px $secondary;
  }
  .partner-login ::v-deep input {
    font-size: 14px;
    border-bottom: 1px solid $secondary;
  }
  .partner-login ::v-deep input:focus, .partner-login ::v-deep .input:focus {
    border-bottom: 1px solid $color-action;
  }
  .partner-login ::v-deep .select select {
    color: $color-general-text;
    font-size: 14px;
    background-color: transparent;
  }
    .pagination-partner-profile{
    position: absolute;
    bottom:0
  }
</style>
