<template>
  <div class="mobile-subscription-wizard">
    <wizard
      ref="mobile-subscription-wizard"
      :steps="steps"
      :on-next="nextClicked"
      :on-back="backClicked"
      :back-label="$t('Back')"
      :next-label="$t('Next')"
      :final-step-label="$t('Done')"
      :class="{ 'wizard-final-step': finalStep, 'wizard-validation-error': wizardValidationError }"
      :state="state"
      class="has-shadow has-rounded-corners"
    >
      <div slot="page1">
        <div class="control-buttons">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            @click="cancelWizard()"
          >
            <v-icon dark>
              fas fa-times
            </v-icon>
          </v-btn>
        </div>
        <div class="step-content">
          <p class="add-key-title">
            {{ $t('Enter the ICCID of your SIM card') }}
          </p>
          <div class="field">
            <div class="has-float-label">
              <v-text-field
                id="add-user-reference-input"
                v-model="selectedSubscriptionICCID"
                type="text"
                :label="$t('ICCID')"
                dense
                autocomplete="no"
                @input="onICCIDInput"
              />
              <p
                v-if="$v.selectedSubscriptionICCID.$error && !$v.selectedSubscriptionICCID.required"
                class="error-message pt-2"
              >
                {{ $t('This is a required field') }}
              </p>
              <p
                v-else-if="$v.selectedSubscriptionICCID.$error && !$v.selectedSubscriptionICCID.minLength"
                class="error-message pt-2"
              >
                {{ $t('The ICCID should contains of 20 numeric characters', { min: $v.selectedSubscriptionICCID.$params.minLength.min}) }}
              </p>
              <p
                v-else-if="$v.selectedSubscriptionICCID.$error && !$v.selectedSubscriptionICCID.maxLength"
                class="error-message pt-2"
              >
                {{ $t('The ICCID should contains of 20 numeric characters', { max: $v.selectedSubscriptionICCID.$params.maxLength.max}) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div slot="page2">
        <div class="control-buttons">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            @click="cancelWizard()"
          >
            <v-icon dark>
              fas fa-times
            </v-icon>
          </v-btn>
        </div>
        <div class="step-content">
          <p class="add-key-title">
            {{ $t('Select a subscription type') }}
          </p>
          <div class="field">
            <div class="has-float-label">
              <v-select
                id="add-user-account-type"
                v-model="selectedSubscriptionSKU"
                :label="$t('SKU')"
                :items="subscriptions"
                :item-text="subscriptionText"
                :item-value="subscriptionValue"
                dense
                :placeholder="$t('Select subscription')"
              />
            </div>
          </div>
        </div>
      </div>
    </wizard>
  </div>
</template>

<script charset="utf-8">
import {required, minLength, maxLength} from 'vuelidate/lib/validators'

export default {
    name: 'MobileSubscriptionWizard',
    props: ['availableSubscriptions', 'subscriptionSKU', 'editMode'],
    data () {
        return {
            steps: [
                {
                    label: '',
                    slot: 'page1'
                },
                {
                    label: '',
                    slot: 'page2'
                }
            ],
            finalStep: false,
            state: 'working',
            wizardValidationError: false,
            selectedSubscriptionICCID: null,
            selectedSubscriptionSKU: null,
            currentPage: 0
        }
    },
    computed: {
        isBrowserIE () {
            return this.$store.getters.isIE
        },
        subscriptions () {
            let subscriptions = []
            if (this.availableSubscriptions) {
                const nofSubscriptions = this.availableSubscriptions.length
                for (let i = 0; i < nofSubscriptions; i++) {
                    subscriptions.push({
                        sku: this.availableSubscriptions[i].sku,
                        dataCap: this.availableSubscriptions[i].dataCap
                    })
                }
            }
            subscriptions = Array.from(subscriptions).sort((a, b) => {
                return a.sku.localeCompare(b.sku, [], {sensitivity: 'base'})
            })
            return subscriptions
        }
    },
    methods: {
        nextClicked (currentPage) {
            this.finalStep = this.steps.length === (currentPage + 1)
            if (currentPage === 0) {
                if (this.editMode) {
                    let subscription = {
                        'sku': this.selectedSubscriptionSKU.sku,
                        'groupType': 'mobile',
                    }
                    this.$emit('onDone', subscription)
                    return true
                } else {
                    if (!this.selectedSubscriptionICCID) {
                        return false
                    }
                    if (this.$v.$invalid) {
                        this.$v.$touch()
                        return false
                    }
                    return true
                }
            } else if (this.finalStep) {
                if (!this.selectedSubscriptionSKU) {
                    return false
                }
                let subscription = {
                    'sku': this.selectedSubscriptionSKU.sku,
                    'groupType': 'mobile',
                    'subscriptionDetails': {
                        'iccid': this.selectedSubscriptionICCID
                    }
                }
                this.$emit('onDone', subscription)
                return true
            }
        },
        subscriptionText: item => `${item.sku}  (${item.dataCap})`,
        subscriptionValue: item => {
            return {
                sku: item.sku,
                dataCap: item.dataCap
            }
        },
        backClicked (currentPage) {
            return true
        },
        cancelWizard () {
            this.currentPage = 0
            this.selectedSubscriptionICCID = null
            this.selectedSubscriptionSKU = null
            this.$emit('onCancel')
        },
        onSelectSubscriptionSKU (item) {
            this.selectedSubscriptionSKU = item
        },
        onICCIDInput () {
            this.$v.selectedSubscriptionICCID.$touch()
        },
        createTextLabel (item) {
            let dataCap = item.dataCap ? ' (' + item.dataCap + ')' : ''
            return item.sku + dataCap
        }
    },
    validations: {
        selectedSubscriptionICCID: {
            required,
            minLength: minLength(20),
            maxLength: maxLength(20)
        }
    },
    mounted () {
        if (!this.editMode) {
            this.steps = [
                {
                    label: '',
                    slot: 'page1'
                },
                {
                    label: '',
                    slot: 'page2'
                }
            ]
        } else {
            this.steps = [
                {
                    label: '',
                    slot: 'page2'
                }
            ]
        }
        if (this.subscriptionSKU) {
            this.selectedSubscriptionSKU = {
                sku: this.subscriptionSKU.sku,
                dataCap: this.subscriptionSKU.dataCap
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../../../../assets/styles/forms";

  .mobile-subscription-wizard {
    .step-content {
      padding: 5rem 10rem;
    }
    .columns {
      margin: 0;
      .column {
        padding: 0 40px;
        margin: 40px 0;
        h2 {
          color: $primary;
          text-transform: uppercase;
          margin: 20px 0;
          font-size: 14px;
        }
        .field {
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .control-buttons {
      padding: 0;
      width: 50px;
      display: inline-block;
      position: absolute;
      right: -30px;
      top: 15px;
      button {
        float: right;
        margin-bottom: 10px;
        font-size: 18px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        vertical-align: middle;
        &.cancel-button {
          color: $primary;
          background-color: #FFFFFF;
          border: 2px solid $primary;
        }
      }
    }
    ::v-deep {
      .wizard__steps, .wizard__arrow {
        display: none !important;
      }
      .wizard__body__actions a.final-step {
        background-color: $primary;
      }
      .wizard__body {
        border: none !important;
        margin: 0;
        padding-bottom: 0;
        min-height: 275px;
      }
      .wizard__body__actions {
        border-top: none !important;
        background-color: #ffffff !important;
        bottom: 20px;
        padding: 0 45px;
      }
      .wizard__body__step {
        padding: 2px 8px;
      }
      .wizard__next, .wizard__back {
        border: none;
        color: #FFFFFF;
        background-color: $primary !important;
        border-radius: $border-radius;
        text-transform: uppercase;
        img {
          display: none !important;
        }
      }
      .wizard-validation-error {
        .wizard__next {
          opacity: 0.5;
        }
      }
      .step-title {
        text-align: left;
        margin-bottom: 20px;
      }
    }
  }

</style>
