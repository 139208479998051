<template>
  <div class="content-wrapper">
    <side-bar>
      <side-bar-item
        name="distributors"
        :text="$t('Distributors')"
        icon="fa fa-th"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
    </side-bar>
    <div class="content">
      <breadcrumb />
      <div
        class="container"
        :class="selectedMenu"
      >
        <add-distributor
          v-if="hasPermission('PartnerAdd') && menuSelected('add-distributor')"
          class="add-distributor"
          :partner-id="partnerId"
          :zoho-id="zohoId"
          @created="createdDistributor"
        />
        <partners
          v-else-if="hasPermission('PartnerOverview') && menuSelected('distributors')"
          id="distributor-list"
          :title="$t('Choose a distributor')"
          :partners="distributors"
          :partner-type="partnerType"
          sort=""
          :initial-sorting="partnerSorting.sorting"
          :initial-sort-order="partnerSorting.order"
          @sort="changePartnerSorting"
        />
        <PermissionWarning v-else-if="hasPermissions() && selectedMenu !== null" />
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
import Partners from '@/components/Partners'
import AddDistributor from '@/components/partner/AddDistributor'
import SideBar from '@/components/base/SideBar'
import SideBarItem from '@/components/base/SideBarItem'
import PermissionWarning from '@/components/base/PermissionWarning'

const restapi = require('@/service/restapi')
const auth = require('@/service/auth')

export default {
    name: 'Admin',
    components: {
        Partners,
        AddDistributor,
        SideBar,
        SideBarItem,
        PermissionWarning
    },
    data () {
        return {
            partnerType: 'Distributor',
            selectedMenu: null,
            defaultMenu: 'distributors',
            distributors: [],
            partnerSorting: {
                sorting: null,
                order: null
            }
        }
    },
    computed: {
        partnerId () {
            return this.$store.getters.myAccount ? this.$store.getters.myAccount.accountId : null
        },
        zohoId () {
            return this.$route.params.zohoid
        },
        isLoggedIn () {
            return this.$store.getters.isAuthorized
        }
    },
    watch: {
        partnerId () {
            this.checkPermission()
        },
        '$route' (to, from) {
            this.selectedMenu = this.$route.params.menu || this.defaultMenu
        },
        distributors: function (newVal, oldVal) {
            let _distributors = this.distributors
            if (_distributors && Array.isArray(_distributors)) {
                for (let i = 0; i < _distributors.length; i++) {
                    let distributor = _distributors[i]
                    if (distributor.active) {
                        if (this.$store.getters.distributorCount[distributor.distributorId] &&
                this.$store.getters.distributorCount[distributor.distributorId].expires > Date.now()) {
                            distributor.count = this.$store.getters.distributorCount[distributor.distributorId].count
                        } else {
                            const that = this
                            restapi.getDistributorCount(distributor.distributorId)
                                .then(function (count) {
                                    distributor.count = count
                                    that.$store.commit('distributorCount', {id: distributor.distributorId, count: count})
                                })
                                .catch(function (error) {
                                    console.error(error)
                                })
                        }
                    }
                }
            }
        }
    },
    mounted () {
        this.$store.commit('crumbs', [])
        if (!this.$route.params.menu) {
            this.$router.push(`/admin/${this.defaultMenu}`)
        } else {
            this.selectedMenu = this.$route.params.menu
        }
        this.loadDistributors()
        this.partnerSorting = this.$cookies.get('adminPartnerSorting') || { sorting: null, order: null }
        this.$cookies.set('adminPartnerSorting', this.partnerSorting, '1y')
    },
    methods: {
        selectMenu (item) {
            this.$router.push({path: '/admin/' + item})
        },
        menuSelected (item) {
            return item === this.selectedMenu
        },
        loadDistributors () {
            let _distributors = []
            const that = this
            restapi.getDistributors()
                .then(function (result) {
                    let distributors = result
                    for (let i = 0; i < distributors.length; i++) {
                        let distributor = distributors[i]
                        distributor.count = {
                            cameraCount: 0,
                            resellerCount: 0,
                            customerCount: 0
                        }
                        _distributors.push(distributor)
                    }
                    that.distributors = _distributors
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        createdDistributor () {
            this.selectMenu(this.defaultMenu)
            this.loadDistributors()
        },
        checkPermission () {
            if (!auth.isAdmin()) this.$router.push({ path: '/' })
        },
        changePartnerSorting (sorting, order) {
            this.partnerSorting.sorting = sorting
            this.partnerSorting.order = order
            this.$cookies.set('adminPartnerSorting', this.partnerSorting, '1y')
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";

  .content-wrapper {
    .content {
      width: 100%;
      background-color: $color-shadow-light;
      .container {
        &.add-distributor {
          width: 900px;
          min-width: 900px;
        }
        &.distributors {
          width: 90%;
          min-width: 90%;
        }
      }
    }
  }
</style>
