<template>
  <v-card elevation="0">
    <v-row>
      <v-col
        cols="auto"
        class="ml-12 pa-0"
        align-self="center"
      >
        <v-icon x-large>
          fas fa-ban
        </v-icon>
      </v-col>
      <v-col>
        <v-card-title>{{ $t("Your account does not have the necessary permissions to view this page.") }}</v-card-title>
        <v-card-text>{{ $t("Please contact an admin if you believe this shouldn't be the case.") }}</v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
    name: 'PermissionWarning',
}
</script>

<style lang="scss" scoped>
</style>