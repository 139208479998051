<template>
  <div
    v-if="hasPermission('PartnerAdd')"
    class="add-reseller"
  >
    <h2>{{ $t('Add new reseller') }}</h2>
    <add-partner
      ref="reseller"
      :partner-id="partnerId"
      :zoho-id="zohoId"
      partner-type="reseller"
      @created="$emit('created')"
    />
  </div>
</template>

<script>
import AddPartner from '@/components/partner/AddPartner'

export default {
    name: 'AddReseller',
    components: {
        AddPartner
    },
    props: ['partnerId', 'zohoId']
}
</script>

<style lang="scss" scoped>
  @import "../../assets/styles/forms";

  .add-reseller {
    h2 {
      color: $primary;
      text-transform: uppercase;
      margin: 50px 0 calc(20px + 0.75rem) 0;
      font-size: 14px;
    }
  }
</style>
