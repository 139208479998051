<template>
  <div class="content-wrapper">
    <side-bar>
      <side-bar-item
        v-if="isDistributor() && !canAddCustomers"
        name="back"
        class="pt-1 pb-1"
        :text="$t('Back')"
        icon="fa fa-arrow-circle-left"
        icon-size="32"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="isPartnerActive && canAddCustomers && hasPermission('CustomerAdd')"
        name="add-customer"
        class="pt-1 pb-1"
        icon-size="32"
        :text="$t('Add customer')"
        icon="fa fa-plus-circle"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="isPartnerActive && hasPermission('CustomerOverview')"
        name="customers"
        :text="$t('Customers')"
        icon="fa fa-th"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="!isMine"
        name="partner-details"
        :text="$t('Details')"
        icon="fa fa-id-card"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="isPartnerActive && !isMine && hasPermission('AccountLogins')"
        name="partner-admins"
        :text="$t('Operators')"
        icon="fa fa-user-plus"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="isPartnerActive && !isMine && hasPermission('AccountProfiles')"
        name="partner-roles"
        :text="$t('Roles')"
        icon="fa fa-align-left"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="isPartnerActive && hasPermission('ManageOauthClient') && !isMine"
        name="api-keys"
        :text="$t('Api keys')"
        icon="fa fa-key"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="isPartnerActive && isAdmin() && hasPermission('AdminPartner') && !isMine"
        name="admin-tools"
        :text="$t('Admin tools')"
        icon="fa fa-cog"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
    </side-bar>
    <div class="content">
      <breadcrumb />
      <div
        class="container"
        :class="selectedMenu"
      >
        <section
          v-if="reseller && !reseller.active"
          class="section notification-section"
        >
          <div v-if="menuSelected('partner-details')">
            <div
              class="user-notification"
              v-html="$t('This partner is inactive', {link: getLinkToDetailsPage() })"
            />
          </div>
          <div v-else>
            <div
              class="user-notification"
              v-html="$t('This partner is inactive - link', {link: getLinkToDetailsPage() })"
            />
          </div>
        </section>
        <add-customer
          v-if="hasPermission('CustomerAdd') && menuSelected('add-customer')"
          :partner-id="partnerId"
          :subscriptions="subscriptions"
          :exclusive-third-party-authentication="exclusiveThirdPartyAuthentication"
          :zoho-id="zohoId"
          @created="createdCustomer"
        />
        <customer-list
          v-else-if="hasPermission('CustomerOverview') && menuSelected('customers')"
          :count="customerCount"
          :partner-id="partnerId"
        />
        <partner-details
          v-else-if="hasPermission('PartnerOverview') && menuSelected('partner-details')"
          id="reseller-details"
          :partner="reseller"
          partner-type="Reseller"
          :customer-count="customerCount"
          :is-billable-entity="isBillableEntity"
          @partnerDeactivated="partnerDeactivated"
          @partnerReactivated="partnerReactivated"
          @transfered="partnerTransfered"
        />
        <partner-logins
          v-else-if="hasPermission('AccountLogins') && menuSelected('partner-admins')"
          id="partner-logins"
          :partner-id="partnerId"
        />
        <partner-profiles
          v-else-if="hasPermission('AccountProfiles') && menuSelected('partner-roles')"
          id="partner-profiles"
          :partner-id="partnerId"
        />
        <api-keys
          v-else-if="hasPermission('ManageOauthClient') && menuSelected('api-keys')"
          id="api-keys"
          :partner="reseller"
          :partner-id="partnerId"
        />
        <partner-modules
          v-else-if="isAdmin() && hasPermission('AdminPartner') && menuSelected('admin-tools')"
          id="partner-modules"
          :partner="reseller"
        />
        <PermissionWarning v-else-if="hasPermissions() && selectedMenu !== null" />
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
import AddCustomer from '@/components/account/AddCustomer'
import Partners from '@/components/Partners'
import PartnerDetails from '@/components/partner/PartnerDetails'
import PartnerLogins from '@/components/partner/PartnerLogins'
import PartnerProfiles from '@/components/partner/PartnerProfiles'
import CustomerList from '@/components/partner/CustomerList'
import ApiKeys from '@/components/partner/ApiKeys'
import PartnerModules from '@/components/partner/PartnerModules'
import { mapGetters } from 'vuex'
import SideBar from '@/components/base/SideBar'
import SideBarItem from '@/components/base/SideBarItem'
import PermissionWarning from '@/components/base/PermissionWarning'

const restapi = require('@/service/restapi')
const auth = require('@/service/auth')

export default {
    name: 'Reseller',
    data () {
        return {
            selectedMenu: null,
            defaultMenu: 'customers',
            subscriptions: [],
            distributor: null,
            reseller: null,
            count: null,
            modules: {}
        }
    },
    computed: {
        partnerId () {
            return this.$route.params.id
        },
        zohoId () {
            return this.$route.params.zohoid
        },
        isBrowserIE () {
            return this.$store.getters.isIE
        },
        isMine () {
            return this.partnerId === this.$store.getters.myAccount.accountId.toString()
        },
        ...mapGetters([
            'partners',
            'partnerDetails'
        ]),
        isPartnerActive () {
            return this.reseller && this.reseller.active
        },
        customerCount () {
            if (this.count) {
                return this.count.customerCount
            }
            return null
        },
        exclusiveThirdPartyAuthentication () {
            return this.modules.hasOwnProperty('ExclusiveThirdPartyAuthentication') ? this.modules.ExclusiveThirdPartyAuthentication : false
        },
        isBillableEntity () {
            return this.distributor && this.reseller ? this.distributor.crmId !== null || this.reseller.crmId !== null : null
        },
        canAddCustomers () {
            return auth.isReseller() || this.isBillableEntity
        }
    },
    components: {
        AddCustomer,
        CustomerList,
        PartnerDetails,
        PartnerLogins,
        PartnerProfiles,
        ApiKeys,
        PartnerModules,
        SideBar,
        SideBarItem,
        PermissionWarning
    },
    watch: {
        partnerId () {
            this.loadReseller()
        },
        reseller () {
            if (this.reseller) {
                this.checkPermission()
                if (!this.reseller.active) {
                    this.selectMenu('partner-details')
                }
                if (auth.isDistributor()) {
                    const that = this
                    restapi.getDistributor(this.reseller.distributorId)
                        .then(function (result) {
                            that.distributor = result
                        })
                        .catch(function (error) {
                            console.error(error)
                        })
                        .then(function () {
                            that.setBreadCrumbs()
                        })
                } else {
                    this.setBreadCrumbs()
                }
            }
        },
        distributor () {
            this.setBreadCrumbs()
        },
        '$route' (to, from) {
            this.selectedMenu = this.$route.params.menu || this.defaultMenu
            if (this.reseller && !this.reseller.active && this.selectedMenu !== 'partner-details') {
                this.selectMenu('partner-details')
            }
        }
    },
    mounted () {
        this.loadReseller()
        if (!this.$route.params.menu) {
            this.$router.replace({path: `/reseller/${this.partnerId}/${this.defaultMenu}`})
        } else {
            this.selectedMenu = this.$route.params.menu
        }
    },
    updated () {
        if (this.$route.params.inactive) {
            this.showActive = !(this.$route.params.inactive === 'true')
        }
    },
    methods: {
        partnerDeactivated () {
        },
        partnerReactivated () {
        },
        partnerTransfered () {
            this.loadReseller()
        },
        getLinkToDetailsPage () {
            return '/#/reseller/' + this.partnerId + '/partner-details'
        },
        selectMenu (item) {
            if (item === 'back') {
                this.$router.push({path: `/distributor/${this.reseller.distributorId}/resellers`})
            } else {
                this.$router.push({path: `/reseller/${this.partnerId}/${item}`})
            }
        },
        menuSelected (item) {
            return item === this.selectedMenu
        },
        isAdmin () {
            return auth.isAdmin()
        },
        isDistributor () {
            return auth.isDistributor()
        },
        loadReseller () {
            const that = this
            if (that.partnerId) {
                restapi.getReseller(that.partnerId)
                    .then(function (result) {
                        that.reseller = result
                        restapi.getResellerCount(that.partnerId)
                            .then(function (count) {
                                that.count = count
                            })
                            .catch(function (error) {
                                console.error(error)
                            })
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
                    .then(function () {
                        restapi.getResellerSubscriptions(that.partnerId)
                            .then(function (result) {
                                that.subscriptions = result
                            })
                            .catch(function (error) {
                                console.error(error)
                            })
                    })
                    .then(function () {
                        if (auth.isAdmin() || auth.isDistributor()) {
                            restapi.getDistributor(that.reseller.distributorId)
                                .then(function (result) {
                                    that.distributor = result
                                })
                                .catch(function (error) {
                                    console.error(error)
                                })
                                .then(function () {
                                    that.setBreadCrumbs()
                                })
                        }
                    })
                    .then(function () {
                        if (auth.isAdmin()) {
                            restapi.getResellerModules(that.partnerId)
                                .then(function (result) {
                                    that.modules = result
                                })
                                .catch(function (error) {
                                    console.error(error)
                                })
                        }
                    })
            }
        },
        deactivatedReseller () {
        },
        reactivatedReseller () {
        },
        checkPermission () {
            if (!auth.isAdmin()) {
                if (auth.isDistributor() && this.reseller.distributorId !== auth.getPartnerId()) {
                    this.$router.push({ path: '/distributor/' + auth.getPartnerId() })
                } else if (auth.isReseller() && this.reseller.resellerId !== auth.getPartnerId()) {
                    this.$router.push({path: '/reseller/' + auth.getPartnerId()})
                }
            }
        },
        createdCustomer (id) {
            console.log('Created customer')
            if (id) {
                const that = this
                restapi.getAccounts(this.reseller.resellerId, id)
                    .then(function (account) {
                        that.$store.commit('addAccount', account)
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
            }
            this.selectMenu(this.defaultMenu)
        },
        setBreadCrumbs () {
            let crumbs = []
            let crumb
            if (auth.isAdmin()) {
                crumb = {route: '/', name: 'Distributors'}
                crumbs.push(crumb)
            }
            if (auth.isDistributor()) {
                crumb = {
                    route: `/distributor/${this.reseller.distributorId}`,
                    name: this.distributor.companyName
                }
                crumbs.push(crumb)
            }
            crumb = {
                route: `/reseller/${this.reseller.resellerId}`,
                name: this.reseller.companyName
            }
            crumbs.push(crumb)
            this.$store.commit('crumbs', crumbs)
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../assets/styles/forms';

  .content-wrapper {
    .content {
      width: 100%;
      background-color: $color-shadow-light;
      .is-transparent {
        background-color: transparent;
      }
      .container {
        width: 90%;
        min-width: 90%;
        .notification-section {
          margin: 0px;
          padding: 0px;
          text-align: center;
          .user-notification {
            color: #fff;
            background: #D43536;
            border-radius: 2em;
            align-self: center;
            display: inline-block;
            margin: 0;
            text-align: center;
            padding: 0.75em 1.5em;
            pointer-events: auto;
            ::v-deep a {
              color: #ffffff;
              text-decoration: underline;
            }
          }
        }
        @media screen and (min-width: 769px) {
          &.customers {
            width: 900px;
            min-width: 900px;
          }
          &.partner-details {
            width: 900px;
            min-width: 900px;
          }
          &.partner-admins {
            width: 90%;
            min-width: 90%;
          }
          &.partner-roles {
            width: 90%;
            min-width: 90%;
          }
          &.add-customer {
            width: 900px;
            min-width: 900px;
          }
          &.partner-modules {
            width: 900px;
            min-width: 900px;
          }
          &.admin-tools {
            width: 900px;
            min-width: 900px;
          }
        }
      }
    }
    box-shadow: none;
  }

  .table-title.columns {
    background-color: transparent;
  }

  .control.no-border {
    border: none;
  }

  .has-float-label label.read-only, .has-float-label > span.read-only {
    top: 0;
  }

  .title {
    margin-bottom: 25px;
  }

  .header {
    color: $color-action;
    font-size: 36px;
    font-weight: normal;
    line-height: 42px;
    text-align: center;
    padding-top: 80px;
    padding-bottom: 20px;
    margin: 10px 0;
  }
</style>
