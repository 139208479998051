<template>
  <v-menu
    z-index="10000"
    :close-on-content-click="false"
    bottom
    offset-y
  >
    <template #activator="{ on: onMenu }">
      <v-avatar
        color="primary"
        class="avatar"
        v-on="onMenu"
      >
        <v-tooltip bottom>
          <template #activator="{ on: onTooltip }">
            <span
              class="white--text headline"
              v-on="onTooltip"
              @click="onMenu"
            >{{ firstLetter }}</span>
          </template>
          <div class="boldText">
            {{ $t("My Account") }}
          </div>
          <div>{{ name }}</div>
          <div>{{ email }}</div>
        </v-tooltip>
      </v-avatar>
    </template>
    <v-list
      width="300px"
      two-line
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ name }}</v-list-item-title>
          <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mx-4" />
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <a
              id="logout"
              class="float-right mr-5"
              @click="logout"
            >{{ $t('logout') }}</a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import auth from '@/service/auth'

export default {
    name: 'MyAccountMenu',
    data () {
        return {
            showSettings: false
        }
    },
    computed: {
        name () {
            return this.email ? this.email.split('@')[0] : null
        },
        firstLetter () {
            return this.email ? this.email.charAt(0).toUpperCase() : null
        },
        email () {
            return this.$store.getters.myAccount ? this.$store.getters.myAccount.email : null
        }
    },
    methods: {
        logout () {
            auth.doLogout(false)
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/styles/main";
  @import "../../assets/styles/custom";
</style>
