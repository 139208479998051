import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies'

import locale from '@/service/languageLoader'
let messages = locale.messages

Vue.use(VueI18n)
Vue.use(VueCookies)

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages
})

Vue.prototype.$locale = {
    setLanguage (lang) {
        i18n.locale = lang
        window.$cookies.set('lang', lang, '1y')
    // After replacing ZenDesk with Zoho Desk this does not work anymore
    // // eslint-disable-next-line no-undef
    // zE(function () { zE.setLocale(lang) })
    },
    getCurrentLanguage () {
        return i18n.locale
    }
}

export default {
    i18n: i18n
}
