import axios from 'axios'
import axiosCancel from 'axios-cancel'
import auth from '@/service/auth'
import { store } from '@/store/index'
import { getConfiguration } from '@/service/main'
import { saveAs } from 'file-saver'
import CustomError from '@eencloud/core-components/src/classes/CustomError'
import { ALLOWED_ACCOUNT_PERMISSIONS } from '../main'
import apiList from "@/assets/errors/apiList.json";

const config = getConfiguration()

const resource = axios.create({
  baseURL: config.apiServer
})

resource.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    console.error('Token expired')
    auth.resetClient()
    window.location = auth.oauthUri()
  }

  return Promise.reject(error);
})

function getErrorCode(apiName) {
  const result = apiList.find(api => api.name === apiName).api_code
  return result ? result : 1
}

axiosCancel(resource, { debug: false })

export function cancelAll () {
  resource.cancelAll()
}

export function createSession () {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.post('/rest/v2.0/users/self/sessions', null, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error('Could not create new session. Token expired?')
      console.error(reason)
      store.commit('session', null)
      throw reason
    })
}

export function getEntityByZohoId (zohoId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get('/rest/v2.0/external/zoho/entities/' + zohoId, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getZohoEntityByIdAndType (entityId, entityType) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get('/rest/v2.0/external/zoho/entities?entityId=' + entityId + '&entityType=' + entityType, {
    headers: {'Authorization': 'Bearer ' + auth.getToken()}
  })
    .then(function (response) {
      if (Array.isArray(response.data) && response.data.length > 0) {
        return Promise.resolve(response.data[0])
      }
      return Promise.resolve(null)
    })
    .catch(function (reason) {
      throw reason
    })
}

export function getZohoAccountById (zohoId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get('/rest/v2.0/external/zoho/accounts/' + zohoId, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function createZohoMapping (zohoId, entityId, entityType) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  const data = {
    zohoId: zohoId,
    entityId: entityId,
    entityType: entityType
  }

  return resource.post('/rest/v2.0/external/zoho/entities/', data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('CREATE_ZOHO_MAPPING'), error.detail)
    })
}

export function updateZohoMapping (oldZohoId, zohoId, entityId, entityType) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  const data = {
    zohoId: zohoId,
    entityId: entityId,
    entityType: entityType
  }

  return resource.put('/rest/v2.0/external/zoho/entities/' + oldZohoId, data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('CREATE_ZOHO_MAPPING'), error.detail)
    })
}

export function getWalkthroughStatus () {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get('/rest/v2.0/users/self/portal/prefs/walkthrough', {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function setWalkthroughStatus (isShown) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  const data = {
    isShown: isShown
  }

  return resource.put('/rest/v2.0/users/self/portal/prefs/walkthrough', data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getResellers (distributorId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get('/rest/v2.0/resellers' + (distributorId !== undefined ? '?distributorId=' + distributorId : ''), {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      // Hack to filter 'invalid' reseller data
      return Promise.resolve(response.data.filter(function(r){return r.resellerId}))
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getReseller (id) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.0/resellers/${id}`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getResellerCount (id) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.0/resellers/${id}/count`, {
    requestId: `get_reseller_count_${id}`,
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getResellerDetails (id) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.0/resellers/${id}/details`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function setReseller (id, reseller) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch(`/rest/v2.0/resellers/${id}`, reseller, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('SET_RESELLER'), error.detail)
    })
}

export function setResellerDetails (id, reseller) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch(`/rest/v2.0/resellers/${id}/details`, reseller, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function() {
      return Promise.resolve(true)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('UPDATE_RESELLER'), error.detail)
    })
}

export function addReseller (reseller) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.post('/rest/v2.0/resellers', reseller, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      const partner = response.data
      const id = partner.resellerId
      return setResellerDetails(id, reseller)
        .then(function (response) {
          return Promise.resolve(partner)
        })
        .catch(function(reason) {
          let error = reason.response.data
          throw new CustomError(error.code, getErrorCode('ADD_RESELLER'), error.detail)
        })
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('ADD_RESELLER'), error.detail)
    })
}

export function moveResellerToDistributor (resellerId, distributorId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.put(`/rest/v2.0/resellers/${resellerId}/distributor/${distributorId}`, null, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(error) {
      if (error.response && error.response.status === 409) {
        return Promise.reject(409)
      } else {
        throw error
      }
    })
}

export function getDistributors () {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get('/rest/v2.0/distributors', {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getDistributor (id) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.0/distributors/${id}`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getDistributorCount (id) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.0/distributors/${id}/count`, {
    requestId: `get_distributor_count_${id}`,
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getDistributorDetails (id) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.0/distributors/${id}/details`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function setDistributor (id, distributor) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch(`/rest/v2.0/distributors/${id}`, distributor, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('SET_DISTRIBUTOR'), error.detail)
    })
}

export function setDistributorDetails (id, distributor) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch(`/rest/v2.0/distributors/${id}/details`, distributor, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function() {
      return Promise.resolve(true)
    })
    .catch(function(reason) {
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('UPDATE_DISTRIBUTOR'), error.detail)
    })
}

export function addDistributor (distributor) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.post('/rest/v2.0/distributors', distributor, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      const partner = response.data
      const id = partner.distributorId
      return setDistributorDetails(id, distributor)
        .then(function(response) {
          return Promise.resolve(partner)
        })
        .catch(function(reason) {
          let error = reason.response.data
          throw new CustomError(error.code, getErrorCode('ADD_DISTRIBUTOR'), error.detail)
        })
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('ADD_DISTRIBUTOR'), error.detail)
    })
}

export function getPartner (id, type) {
  if (type.toLowerCase() === 'reseller') {
    return this.getReseller(id)
  } else if (type.toLowerCase() === 'distributor') {
    return this.getDistributor(id)
  }
}

export function setPartner (id, type, partner) {
  if (type.toLowerCase() === 'reseller') {
    return this.setReseller(id, partner)
  } else if (type.toLowerCase() === 'distributor') {
    return this.setDistributor(id, partner)
  }
}

export function getPartnerCount (id, type) {
  if (type.toLowerCase() === 'reseller') {
    return this.getResellerCount(id)
  } else if (type.toLowerCase() === 'distributor') {
    return this.getDistributorCount(id)
  }
}

export function getPartnerDetails (id, type) {
  if (type.toLowerCase() === 'reseller') {
    return this.getResellerDetails(id)
  } else if (type.toLowerCase() === 'distributor') {
    return this.getDistributorDetails(id)
  }
}

export function setPartnerDetails (id, type, partner) {
  if (type.toLowerCase() === 'reseller') {
    return this.setResellerDetails(id, partner)
  } else if (type.toLowerCase() === 'distributor') {
    return this.setDistributorDetails(id, partner)
  }
}

export function addPartner(type, partner) {
  if (type.toLowerCase() === 'reseller') {
    return this.addReseller(partner)
  } else if (type.toLowerCase() === 'distributor') {
    return this.addDistributor(partner)
  }
}

export async function getResellerAccounts (resellerId, active = null, cache = true) {
  const fetchSize = 200
  let fetchNext = true
  let lastAccountId = null
  const activeLoaded = store.getters.resellerAccounts.activeLoaded
  const inactiveLoaded = store.getters.resellerAccounts.inactiveLoaded

  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  // Checking if we loaded all accounts or (in)active ones. In case of all, 'active' is null.
  const loaded = !!((activeLoaded && inactiveLoaded) || (active && activeLoaded) || (!active && inactiveLoaded))

  if (store.getters.resellerAccounts.resellerId != resellerId || !cache || !loaded) {
    // Only clear the store if this is another reseller
    if (store.getters.resellerAccounts.resellerId != resellerId)
      store.commit('resellerAccounts', null)
    
    while (fetchNext) { 
      let nextChunk = await getAccounts(resellerId, null, lastAccountId, fetchSize, active)
      if (nextChunk && nextChunk.length > 0 && store.getters.selectedReseller == resellerId) {
        store.commit('resellerAccounts', {
          resellerId: resellerId,
          accounts: nextChunk
        })
        lastAccountId = nextChunk[nextChunk.length-1].accountId
        fetchNext = nextChunk.length === fetchSize
      } else {
        fetchNext = false
      }
    }

    //set to loaded even though fetching is still in progress, because it will be fetched in the background
    if (active === null) {
      store.commit('activeAccountsLoaded' , true)
      store.commit('inactiveAccountsLoaded', true)
    } else if (active) 
      store.commit('activeAccountsLoaded' , true)
    else store.commit('inactiveAccountsLoaded' , true)
    
  }

  return store.getters.resellerAccounts.accounts
}

export function getAccounts (resellerId, accountId, from = null, limit = 200, active = null) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.4/resellers/${resellerId}/accounts` + (accountId ? `/${accountId}` : '?' + (from ? `from=${from}&` : '') + `limit=${limit}` + (active !== null ? `&active=${active}` : '')), {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      let account = response.data
      if (accountId) {
        const attributes = ['city', 'zipCode', 'currency', 'blocked']
        attributes.forEach(function (attribute) {
          if (!account.hasOwnProperty(attribute)) account[attribute] = null
        })
      }
      return Promise.resolve(account)
    })
    .catch(function(error) {
      if (error.response && error.response.status === 404) {
        return Promise.resolve(null)
      } else {
        throw error
      }
    })
}

export function getAccountUsers (resellerId, accountId, userId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.0/resellers/${resellerId}/accounts/${accountId}/users` + (userId ? `/${userId}` : ''), {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getAccountOwner (resellerId, accountId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.0/resellers/${resellerId}/accounts/${accountId}/users?role=owner`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data[0])
    })
    .catch(function(reason) {
      throw reason
    })
}

export function addAccount (resellerId, data) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.post(`/rest/v2.0/resellers/${resellerId}/accounts`, data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('ADD_ACCOUNT'), error.detail)
    })
}

export function setAccount (resellerId, accountId, data) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch(`/rest/v2.4/resellers/${resellerId}/accounts/${accountId}`, data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('SET_ACCOUNT'), error.detail)
    })
}

export function setAccountUser (resellerId, accountId, userId, data) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch(`/rest/v2.0/resellers/${resellerId}/accounts/${accountId}/users/${userId}`, data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function setAccountUserPassword (resellerId, accountId, userId, password) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch(`/rest/v2.0/resellers/${resellerId}/accounts/${accountId}/users/${userId}/credentials`, {
    usernameAndPassword: {
      password: password
    }
  }, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getApiKeys (partnerId, clientId=null) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  let apiCall = '/rest/v2.0/accounts/' + partnerId + '/credentials/oauthclients'
  if (clientId) {
    apiCall += '/' + clientId
  }

  return resource.get(apiCall, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('GET_API_KEYS'), error.detail)
    })
}

export function createApiKey (partnerId, clientId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  const data = {
    clientId: clientId
  }

  return resource.post('/rest/v2.0/accounts/' + partnerId + '/credentials/oauthclients', data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('ADD_API_KEY'), error.detail)
    })
}

export function deleteApiKey (partnerId, clientId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.delete('/rest/v2.0/accounts/' + partnerId + '/credentials/oauthclients/' + clientId, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('DELETE_API_KEY'), error.detail)
    })
}

export function getAccountSubscription (resellerId, userId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get('/rest/v2.0/resellers/' + resellerId + '/accounts/' + userId + '/subscriptions/current', {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getAccountSubscriptions (resellerId, userId, groupType) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get('/rest/v2.4/resellers/' + resellerId + '/accounts/' + userId + '/subscriptions', {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      let subscriptionsData = response.data
      if (groupType === 'cloud' || groupType === 'mobile' || groupType === 'addOn') {
        subscriptionsData = subscriptionsData.filter(function (subscription) {
          return subscription.groupType === groupType
        })
      }
      return Promise.resolve(subscriptionsData)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('GET_ACCOUNT_SUBSCRIPTIONS'), error.detail)
    })
}

export function getAccountMobileSubscriptionsSummary (resellerId, userId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get('/rest/v2.4/resellers/' + resellerId + '/accounts/' + userId + '/sims/all/summary', {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('GET_MOBILE_SUBSCRIPTIONS'), error.detail)
    })
}

export function getMobileSubscriptionDataUsage (resellerId, accountId, iccid) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.4/resellers/${resellerId}/accounts/${accountId}/sims/${iccid}/dataUsage`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('GET_MOBILE_SUBSCRIPTION_DATA_USAGE'), error.detail)
    })
}

export function getAccountAvailableSubscriptions (resellerId, userId, groupType) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.3/resellers/${resellerId}/accounts/${userId}/availableSubscriptions` + (groupType ? `?groupType=${groupType}` : '') , {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('GET_ACCOUNT_AVAILABLE_SUBSCRIPTIONS'), error.detail)
    })
}

export function createAccountSubscription (resellerId, userId, data) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.post(`/rest/v2.4/resellers/${resellerId}/accounts/${userId}/subscriptions`, data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('ADD_ACCOUNT_SUBSCRIPTION'), error.detail)
    })
}

export function updateAccountMobileSubscription (resellerId, userId, subscriptionId, data) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch(`/rest/v2.3/resellers/${resellerId}/accounts/${userId}/subscriptions/${subscriptionId}`, data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('UPDATE_MOBILE_SUBSCRIPTION'), error.detail)
    })
}

export function updateAccountAddonSubscription (resellerId, userId, subscriptionId, data) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch(`/rest/v2.4/resellers/${resellerId}/accounts/${userId}/subscriptions/${subscriptionId}`, data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('UPDATE_ADDON_SUBSCRIPTION'), error.detail)
    })
}

export function deleteAccountSubscription (resellerId, accountId, subscriptionId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.delete(`/rest/v2.4/resellers/${resellerId}/accounts/${accountId}/subscriptions/${subscriptionId}`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('DELETE_ACCOUNT_SUBSCRIPTION'), error.detail)
    })
}

export function updateAccountSubscription (resellerId, userId, nextSubscriptionId, effectiveToday = null) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  let data = {
    "nextSubscriptionId": nextSubscriptionId
  }

  if (effectiveToday !== null) {
    data.effectiveToday = effectiveToday
  }

  return resource.patch(`/rest/v2.0/resellers/${resellerId}/accounts/${userId}/subscriptions/current`, data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('UPDATE_ACCOUNT_SUBSCRIPTION'), error.detail)
    })
}

export function cancelAccountSubscription (resellerId, userId, effectiveToday = null) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  let data = {
    "deactivate": true
  }

  if (effectiveToday !== null) {
    data.effectiveToday = effectiveToday
  }

  return resource.patch(`/rest/v2.0/resellers/${resellerId}/accounts/${userId}/subscriptions/current`, data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('CANCEL_ACCOUNT_SUBSCRIPTION'), error.detail)
    })
}

export function getLanguages (cache = true) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  if (cache && store.getters.languages && store.getters.languages.length > 0) {
    return Promise.resolve(store.getters.languages)
  }

  return resource.get('/rest/v2.1/languages', {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      let languages = []
      const result = response.data
      const txt = document.createElement('textarea')
      for (let i = 0; i < result.length; i++) {
        txt.innerHTML = result[i]['name']
        const l = {
          value: result[i]['key'],
          text: txt.value
        }
        languages.push(l)
      }
      store.commit('languages', languages)
      return Promise.resolve(languages)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getCountries (cache = true) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  if (cache && store.getters.countries && store.getters.countries.length > 0) {
    return Promise.resolve(store.getters.countries)
  }

  return resource.get('/rest/v2.1/countries', {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      let countries = []
      const result = response.data
      for (let i = 0; i < result.length; i++) {
        const c = {
          value: result[i]['code'],
          text: result[i]['name']
        }
        countries.push(c)
      }
      store.commit('countries', countries)
      return Promise.resolve(countries)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function downloadHelpdeskJnlp (accountId, userId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`rest/v2.1/resellers/self/accounts/${accountId}/users/${userId}/applications/desktop/helpdesk`, {
    responseType: 'blob',
    headers: {
      'Authorization': 'Bearer ' + auth.getToken(),
      'Accept': 'application/json,application/x-java-jnlp-file'
    }
  })
    .then(function(response) {
      saveAs(response.data, 'DesktopApp.jnlp')
      return Promise.resolve(true)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('DOWNLOAD_HELPDESK_JNLP'), error.detail)
    })
}

export function getMobileHelpdeskUrl (accountId, userId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`rest/v2.2/resellers/self/accounts/${accountId}/users/${userId}/applications/mobile/helpdesk`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('GET_MOBILE_HELPDESK_URL'), error.detail)
    })
}

export function getWebappHelpdeskUrl (accountId, userId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`rest/v2.3/resellers/self/accounts/${accountId}/users/${userId}/applications/webapp/helpdesk`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getResellerSubscriptions (resellerId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.3/resellers/${resellerId}/subscriptions`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getResellerSubscription (resellerId, subscriptionId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.3/resellers/${resellerId}/subscriptions/${subscriptionId}`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getCamerasSummary (resellerId, accountId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.1/resellers/${resellerId}/accounts/${accountId}/cameras/all/summary`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('GET_ENDUSER_CAMERAS'), error.detail)
    })
}

export function getCameraDetails (resellerId, accountId, cameraId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.1/resellers/${resellerId}/accounts/${accountId}/cameras/${cameraId}/summary/details`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('GET_CAMERA_DETAILS'), error.detail)
    })
}

export function moveAccountToReseller (resellerId, accountId, targetResellerId, subscriptionId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  const data = {
    'subscriptionId': subscriptionId
  }

  return resource.put(`/rest/v2.1/resellers/${resellerId}/accounts/${accountId}/reseller/${targetResellerId}`, data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('MOVE_ACCOUNT_TO_RESELLER'), error.detail)
    })
}

export function getResellerModules (resellerId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.1/resellers/${resellerId}/modules`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getPolicyData (partnerId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.1/accounts/${partnerId}/policyAcceptance`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function addPolicy (partnerId, version, accepted) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  const data = {
    'version': version,
    'accepted': accepted
  }

  return resource.post(`/rest/v2.1/accounts/${partnerId}/policyAcceptance`, data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(error) {
      throw error
    })
}

export function updatePolicy (partnerId, version, accepted) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  const data = {
    'version': version,
    'accepted': accepted
  }

  return resource.patch(`/rest/v2.1/accounts/${partnerId}/policyAcceptance`, data, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(error) {
      throw error
    })
}

export function searchUser (search, limit = 200) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  let searchEncoded = encodeURIComponent(search)

  return resource.get(`/rest/v2.1/searchUser?searchString=${searchEncoded}&limit=${limit}`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function searchCameras (search, limit = 200) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  let searchEncoded = encodeURIComponent(search)

  return resource.get(`/rest/v2.4/searchCameras?searchString=${searchEncoded}&limit=${limit}`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getReportInfo (partnerId, reportType) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')
  return resource.get(`/rest/v2.2/reports/${reportType}/today` + (partnerId!==null ? `?targetResellerId=${partnerId}` : ''), {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function downloadReport (partnerId, reportType, partnerName, generationDate, downloadName) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  const data = {
    responseType: 'blob',
    headers: {
      'Authorization': 'Bearer ' + auth.getToken(),
      'Accept': 'application/json,text/csv'
    }
  }

  return resource.get(`/rest/v2.2/reports/${reportType}/today.csv` + (partnerId!==null ? `?targetResellerId=${partnerId}` : ''), data)
    .then(function(response) {
      saveAs(response.data, `${downloadName}_${partnerName}_${generationDate}.csv`)
      return Promise.resolve(true)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getPartnerAccounts (partnerId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.2/accounts/${partnerId}/users`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getPartnerAccount (partnerId, userId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.2/accounts/${partnerId}/users/${userId}`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function addPartnerAccount (partnerId, account) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.post(`/rest/v2.2/accounts/${partnerId}/users`, account, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('ADD_PARTNER_ACCOUNT'), error.detail)
    })
}

export function updatePartnerAccount (partnerId, userId, account) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch(`/rest/v2.2/accounts/${partnerId}/users/${userId}`, account, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('UPDATE_PARTNER_ACCOUNT'), error.detail)
    })
}

export function deletePartnerAccount (partnerId, userId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.delete(`/rest/v2.2/accounts/${partnerId}/users/${userId}`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function() {
      return Promise.resolve(true)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getUserToken (accountId, userId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.post(`/rest/v2.2/resellers/self/accounts/${accountId}/users/${userId}/token`, null, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getCurrentUser () {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.2/users/self`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      throw reason
    })
}

export function getAccountStatus(token) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get('/rest/v2.0/users/self/status', {
      headers: {'Authorization': `Bearer ${token}`}
    }
  ).then(function (response) {
    return Promise.resolve(response.data)
  }).catch(function (reason) {
    console.error(reason)
    throw reason
  })
}

export function setAccountStatus(expirationDate) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch('/rest/v2.4/users/self/status', { allowsHelpdeskExpirationDate: expirationDate }, {
      headers: {'Authorization': 'Bearer ' + auth.getToken()}
    })
    .then(function () {
      return Promise.resolve(true)
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
}

export function getAccountProfiles(accountId, profileId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.2/accounts/${accountId}/permissionProfiles` + (profileId ? `/${profileId}` : ''), {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function (response) {
      let profiles;
      let isArrayResponse = Array.isArray(response.data) && response.data.length > 0
      if (isArrayResponse) {
        profiles = response.data
      } else {
        profiles = [response.data]
      }
      // filter out the profiles that are not allowed to show in the frontend
      let filteredProfiles = [];
      profiles.forEach( profile => {
        let profilePermissions = profile.permissions
        let profilePermissionsKeys = Object.keys(profilePermissions)
        let filteredPermissions = {}
        profilePermissionsKeys.forEach( profileKey => {
          if(ALLOWED_ACCOUNT_PERMISSIONS.includes(profileKey)) {
            filteredPermissions[profileKey] = profilePermissions[profileKey]
          }
        })
        let updatedProfile = {
          "name": profile.name,
          "profileId": profile.profileId,
          "permissions": filteredPermissions
        }
        filteredProfiles.push(updatedProfile)
      })
      return Promise.resolve(isArrayResponse ? filteredProfiles : filteredProfiles[0])
    })
    .catch(function (reason) {
      console.error(reason)
      throw reason
    })
}

export function createAccountProfile (accountId, profile) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.post(`/rest/v2.2/accounts/${accountId}/permissionProfiles`, profile, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function(response) {
      return Promise.resolve(response.data)
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('ADD_PERMISSION_PROFILE'), error.detail)
    })
}

export function updateAccountProfile (accountId, profileId, profile) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch(`/rest/v2.2/accounts/${accountId}/permissionProfiles/${profileId}`, profile, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function (response) {
      return Promise.resolve(response.data)
    })
    .catch(function (reason) {
      console.error(reason)
      throw reason
    })
}

export function deleteAccountProfile (accountId, profileId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.delete(`/rest/v2.2/accounts/${accountId}/permissionProfiles/${profileId}`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function (response) {
      return Promise.resolve(response.data)
    })
    .catch(function (reason) {
      console.error(reason)
      throw reason
    })
}

export function updateAccountProfilePermissions (accountId, profileId, permissions) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch(`/rest/v2.2/accounts/${accountId}/permissionProfiles/${profileId}/permissions`, permissions, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function (response) {
      return Promise.resolve(response.data)
    })
    .catch(function (reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('UPDATE_PERMISSION_PROFILE'), error.detail)
    })
}

export function updateProfileProfile(resellerId,  permissions) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.patch( `/rest/v2.2/resellers/${resellerId}/modules`, permissions, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function (response) {
      return Promise.resolve(response.data)
    })
    .catch(function (reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('UPDATE_PROFILE_PERMISSIONS'), error.detail)
    })
}

export function getUserProfile(userId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.2/users/${userId}/permissionProfiles`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function (response) {
      return Promise.resolve(response.data.provProfileId)
    })
    .catch(function (reason) {
      console.error(reason)
      throw reason
    })
}

export function getUserModules(resellerId, userId) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get(`/rest/v2.4/resellers/${resellerId}/accounts/${userId}/modules`, {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function (response) {
      return Promise.resolve(response.data)
    })
    .catch(function (reason) {
      console.error(reason)
      return Promise.reject(reason)
    })
}

export function updateUserModule (partnerId, userId, module, active) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  const payload = {
    name: module,
    enabled: active
  }

  return resource.patch(`/rest/v2.4/resellers/${partnerId}/accounts/${userId}/modules`, payload, {
      headers: { 'Authorization': 'Bearer ' + auth.getToken() }
    })
      .then(function () {
        return Promise.resolve()
      })
      .catch(function (reason) {
        console.error(reason)
        return Promise.reject(reason)
      })
}

export function requestEndUserPasswordResetLink (email) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.post('/auth/passwordResets/request?clientId=web.v2', 
    `${encodeURIComponent('email')}=${encodeURIComponent(email)}`, {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }
  )
    .then(function() {
      return Promise.resolve()
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('REQUEST_PASSWORD_RESET'), error.detail)
    })
}

export function requestDDuserPasswordResetLink (email) {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.post('/auth/passwordResets/request?clientId=portal.v2', 
    `${encodeURIComponent('email')}=${encodeURIComponent(email)}`, {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }
  )
    .then(function() {
      return Promise.resolve()
    })
    .catch(function(reason) {
      console.error(reason)
      let error = reason.response.data
      throw new CustomError(error.code, getErrorCode('REQUEST_PASSWORD_RESET'), error.detail)
    })
}

export function getTimeZones() {
  if (!auth.isAuthorized()) return Promise.reject('Not authorized')

  return resource.get('/rest/v2.4/timeZones', {
    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
  })
    .then(function (response) {
      return Promise.resolve(response.data)
    })
    .catch(function (reason) {
      console.error(reason)
      return Promise.reject(reason)
    })
}

export default {
  cancelAll,
  createSession,
  getEntityByZohoId,
  getZohoAccountById,
  getZohoEntityByIdAndType,
  createZohoMapping,
  updateZohoMapping,
  getWalkthroughStatus,
  setWalkthroughStatus,
  getDistributors,
  getDistributor,
  getDistributorCount,
  getDistributorDetails,
  setDistributorDetails,
  addDistributor,
  getResellers,
  getReseller,
  getResellerCount,
  getResellerDetails,
  setResellerDetails,
  addReseller,
  getPartner,
  setPartner,
  getPartnerDetails,
  setPartnerDetails,
  addPartner,
  getResellerAccounts,
  getAccounts,
  getAccountUsers,
  getAccountOwner,
  addAccount,
  setAccount,
  setAccountUser,
  setAccountUserPassword,
  deleteApiKey,
  createApiKey,
  getApiKeys,
  getAccountSubscription,
  updateAccountSubscription,
  cancelAccountSubscription,
  getLanguages,
  getCountries,
  moveResellerToDistributor,
  downloadHelpdeskJnlp,
  getMobileHelpdeskUrl,
  getWebappHelpdeskUrl,
  getCamerasSummary,
  getCameraDetails,
  moveAccountToReseller,
  getPolicyData,
  addPolicy,
  updatePolicy,
  getResellerModules,
  getResellerSubscriptions,
  searchUser,
  searchCameras,
  getReportInfo,
  downloadReport,
  getPartnerAccounts,
  getPartnerAccount,
  addPartnerAccount,
  updatePartnerAccount,
  deletePartnerAccount,
  getUserToken,
  getCurrentUser,
  getAccountStatus,
  setAccountStatus,
  getAccountProfiles,
  createAccountProfile,
  updateAccountProfile,
  deleteAccountProfile,
  updateAccountProfilePermissions,
  getUserProfile,
  getAccountSubscriptions,
  getAccountMobileSubscriptionsSummary,
  getAccountAvailableSubscriptions,
  createAccountSubscription,
  deleteAccountSubscription,
  updateAccountMobileSubscription,
  getMobileSubscriptionDataUsage,
  getUserModules,
  updateUserModule,
  getTimeZones
}
