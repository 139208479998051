<template>
  <div class="partner-list">
    <v-row>
      <div
        v-show="!hasCrumbs"
        class="grey--text text-uppercase ma-auto"
      >
        {{ title }}
      </div>
    </v-row>
    <v-row class="py-0">
      <v-col
        class="py-0"
        cols="3"
      >
        <v-text-field
          v-model="filter"
          :append-icon-cb="() => {}"
          :placeholder="$t('Search')"
          dense
          single-line
          hide-details
          class="partner-search"
        >
          <v-icon
            slot="prepend-inner"
            color="grey"
            size="0.9rem"
            class="pt-1"
          >
            fa fa-search
          </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="py-2">
      <v-col>
        <v-switch
          v-model="showActive"
          class="mt-0 active-switch"
          color="primary"
          inset
          dense
          :label="showActive ? $t('Active') : $t('In-active')"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="auto"
      >
        <v-select
          v-model="sorting"
          :items="sortOptions"
          class="sort-select pa-1 filter-select"
          dense
          solo
          flat
        />
      </v-col>
      <v-col
        class="py-0"
        cols="1"
      >
        <a @click="toggleSortOrder">
          <v-icon
            size="1rem"
            color="primary"
            class="pt-4"
            aria-hidden="true"
            v-html="sortOrderClass"
          />
        </a>
      </v-col>
    </v-row>
    <div class="partner-container">
      <partner-card
        v-for="partner in sortedPartners"
        :key="id(partner)"
        :partner="partner"
        :partner-type="partnerType"
      />
    </div>
    <v-row v-if="noPartners">
      <div
        class="grey--text text-uppercase ma-auto mb-2"
      >
        {{ noPartnersFoundText }}
      </div>
    </v-row>
  </div>
</template>

<script charset="utf-8">
import PartnerCard from '@/components/partner/PartnerCard'

export default {
    name: 'Partners',
    components: {
        'partner-card': PartnerCard
    },
    props: {
        partners: {
            type: Array,
            required: true
        },
        partnerType: {
            type: String,
            required: true
        },
        title: {
            type: String
        },
        initialSorting: {
            type: String
        },
        initialSortOrder: {
            type: String
        }
    },
    data () {
        return {
            addDistributorAsReseller: true,
            name: 'Partners',
            showActive: true,
            sorting: null,
            sortOrder: null,
            filter: null
        }
    },
    computed: {
        sortOptions () {
            let sOptions = []
            if (this.partnerType === 'Distributor' && this.showActive) {
                sOptions.push({
                    value: 'resellers',
                    text: this.$t('Sort by number of resellers'),
                    iconType: 'amount',
                    initialSort: 'desc'
                })
            }
            if (this.partnerType === 'Reseller' && this.showActive) {
                sOptions.push({
                    value: 'customers',
                    text: this.$t('Sort by number of customers'),
                    iconType: 'amount',
                    initialSort: 'desc'
                })
            }
            if (this.showActive) {
                sOptions.push({
                    value: 'cameras',
                    text: this.$t('Sort by number of camera slots'),
                    iconType: 'amount',
                    initialSort: 'desc'
                })
            }
            sOptions.push({
                value: 'name',
                text: this.$t('Sort by name'),
                iconType: 'alpha',
                initialSort: 'asc'
            })
            return sOptions
        },
        hasCrumbs () {
            return this.$store.getters.crumbs && this.$store.getters.crumbs.length > 0
        },
        noPartners () {
            const showActive = this.showActive
            return this.partners.filter(function (p) { return p.active === showActive }).length === 0
        },
        noPartnersFoundText () {
            if (this.showActive && this.partnerType === 'Distributor') {
                return this.$t('No active distributors found')
            } else if (!this.showActive && this.partnerType === 'Distributor') {
                return this.$t('No inactive distributors found')
            } else if (this.showActive && this.partnerType === 'Reseller') {
                return this.$t('No active resellers found')
            } else {
                return this.$t('No inactive resellers found')
            }
        },
        sortOrderClass: function () {
            const sorting = this.sorting
            const iconType = sorting ? this.sortOptions.find(function (s) { return s.value === sorting }).iconType : 'amount'
            return `fas fa-sort-${iconType}-${this.sortOrder === 'asc' ? 'down-alt' : 'down'}`
        },
        sortedPartners () {
            const showActive = this.showActive
            const filter = this.filter ? this.filter.toLowerCase() : null
            let filteredPartners = this.partners.filter(function (p) { return p.active === showActive && (!filter || p.companyName.toLowerCase().indexOf(filter) !== -1) })
            let sortingProperty
            let sortingType

            let sortOption = this.sorting

            if (!sortOption) {
                let sOptions = this.sortOptions
                sortOption = sOptions && sOptions.length>0 ? sOptions[0].value : 'name'
            }

            if (sortOption === 'customers') {
                sortingProperty = 'customerCount'
                sortingType = 'number'
            } else if (sortOption === 'resellers') {
                sortingProperty = 'resellerCount'
                sortingType = 'number'
            } else if (sortOption === 'cameras') {
                sortingProperty = 'cameraCount'
                sortingType = 'number'
            } else if (sortOption === 'name') {
                sortingProperty = 'companyName'
                sortingType = 'string'
            }

            let sorted = filteredPartners.sort(function (a, b) {
                if (sortingType === 'number') {
                    return parseFloat(a.count[sortingProperty]) - parseFloat(b.count[sortingProperty])
                } else {
                    return a[sortingProperty].localeCompare(b[sortingProperty], [], {sensitivity: 'base'})
                }
            })
            return (this.sortOrder === 'asc' ? sorted : sorted.reverse())
        }
    },
    watch: {
        sorting: function (newVal, oldVal) {
            if (newVal) {
                const sortOption = this.sortOptions.find(function (s) { return s.value === newVal })
                this.sortOrder = (!oldVal && this.initialSortOrder) ? this.initialSortOrder : sortOption.initialSort
                this.$emit('sort', this.sorting, this.sortOrder)
            }
        },
        showActive () {
            let sOptions = this.sortOptions
            this.sorting = sOptions && sOptions.length>0 ? sOptions[0].value : 'name'
        }
    },
    mounted () {
        if (this.initialSortOrder) this.sortOrder = this.initialSortOrder
        if (this.initialSorting) this.sorting = this.initialSorting
    },
    methods: {
        showModal (row) {
            this.partner = row
            this.$store.commit('modalShown', true)
        },
        isModalOpened () {
            return this.$store.getters.isModalShown
        },
        closeModal () {
            this.$store.commit('modalShown', false)
        },
        toggleSortOrder () {
            if (this.sortOrder === 'asc') {
                this.sortOrder = 'desc'
            } else {
                this.sortOrder = 'asc'
            }
            this.$emit('sort', this.sorting, this.sortOrder)
        },
        id (partner) {
            if (this.partnerType === 'Distributor') {
                return partner.distributorId
            } else {
                return partner.resellerId
            }
        }
    }
}
</script>

<style lang="scss">
  #change-active-partner-listing {
    p.label {
      text-transform: none;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../assets/styles/main";

  .top-margin-5 {
    margin-top: 5px;
  }
  .lft-margin-10 {
    margin-left: 10px;
  }
  .lft-margin-15 {
    margin-left: 15px;
  }
  select {
    color: $color-general-text;
    font-size: 11px;
    text-transform: uppercase;
  }

  .active-switch, .filter-select {
    height: 24px !important;
  }

  #sort-order-partners-button {
    color: $color-action;
  }
  .partner-list {
    .field {
      &.search {
        min-width: 50%;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 1.5rem;
        p.control.has-icons-left {
          border: none;
          .icon {
            height: 40px;
          }
          input.input {
            margin: 0 0 0 30px;
            padding: 0;
            height: 40px;
            width: 95%;
            border: none;
            border-bottom: 2px solid #FFFFFF;
            + .icon {
              border-bottom: 2px solid #FFFFFF;
            }
            &:focus {
              border-bottom-color: $primary;
              + .icon {
                color: $primary;
                border-bottom-color: $primary;
              }
            }
          }
        }
      }
    }
    .partner-filter-row {
      &.columns {
        margin-bottom: 0;
      }
    }
    .partner-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .no-partners-container {
      p {
        color: $color-title-light;
      }
    }
  }
</style>
