const state = {
    selectedSubmenu: null
}

const getters = {
    selectedSubmenu: state => {
        return state.selectedSubmenu
    }
}

const mutations = {
    selectedSubmenu: (state, menu) => {
        state.selectedSubmenu = menu
    }
}

export default {
    state,
    getters,
    mutations
}
