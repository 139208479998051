<template>
  <div
    id="select-partner-page"
    class="mx-12"
  >
    <v-autocomplete
      id="select-distributor-input"
      v-model="selectedDistributor"
      :label="$t('Select Distributor')" 
      :items="activeDistributors"
      item-text="companyName"
      item-value="distributorId"
      return-object
      dense
      @change="onSelectDistributor"
    />
    <v-autocomplete
      id="select-reseller-input"
      v-model="reseller"
      :label="$t('Select Reseller')" 
      :items="activeResellers"
      item-text="companyName"
      item-value="resellerId"
      return-object
      dense
      @change="onSelectReseller"
    />
  </div>
</template>

<script>
const restapi = require('@/service/restapi')

export default {
    name: 'SelectPartnerPage',
    props: ['isAdmin', 'isDistributor', 'distributors', 'currentReseller', 'distributor', 'showDistributorSelect'],
    data () {
        return {
            reseller: null,
            resellers: [],
            selected: null
        }
    },
    computed: {
        activeDistributors () {
            return this.distributors ? this.distributors.filter(d => { return d.active }) : []
        },
        activeResellers () {
            const currentId = this.currentReseller.resellerId
            return this.resellers.filter(r => { return r.active && r.resellerId !== currentId })
        },
        isBrowserIE () {
            return this.$store.getters.isIE
        },
        selectedDistributor: {
            get: function() {
                return this.selected ? this.selected : this.distributor
            },
            set: function(val) { 
                this.selected = val
            }
        }
    },
    watch: {
        distributor: function (newVal, oldVal) {
            console.log('Watch distributor', oldVal, '=>', newVal)
            if (newVal) {
                this.reseller = null
                this.resellers = []
                const that = this
                restapi.getResellers(this.distributor.distributorId)
                    .then(function (resellers) {
                        that.resellers = resellers
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
            }
        }
    },
    methods: {
        onSelectDistributor (distributor) {
            this.$emit('distributorSelected', distributor)
        },
        onSelectReseller (reseller) {
            this.reseller = reseller
            this.$emit('resellerSelected', this.reseller)
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/styles/forms";
  .columns {
    justify-content: center;
  }

  .distributor-block {
    margin-bottom: 35px;
  }
</style>
