<template>
    <v-container class="add-customer-page has-shadow has-rounded-corners">
        <v-row>
            <v-col>
                <div class="create-addon-subscription">
                    <div>
                        <div v-if="hasCameras" class="choose-subscription-block">
                            <div>
                                <div class="subscriptions-type-selection item-block">
                                    <p class="title">
                                        {{ $t('Camera') }}
                                    </p>
                                    <div class="field">
                                        <div class="has-float-label">
                                            <v-autocomplete v-model="cameraId"
                                                :items="filteredCameras" :item-text="cameraText" item-value="cameraId" 
                                                :no-data-text="$t('No cameras found that could be assigned to given add-on subscription type')"
                                                dense :placeholder="$t('Select camera to assign')" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                              <p class="text-center pt-2 pb-4" v-if="!canAssignCamera">{{ $t('Selected camera is already assigned to this add-on subscription type') }}</p>
                            </div>
                            <div>
                                <v-btn
                                    large
                                    class="float-left mt-2"
                                    color="primary"
                                    outlined
                                    @click="cancelWizard()"
                                >
                                    {{ $t('Cancel') }}
                                </v-btn>
                                <v-btn
                                    large
                                    class="float-right mt-2"
                                    color="primary"
                                    :disabled="!cameraId || !canAssignCamera"
                                    @click="assignCameraToAddonSubscription()"
                                >
                                    {{ $t('Done') }}
                                </v-btn>
                            </div>
                        </div>
                        <div v-else class="has-text-centered">
                            <p class="has-text-error">
                                {{ $t('There are no available cameras to choose from') }}
                            </p>
                            <p>{{ $t('Please contact support') }}</p>
                            <v-btn
                                    large
                                    class="float-left mt-2"
                                    color="primary"
                                    outlined
                                    @click="cancelWizard()"
                                >
                                    {{ $t('Back') }}
                                </v-btn>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script charset="utf-8">

const restapi = require('@/service/restapi')

export default {
    name: 'AssignCameraToAddonSubscription',
    props: ['cameras', 'addonSubscription', 'skuToCameraIdMap'],
    data () {
        return {
            cameraId: null,
        }
    },
    computed: {
        resellerId () {
            return this.$route.params.resellerid
        },
        accountId () {
            return this.$route.params.accountid
        },
        userId () {
            return this.$route.params.userid
        },
        subscriptionId () {
            return this.addonSubscription.subscriptionId
        },
        hasCameras () {
            return this.cameras && this.cameras.length > 0
        },
        canAssignCamera () {
            const cameras = this.skuToCameraIdMap.get(this.getSKUWithoutMonthSuffix(this.addonSubscription.sku))
            return !cameras || !cameras.includes(this.cameraId)
        },
        filteredCameras () {
            let filteredCameras = this.skuToCameraIdMap.get(this.getSKUWithoutMonthSuffix(this.addonSubscription.sku))
            if (filteredCameras) {
                filteredCameras = this.cameras.filter(camera => {
                    return !filteredCameras.includes(camera.cameraId)
                })
            } else {
                filteredCameras = this.cameras
            }
            return filteredCameras
        }
    },
    methods: {
        getSKUWithoutMonthSuffix (sku) {
            return sku.replace(/-\d+$/, '')
        },
        cancelWizard () {
            this.$emit('onCancel')
        },
        async assignCameraToAddonSubscription() {
            this.isLoading(true)
            try {
                const payload = { 
                    cameraId: this.cameraId,
                }
                await restapi.updateAccountAddonSubscription(this.resellerId, this.accountId, this.subscriptionId, payload) 
                this.$emit('onCameraAssigned', {
                    subscription: this.addonSubscription,
                    cameraId: this.cameraId
                }) 
            } catch (error) {
                console.error(error)
                this.$store.commit('toastMessage', {
                    showing: true,
                    text: this.getMessageFromError(error, 'UPDATE_ADDON_SUBSCRIPTION'),
                    timeout : -1,
                    color: 'error',
                    support: true
                })
            } finally {
                this.isLoading(false)
            }
        },
        cameraText: item => `${item.name} (${item.cameraId})`,
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/forms';
@import '../../../../assets/styles/custom';

.add-customer-page {
    background-color: #ffffff;
    max-width: 700px;
  }
.choose-subscription-block {
  min-height: 180px;
  .item-block {
    padding-top:10px;
    text-align: center;
  }
  flex-direction: column;
}
.choose-subscription-block ::v-deep .switch input[type=checkbox] + .check {
  border-radius: 4px;
  background-color: #CCCCCC;
}
.choose-subscription-block ::v-deep .switch input[type=checkbox] + .check:before {
  border-radius: 4px;
}
.choose-subscription-block ::v-deep .switch .control-label {
  padding-left: 0;
  padding-right: 0.5em;
}
.choose-subscription-block ::v-deep .switch .control-label.is-large {
  font-size: large;
  font-weight: bold;

}
.choose-subscription-block ::v-deep .switch:focus input[type=checkbox]:checked + .check,
.choose-subscription-block ::v-deep .switch:focus input[type=checkbox] + .check {
  box-shadow: none;
}

p.title {
  color: #b9b9b9!important;
  text-transform: uppercase;
  margin-bottom: 10px;
   font-size: 85%!important;
}

.subscriptions-type-selection {
  justify-content: center;
}

button {
  margin-right: 10px;
  margin-bottom: 10px;
  color: $color-action;
  background-color: white;
}

.choose-subscription-block {
  display: flex;
  justify-content: center;
  padding: 0px 10px;
}

#change-number-of-cameras-button.field {
  max-height: 48px;
  margin: 0  auto 0 auto;
  width: 180px;
}

#change-number-of-cameras-button ::v-deep .field {
  max-height: 48px;
  margin: 10px 0;
}
</style>
