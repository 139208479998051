<template>
  <div
    class="distributor-details"
    :class="{ 'edit-mode': editMode }"
  >
    <h2
      v-if="!editMode"
      class="component-title"
    >
      {{ $t('Partner details') }}
    </h2>
    <p
      v-if="!editMode"
      class="component-description"
    >
      {{ $t('Here you can see the partner details') }}
    </p>
    <v-card>
      <v-card-subtitle>
        <v-row>
          <v-col>
            <div class="field">
              <div class="has-float-label">
                <v-select
                  v-if="editMode"
                  id="select-distributor-input"
                  v-model="distributorId"
                  :label="$t('Select Distributor')"
                  :items="selectableDistributors"
                  item-text="companyName"
                  item-value="distributorId"
                  dense
                  @change="onSelectDistributor"
                />
                <p
                  v-if="!editMode"
                  class="content"
                >
                  {{ distributorName }}
                </p>
                <span v-if="!editMode">{{ $t("Distributor") }}</span>
              </div>
            </div>
            <div
              v-if="editMode"
              class="control-buttons"
            >
              <div class="is-pulled-right">
                <v-btn
                  class="mx-2"
                  fab
                  small
                  color="primary"
                  @click="cancelTransferReseller"
                >
                  <v-icon dark>
                    fas fa-times
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <v-btn
              v-if="editMode"
              large
              class="float-right"
              color="primary"
              :disabled="!hasChanged"
              @click="saveTransferReseller"
            >
              {{ $t('Transfer to other partner') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-card>
    <div class="text-center">
      <v-dialog
        v-model="showTransferDialog"
        width="500"
      >
        <v-card>
          <v-card-title
            class="headline lighten-2"
            primary-title
          >
            {{ $t('Transfer reseller to new partner') }}
          </v-card-title>

          <v-card-text v-html="$t('TRANSFER_RESELLER_TEXT')" />

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              large
              outlined
              @click="showTransferDialog = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              large
              color="primary"
              @click="movePartnerToDistributor() ; showTransferDialog = false "
            >
              {{ $t('Transfer') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import restapi from '@/service/restapi'
import auth from '@/service/auth'

export default {
    name: 'DistributorDetails',
    props: ['partner', 'transferReseller'],
    data () {
        return {
            isEditActive: false,
            editMode: false,
            distributor: null,
            selectedDistributor: null,
            distributors: [],
            showTransferDialog: false
        }
    },
    computed: {
        isAdmin () {
            return auth.isAdmin()
        },
        selectableDistributors () {
            const currentId = this.partner.distributorId
            let selectables = this.distributors.filter(function (d) { return d.active && d.distributorId !== currentId })
                .sort((a, b) => { return a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase()) })
            return selectables.map(function (d) { d.id = d.distributorId.toString(); return d })
        },
        distributorName () {
            return this.distributor ? this.distributor.companyName : 'Unknown'
        },
        isBrowserIE () {
            return this.$store.getters.isIE
        },
        hasChanged () {
            if (this.partner && this.distributor) {
                return this.selectedDistributor !== null && this.partner.distributorId !== this.selectedDistributor.distributorId
            }
            return false
        },
        selectedDistributorId () {
            return this.selectedDistributor ? this.selectedDistributor.distributorId : null
        }
    },
    watch: {
        transferReseller () {
            this.editMode = !!this.transferReseller
        },
        partner: function (newVal, oldVal) {
            if (this.partner && this.partner.distributorId) {
                const that = this
                restapi.getDistributor(this.partner.distributorId)
                    .then(function (result) {
                        that.distributor = result
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
            }
        },
        editMode () {
            document.documentElement.scrollTop = 0
        }
    },
    mounted () {
        if (this.partner && this.partner.distributorId !== null) {
            const that = this
            restapi.getDistributor(this.partner.distributorId)
                .then(function (result) {
                    that.distributor = result
                    restapi.getDistributors()
                        .then(function (result) {
                            that.distributors = result
                        })
                        .catch(function (error) {
                            console.error(error)
                        })
                })
                .catch(function (error) {
                    console.error(error)
                })
        }
    },
    methods: {
        startEdit () {
            this.editMode = true
        },
        onSelectDistributor (partner) {
            this.selectedDistributor = partner
        },
        cancelTransferReseller () {
            this.newDistributorId = null
            this.editMode = false
            this.$emit('cancel')
        },
        saveTransferReseller () {
            if (this.hasChanged) {
                this.showTransferDialog = true
            }
        },
        movePartnerToDistributor () {
            const that = this
            this.isLoading(true)
            restapi.moveResellerToDistributor(this.partner.resellerId, this.selectedDistributor)
                .then(function () {
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.$t('Moved reseller to another distributor'),
                        timeout : 4000,
                        color: 'primary'
                    })
                    that.editMode = false
                    that.$emit('transfered', that.selectedDistributor)
                })
                .catch(function (error) {
                    let errMsg = 'Could not move reseller to another distributor'
                    if (parseInt(error) === 409) {
                        errMsg += ', because the subscriptions are not compatible'
                    }
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.$t(errMsg),
                        timeout : -1,
                        color: 'error',
                        support: true
                    })
                })
                .then(function () {
                    that.isLoading(false)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/custom';

  .distributor-details {
    padding: 20px 0;
    position: relative;
    &.edit-mode {
      max-width: 500px;
      margin: 0 auto;
    }
    .component-title span {
      color: $color-error-dark;
    }
    .columns {
      margin: 0;
      @media screen and (min-width: 768px) {
        .column {
          padding: 0 40px;
        }
      }
      &.edit-mode {
        padding: 30px 0;
        margin-bottom: 0.75em;

      }
    }
    .control-buttons {
      padding: 0;
      width: 50px;
      display: inline-block;
      position: absolute;
      right: -35px;
      top: 20px;
      button {
        float: right;
        margin-bottom: 10px;
        font-size: 18px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        vertical-align: middle;
        &.cancel-button {
          color: $primary;
          background-color: #FFFFFF;
          border: 2px solid $primary;
        }
        &.save-button {
          color: #FFFFFF;
          background-color: $primary;
        }
      }
    }
    .dropdown {
      &.edit-menu {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 20;
        .dropdown-trigger {
          color: $primary;
          font-weight: bold;
          position: absolute;
          top: 0;
          right: 10px;
          cursor: pointer;
          span {
            font-weight: 600;
            font-size: 18px;
          }
        }
        .dropdown-menu {
          position: relative;
          margin-top: 25px;
          .dropdown-content {
            border-radius: $border-radius;
            .dropdown-item {
              text-align: center;
              padding: 0.375rem 1rem;
              text-transform: uppercase;
              color: $primary;
              &.is-danger {
                color: $color-error-dark;
              }
            }
            hr {
              height: 1px !important;
            }
          }
        }
      }
    }
  }
</style>
