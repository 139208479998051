<template>
  <div />
</template>

<script>
// This is just a stub for the home page to go to the correct page dependent on the type of user
const auth = require('@/service/auth')

export default {
    data () {
        return {
            auth: null
        }
    },
    beforeMount () {
        this.auth = auth
        const partnerId = auth.getPartnerId()
        if (auth.isAdmin()) {
            this.$router.replace({path: '/admin'})
        } else if (auth.isDistributor() && partnerId !== null) {
            this.$router.replace({path: `/distributor/${partnerId}`})
        } else if (auth.isReseller() && partnerId !== null) {
            this.$router.replace({path: `/reseller/${partnerId}`})
        } else {
            console.log('I don\'t know what I am!')
        }
    }
}
</script>
