import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Admin from '@/components/Admin'
import Distributor from '@/components/Distributor'
import Reseller from '@/components/Reseller'
import User from '@/components/User'
import Insights from '@/components/Insights'
import MyAccount from '@/components/MyAccount'
import Zoho from '@/components/Zoho'
import Terms from '@/components/Terms'
import Cookies from '@/components/Cookies'
import Logout from '@/components/Logout'
import LoggedIntoOtherApp from '@/components/LoggedIntoOtherApp'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                auth: true,
                title: 'Home'
            }
        },
        {
            path: '/admin/:menu?/:zohoid?',
            name: 'Admin',
            component: Admin,
            meta: {
                auth: true,
                topMenu: ['management']
            }
        },
        {
            path: '/distributor/:id/:menu?/:zohoid?',
            name: 'Distributor',
            component: Distributor,
            meta: {
                auth: true,
                topMenu: ['management']
            }
        },
        {
            path: '/reseller/:id/:menu?/:zohoid?',
            name: 'Reseller',
            component: Reseller,
            meta: {
                auth: true,
                topMenu: ['management']
            }
        },
        {
            path: '/user/:resellerid/:accountid/:userid/:menu?/:cameraId?',
            name: 'User',
            component: User,
            meta: {
                auth: true,
                topMenu: ['management']
            }
        },
        {
            path: '/my-account/:menu?',
            name: 'My Account',
            component: MyAccount,
            meta: {
                auth: true,
                topMenu: ['my-account']
            }
        },
        {
            path: '/insights/:menu?',
            name: 'Insights',
            component: Insights,
            meta: {
                auth: true,
                topMenu: ['insights']
            }
        },
        {
            path: '/create/:id',
            name: 'Create Zoho entity',
            component: Zoho,
            meta: {
                auth: true
            }
        },
        {
            path: '/view/:id',
            name: 'View Zoho entity',
            component: Zoho,
            meta: {
                auth: true
            }
        },
        {
            path: '/terms',
            name: 'Terms and conditions',
            component: Terms,
            meta: {
                auth: false,
                title: 'Terms & Conditions'
            }
        },
        {
            path: '/cookies',
            name: 'Cookies',
            component: Cookies,
            meta: {
                auth: false,
                title: 'Cookies'
            }
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout,
            meta: {
                auth: false,
                title: 'Logout'
            }
        },
        {
            path: '/firstlogout',
            name: 'Logged in at other app',
            component: LoggedIntoOtherApp,
            meta: {
                auth: false,
                title: 'Logged out'
            }
        },
        {
            path: '*',
            redirect: '/'
        }
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

export default router
