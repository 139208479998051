<template>
  <div class="customer-details">
    <contact-information
      v-if="!transfering"
      id="contact-information"
      :account="account"
      @changed="$emit('changed')"
      @canceled="$emit('canceled')"
    />
    <reseller-details
      v-if="!isReseller"
      id="reseller-details"
      :account="account"
      :distributor="distributor"
      :reseller="reseller"
      :subscription="currentSubscription"
      :subscriptions="subscriptions"
      :transfering="transfering"
      @transfering="setTransfering"
      @transfered="isTransfered"
    />
    <div
      v-if="!transfering"
      class="columns"
    >
      <div class="column">
        <customer-block
          v-if="hasPermission('CustomerBlock')"
          :account="account"
          :user="user"
          @blocked="$emit('blocked')"
          @unblocked="$emit('unblocked')"
        />
      </div>
      <div class="column">
        <account-activate
          v-if="canShowActivateDeactivateBlock"
          :account="account"
          :subscription="subscription"
          :reseller="reseller"
          :subscriptions="subscriptions"
          @reactivated="$emit('reactivated')"
          @deactivated="deactivatedAccount"
        />
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
import ContactInformation from '@/components/account/ContactInformation'
import ResellerDetails from '@/components/account/ResellerDetails'
import CustomerBlock from '@/components/account/CustomerBlock'
import AccountActivate from '@/components/account/AccountActivate'

const auth = require('@/service/auth')

export default {
    name: 'AccountDetails',
    components: {
        ContactInformation,
        ResellerDetails,
        CustomerBlock,
        AccountActivate
    },
    props: ['account', 'user', 'subscription', 'distributor', 'reseller', 'subscriptions'],
    data () {
        return {
            transfering: false
        }
    },
    computed: {
        userId () {
            return this.user ? this.user.userId : null
        },
        accountIsActive () {
            return this.account ? this.account.active : null
        },
        canShowActivateDeactivateBlock () {
            if(this.isAdmin) {
                return this.hasPermission('CustomerAccount') || this.hasPermission('CustomerDeactivate')
            } else {
                return this.hasPermission('CustomerAccount') && !this.accountIsActive
            }
        },
        currentSubscription () {
            if (this.subscription && this.subscriptions) {
                const sId = this.subscription.subscriptionId
                return this.subscriptions.find(function (s) { return s.subscriptionId === sId })
            }
            return null
        },
        isAdmin () {
            return auth.isAdmin()
        },
        isDistributor () {
            return auth.isDistributor()
        },
        isReseller () {
            return auth.isReseller()
        }
    },
    methods: {
        setTransfering (transfering) {
            this.transfering = transfering
        },
        isTransfered (resellerId) {
            this.transfering = false
            this.$emit('transfered', this.userId, resellerId)
        },
        deactivatedAccount (userId, when) {
            this.$emit('deactivated', userId, when)
        }
    }
}
</script>

<style lang="scss" scoped>
  .customer-details {
    padding-top: 40px;
    .columns {
      padding: 20px 0px 80px 0;
      background-color: transparent;
    }
  }
</style>
