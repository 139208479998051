const state = {
    isIE: false
}

const getters = {
    isIE: state => {
        return state.isIE
    }
}

const mutations = {
    isIE: (state, isIE) => {
        state.isIE = isIE
    }
}

export default {
    state,
    getters,
    mutations
}
