<template>
  <div class="content-wrapper">
    <side-bar>
      <side-bar-item
        name="back"
        class="pt-1 pb-1"
        :text="$t('Back')"
        icon-size="32"
        icon="fa fa-arrow-circle-left"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="hasPermission('CustomerOverview')"
        name="customer-details"
        :text="$t('Details')"
        icon="fas fa-id-card"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="hasPermission('CustomerOverview')"
        name="cameras"
        :text="$t('Cameras')"
        icon="fas fa-video"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="hasPermission('CustomerOverview')"
        name="subscription"
        :text="$t('Cloud subscription')"
        icon="fa fa-cloud"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="hasPermission('CustomerOverview')"
        name="mobile-subscriptions"
        :text="$t('Mobile subscriptions')"
        icon="fa fa-signal"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="hasPermission('CustomerOverview') && hasAddonSubscriptions"
        name="addon-subscriptions"
        :text="$t('Add-on subscriptions')"
        icon="fa fa-dice-d6"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="active && hasPermission('CustomerSubusers')"
        name="subusers"
        :text="$t('Subusers')"
        icon="fa fa-users"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="active && (hasPermission('CustomerHelpdesk') || hasPermission('CustomerPassword'))"
        name="support"
        :text="$t('Support')"
        icon="fa fa-medkit"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="active && isAdmin() && hasPermission('CustomerModules')"
        name="admin-tools"
        :text="$t('Admin tools')"
        icon="fa fa-cog"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
    </side-bar>
    <div class="content">
      <breadcrumb />
      <div
        class="container"
        :class="selectedMenu"
      >
        <section
          v-if="showNotificationBar && hasPermission('CustomerOverview')"
          class="section notification-section"
        >
          <div v-if="menuSelected('customer-details')">
            <div
              v-if="!active"
              class="user-notification"
              v-html="$t('This user is inactive', {link: getLinkToDetailsPage() })"
            />
            <div
              v-else
              class="user-notification"
              v-html="$t('This user is blocked', {link: getLinkToDetailsPage() })"
            />
          </div>
          <div v-else>
            <div
              v-if="!active"
              class="user-notification"
              v-html="$t('This user is inactive - link', {link: getLinkToDetailsPage() })"
            />
            <div
              v-else
              class="user-notification"
              v-html="$t('This user is blocked - link', {link: getLinkToDetailsPage() })"
            />
          </div>
        </section>
        <account-details
          v-if="menuSelected('customer-details') && hasPermission('CustomerOverview')"
          id="customer-details"
          :account="account"
          :user="user"
          :subscription="subscription"
          :distributor="distributor"
          :reseller="reseller"
          :subscriptions="subscriptions"
          @changed="loadAccount"
          @canceled="loadAccount"
          @reactivated="reactivated"
          @deactivated="deactivated"
          @transfered="transfered"
          @blocked="blocked"
          @unblocked="unblocked"
        />
        <cameras
          v-else-if="menuSelected('cameras') && hasPermission('CustomerOverview')"
          id="cameras"
          :account="account"
          :camera-id="cameraId"
        />
        <subscription
          v-else-if="menuSelected('subscription') && hasPermission('CustomerOverview')"
          id="subscription"
          :current-subscription="currentSubscription"
          :next-subscription="nextSubscription"
          :subscriptions="subscriptions"
          :reseller-id="resellerId"
          :user-id="userId"
          :account="account"
          @changed="changedSubscription"
        />
        <mobile-subscriptions
          v-else-if="menuSelected('mobile-subscriptions') && hasPermission('CustomerOverview')"
          id="mobile-subscriptions"
          :account="account"
          :subscriptions="subscriptions"
        />
        <addon-subscriptions
          v-else-if="menuSelected('addon-subscriptions') && hasPermission('CustomerOverview')"
          id="addon-subscriptions"
          :availableAddonSubscriptions="availableAddonSubscriptions"
          :maxAmountOfCameras="maxAmountOfCameras"
        />
        <sub-users
          v-else-if="menuSelected('subusers') && hasPermission('CustomerSubusers')"
          id="subusers"
          :account="account"
          :users="users"
        />
        <support
          v-else-if="menuSelected('support') && (hasPermission('CustomerHelpdesk') || hasPermission('CustomerPassword'))"
          id="support"
          :user="user"
          :account="account"
          :enable-access-token-generation="enableAccessTokenGeneration"
          :exclusive-third-party-authentication="exclusiveThirdPartyAuthentication"
        />
        <user-modules
          v-else-if="isAdmin() && menuSelected('admin-tools') && isAdmin() && hasPermission('CustomerModules')"
          id="user-modules"
          :account="account"
          :user="user"
        />
        <PermissionWarning v-else-if="hasPermissions() && selectedMenu !== null" />
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
import AccountDetails from '@/components/account/AccountDetails'
import SubUsers from '@/components/account/SubUsers'
import Cameras from '@/components/account/Cameras'
import Subscription from '@/components/account/Subscription'
import Support from '@/components/account/Support'
import UserModules from '@/components/account/UserModules'
import MobileSubscriptions from '@/components/account/subscription/mobile/MobileSubscriptions'
import AddonSubscriptions from '@/components/account/subscription/addons/AddonSubscriptions'
import SideBar from '@/components/base/SideBar'
import SideBarItem from '@/components/base/SideBarItem'
import PermissionWarning from '@/components/base/PermissionWarning'

const restapi = require('@/service/restapi')
const auth = require('@/service/auth')

export default {
    name: 'User',
    components: {
        MobileSubscriptions,
        AddonSubscriptions,
        AccountDetails,
        Subscription,
        Cameras,
        SubUsers,
        Support,
        UserModules,
        SideBar,
        SideBarItem,
        PermissionWarning
    },
    data () {
        return {
            selectedMenu: null,
            defaultMenu: 'customer-details',
            active: true,
            subscriptions: [],
            account: null,
            user: null,
            subscription: null,
            reseller: null,
            distributor: null,
            users: null,
            modules: {},
            availableAddonSubscriptions: null,
        }
    },
    computed: {
        isDistributor () {
            return auth.isDistributor()
        },
        showNotificationBar () {
            return this.account && (!this.active || this.IsUserBlocked)
        },
        isUserBlocked () {
            return this.account && this.account.blocked
        },
        resellerId () {
            return this.$route.params.resellerid
        },
        accountId () {
            return this.$route.params.accountid
        },
        userId () {
            return this.$route.params.userid
        },
        cameraId () {
            return this.$route.params.cameraId
        },
        currentSubscription () {
            let sub = {}
            if (this.subscription && this.subscriptions) {
                const sId = this.subscription.subscriptionId
                sub = this.subscriptions.find(function (s) { return s.subscriptionId === sId }) || {available: false, valid: false, name: 'Invalid subscription'}
            }
            if (this.subscription) {
                Object.assign(sub, this.subscription)
            }
            return sub
        },
        nextSubscription () {
            let sub = {}
            if (this.subscription && this.subscription.nextSubscriptionId !== -1 && this.subscriptions) {
                const sId = this.subscription.nextSubscriptionId
                sub = this.subscriptions.find(function (s) { return s.subscriptionId === sId }) || {available: false, valid: false, name: 'Invalid subscription'}
            }
            return sub
        },
        hasSelected () {
            return this.selectedCustomer !== 'undefined' && Object.keys(this.selectedCustomer).length > 0
        },
        filteredUsers () {
            let users = this.users
            let filter = this.filter || false
            if (filter && filter.length > 3) {
                filter = filter.toLowerCase()
                users = users.filter(function (u) {
                    return (u.LastName && u.LastName.toLowerCase().indexOf(filter) >= 0) ||
              (u.FirstName && u.FirstName.toLowerCase().indexOf(filter) >= 0) ||
              (u.Email && u.Email.toLowerCase().indexOf(filter) >= 0) ||
              (u.Company && u.Company.toLowerCase().indexOf(filter) >= 0)
                })
            }
            return users
        },
        classInfoBox () {
            if (this.isOpen) {
                return 'boxed'
            }
            return ''
        },
        classPartnerInfo () {
            if (this.isOpen) {
                return 'is-open'
            } else {
                return 'is-closed'
            }
        },
        classToggleButton () {
            if (this.isOpen) {
                return 'fa fa-sort-asc'
            } else {
                return 'fa fa-sort-desc'
            }
        },
        enableAccessTokenGeneration () {
            return this.modules.hasOwnProperty('enableAccessTokenGeneration') ? this.modules.enableAccessTokenGeneration : false
        },
        exclusiveThirdPartyAuthentication () {
            return this.modules.hasOwnProperty('ExclusiveThirdPartyAuthentication') ? this.modules.ExclusiveThirdPartyAuthentication : false
        },
        isBrowserIE () {
            return this.$store.getters.isIE
        },
        isBillableEntity () {
            return this.account ? this.account.crmId !== null : null
        },
        hasAddonSubscriptions () {
            return this.availableAddonSubscriptions
        },
        maxAmountOfCameras () {
            return this.subscription.maxAmountOfCameras
        }
    },
    watch: {
        'resellerId' () {
            this.loadData()
        },
        '$route' (to, from) {
            this.selectedMenu = this.$route.params.menu || this.defaultMenu
        },
        selectedMenu () {
            if (!this.active && this.canVisitSubPage(this.selectedMenu)) {
                this.selectMenu('customer-details')
            }
        },
        userId () {
            this.loadData()
        },
        account: function (newVal, oldVal) {
            if (newVal && newVal.active !== this.active) {
                this.active = newVal.active
            }
        }
    },
    mounted () {
        if (!this.$route.params.menu) {
            this.$router.replace({path: `/user/${this.resellerId}/${this.accountId}/${this.userId}/${this.defaultMenu}`})
        } else {
            this.selectedMenu = this.$route.params.menu
        }
        this.loadData()
        this.$store.commit('userId', this.userId)
        this.$store.commit('resellerId', this.resellerId)
    },
    methods: {
        getLinkToDetailsPage () {
            return `/#/user/${this.resellerId}/${this.userId}/customer-details`
        },
        selectMenu (item) {
            if (item === 'back') {
                this.$router.push({path: `/reseller/${this.resellerId}/customers`})
            } else {
                this.$router.push({path: `/user/${this.resellerId}/${this.accountId}/${this.userId}/${item}`})
            }
        },
        menuSelected (item) {
            return item === this.selectedMenu
        },
        canVisitSubPage () {
            return this.selectedMenu !== 'customer-details' &&
          this.selectedMenu !== 'cameras' &&
          this.selectedMenu !== 'subscription' &&
          this.selectedMenu !== 'mobile-subscriptions' &&
          this.selectedMenu !== 'addon-subscriptions'
        },
        setBreadCrumbs () {
            let crumbs = []
            let crumb
            if (auth.isAdmin()) {
                crumb = {route: '/', name: 'Distributors'}
                crumbs.push(crumb)
            }
            if (auth.isDistributor() && this.distributor) {
                crumb = {route: '/distributor/' + this.distributor.distributorId, name: this.distributor.companyName}
                crumbs.push(crumb)
            }
            if (this.reseller) {
                crumb = {
                    route: '/reseller/' + this.reseller.resellerId,
                    name: this.reseller.companyName
                }
                crumbs.push(crumb)
            }
            crumb = {
                route: `/user/${this.account.resellerId}/${this.account.accountId}/${this.account.accountOwnerId}`,
                name: this.account.firstName + ' ' + this.account.lastName
            }
            crumbs.push(crumb)
            this.$store.commit('crumbs', crumbs)
        },
        deactivated (userId) {
            this.active = false
            this.loadAccount()
            this.loadUser()
            this.loadSubscription()
            this.$router.push({ path: `/user/${this.resellerId}/${this.accountId}/${this.userId}` })
        },
        reactivated (userId) {
            this.active = true
            this.loadAccount()
            this.loadUser()
            this.loadSubscription()
            this.$router.push({ path: `/user/${this.resellerId}/${this.accountId}/${this.userId}` })
        },
        transfered (userId, resellerId) {
            this.$store.commit('resellerAccounts', null)
            this.$router.push({ path: `/user/${resellerId}/${this.accountId}/${this.userId}` })
        },
        blocked () {
            this.loadAccount()
            this.loadUser()
        },
        unblocked () {
            this.loadAccount()
            this.loadUser()
        },
        isAdmin () {
            return auth.isAdmin()
        },
        loadUser () {
            const that = this
            restapi.getAccountUsers(this.resellerId, this.accountId, this.userId)
                .then(function (result) {
                    that.user = result
                    that.$store.commit('helpdesk', result.allowsHelpdesk)
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        loadAccount () {
            const that = this
            restapi.getAccounts(that.resellerId, that.accountId)
                .then(function (result) {
                    console.log('Account details loaded', result)
                    that.account = result
                    that.$store.commit('updateAccount', result)
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        loadSubscription () {
            const that = this
            restapi.getAccountSubscription(that.resellerId, that.userId)
                .then(function (result) {
                    that.subscription = result
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        changedSubscription () {
            const currentSubscriptionId = this.subscription.subscriptionId
            const that = this
            restapi.getAccountSubscription(that.resellerId, that.userId)
                .then(function (result) {
                    that.subscription = result
                    let successMsg = ''
                    if (currentSubscriptionId !== that.subscription.subscriptionId) {
                        successMsg = that.$t('Subscription was updated successfully and wil be active now')
                    } else {
                        successMsg = that.$t('Subscription was updated successfully and wil be active at', {subscriptionEnd: that.subscription.endDate})
                    }
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: successMsg,
                        timeout : 4000,
                        color: 'primary'
                    })
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        async loadData () {
            if (this.resellerId && this.accountId && this.userId) {
                
                this.isLoading(true)
                
                try {
                    const account = await restapi.getAccounts(this.resellerId, this.accountId)
                    this.account = account

                    const user = await restapi.getAccountUsers(this.resellerId, this.accountId, this.userId)
                    this.user = user
                    this.$store.commit('helpdesk', user.allowsHelpdesk)

                    const subscription = await restapi.getAccountSubscription(this.resellerId, this.userId)
                    this.subscription = subscription

                    const reseller = await restapi.getResellerDetails(this.resellerId)
                    this.reseller = reseller

                    if (this.isDistributor) {
                        const distributor = await restapi.getDistributorDetails(this.reseller.distributorId)
                        this.distributor = distributor
                    }

                    this.setBreadCrumbs()

                    const subscriptions = await restapi.getResellerSubscriptions(this.resellerId)
                    console.log(`Fetched available account types for partner ${this.resellerId}`)
                    this.subscriptions = subscriptions

                    this.subscription.maxAmountOfCameras = this.subscriptions.find(sub => sub.subscriptionId === this.subscription.subscriptionId)?.maxAmountOfCameras

                    const users = await restapi.getAccountUsers(this.resellerId, this.accountId)
                    this.users = users

                    const modules = await restapi.getResellerModules(this.resellerId)
                    this.modules = modules

                    const availableAddonSubscriptions = await restapi.getAccountAvailableSubscriptions(this.resellerId, this.userId, 'addOn')
                    if(availableAddonSubscriptions.length > 0) {
                        this.availableAddonSubscriptions = availableAddonSubscriptions
                    }

                } catch (error) {
                    console.error(error)
                } finally {
                    this.isLoading(false)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../assets/styles/forms';

  .main-div {
    width: 100%;
    .permission-warning {
        margin: 50px;
    }
  }

  .content-wrapper {
    .content {
      width: 100%;
      background-color: $color-shadow-light;
      .is-transparent {
        background-color: transparent;
      }
      .container {
        width: 90%;
        min-width: 90%;
        .notification-section {
          margin: 0px;
          padding: 0px;
          text-align: center;
          .user-notification {
            color: #fff;
            background: #D43536;
            border-radius: 2em;
            align-self: center;
            display: inline-block;
            margin: 0;
            text-align: center;
            padding: 0.75em 1.5em;
            pointer-events: auto;
            ::v-deep a {
              color: #ffffff;
              text-decoration: underline;
            }
          }
        }
        @media screen and (min-width: 769px) {
          &.customer-details {
            width: 900px;
            min-width: 900px;
          }
          &.cameras {
            width: 1100px;
            min-width: 1100px;
          }
          &.subscription {
            width: 900px;
            min-width: 900px;
          }
          &.mobile-subscriptions {
            width: 900px;
            min-width: 900px;
          }
          &.addon-subscription {
            width: 900px;
            min-width: 900px;
          }
          &.subusers {
            width: 900px;
            min-width: 900px;
          }
          &.support {
            width: 900px;
            min-width: 900px;
          }
          &.admin-tools {
            width: 900px;
            min-width: 900px;
          }
        }
      }
    }
  }
</style>
