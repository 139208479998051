import { render, staticRenderFns } from "./Reseller.vue?vue&type=template&id=72e7f39e&scoped=true"
import script from "./Reseller.vue?vue&type=script&charset=utf-8&lang=js"
export * from "./Reseller.vue?vue&type=script&charset=utf-8&lang=js"
import style0 from "./Reseller.vue?vue&type=style&index=0&id=72e7f39e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72e7f39e",
  null
  
)

export default component.exports