<template>
  <v-container class="add-partner-form has-shadow has-rounded-corners">
    <v-row id="contact-info-page">
      <v-col cols="6">
        <div class="field">
          <v-text-field
            id="add-user-company-input"
            v-model="companyName"
            :label="$t('Company')+'*'"
            dense
            autocomplete="no"
            @input="$v.companyName.$touch()"
          />
          <p
            v-if="$v.companyName.$error && !$v.companyName.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="$v.companyName.$error && !$v.companyName.minLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a minimum of X characters', { min: $v.companyName.$params.minLength.min }) }}
          </p>
          <p
            v-else-if="$v.companyName.$error && !$v.companyName.maxLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a maximum of X characters', { max: $v.companyName.$params.maxLength.max }) }}
          </p>
          <p
            v-else-if="$v.companyName.$error && !$v.companyName.validName"
            class="error-message pt-2"
          >
            {{ $t('Please enter a valid name') }}
          </p>
        </div>
        <div class="field">
          <v-text-field
            id="add-user-reference-input"
            v-model="reference"
            :label="$t('Reference')+'*'"
            dense
            autocomplete="no"
            @input="$v.reference.$touch()"
          />
          <p
            v-if="$v.reference.$error && !$v.reference.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="$v.reference.$error && !$v.reference.minLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a minimum of X characters', { min: $v.reference.$params.minLength.min }) }}
          </p>
          <p
            v-else-if="$v.reference.$error && !$v.reference.maxLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a maximum of X characters', { max: $v.reference.$params.maxLength.max }) }}
          </p>
          <p
            v-else-if="$v.reference.$error && !$v.reference.validReference"
            class="error-message pt-2"
          >
            {{ $t('Please enter a valid value') }}
          </p>
        </div>
        <div class="field">
          <v-text-field
            id="add-user-custom-reference-input"
            v-model="customReference"
            :label="$t('Custom Reference')"
            dense
            autocomplete="no"
            @input="$v.customReference.$touch()"
          />
          <p
            v-if="$v.customReference.$error && !$v.customReference.maxLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a maximum of X characters', { max: $v.customReference.$params.maxLength.max }) }}
          </p>
        </div>
        <div class="field">
          <v-text-field
            id="add-user-email-input"
            v-model="email"
            :label="$t('Contact email')+'*'"
            dense
            autocomplete="no"
            @input="$v.email.$touch()"
          />
          <p
            v-if="$v.email.$error && !$v.email.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="$v.email.$error && !$v.email.email"
            class="error-message pt-2"
          >
            {{ $t('Please enter a valid email address') }}
          </p>
          <p
            v-else-if="$v.email.$error && !$v.email.minLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a minimum of X characters', { min: $v.email.$params.minLength.min }) }}
          </p>
          <p
            v-else-if="$v.email.$error && !$v.email.maxLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a maximum of X characters', { max: $v.email.$params.maxLength.max }) }}
          </p>
        </div>
        <div class="field">
          <v-text-field
            id="partner-details-firstname-input"
            v-model="firstName"
            :label="$t('First name')+'*'"
            dense
            autocomplete="no"
            @input="$v.firstName.$touch()"
          />
          <p
            v-if="$v.firstName.$error && !$v.firstName.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="$v.firstName.$error && !$v.firstName.minLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a minimum of X characters', { min: $v.firstName.$params.minLength.min }) }}
          </p>
          <p
            v-else-if="$v.firstName.$error && !$v.firstName.maxLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a maximum of X characters', { max: $v.firstName.$params.maxLength.max }) }}
          </p>
          <p
            v-else-if="$v.firstName.$error && !$v.firstName.validName"
            class="error-message pt-2"
          >
            {{ $t('Please enter a valid name') }}
          </p>
        </div>
        <div class="field">
          <v-text-field
            id="partner-details-lastname-input"
            v-model="lastName"
            :label="$t('Last name')+'*'"
            dense
            autocomplete="no"
            @input="$v.lastName.$touch()"
          />
          <p
            v-if="$v.lastName.$error && !$v.lastName.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="$v.lastName.$error && !$v.lastName.minLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a minimum of X characters', { min: $v.lastName.$params.minLength.min }) }}
          </p>
          <p
            v-else-if="$v.lastName.$error && !$v.lastName.maxLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a maximum of X characters', { max: $v.lastName.$params.maxLength.max }) }}
          </p>
          <p
            v-else-if="$v.lastName.$error && !$v.lastName.validName"
            class="error-message pt-2"
          >
            {{ $t('Please enter a valid name') }}
          </p>
        </div>
        <div class="field">
          <v-text-field
            id="add-user-phone-input"
            v-model="phone"
            :label="$t('Phone')+'*'"
            dense
            autocomplete="no"
            @input="$v.phone.$touch()"
          />
          <p
            v-if="$v.phone.$error && !$v.phone.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="$v.phone.$error && !$v.phone.phoneNumber"
            class="error-message pt-2"
          >
            {{ $t('Please enter a valid phone number') }}
          </p>
          <p
            v-else-if="$v.phone.$error && !$v.phone.minLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a minimum of X characters', { min: $v.phone.$params.minLength.min }) }}
          </p>
          <p
            v-else-if="$v.phone.$error && !$v.phone.maxLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a maximum of X characters', { max: $v.phone.$params.maxLength.max }) }}
          </p>
        </div>
        <div class="field">
          <v-text-field
            id="add-user-website-input"
            v-model="website"
            :label="$t('Website')+'*'"
            dense
            autocomplete="no"
            @input="$v.website.$touch()"
          />
          <p
            v-if="$v.website.$error && !$v.website.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-if="$v.website.$error && !$v.website.url"
            class="error-message pt-2"
          >
            {{ $t('Please enter a valid URL') }}
          </p>
        </div>
        <div class="field">
          <v-text-field
            id="partner-details-vatnumber-input"
            v-model="vatNumber"
            :label="$t('VAT number')"
            dense
            autocomplete="no"
            @input="$v.vatNumber.$touch()"
          />
          <p
            v-if="$v.vatNumber.$error && !$v.vatNumber.validVATNumber"
            class="error-message pt-2"
          >
            {{ $t('Please enter a valid VAT number') }}
          </p>
        </div>
        <div class="field">
          <v-text-field
            id="partner-details-discount-input"
            v-model="discountLevel"
            :label="$t('Discount')"
            dense
            autocomplete="no"
            @input="$v.discountLevel.$touch()"
          />
          <p
            v-if="$v.discountLevel.$error && !$v.discountLevel.numeric"
            class="error-message pt-2"
          >
            {{ $t('Please enter a numeric value') }}
          </p>
          <p
            v-else-if="$v.discountLevel.$error && !$v.discountLevel.between"
            class="error-message pt-2"
          >
            {{ $t('Please enter a value in range', {low: getMinDiscountValue, high: getMaxDiscountValue}) }}
          </p>
        </div>
      </v-col>
      <v-col>
        <div class="field">
          <v-text-field
            id="add-user-address-input"
            v-model="address"
            :label="$t('Address')+'*'"
            dense
            autocomplete="no"
            @input="$v.address.$touch()"
          />
          <p
            v-if="$v.address.$error && !$v.address.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="$v.address.$error && !$v.address.minLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a minimum of X characters', { min: $v.address.$params.minLength.min }) }}
          </p>
          <p
            v-else-if="$v.address.$error && !$v.address.maxLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a maximum of X characters', { max: $v.address.$params.maxLength.max }) }}
          </p>
          <p
            v-else-if="$v.address.$error"
            class="error-message pt-2"
          >
            {{ $t('Please enter only valid characters') }}
          </p>
        </div>
        <div class="field">
          <v-text-field
            id="add-user-zip-code-input"
            v-model="zip"
            :label="$t('Zip code')+'*'"
            dense
            autocomplete="no"
            @input="$v.zip.$touch()"
          />
          <p
            v-if="$v.zip.$error && !$v.zip.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="$v.zip.$error && !$v.zip.minLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a minimum of X characters', { min: $v.zip.$params.minLength.min }) }}
          </p>
          <p
            v-else-if="$v.zip.$error && !$v.zip.maxLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a maximum of X characters', { max: $v.zip.$params.maxLength.max }) }}
          </p>
          <p
            v-else-if="$v.zip.$error && !$v.zip.alphaNumericSpace"
            class="error-message pt-2"
          >
            {{ $t('Please enter only valid characters') }}
          </p>
        </div>
        <div class="field">
          <v-text-field
            id="add-user-city-input"
            v-model="city"
            :label="$t('City')+'*'"
            dense
            autocomplete="no"
            @input="$v.city.$touch()"
          />
          <p
            v-if="$v.city.$error && !$v.city.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="$v.city.$error && !$v.city.minLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a minimum of X characters', { min: $v.city.$params.minLength.min }) }}
          </p>
          <p
            v-else-if="$v.city.$error && !$v.city.maxLength"
            class="error-message pt-2"
          >
            {{ $t('Please enter a maximum of X characters', { max: $v.city.$params.maxLength.max }) }}
          </p>
          <p
            v-else-if="$v.city.$error && !$v.city.alphaNumericSpace"
            class="error-message pt-2"
          >
            {{ $t('Please enter only valid characters') }}
          </p>
        </div>
        <div class="field">
          <v-autocomplete
            id="add-user-country-input"
            v-model="country"
            :label="$t('Country')+'*'"
            :items="countries"
            dense
            class="zIndex100"
            :placeholder="$t('Select country')"
            autocomplete="no"
            type="search"
          />
          <p
            v-if="$v.country.$error && !$v.country.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
        </div>
        <div class="field">
          <v-autocomplete
            id="add-user-languages-input"
            v-model="language"
            :label="$t('Language')+'*'"
            :selected-option="selectedLanguage"
            :items="languages"
            dense
            class="zIndex100"
            :placeholder="$t('Select language')"
            autocomplete="no"
            type="search"
          />
          <p
            v-if="$v.language.$error && !$v.language.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
        </div>
        <div class="field">
          <v-autocomplete
            id="add-user-timezone-input"
            v-model="timeZone"
            :label="$t('Timezone')+'*'"
            :items="timezones"
            dense
            class="zIndex100"
            :placeholder="$t('Select timezone')"
            autocomplete="no"
            type="search"
          >
            <template #item="data">
              {{ data.item + addOffset(data.item) }}
            </template>
          </v-autocomplete>
          <p
            v-if="$v.timeZone.$error && !$v.timeZone.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
        </div>
        <div class="field">
          <v-autocomplete
            v-model="currency"
            :label="$t('Currency')+'*'"
            :items="currencies"
            dense
            class="zIndex100"
            :placeholder="$t('Select currency')"
          />
          <p
            v-if="$v.currency.$error && !$v.currency.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
        </div>
        <div
          v-if="zohoId"
          class="field"
        >
          <v-text-field
            id="add-zoho-id-input"
            v-model="zohoId"
            :label="$t('Zoho ID')"
            dense
            readonly
            autocomplete="no"
          />
        </div>
      </v-col>
    </v-row>
    <v-row id="add-user-password-block">
      <v-col cols="6">
        <div class="field">
          <v-text-field
            id="add-user-password-input"
            v-model="password"
            :label="$t('Password')"
            dense
            :type="showPassword ? 'text' : 'password'"
            autocomplete="no"
            :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
            @input="$v.password.$touch()"
            @click:append="showPassword = !showPassword"
          />
          <p
            v-if="$v.password.$error && !$v.password.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="$v.password.$error && !$v.password.validPassword"
            class="error-message pt-2"
          >
            {{ $t('Please enter a valid password') }}
          </p>
          <p
            v-else-if="$v.password.$error && !($v.password.minLength && $v.password.maxLength)"
            class="error-message pt-2"
          >
            {{ $t('Password length') }}
          </p>
        </div>
        <div class="field">
          <v-text-field
            id="add-user-confirm-password-input"
            v-model="confirmPassword"
            :label="$t('Confirm Password')"
            dense
            :type="showPasswordConfirm ? 'text' : 'password'"
            autocomplete="no"
            :append-icon="showPasswordConfirm ? 'fas fa-eye' : 'fas fa-eye-slash'"
            @input="$v.confirmPassword.$touch()"
            @click:append="showPasswordConfirm = !showPasswordConfirm"
          />
          <p
            v-if="$v.confirmPassword.$error && !$v.confirmPassword.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="$v.confirmPassword.$error && !$v.confirmPassword.sameAsPassword"
            class="error-message pt-2"
          >
            {{ $t('Password does not match') }}
          </p>
        </div>
      </v-col>
    </v-row>
    <v-btn
      class="float-right mt-4"
      color="primary"
      @click="addPartner"
    >
      {{ $t('Add ' + partnerType ) }}
    </v-btn>
  </v-container>
</template>

<script>
import { required, minLength, maxLength, email, url, sameAs, numeric, between } from 'vuelidate/lib/validators'
import { phoneNumber, validName, validReference, alphaNumericSpace, validPassword, validVATNumber } from '@/service/main'
import { getLanguageLabel } from '@/service/guiUtils'
import {DISTRIBUTOR_ONLY_ACCOUNT_PERMISSIONS, INITIAL_ACCOUNT_PERMISSIONS} from '../../main'
import { findTimeZone, getUTCOffset } from 'timezone-support'

const DISCOUNT_MIN_VALUE = 0
const DISCOUNT_MAX_VALUE = 100

const auth = require('@/service/auth')
const restapi = require('@/service/restapi')

export default {
    name: 'AddPartner',
    props: ['partnerId', 'partnerType', 'zohoId'],
    data () {
        return {
            companyName: null,
            reference: null,
            customReference: null,
            email: null,
            firstName: null,
            lastName: null,
            phone: null,
            website: 'https://www.',
            address: null,
            zip: null,
            city: null,
            language: null,
            timeZone: null,
            country: null,
            currency: null,
            vatNumber: null,
            discountLevel: 0,
            password: null,
            confirmPassword: null,
            countries: [],
            languages: [],
            showPassword: false,
            showPasswordConfirm: false,
            currencies: [
                { 'text': this.$t('USD'), 'value': 'USD' },
                { 'text': this.$t('EUR'), 'value': 'EUR' },
                { 'text': this.$t('YEN'), 'value': 'YEN' },
                { 'text': this.$t('GBP'), 'value': 'GBP' }
            ]
        }
    },
    computed: {
        newPartnerData () {
            return {
                active: true,
                companyName: this.companyName,
                reference: this.reference,
                customReference: this.customReference,
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone,
                website: this.website,
                address: this.address,
                zipCode: this.zip,
                city: this.city,
                language: this.language,
                timeZone: this.timeZone,
                country: this.country,
                currency: this.currency,
                vatNumber: this.vatNumber,
                discountLevel: this.discountLevel,
                password: this.password
            }
        },
        timezones () {
            return this.$store.getters.timeZones
        },
        selectedCountry () {
            if (this.country && this.countries) {
                let currentCountry = this.country
                return this.countries.find(function (c) { return c.value === currentCountry })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        selectedLanguage () {
            if (this.language && this.languages) {
                let currentLanguage = this.language
                return this.languages.find(function (l) { return l.value === currentLanguage })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        selectedTimezone () {
            if (this.timeZone && this.timezones) {
                let currentZone = this.timeZone
                return this.timezones.find(function (l) { return l.value === currentZone })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        selectedCurrency () {
            if (this.currency && this.currencies) {
                let currentCurrency = this.currency
                return this.currencies.find(function (c) { return c.value === currentCurrency })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        isBrowserIE () {
            return this.$store.getters.isIE
        }
    },
    mounted () {
        const that = this
        this.countries = []
        this.languages = []
        this.initNewPartnerData()
        restapi.getCountries()
            .then(function (result) {
                that.countries = result
            })
            .catch(function (e) {
                console.error(e)
            })
            .then(function () {
                restapi.getLanguages()
                    .then(function (result) {
                        that.languages = result
                    })
                    .catch(function (e) {
                        console.error(e)
                    })
            })
            .then(function () {
                if (that.zohoId) {
                    restapi.getZohoAccountById(that.zohoId)
                        .then(function (result) {
                            if (result.website && !result.website.match(/^[a-zA-Z]+:\/\//)) {
                                result.website = 'http://' + result.website
                            }
                            that.website = result.website
                            that.companyName = result.name
                            that.phone = result.phone.replace(/ /g, '')
                            that.address = result.billingStreet
                            that.city = result.billingCity
                            that.country = result.billingCountry
                        })
                        .catch(function (error) {
                            console.error(error)
                        })
                }
            })
    },
    methods: {
        initNewPartnerData () {
            this.companyName = null
            this.reference = null
            this.customReference = null
            this.email = null
            this.firstName = null
            this.lastName = null
            this.telephone = null
            this.website = 'https://www.'
            this.address = null
            this.zip = null
            this.city = null
            this.language = null
            this.timeZone = null
            this.country = null
            this.currency = null
            this.vatNumber = null
            this.discountLevel = 0
            this.password = ''
        },
        onSelectLanguage (l) {
            this.language = l.value
        },
        onSelectCountry (c) {
            this.country = c.value
        },
        onSelectTimezone (tz) {
            this.timeZone = tz.value
        },
        onSelectCurrency (c) {
            this.currency = c.value
        },
        getLanguageLabel (code) {
            return getLanguageLabel(this.languages, code)
        },
        canEditDiscount () {
            if (auth.isAdmin()) {
                return true
            } else if (auth.isDistributor()) {
                return this.$store.getters.myAccount.accountId !== this.partnerId
            } else {
                return false
            }
        },
        getMinDiscountValue () {
            return DISCOUNT_MIN_VALUE
        },
        getMaxDiscountValue () {
            return DISCOUNT_MAX_VALUE
        },
        addPartner () {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                const that = this
                that.isLoading(true)
                if (this.partnerType.toLocaleLowerCase() === 'reseller') {
                    this.newPartnerData.distributorId = this.partnerId
                }
                if (this.zohoId) {
                    this.newPartnerData.crmId = this.zohoId
                }
                restapi.addPartner(this.partnerType, this.newPartnerData)
                    .then(function (partner) {
                        let partnerId = (that.partnerType.toLocaleLowerCase() === 'reseller') ? partner.resellerId : partner.distributorId
                        restapi.createAccountProfile(partnerId, { name: 'Admin' })
                            .then(function (profile) {
                                let permissions = Object.assign({}, that.$store.getters.myAccount.profile.permissions)
                                let rights = INITIAL_ACCOUNT_PERMISSIONS
                                if (that.partnerType.toLocaleLowerCase() === 'distributor') {
                                    rights = rights.concat(DISTRIBUTOR_ONLY_ACCOUNT_PERMISSIONS)
                                }
                                for (let right in permissions) {
                                    if (permissions.hasOwnProperty(right)) {
                                        permissions[right] = rights.indexOf(right) !== -1
                                    }
                                }
                                restapi.updateAccountProfilePermissions(partnerId, profile.profileId, permissions)
                                    .then(function (result) {
                                        const account = {
                                            active: true,
                                            email: that.newPartnerData.email,
                                            password: that.newPartnerData.password,
                                            roleId: profile.profileId
                                        }
                                        restapi.addPartnerAccount(partnerId, account)
                                            .then(function () {
                                                that.$store.commit('toastMessage', {
                                                    showing: true,
                                                    text: that.$t('The PARTNERTYPE was successfully created', {type: that.$t(that.partnerType)}),
                                                    timeout : 4000,
                                                    color: 'primary'
                                                })
                                                that.$emit('created')
                                            })
                                            .catch(function (error) {
                                                console.error(error)
                                                that.$store.commit('toastMessage', {
                                                    showing: true,
                                                    text: that.getMessageFromError(error, (that.partnerType.toLocaleLowerCase() === 'reseller') ? 'ADD_RESELLER' : 'ADD_DISTRIBUTOR'),
                                                    timeout : -1,
                                                    color: 'error',
                                                    support: true
                                                })
                                            })
                                    })
                                    .catch(function (error) {
                                        console.error(error)
                                        that.$store.commit('toastMessage', {
                                            showing: true,
                                            text: that.getMessageFromError(error, (that.partnerType.toLocaleLowerCase() === 'reseller') ? 'ADD_RESELLER' : 'ADD_DISTRIBUTOR'),
                                            timeout : -1,
                                            color: 'error',
                                            support: true
                                        })
                                    })
                                    .then(function () {
                                        if (that.zohoId) {
                                            const partnerType = that.partnerType.toLowerCase()
                                            restapi.createZohoMapping(that.zohoId, partnerId, partnerType)
                                                .then(function () {})
                                                .catch(function (error) {
                                                    console.error(error)
                                                    that.$store.commit('toastMessage', {
                                                        showing: true,
                                                        text: that.getMessageFromError(error, (that.partnerType.toLocaleLowerCase() === 'reseller') ? 'ADD_RESELLER' : 'ADD_DISTRIBUTOR'),
                                                        timeout : -1,
                                                        color: 'error',
                                                        support: true
                                                    })                                                })
                                        }
                                    })
                            })
                            .catch(function (error) {
                                console.error(error)
                                that.$store.commit('toastMessage', {
                                    showing: true,
                                    text: that.getMessageFromError(error, (that.partnerType.toLocaleLowerCase() === 'reseller') ? 'ADD_RESELLER' : 'ADD_DISTRIBUTOR'),
                                    timeout : -1,
                                    color: 'error',
                                    support: true
                                })
                            })
                    })
                    .catch(function (error) {
                        console.error(error)
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.getMessageFromError(error, (that.partnerType.toLocaleLowerCase() === 'reseller') ? 'ADD_RESELLER' : 'ADD_DISTRIBUTOR'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                    })
                    .then(function () {
                        that.isLoading(false)
                    })
            }
        },
        addOffset(timezone) {
            const offset = this.getOffset(timezone)
            const hours = offset / 60
            const minutes = offset % 60
            if (hours > 0) return ' ( -' + hours + (minutes ? `:${Math.abs(minutes)}` : '') + ' )'
            else return ' ( +' + Math.floor(Math.abs(hours)) + (minutes ? `:${Math.abs(minutes)}` : '') + ' )'
        },
        getOffset(timeZone) {
            const foundTimeZone = findTimeZone(timeZone)
            const date = new Date()
            const timeZoneOffset = getUTCOffset(date, foundTimeZone)
            return timeZoneOffset.offset
        }
    },
    validations: {
        companyName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
            validName
        },
        reference: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(250),
            validReference
        },
        customReference: {
            maxLength: maxLength(255)
        },
        phone: {
            required,
            phoneNumber,
            minLength: minLength(10),
            maxLength: maxLength(20)
        },
        website: {
            required,
            url
        },
        address: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(250),
        },
        zip: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(16),
            alphaNumericSpace
        },
        city: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(50),
            alphaNumericSpace
        },
        email: {
            required,
            email,
            minLength: minLength(8),
            maxLength: maxLength(128)
        },
        firstName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(32),
            validName
        },
        lastName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(32),
            validName
        },
        country: {
            required
        },
        language: {
            required
        },
        timeZone: {
            required
        },
        currency: {
            required
        },
        vatNumber: {
            validVATNumber
        },
        discountLevel: {
            numeric,
            between: between(DISCOUNT_MIN_VALUE, DISCOUNT_MAX_VALUE)
        },
        password: {
            required,
            validPassword,
            minLength: minLength(6),
            maxLength: maxLength(32)
        },
        confirmPassword: {
            required,
            sameAsPassword: sameAs('password')
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';

  .zIndex100{
    z-index: 100;
  }

  .add-partner-form {
    background-color: #ffffff;
    .button-bar {
      .button {
        margin: 20px 0;
      }
    }
    .columns {
      margin: 0;
      padding: 0;
      &:first-child {
        padding-top: 20px;
      }
      &:last-child {
        padding-bottom: 20px;
      }
      &.add-user-password-block {
        padding-bottom: 20px;
      }
      background-color: transparent;
      .column {
        padding: 0 40px;
        margin: 20px 0;
        @media screen and (max-width: 767px) {
          border-left: 8px solid $primary;
          padding-left: 32px;
        }
        @media screen and (min-width: 768px) {
          &:first-child {
            border-left: 8px solid $primary;
            padding-left: 32px;
          }
        }
        .field {
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .mb-40 {
        margin-bottom: 40px;
      }
    }
  }
</style>

