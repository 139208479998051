<template>
  <v-hover>
    <template #default="{ hover }">
      <v-card
        :id="cardId"
        :data-id="id"
        class="partnerCard"
        max-width="400"
        :elevation="hover ? 10 : ''"
        :ripple="false"
        @click="selectPartner(id)"
      >
        <v-card-text :class="`${ partner.active ? 'img-gradient' : 'img-gradient-disabled'} pb-0`">
          <v-row class="card-content pa-0">
            <v-col v-if="canShowTotalConnectedCameras">
              <p class="camera-count font-weight-bold mb-0">
                {{ cameraCount }}
              </p>
              <p class="number-label text-uppercase font-weight-light">
                {{ $t("Camera slots") }}
              </p>
            </v-col>
            <v-col v-if="canShowNumberResellers">
              <p class="reseller-count mb-0">
                {{ resellerCount }}
              </p>
              <p class="number-label text-uppercase font-weight-light">
                {{ $t("Resellers") }}
              </p>
            </v-col>
            <v-col v-if="canShowTotalCustomers">
              <p class="customer-count mb-0">
                {{ customerCount }}
              </p>
              <p class="number-label text-uppercase font-weight-light">
                {{ $t("Customers") }}
              </p>
            </v-col>
            <v-col v-if="!partner.active">
              <p class="text-uppercase text-center mt-3">
                {{ $t('In-active') }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <span :class="`${ partner.active ? 'primary--text' : 'gray--text'} text-uppercase text-center font-weight-bold text-truncate width-100`">{{ company }}</span>
        </v-card-actions>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
const restapi = require('@/service/restapi')

export default {
    name: 'PartnerCard',
    props: {
        partner: {
            type: Object,
            required: true
        },
        partnerType: {
            type: String,
            required: true
        },
        rest: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            count: {
                cameraCount: 0,
                resellerCount: 0,
                customerCount: 0
            }
        }
    },
    computed: {
        id () {
            if (this.partnerType === 'Distributor') {
                return this.partner.distributorId
            } else {
                return this.partner.resellerId
            }
        },
        company () {
            return this.partner.companyName
        },
        cameraCount () {
            return this.partner.active ? this.partner.count.cameraCount : 0
        },
        resellerCount () {
            return this.partner.active ? this.partner.count.resellerCount : 0
        },
        customerCount () {
            return this.partner.active ? this.partner.count.customerCount : 0
        },
        canShowTotalCustomers () {
            return this.partner && this.partner.active && this.partnerType === 'Reseller'
        },
        canShowNumberResellers () {
            return this.partner && this.partner.active && this.partnerType === 'Distributor'
        },
        canShowTotalConnectedCameras () {
            return this.partner && this.partner.active
        },
        cardId () {
            return 'partner-card-' + this.id
        },
        accountStatistics () {
            return this.$store.getters.accountStatistics[this.id] || {}
        },
        partnerLogo () {
            if (!this.partner.Website) return false
            return '//logo.clearbit.com/' + this.partner.Website + '?s=512'
        }
    },
    mounted () {
        if (this.canShowTotalConnectedCameras && !this.count) {
            const that = this
            if (that.partnerType === 'Distributor' || that.partner.distributorId === that.partner.resellerId) {
                restapi.getDistributorCount(that.id)
                    .then(function (result) {
                        that.count = result
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
            } else {
                restapi.getResellerCount(that.id)
                    .then(function (result) {
                        that.count = result
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
            }
        }
    },
    methods: {
        selectPartner (id) {
            if (this.partnerType) {
                this.$router.push('/' + this.partnerType.toLowerCase() + '/' + id)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/styles/main";
  @import "../../assets/styles/custom";

  .width-100 {
    width: 100%;
  }

  .partnerCard {
    @media screen and (max-device-width: 769px) {
      width: 275px;
    }
    @media screen and (min-device-width: 769px) {
      width: 320px;
    }
    box-sizing: border-box;
    margin: 5px 10px 5px 0;
    box-shadow: 0 4px 16px 0 rgba(10,10,10,.1), 0 0 0 0 rgba(10,10,10,.1) !important;
  }

  .img-gradient {
    background: -webkit-linear-gradient(to top left, var(--v-primary-lighten2),  var(--v-primary-base));
    background: linear-gradient(to top left, var(--v-primary-lighten2),  var(--v-primary-base));
  }

  .img-gradient-disabled {
    background: -webkit-linear-gradient(to top left, rgb(202, 202, 202), rgb(133, 133, 133));
    background: linear-gradient(to top left, rgb(202, 202, 202), rgb(133, 133, 133));
  }

  .card-content {
    min-height: 84px;
    p {
      color: white;
    }
    .camera-count {
      font-size: 28px;
    }
    .reseller-count {
      font-size: 22px;
    }
    .number-label {
      font-size: 12px;
    }
  }
</style>
