<template>
    <div class="view-addon-subscriptions">
      <div
        v-if="hasAddonSubscriptions"
        class="addon-subscriptions-list"
      >
        <div class="table-top-actions-block">
          <div class="search-block">
            <v-text-field
              v-model="filter"
              class="partner-search"
              type="text"
              placeholder="Search"
            >
              <template #prepend>
                <v-icon
                  size="1rem"
                  class="pt-1"
                >
                  fa fa-search
                </v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="fill-remaining-space" />
          <div class="add-new-button-block">
            <v-btn
              large
              color="primary"
              :disabled="!hasPermission('CustomerAccount')"
              @click="addNewAddonSubscription()"
            >
              {{ $t("Add new") }}
            </v-btn>
          </div>
        </div>
        <addon-subscriptions-table
          :addonSubscriptions="addonSubscriptions"
          :cameras="cameras"
          :filter="filter"
          @onAssignCamera="onAssignCamera"
          @onCameraUnassigned="onCameraUnassigned"
          @onAddonSubscriptionRemoved="onAddonSubscriptionRemoved"/>
      </div>
      <div
        v-else
        class="empty-addon-subscriptions-list"
      >
        <div class="columns is-dashed px-10 pa-5">
          <v-row>
            <v-col cols="auto">  
              <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                class="svg-inline--fa fa-dice-d6 fa-w-12 fa-3x addon-image"
              >
                <path
                  fill="currentColor"
                  d="M201 10.3c14.3-7.8 31.6-7.8 46 0L422.3 106c5.1 2.8 8.3 8.2 8.3 14s-3.2 11.2-8.3 14L231.7 238c-4.8 2.6-10.5 2.6-15.3 0L25.7 134c-5.1-2.8-8.3-8.2-8.3-14s3.2-11.2 8.3-14L201 10.3zM23.7 170l176 96c5.1 2.8 8.3 8.2 8.3 14V496c0 5.6-3 10.9-7.8 13.8s-10.9 3-15.8 .3L25 423.1C9.6 414.7 0 398.6 0 381V184c0-5.6 3-10.9 7.8-13.8s10.9-3 15.8-.3zm400.7 0c5-2.7 11-2.6 15.8 .3s7.8 8.1 7.8 13.8V381c0 17.6-9.6 33.7-25 42.1L263.7 510c-5 2.7-11 2.6-15.8-.3s-7.8-8.1-7.8-13.8V280c0-5.9 3.2-11.2 8.3-14l176-96z"
                />
              </svg>
            </v-col>
            <v-col>
              <p class="contact-dealer">
                {{ $t("Click 'ADD NEW' button to add a new add-on subscription to the account") }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-btn
            large
            color="primary"
            :disabled="!(hasPermission('CustomerAccount'))"
            @click="addNewAddonSubscription()"
          >
            {{ $t("Add new") }}
          </v-btn>
        </div>
      </div>
    </div>
  </template>
  
<script charset="utf-8">
import AddonSubscriptionsTable from './AddonSubscriptionsTable.vue'

export default {
    name: 'ViewAddonSubscriptions',
    components: {
        AddonSubscriptionsTable,
    },
    props: ['addonSubscriptions', 'cameras'],
    data () {
        return {
            filter: null
        }
    },
    computed: {
        hasAddonSubscriptions () {
            return this.addNewAddonSubscription && this.addonSubscriptions.length > 0
        },
        resellerId () {
            return this.$route.params.resellerid
        },
        accountId () {
            return this.$route.params.accountid
        },
        userId () {
            return this.$route.params.userid
        },
    },
    methods: {
        addNewAddonSubscription () {
            this.$emit('onAddNewAddonSubscription')
        },
        onAssignCamera (subscription) {
            this.$emit('onAssignCamera', subscription)
        },
        onCameraUnassigned (subscription) {
            this.$emit('onCameraUnassigned', subscription)
        },
        onAddonSubscriptionRemoved (subscription) {
            this.$emit('onAddonSubscriptionRemoved', subscription)
        },
    }
}
</script>
  
<style lang="scss" scoped>
  @import '../../../../assets/styles/forms';
  @import '../../../../assets/styles/custom';

  .empty-addon-subscriptions-list {
    padding: 5px 0;
  }

  .content-block {
    margin-top: 30px;
  }

  .columns {
    margin-top: 20px;
    padding: 5px 0;
    &.is-dashed {
      background-color: transparent;
      border: dashed 2px $color-title-light;
      border-radius: 4px;
      padding: 0 0 10px 20px;
      margin-left: 0;
      margin-bottom: 20px;
      .column {
        margin: 20px 0 10px 0;
        padding: 0 0 0 10px;
      }
      .contact-dealer {
        padding-top: 10px;
        padding-left: 10px;
        font-size: 13px;
      }
    }
  }

  .columns.title-block {
    background-color: transparent;
    p {
      font-size: 12px;
    }
  }

  .field {
    &.search {
      min-width: 50%;
      display: inline-block;
      margin: 20px 0 30px;
      max-height: 28px;
      p.control.has-icons-left {
        border: none;
        .icon {
          height: 40px;
        }
        input.input {
          margin: 0 0 0 30px;
          padding: 0;
          height: 40px;
          border: none;
          border-bottom: 2px solid #FFFFFF;
          + .icon {
            border-bottom: 2px solid #FFFFFF;
          }
          &:focus {
            border-bottom-color: $primary;
            + .icon {
              color: $primary;
              border-bottom-color: $primary;
            }
          }
        }
      }
    }
  }

  .table-top-actions-block {
    display: flex;
    overflow: hidden;
    margin: 0;
    align-items: center;
  }

  .fill-remaining-space {
    flex: auto;
  }

  .addon-image {
    width: 30px;
    path {
      fill: $color-gray;
    }
  }

</style>
  