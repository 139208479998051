<template>
  <img
    :src="url"
    :alt="alt"
    :title="title"
    class="user-image"
  >
</template>

<script>
import md5 from 'md5'

export default {
    name: 'Gravatar',
    props: {
        email: {
            type: String,
            default: 'someone@somewhere.com'
        },
        hash: {
            type: String,
            default: ''
        },
        size: {
            type: Number,
            default: 30
        },
        type: {
            type: String,
            default: 'png'
        },
        defaultImg: {
            type: String,
            default: 'mm'
        },
        rating: {
            type: String,
            default: 'g'
        },
        alt: {
            type: String,
            default: 'Avatar'
        },
        title: {
            type: String,
            default: 'Avatar'
        }
    },
    computed: {
        url: function () {
            const img = [
                '//www.gravatar.com/avatar/',
                this.hash || md5(this.email.trim().toLowerCase()),
                '.',
                this.type.toLowerCase(),
                '?s=',
                this.size,
                '&d=',
                this.defaultImg,
                '&r=',
                this.rating
            ]

            return img.join('')
        }
    }
}
</script>

<style scoped>
  .user-image {
    border-radius: 20px;
    margin-right: 10px;
  }
</style>
