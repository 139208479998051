import { render, staticRenderFns } from "./PartnerDetails.vue?vue&type=template&id=13737433&scoped=true"
import script from "./PartnerDetails.vue?vue&type=script&lang=js"
export * from "./PartnerDetails.vue?vue&type=script&lang=js"
import style0 from "./PartnerDetails.vue?vue&type=style&index=0&id=13737433&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13737433",
  null
  
)

export default component.exports