<template>
    <div class="addon-subscriptions">
      <h2 class="component-title">
        {{ $t('Add-on subscriptions') }}
      </h2>
      <p class="component-description">
        {{ $t('View all add-on subscriptions and assign them to the cameras') }}
      </p>
      <view-addon-subscriptions v-if="shouldBeShown('view')" 
            :addonSubscriptions="addonSubscriptions" 
            :cameras="cameras"
            @onAddNewAddonSubscription="addNewAddonSubscription"
            @onAddonSubscriptionRemoved="addonSubscriptionRemoved"
            @onCameraUnassigned="cameraUnassigned"
            @onAssignCamera="assignCamera"
            />
      <create-addon-subscription v-if="shouldBeShown('create')" 
            :availableAddonSubscriptions="availableAddonSubscriptions"
            :maxAmountOfCameras="maxAmountOfCameras" 
            :addonSubscriptions="addonSubscriptions"
            @onCancel="createAddonSubscriptionCancelled" 
            @onCreated="addonSubscriptionCreated"/>

      <assign-camera-to-addon-subscription v-if="shouldBeShown('assign')" 
            :cameras="cameras" 
            :addonSubscription="addonSubscriptionToAssignCamera"
            :skuToCameraIdMap="skuToCameraIdMap"
            @onCancel="assignCameraCancelled"
            @onCameraAssigned="cameraAssigned"/>
  </div>
</template>

<script charset="utf-8">
import ViewAddonSubscriptions from '@/components/account/subscription/addons/ViewAddonSubscriptions'
import CreateAddonSubscription from '@/components/account/subscription/addons/CreateAddonSubscription'
import AssignCameraToAddonSubscription from './AssignCameraToAddonSubscription.vue'

const restapi = require('@/service/restapi')

const actionState = {
    VIEW: 'view',
    CREATE: 'create',
    ASSIGN: 'assign'
}

export default {
    name: 'Addons',
    components: {
        ViewAddonSubscriptions,
        CreateAddonSubscription,
        AssignCameraToAddonSubscription
    },
    props: ['maxAmountOfCameras', 'availableAddonSubscriptions'],
    data () {
        return {
            addonSubscriptions: [],
            filter: null,
            state: actionState.VIEW,
            cameras: [],
            addonSubscriptionToAssignCamera: null,
            skuToCameraIdMap: new Map()
        }
    },
    computed: {
        resellerId () {
            return this.$route.params.resellerid
        },
        accountId () {
            return this.$route.params.accountid
        },
        userId () {
            return this.$route.params.userid
        },
    },
    async mounted () {
        await this.loadAddonSubscriptions()
        await this.loadCameras()
        this.enrichAddonSubscriptionsWithCamerasNames()
        this.buildSkuToAssignedCamerasMap()
    },
    methods: {
        buildSkuToAssignedCamerasMap() {
            for (let i = 0; i < this.availableAddonSubscriptions.length; i++) {
                const addon = this.availableAddonSubscriptions[i]
                const skuWithoutMonthSuffix = this.getSKUWithoutMonthSuffix(addon.sku)

                if(!this.skuToCameraIdMap.has(skuWithoutMonthSuffix)) {
                    this.skuToCameraIdMap.set(skuWithoutMonthSuffix, [])
                } 

                const matchingAddonWithCameraAssigned = this.addonSubscriptions.filter(a => a.sku === addon.sku && a.cameraId !== null)

                if (matchingAddonWithCameraAssigned && matchingAddonWithCameraAssigned.length > 0) {
                    const assignedCameras = this.skuToCameraIdMap.get(skuWithoutMonthSuffix)
                    for (const addon of matchingAddonWithCameraAssigned) {
                        assignedCameras.push(addon.cameraId)
                    }
                } 
            }
        },
        getSKUWithoutMonthSuffix (sku) {
            return sku.replace(/-\d+$/, '')
        },
        shouldBeShown (state) {
            return this.state === state
        },
        changeState (state) {
            this.state = state
        },
        async loadAddonSubscriptions () {
            this.isLoading(true)

            try {
                const addons = await restapi.getAccountSubscriptions(this.resellerId, this.accountId, 'addOn')    
                // enrich the addons with the name property
                this.addonSubscriptions = addons.map(addon => {
                    const matchingAddon = this.availableAddonSubscriptions.find(a => a.sku === addon.sku)
                    return {
                        ...addon,
                        name: matchingAddon ? matchingAddon.name : '',
                        cameraName: null
                    }
                })
            } catch (error) {
                console.error(error)
                this.$store.commit('toastMessage', {
                    showing: true,
                    text: this.getMessageFromError(error, 'GET_ACCOUNT_SUBSCRIPTIONS'),
                    timeout : -1,
                    color: 'error',
                    support: true
                })
            } finally { 
                this.isLoading(false)
            }
        },
        async loadCameras () {
            try {
                this.cameras = await restapi.getCamerasSummary(this.resellerId, this.accountId)
            } catch (error) {
                console.error(error)
                this.$store.commit('toastMessage', {
                    showing: true,
                    text: this.getMessageFromError(error, 'GET_ENDUSER_CAMERAS'),
                    timeout : -1,
                    color: 'error',
                    support: true
                })
            }
        },
        createAddonSubscriptionCancelled () {
            this.changeState(actionState.VIEW)
        },
        addNewAddonSubscription () {
            if (!this.hasPermission('CustomerAccount')) return
            this.changeState(actionState.CREATE)
        },
        async addonSubscriptionCreated () {
            this.changeState(actionState.VIEW)
            await this.loadAddonSubscriptions()
            this.enrichAddonSubscriptionsWithCamerasNames()
        },
        addonSubscriptionRemoved (subscription) {
            this.addonSubscriptions = this.addonSubscriptions.filter(s => s.subscriptionId !== subscription.subscriptionId)
        },
        assignCamera (subscription) {
            this.addonSubscriptionToAssignCamera = subscription
            this.changeState(actionState.ASSIGN)
        },
        assignCameraCancelled () {
            this.changeState(actionState.VIEW)
        },
        async cameraAssigned (data) {
            // add the camera to the skuToCameraIdMap
            const skuWithoutMonthSuffix = this.getSKUWithoutMonthSuffix(data.subscription.sku)
            const camerasAssigned = this.skuToCameraIdMap.get(skuWithoutMonthSuffix)
            camerasAssigned.push(data.cameraId)   

            this.addonSubscriptionToAssignCamera = null
            this.changeState(actionState.VIEW)
            await this.loadAddonSubscriptions()
            this.enrichAddonSubscriptionsWithCamerasNames()
        },
        async cameraUnassigned (subscription) {
            // remove the camera from the skuToCameraIdMap
            const skuWithoutMonthSuffix = this.getSKUWithoutMonthSuffix(subscription.sku)
            const camerasAssigned = this.skuToCameraIdMap.get(skuWithoutMonthSuffix)
            const index = camerasAssigned.indexOf(subscription.cameraId)
            if (index > -1) {
                camerasAssigned.splice(index, 1)
            }

            await this.loadAddonSubscriptions()
            this.enrichAddonSubscriptionsWithCamerasNames()
        },
        enrichAddonSubscriptionsWithCamerasNames () {
            for (let i = 0; i < this.addonSubscriptions.length; i++) {
                const addon = this.addonSubscriptions[i]
                const camera = this.cameras.find(c => c.cameraId === addon.cameraId)
                if(camera) {
                    addon.cameraName = camera.name
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/forms';
@import '../../../../assets/styles/custom';

.addon-subscriptions {
    padding-top: 50px;
}
</style>