<template>
  <div>
    <v-menu
      nudge-top="10"
      nudge-left="5"
      top
      offset-y
    >
      <template #activator="{ on }">
        <v-btn 
          class="language-switcher"
          v-on="on"
        >
          <span
            class="flag"
            :class="getFlag(languageCode)"
          />
          <span class="lang-name"> {{ languageName }} </span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="lang in languages"
          :key="lang.code"
          @click="selectLanguage(lang.code)"
        >
          <v-list-item-title>
            <span
              class="flag"
              :class="getFlag(lang.code)"
            />
            <span class="lang-name">{{ lang.name }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import locale from '@/service/languageLoader'

export default {
    name: 'LanguageSelection',
    props: {
        position: {
            type: String,
            default: 'is-bottom-right'
        }
    },
    data () {
        return {
            languages: locale.info
        }
    },
    computed: {
        languageCode () {
            return this.$locale.getCurrentLanguage()
        },
        languageName () {
            let code = this.$locale.getCurrentLanguage()
            return this.languages[code].name
        },
        currentLanguage () {
            return this.$locale.getCurrentLanguage()
        }
    },
    watch: {
        currentLanguage(newValue, oldValue) {
            this.loadCrowdin()
        }
    },
    methods: {
        selectLanguage (code) {
            this.$locale.setLanguage(code)
            this.loadCrowdin()
        },
        countryCode (code) {
            if (code === 'en') {
                return 'combo'
            } else if (code === 'ja') {
                return 'jp'
            }
            return code
        },
        getFlag (lang) {
            lang = lang.substr(0, 2)
            return 'flag-' + this.countryCode(lang)
        },
        loadCrowdin() {
            const config = this.getConfiguration()
            if (config.crowdinIntegration && this.currentLanguage === 'aa') {
                window._jipt = []
                window._jipt.push(['project', 'CameraManager'])
                let scr = document.createElement('script')
                scr.src = '//cdn.crowdin.com/jipt/jipt.js'
                scr.setAttribute('id', 'crowdin-script')
                document.getElementsByTagName('head')[0].appendChild(scr)
            } else {
                let el = document.getElementById('crowdin-script')
                if (el) {
                    el.parentNode.removeChild(el)
                    location.reload()
                }
            }
        }
    },
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/main';
  @import '../../assets/styles/flags.css';

  span {
    vertical-align: middle;
  }

  .flag {
    margin-right: 5px;
  }
  .flag.flag-aa {
    background: url('../../assets/images/crowdin32.png') no-repeat;
  }
  .flag.flag-combo {
    margin-top: 10px;
    background: url('../../assets/images/combo.png') no-repeat;
  }
  .dropdown {
    margin-right: 10px;
    align-items: center;
  }
  .dropdown .option {
    width: 120px;
  }
  a.dropdown-item {
    display: flex;
  }
  .lang-icon {
    width: 32px;
    margin-right: 5px;
  }
  .lang-name {
    color: $color-action;
  }
  a.dropdown-item .lang-name {
    padding-top: 7px;
    padding-left: 5px;
  }

  .dropdown-menu {
    z-index: 1001;
  }
</style>
