<template>
  <div v-if="hasPermission('CustomerOverview')">
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="filter"
          :append-icon-cb="() => {}"
          :placeholder="$t('Search')"
          dense
          single-line
          hide-details
          class="partner-search"
        >
          <v-icon
            slot="prepend-inner"
            color="grey"
            size="0.9rem"
            class="pt-1"
          >
            fa fa-search
          </v-icon>
        </v-text-field>
        <v-switch
          v-model="showActive"
          class="pl-1 active-switch"
          color="primary"
          inset
          dense
          :label="showActive ? $t('Active') : $t('In-active')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="filteredAccounts"
            :items-per-page="10"
            :page.sync="selectedPage"
            :footer-props="{ 'items-per-page-text': '', 'items-per-page-options': []}"
            hide-default-header
            calculate-widths
            dense
            :loading="loadingCustomers"
          >
            <template #header="{ props }">
              <thead>
                <tr>
                  <th
                    v-for="(header, index) in props.headers"
                    :key="index"
                    class="primary--text table-head"
                  >
                    {{ $t(header.text) }}
                  </th>
                </tr>
              </thead>
            </template>
            <template #body="{ items, headers }">
              <tbody>
                <td
                  v-if="filteredAccounts.length < 1"
                  class="table-cell expanded-area pl-12 py-12 text-center"
                  :colspan="headers.length"
                >
                  {{ emptyTableMessage }}
                </td>
                <tr
                  v-for="item in items"
                  :key="item.name"
                  class="table-row"
                >
                  <td class="table-cell">
                    <a :href="`/#/user/${partnerId}/${item.accountId}/${item.accountOwnerId}`">
                      {{ item.lastName }}
                    </a>
                  </td>
                  <td class="table-cell">
                    <a :href="`/#/user/${partnerId}/${item.accountId}/${item.accountOwnerId}`">
                      {{ item.firstName }}
                    </a>
                  </td>
                  <td class="table-cell email">
                    <a :href="`/#/user/${partnerId}/${item.accountId}/${item.accountOwnerId}`">
                      {{ item.contactEmail }}
                    </a>
                  </td>
                  <td class="table-cell">
                    <a :href="`/#/user/${partnerId}/${item.accountId}/${item.accountOwnerId}`">
                      {{ item.companyName }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const restapi = require('@/service/restapi')

export default {
    name: 'CustomerList',
    props: ['partnerId', 'count'],
    data () {
        return {
            totalAccounts: null,
            selectedPage: 1,
            perPage: 10,
            filter: null,
            showActive: true,
            filterLastname: null,
            filterFirstname: null,
            filterEmail: null,
            filterCompany: null,
            focusedInput: null,
            emptyTableMessage: this.$t('Loading customers...'),
            headers: [
                { text: 'Last Name', value: 'lastName', width: '200px', fixed: true },
                { text: 'First Name', value: 'firstName', width: '200px', fixed: true },
                { text: 'Email', value: 'contactEmail', width: '200px', fixed: true },
                { text: 'Company', value: 'companyName', width: '200px', fixed: true },
            ],
        }
    },
    computed: {
        currentPage: {
            get () {
                if (this.showActive) {
                    return this.$store.getters.resellerAccounts.currentActivePage || 1
                } else {
                    return this.$store.getters.resellerAccounts.currentInactivePage || 1
                }
            },
            set (page) {
                this.$store.commit('accountsCurrentPage', { active: this.showActive, page: page })
            }
        },
        accounts () {
            return this.$store.getters.resellerAccounts.accounts || []
        },
        filteredAccounts () {
            const _this = this
            let accounts = this.accounts || []
            accounts = accounts.filter(function (a) { return a.active === _this.showActive })
            if (accounts.length && this.filter) {
                const search = this.filter
                accounts = accounts.filter(function (a) {
                    return (a.lastName && a.lastName.toLowerCase().indexOf(search.toLowerCase()) >= 0) ||
                (a.firstName && a.firstName.toLowerCase().indexOf(search.toLowerCase()) >= 0) ||
                (a.contactEmail && a.contactEmail.toLowerCase().indexOf(search.toLowerCase()) >= 0) ||
                (a.companyName && a.companyName && a.companyName.toLowerCase().indexOf(search.toLowerCase()) >= 0)
                })
            }
            return accounts
        },
        loadingCustomers() {
            const safetyMargin = 50
            return (this.count - safetyMargin) > this.accounts.length
        }
    },
    watch: {
        partnerId () {
            this.$store.dispatch('selectReseller', this.partnerId)
            this.clearTableData()
            this.selectedPage = this.currentPage
            this.loadData()
        },
        selectedPage: function (newVal, oldVal) {
            this.currentPage = newVal
        },
        showActive: function (newVal, oldVal) {
            this.selectedPage = this.currentPage
            this.loadData()
        }
    },
    mounted () {
        if (this.partnerId) {
            this.$store.dispatch('selectReseller', this.partnerId)
            this.selectedPage = this.currentPage
            this.loadData()
        }
    },
    methods: {
        loadData () {
            this.emptyTableMessage = this.$t('Loading customers...')
            restapi.getResellerAccounts(this.partnerId, this.showActive)
                .then(result => {
                    this.emptyTableMessage = this.$t('No customers found')
                })
                .catch(error => {
                    console.error(error)
                })
        },
        clearTableData () {
            this.emptyTableMessage = this.$t('Loading customers...')
        },
        columnVisible (type) {
        // let invisible = this.hasSelected && (type === 'firstname' || type === 'company')
        // return !invisible
            console.log(`Visible? ${type}`)
            return type !== 'email' || (type === 'email' && this.showActive)
        },
        blockedClass (account) {
            return (account && account.accountOwnerBlocked) ? 'red' : 'transparent'
        },
        loadOwnerData () {
            console.log('Now loading owner data')
            this.accounts.forEach(account => {
                if (!account.hasOwnProperty('owner')) {
                    restapi.getAccountOwner(account.resellerId, account.accountId)
                        .then(function (result) {
                            account.owner = result
                        })
                        .catch(function (error) {
                            console.error(error)
                        })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>

    .active-switch {
        height: 24px !important;
    }

    .table-head {
        border-bottom-color: var(--v-primary-lighten1) !important;
        border-bottom-width: 2px !important;
    }

    .table-row {
        &:nth-child(even) {
            background-color: #f8f8f8;
        }
        &.expanded {
            background-color: #f5f5f5;
        }
        .table-cell {
            padding: 0px;
            vertical-align: middle;
            font-size: 0.9em;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border: none !important;
            a {
              &:nth-child(1) {
                padding-left: 20px;
              }
              display: block;
              padding: 10px;
              text-decoration: none;
              color: black;
            }
            &:hover {
                cursor: pointer;
            }
        }
        .email {
            max-width: 300px;
            width: 300px;
        }
    }
</style>
