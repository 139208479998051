<template>
  <v-card
    class="mx-auto"
    elevation="0"
  >
    <v-navigation-drawer
      :mini-variant="mini"
      mini-variant-width="40"
      permanent
      floating
    >
      <v-list
        class="pa-0"
        nav
      >
        <slot />
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
    name: 'SideBar',
    computed : {
        mini (){
            return this.$vuetify.breakpoint.mdAndDown
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
