<template>
  <v-col>
    <v-menu
      v-model="menuEnabled"
      z-index="1000"
      nudge-bottom="10px"
      max-width="100%"
      max-height="90%"
      bottom
      offset-y
      class="mobile-search"
      :close-on-click="true"
    >
      <template #activator="{ on }">
        <v-text-field
          v-model="search"
          :append-icon-cb="() => {}"
          :placeholder="placeholder"
          dense
          single-line
          :loading="searchInProgress"
          clearable
          color="white"
          hide-details
          class="nav-search"
          clear-icon="fas fa-times"
          @focus="onFocus(true)"
          @blur="onFocus(false)"
        >
          <template #prepend>
            <v-icon size="1rem">
              fa fa-search
            </v-icon>
          </template>
        </v-text-field>
      </template>
      <v-list
        class="mobile-search"
        two-line
      >
        <div
          v-for="(array, name) in result"
          :key="name"
        >
          <div
            v-for="(item, index) in array"
            :key="index"
          >
            <!-- DISTRIBUTOR  -->
            <v-list-item
              v-if="name === 'distributors'"
              @click="showDistributor(item)"
            >
              <v-list-item-content>
                <v-row>
                  <v-col
                    class="ml-3 px-0"
                    cols="2"
                  >
                    <p
                      class="mb-0 pt-2 text-center"
                      :class="{ 'grey--text': item.IsActive === 'no' }"
                    >
                      Distributor
                    </p>
                  </v-col>
                  <v-col
                    cols="9"
                    class="search-item"
                  >
                    <v-list-item-title
                      :class="{ 'grey--text': item.IsActive === 'no' }"
                      v-html="`${item.companyName}`"
                    />
                    <v-list-item-subtitle
                      :class="item.IsActive === 'yes' ? 'primary--text' : 'grey--text'"
                      v-html="`${item.email}`"
                    />
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon
                  class="pt-3"
                  :color="item.IsActive === 'yes' ? 'primary' : 'grey'"
                >
                  fa fa-chevron-right
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
            <!-- RESELLER  -->
            <v-list-item
              v-if="name === 'resellers'"
              @click="showReseller(item)"
            >
              <v-list-item-content>
                <v-row>
                  <v-col
                    class="ml-3 px-0"
                    cols="2"
                  >
                    <p
                      class="mb-0 pt-2 text-center"
                      :class="{ 'grey--text': item.IsActive === 'no' }"
                    >
                      Reseller
                    </p>
                  </v-col>
                  <v-col
                    cols="9"
                    class="search-item"
                  >
                    <v-list-item-title
                      :class="{ 'grey--text': item.IsActive === 'no' }"
                      v-html="`${item.companyName}`"
                    />
                    <v-list-item-subtitle
                      :class="item.IsActive === 'yes' ? 'primary--text' : 'grey--text'"
                      v-html="`${item.email}`"
                    />
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon
                  class="pt-3"
                  :color="item.IsActive === 'yes' ? 'primary' : 'grey'"
                >
                  fa fa-chevron-right
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
            <!-- USER  -->
            <v-list-item
              v-if="name === 'users'"
              @click="showUser(item)"
            >
              <v-list-item-content>
                <v-row>
                  <v-col
                    class="ml-3 px-0"
                    cols="2"
                  >
                    <p
                      v-if="isSubUser(item)"
                      class="mb-0 pt-2 text-center"
                      :class="{ 'grey--text': !item.isAccountActive }"
                    >
                      Sub-User
                    </p>
                    <p
                      v-else
                      class="mb-0 pt-2 text-center"
                      :class="{ 'grey--text': !item.isAccountActive }"
                    >
                      User
                    </p>
                  </v-col>
                  <v-col
                    cols="9"
                    class="search-item"
                  >
                    <v-list-item-title :class="{ 'grey--text': !item.isAccountActive }">
                      {{ item.Description }}
                    </v-list-item-title>
                    <v-list-item-title :class="{ 'grey--text': !item.isAccountActive }">
                      {{ item.email }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="isDistributor"
                      :class="item.isAccountActive ? 'primary--text' : 'grey--text'"
                    >
                      {{ resellerName(item.resellerId) }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon
                  class="pt-3"
                  :color="item.isAccountActive ? 'primary' : 'grey'"
                >
                  fa fa-chevron-right
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
            <!-- CAMERA  -->
            <v-list-item
              v-if="name === 'cameras'"
              @click="showCamera(item)"
            >
              <v-list-item-content>
                <v-row>
                  <v-col
                    class="ml-3 px-0"
                    cols="2"
                  >
                    <p
                      class="mb-0 pt-2 text-center"
                      :class="{ 'grey--text': !item.isAccountActive }"
                    >
                      Camera
                    </p>
                  </v-col>
                  <v-col
                    cols="9"
                    class="search-item"
                  >
                    <v-list-item-title :class="{ 'grey--text': !item.isAccountActive }">
                      {{ item.macAddress }}
                    </v-list-item-title>
                    <v-list-item-subtitle :class="{ 'grey--text': !item.isAccountActive }">
                      {{ `${ item.isAccountActive ? `${item.email} - ` : '' }${item.cameraId}` }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="isDistributor"
                      :class="item.isAccountActive ? 'primary--text' : 'grey--text'"
                    >
                      {{ resellerName(item.resellerId) }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon
                  class="pt-3"
                  :color="item.isAccountActive ? 'primary' : 'grey'"
                >
                  fa fa-chevron-right
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
            <!-- NOTHING FOUND  -->
            <v-list-item v-if="name === 'nothing'">
              <v-list-item-icon>
                <v-icon
                  class="pt-3 pl-3"
                  size="1em"
                >
                  fas fa-exclamation
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="pr-12 mr-12"
                  v-html=" $t('Nothing found') "
                />
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>
    </v-menu>
  </v-col>
</template>

<script>
import restapi from '@/service/restapi'
import auth from '@/service/auth'

export default {
    name: 'Search',
    props: {
        isSearching: {
            type: Boolean
        },
        placeholder: {
            type: String
        }
    },
    data () {
        return {
            search: null,
            model: null,
            invalidSearch: false,
            showResults: false,
            searchingUser: false,
            searchingCamera: false,
            menuEnabled: false,
            result: {},
            resultCount: 0,
            timeout: null,
            users: [],
            cameras: [],
            isLoading: false
        }
    },
    computed: {
        searchText () {
            if (auth.isAdmin()) return this.$t('search_text_admin')
            else if (auth.isDistributor()) return this.$t('search_text_distributor')
            return this.$t('search_text_reseller')
        },
        hasResult () {
            return !!(this.resultCount > 0)
        },
        partners () {
            return this.$store.getters.allPartners
        },
        distributors () {
            return this.$store.getters.allDistributors
        },
        resellers () {
            return this.$store.getters.allResellers
        },
        isDistributor () {
            return auth.isDistributor()
        },
        searchInProgress () {
            return this.searchingUser || this.searchingCamera
        },
    },
    watch: {
        search () {
            this.invalidSearch = !!(this.search && this.search.length > 0 && this.search.length < 3)
            this.menuEnabled = this.resultCount > 0 && this.search && this.search.length >= 3
            if (!this.invalidSearch) this.doSearch()
        },
        searchInProgress () {
            if (!this.searchInProgress && this.isLoading && this.resultCount === 0 ) {
                this.result = { nothing: [0] }
                this.resultCount += 1
            }
            this.menuEnabled = this.resultCount > 0 && this.search && this.search.length >= 3
        }
    },
    mounted () {
        const that = this
        restapi.getDistributors()
            .then(function (result) {
                that.$store.commit('allDistributors', result)
            })
        restapi.getResellers()
            .then(function (result) {
                that.$store.commit('allResellers', result)
            })    
    },
    methods: {
        onFocus (val) {
            this.$emit('focus', val)
        },
        initSearch () {
            this.search = null
            this.result = {}
            this.resultCount = 0
            this.showResults = false
            this.searchingUser = false
            this.searchingCamera = false
        },
        doSearch () {
            const that = this
            clearTimeout(this.timeout)
            this.timeout = setTimeout(function (e) {
                if (that.search && that.search.length > 2) {
                    that.result = {}
                    that.resultCount = 0
                    that.showResults = true
                    that.searchingUser = true
                    const searchString = that.search
                    const search = that.search.toLowerCase().trim()
                    restapi.searchUser(search)
                        .then(function (result) {
                            if (searchString !== that.search) return
                            that.users = result
                            that.resultCount += that.users.length
                            if (auth.isAdmin()) {
                                that.result.distributors = that.distributors.filter(function (p) {
                                    return ((p.companyName && p.companyName.toLowerCase().indexOf(search) >= 0) || (p.email && p.email.toLowerCase().indexOf(search) >= 0))
                                })
                                that.resultCount += that.result.distributors.length
                            }
                            if (auth.isDistributor()) {
                                that.result.resellers = that.resellers.filter(function (p) {
                                    return ((p.companyName && p.companyName.toLowerCase().indexOf(search) >= 0) || (p.email && p.email.toLowerCase().indexOf(search) >= 0))
                                })
                                that.resultCount += that.result.resellers.length
                            }
                            that.result.users = result.map((user) => {
                                const Description = `${user.firstName} ${user.lastName}`
                                return { ...user, ...{ Description } }
                            })
                            that.searchingUser = false
                        })
                        .catch(function (error) {
                            console.error(error)
                            that.searchingUser = false
                        })
                    that.searchingCamera = true
                    restapi.searchCameras(search)
                        .then(function (result) {
                            if (searchString !== that.search) return
                            that.cameras = result
                            that.resultCount += that.cameras.length
                            that.result.cameras = that.cameras
                            that.searchingCamera = false
                        })
                        .catch(function (error) {
                            console.error(error)
                            that.searchingCamera = false
                        })
                    that.isLoading = true
                }
            }, 700)
        },
        closeSearch () {
            this.initSearch()
            this.users = []
            this.$emit('close')
        },
        typeOfUser (user) {
            return this.isSubUser(user) ? this.$t('Sub user') : this.$t('End user')
        },
        isSubUser (user) {
            return user.userId !== user.accountId
        },
        userName (user) {
            let name = (user.firstName + ' ' + user.lastName).trim()
            if (name.length === 0) name = '<no name set>'
            return name
        },
        showUser (user) {
            this.closeSearch()
            if (this.isSubUser(user) && user.isAccountActive) {
                this.$router.push(`/user/${user.resellerId}/${user.accountId}/${user.accountId}/subusers`)
            } else {
                this.$router.push(`/user/${user.resellerId}/${user.accountId}/${user.userId}?active=${user.isAccountActive}`)
            }
        },
        showDistributor (partner) {
            this.closeSearch()
            this.$router.push('/distributor/' + partner.distributorId)
        },
        showReseller (partner) {
            this.closeSearch()
            this.$router.push('/reseller/' + partner.resellerId)
        },
        resellerName (partnerId) {
            let r
            if (auth.getPartnerId().toString() === partnerId.toString()) {
                r = this.$store.getters.myPartnerDetails
            } else {
                r = this.partners.find(function (p) { return p.PartnerId === partnerId.toString() })
            }
            if (r) return r.Company
            return '-'
        },
        showCamera (camera) {
            this.closeSearch()
            this.$router.push(`/user/${camera.resellerId}/${camera.accountId}/${camera.accountId}/cameras/${camera.cameraId}`)
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/main';
  @import '../../assets/styles/forms';
  @import '../../assets/styles/custom';

  .search-item {
    width: 364px;
  }

  .search-menu {
    width: 500px !important;
  }

  .nav-search {
    @media screen and (min-device-width: 960px) {
      min-width: 150px !important;
    }
  }

  .mobile-search {
    @media screen and (max-device-width: 960px) {
      left: 0 !important;
      height: 100% !important;
      width: 100vw !important;
    }
    @media screen and (min-device-width: 960px) {
      width: 500px !important;
    }
  }

  .camera-id {
    color: $secondary;
    padding-left: 5px;
  }

  .search-block {
    .search-background-overlay {
      background-color: rgba(0,0,0,0.2);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
      z-index: 110;
    }
    .search-input-and-results-block {
      background: linear-gradient(339.7deg, $border-blue-light 0%, $primary 100%);
      padding: 9px 0;
      max-width: $main-frame-max-width;
      width: $main-frame-max-width;
      min-height: 80px;
      text-align: center;
      position: fixed;
      top: 0;
      z-index: 120;
      .search-input {
        color: #FFFFFF;
        font-size: 24px;
        min-height: 3.25rem;
        max-height: $nav-bar-height;
        border: none;
        position: relative;
        z-index: 30;
        input {
          &.search-input-field {
            color: #FFFFFF;
            border: none;
            font-size: 24px;
            min-height: 42px;
            min-width: 800px;
            border-bottom: 2px solid $primary;
          }
        }
        ::placeholder {
          color: #FFFFFF;
          font-size: 24px;
          opacity: 0.5;
        }
        i.fa {
          color: #FFFFFF;
          margin: 0 20px;
          ::before {
            color: #FFFFFF;
            font-size: 24px;
          }
          &.fa-times {
            cursor: pointer;
          }
        }
        span {
          position: relative;
          .info-message {
            color: #FFFFFF;
            float: left;
            font-size: 12px;
            position: absolute;
            left: 0;
            bottom: -24px;
          }
        }
        @media screen and (max-width: 767px) {
          font-size: 14px;
          input {
            &.search-input-field {
              font-size: 14px;
              min-width: 80%;
              &::-webkit-input-placeholder {
                font-size: 12px;
              }
            }
          }
          i.fa {
            margin: 0;
          }
        }
      }
      .search-results {
        height: 500px;
        overflow-y: auto;
        position: relative;
        width: 65%;
        margin: auto;
        margin-top: 15px;
        border-radius: $border-radius;
        padding: 10px;
        color: $primary;
        z-index: 30;
        &.show-results {
          &:not(.searching) {
            background-color: #FFFFFF;
          }
        }
        div.spinner-container {
          position: absolute;
          left: 50%;
          top: 50%;
          margin-right: -50%;
          transform: translate(-50%, -50%);
          i.fa.fa-spin {
            font-size: 100px;
            color: #FFFFFF;
          }
        }
        .search-result-table {
          display: table;
          width: 100%;
          li {
            display: table-row;
            line-height: 46px;
            font-size: 12px;
            &.in-active {
              color: $color-error-dark;
            }
            span {
              display: table-cell;
              text-align: left;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              border-bottom: 3px solid $color-shadow-light;
              &.user-type {
                color: $secondary;
              }
              &.camera-type {
                color: $secondary;
              }
            }
          }
          .level {
            line-height: 20px;
            &.in-active {
              color: $color-error-dark;
            }
            &.is-link {
              border-bottom: 3px solid $color-shadow-light;
              max-width: 100%;
              @media screen and (max-width: 767px) {
                max-width: 95vw;
              }
            }
            .level {
              @media screen and (min-width: 769px) {
                max-width: 50%;
                width: 50%;
              }
              .level-item {
                justify-content: left;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 45%;
                width: 45%;
                &.reseller {
                  max-width: 60%;
                  width: 60%;
                }
                &.user-type {
                  color: $secondary;
                  max-width: 32%;
                  width: 32%;
                }
                &.camera-type {
                  color: $secondary;
                  max-width: 32%;
                  width: 32%;
                }
                &.chevron {
                  justify-content: right;
                  max-width: 5%;
                  width: 5%;
                }
              }
              margin-bottom: 0.5rem;
            }
            @media screen and (max-width: 767px) {
              max-width: 100%;
              width: 100%;
              font-size: 12px;
              .level {
                .level-item {
                  max-width: 45%;
                  width: 45%;
                  &.reseller {
                    max-width: 60%;
                    width: 60%;
                  }
                  &.user-type {
                    max-width: 30%;
                    width: 30%;
                  }
                  &.camera-type {
                    max-width: 30%;
                    width: 30%;
                  }
                  &.chevron {
                    max-width: 5%;
                    width: 5%;
                  }
                }
              }
            }
          }
        }
        @media screen and (max-width: 767px) {
          height: 90vh;
          width: 100%;
        }
      }
    }
    .is-link {
      cursor: pointer;
    }
    .fade-enter-active, .fade-leave-active {
      transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to {
      opacity: 0;
    }
  }
</style>
