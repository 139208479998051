import { render, staticRenderFns } from "./ChooseSubscription.vue?vue&type=template&id=84c0fd50&scoped=true"
import script from "./ChooseSubscription.vue?vue&type=script&lang=js"
export * from "./ChooseSubscription.vue?vue&type=script&lang=js"
import style0 from "./ChooseSubscription.vue?vue&type=style&index=0&id=84c0fd50&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84c0fd50",
  null
  
)

export default component.exports