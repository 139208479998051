<template>
  <div class="columns table-row">
    <div class="column">
      <v-data-table
        :headers="headers"
        :items="filteredCameras"
        item-key="cameraId"
        hide-default-header
        :footer-props="{ 'items-per-page-text': '', 'items-per-page-options': [10000]}"
        dense
      >
        <template #header="{ props, on }">
          <thead>
            <tr>
              <th
                v-for="(header, index) in props.headers"
                :key="index"
                class="primary--text table-head"
              >
                <a @click="on.sort(header.value)">
                  {{ $t(header.text) }}
                </a>
              </th>
            </tr>
          </thead>
        </template>
        <template #item="{ item, isExpanded, expand }">
          <tr
            :key="item.cameraId"
            :class="{ 'table-row': true, 'expanded': isExpanded }"
            @click="() => { expand(!isExpanded); if(!isExpanded) openDetails(item); }"
          >
            <td class="table-cell text-right pa-0">
              <v-icon
                v-if="!isExpanded"
                small
              >
                fas fa-chevron-down
              </v-icon>
              <v-icon
                v-if="isExpanded"
                small
              >
                fas fa-chevron-up
              </v-icon>
            </td>
            <td class="table-cell camera-status">
              <span :class="item.status.toLowerCase()">
                {{ cameraStatus(item.status) }}
              </span>
            </td>
            <td class="table-cell">
              {{ item.name }}
            </td>
            <td class="table-cell">
              {{ item.zoneName }}
            </td>
            <td class="table-cell">
              {{ item.model }}
            </td>
            <td class="table-cell">
              {{ item.macAddress !== '00-00-00-00-00-00' ? item.macAddress : $t('n.a.') }}
            </td>
            <td class="table-cell">
              {{ item.cameraId }}
            </td>
          </tr>
        </template>
        <template #expanded-item="{ headers, item }">
          <td
            v-if="isCameraDetailsLoading(item.cameraId)"
            class="table-cell expanded-area pl-12 py-12 text-center"
            :colspan="headers.length"
          >
            {{ $t('Loading') }}
          </td>
          <td
            v-else
            class="table-cell expanded-area pl-12 py-5"
            :colspan="headers.length"
          >
            <v-row>
              <v-col>
                <span>{{ $t("Days offline") }}</span>
                <p class="content">
                  {{ daysOffline(item) }}
                </p>
              </v-col>
              <v-col>
                <span>{{ $t("Brand") }}</span>
                <p class="content">
                  {{ item.brand }}
                </p>
              </v-col>
              <v-col>
                <span>{{ $t("Local URL") }}</span>
                <p
                  v-if="item.localUrl"
                  class="content"
                >
                  <a
                    :href="item.localUrl"
                    target="_blank"
                  >{{ item.localUrl }}</a>
                </p>
                <p
                  v-else
                  class="content"
                >
                  {{ $t('n.a.') }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>{{ $t("Last known connection") }}</span>
                <p
                  v-if="item.details && isLastConnectionKnown(item)"
                  class="content"
                >
                  <span
                    v-for="(c, index) in lastKnownConnections(item)"
                    :key="`c-${index}`"
                    class="connectionIcon"
                  >
                    <img :src="connectionImages[c.icon]">
                    {{ c.name }}
                  </span>
                </p>
                <p
                  v-else
                  class="content"
                >
                  {{ $t('Unknown') }}
                </p>
              </v-col>
              <v-col>
                <span>{{ $t("Firmware version") }}</span>
                <p
                  v-if="item.details"
                  class="content"
                  :title="firmwareVersion(item)"
                >
                  {{ firmwareVersion(item) }}
                </p>
                <p
                  v-else
                  class="content"
                >
                  {{ $t('Unknown') }}
                </p>
              </v-col>
              <v-col>
                <span>{{ $t("Global URL") }}</span>
                <p
                  v-if="item.globalUrl && item.globalUrl !== 'http://0.0.0.0:80'"
                  class="content"
                >
                  <a
                    :href="item.globalUrl"
                    target="_blank"
                  >{{ item.globalUrl }}</a>
                </p>
                <p
                  v-else
                  class="content"
                >
                  {{ $t('n.a.') }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>{{ $t("Last known connection method") }}</span>
                <p
                  v-if="item.details && item.details.cameraConnection"
                  class="content"
                >
                  {{ $t(item.details.cameraConnection.type) }} ({{ $t(item.details.cameraConnection.apiType) }})
                </p>
                <p
                  v-else
                  class="content"
                >
                  {{ $t('Unknown') }}
                </p>
              </v-col>
              <v-col>
                <span>{{ $t("Firmware update available") }}</span>
                <p
                  v-if="item.details"
                  class="content"
                  :title="firmwareUpdateStatus(item)"
                >
                  {{ firmwareUpdateStatus(item) }}
                </p>
                <p
                  v-else
                  class="content"
                >
                  {{ $t('Unknown') }}
                </p>
              </v-col>
              <v-col>
                <span>{{ $t("Password login status") }}</span>
                <p
                  v-if="item.details"
                  class="content"
                >
                  {{ passwordLoginStatus(item) }}
                </p>
                <p
                  v-else
                  class="content"
                >
                  {{ $t('Unknown') }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>{{ $t("Date added") }}</span>
                <p
                  v-if="item.details"
                  class="content"
                >
                  {{ dateAdded(item) }}
                </p>
                <p
                  v-else
                  class="content"
                >
                  {{ $t('Unknown') }}
                </p>
              </v-col>
              <v-col />
              <v-col />
            </v-row>
            <v-row
              v-if="hasSimDetails(item)"
              class="is-divider"
            />
            <v-row v-if="hasSimDetails(item)">
              <v-col>
                <span>{{ simStatusLabel(item) }}</span>
                <p class="content">
                  {{ simStatus(item) }}
                </p>
              </v-col>
              <v-col>
                <span>{{ iccidLabel(item) }}</span>
                <p class="content">
                  {{ iccid(item) }}
                </p>
              </v-col>
              <v-col>
                <span>{{ imsiLabel(item) }}</span>
                <p class="content">
                  {{ imsi(item) }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="hasSimDetails(item)">
              <v-col>
                <span>{{ simTypeLabel(item) }}</span>
                <p class="content">
                  {{ simType(item) }}
                </p>
              </v-col>
              <v-col />
              <v-col />
            </v-row>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script charset="utf-8">
import { getImgUrl } from '@/service/main'
import { getTimeStamp } from '@eencloud/core-components/src/service/time'

const restapi = require('@/service/restapi')

export default {
    name: 'CameraList',
    props: ['cameras', 'filter', 'account'],
    data () {
        return {
            connectionImages: {
                '3g-1': getImgUrl('3g-1.png'),
                '3g-2': getImgUrl('3g-2.png'),
                '3g-3': getImgUrl('3g-3.png'),
                '4g-1': getImgUrl('4g-1.png'),
                '4g-2': getImgUrl('4g-2.png'),
                '4g-3': getImgUrl('4g-3.png'),
                'wifi-1': getImgUrl('wifi-1.png'),
                'wifi-2': getImgUrl('wifi-2.png'),
                'wifi-3': getImgUrl('wifi-3.png'),
                'cable': getImgUrl('cable.png')
            },
            defaultOpenedDetails: [],
            loadingDetails: [],
            headers: [
                { text: '', value: 'expand', align:'end' },
                { text: 'Status', value: 'status'},
                { text: 'Name', value: 'name' },
                { text: 'Zone', value: 'zoneName' },
                { text: 'Model', value: 'model' },
                { text: 'Mac Address', value: 'macAddress' },
                { text: 'ID', value: 'cameraId' },
            ],
        }
    },
    computed: {
        filteredCameras () {
            let cameras = this.cameras
            if (this.filter) {
                let _this = this
                cameras = cameras.filter(function (c) {
                    return (c.cameraId && c.cameraId.toString().indexOf(_this.filter.toLowerCase()) !== -1) ||
              (c.name && c.name.toLowerCase().indexOf(_this.filter.toLowerCase()) !== -1) ||
              (c.brand && c.brand.toLowerCase().indexOf(_this.filter.toLowerCase()) !== -1) ||
              (c.model && c.model.toLowerCase().indexOf(_this.filter.toLowerCase()) !== -1) ||
              (c.macAddress && c.macAddress.toLowerCase().indexOf(_this.filter.toLowerCase()) !== -1)
                })
            }
            return cameras
        }
    },
    methods: {
        cameraStatus: function (status) {
            return (status === 'Online') ? this.$t('Online') : this.$t('Offline')
        },
        dateAdded: function (camera) {
            return camera.details && camera.details.dateAdded ?
                getTimeStamp(this.$store.getters.myPartner.timeZoneOffset, new Date(camera.details.dateAdded)).slice(0,17) 
                : '-'
        },
        daysOffline: function (camera) {  
            const lastSeen = camera.details ? camera.details.lastSeenAvailable || null : null
            const daysOffline = lastSeen ? Math.floor((Date.now() - Date.parse(lastSeen)) / (1000 * 60 * 60 * 24)) : null
            const dateString = lastSeen ? lastSeen.replace(/T/, ' ').replace(/\..+/, '') : null
            const daysString = lastSeen ? (daysOffline === 1 ? this.$t('day') : this.$t('days')) : null
            return (camera.status === 'Online') ? 'n.a.' : (lastSeen ? `${daysOffline} ${daysString} (${dateString})` : 'Unknown')
        },
        isLastConnectionKnown: function (camera) {
            return camera.details && camera.details.networkConnectivity &&
          (camera.details.networkConnectivity.connectionType !== 'Unknown' ||
            (camera.details.networkConnectivity.lanConnectionDetails && camera.details.networkConnectivity.lanConnectionDetails.state === 'connected') ||
            (camera.details.networkConnectivity.wifiConnectionDetails && camera.details.networkConnectivity.wifiConnectionDetails.state === 'connected') ||
            (camera.details.networkConnectivity.mobileConnectionDetails && camera.details.networkConnectivity.mobileConnectionDetails.state === 'connected'))
        },
        lastKnownConnection: function (camera) {
            return (camera.details && camera.details.networkConnectivity)
                ? (camera.details.networkConnectivity.connectionType === 'Unknown' && camera.details.networkConnectivity.wifiConnectionDetails.state === 'connected' ? 'Wifi' : camera.details.networkConnectivity.connectionType)
                : 'Unknown'
        },
        connectionIcon: function (camera) {
            const strengths = { high: 3, medium: 2, low: 1 }
            const cType = this.lastKnownConnection(camera)
            if (cType === 'Ethernet') return 'cable'
            if (cType === 'Mobile') {
                if (camera.details && camera.details.networkConnectivity && camera.details.networkConnectivity.mobileConnectionDetails && camera.details.networkConnectivity.mobileConnectionDetails.state === 'connected') {
                    if (camera.details.networkConnectivity.mobileConnectionDetails.type === 'Fourth_Generation') {
                        return `4g-${strengths[camera.details.networkConnectivity.mobileConnectionDetails.strength.toLowerCase()]}`
                    } else {
                        return `3g-${strengths[camera.details.networkConnectivity.mobileConnectionDetails.strength.toLowerCase()]}`
                    }
                }
                return '3g-3'
            }
            if (cType === 'Wifi') {
                if (camera.details && camera.details.networkConnectivity.wifiConnectionDetails.state === 'connected') {
                    return `wifi-${strengths[camera.details.networkConnectivity.wifiConnectionDetails.strength.toLowerCase()]}`
                }
                return 'wifi-2'
            }
        },
        lastKnownConnections: function (camera) {
            const strengths = { high: 3, medium: 2, low: 1 }
            if (camera.details && camera.details.networkConnectivity) {
                let cons = []
                if (camera.details.networkConnectivity.lanConnectionDetails.state === 'connected') cons.push({name: 'Ethernet', icon: 'cable'})
                if (camera.details.networkConnectivity.wifiConnectionDetails.state === 'connected') {
                    cons.push({name: 'Wifi', icon: `wifi-${strengths[camera.details.networkConnectivity.wifiConnectionDetails.strength.toLowerCase()]}`})
                }
                if (camera.details.networkConnectivity.mobileConnectionDetails.state === 'connected') {
                    if (camera.details.networkConnectivity.mobileConnectionDetails.type === 'Fourth_Generation') {
                        cons.push({name: 'Mobile', icon: `4g-${strengths[camera.details.networkConnectivity.mobileConnectionDetails.strength.toLowerCase()]}`})
                    } else {
                        cons.push({name: 'Mobile', icon: `3g-${strengths[camera.details.networkConnectivity.mobileConnectionDetails.strength.toLowerCase()]}`})
                    }
                }
                if (cons.length > 0) return cons
            }
            return ['Unknown']
        },
        passwordLoginStatus: function (camera) {
            if (!camera.details) return 'Unknown'
            switch (camera.details.passwordLoginStatus) {
            case 'PasswordVerified':
                return this.$t('Verified')
            case 'PasswordNotVerified':
                return this.$t('Not verified')
            default:
                return 'Unknown'
            }
        },
        firmwareVersion: function (camera) {
            if (camera.details && camera.details.firmwareStatus) {
                return camera.details.firmwareStatus.currentVersion
            }
            return 'Unknown'
        },
        firmwareUpdateStatus: function (camera) {
            if (camera.details && camera.details.firmwareStatus) {
                switch (camera.details.firmwareStatus.status) {
                case 'upgradable':
                    return this.$t('Yes') + ` (${camera.details.firmwareStatus.targetVersion})`
                case 'up-to-date':
                    return this.$t('No')
                case 'upgrading':
                    return this.$t('upgrading')
                case 'unknown':
                    return this.$t('Unknown')
                default:
                    return this.$t('Not supported')
                }
            }
            return 'Unknown'
        },
        simStatus: function (camera) {
            let simStatusLabel = 'unknown'
            if (camera.status === 'Online' && camera.details && camera.details.simDetails) {
                switch (camera.details.simDetails.simState) {
                case 'inserted':
                    simStatusLabel = 'SIM installed'
                    break
                case 'noSim':
                    simStatusLabel = 'no SIM'
                    break
                }
            }
            return simStatusLabel
        },
        simStatusLabel: function (camera) {
            return (camera.status === 'Online') ? this.$t('SIM status') : this.$t('Last known SIM status')
        },
        iccid: function (camera) {
            let iccidLabel = 'n.a.'
            if (camera.details && camera.details.simDetails && camera.details.simDetails.iccid) {
                iccidLabel = camera.details.simDetails.iccid
            }
            return iccidLabel
        },
        iccidLabel: function (camera) {
            return (camera.status === 'Online') ? this.$t('ICCID') : this.$t('Last known ICCID')
        },
        imsi: function (camera) {
            let imsiLabel = 'n.a.'
            if (camera.details && camera.details.simDetails && camera.details.simDetails.imsi) {
                imsiLabel = camera.details.simDetails.imsi
            }
            return imsiLabel
        },
        imsiLabel: function (camera) {
            return (camera.status === 'Online') ? this.$t('IMSI') : this.$t('Last known IMSI')
        },
        simType: function (camera) {
            let simTypeLabel = 'n.a.'
            if (camera.details && camera.details.simDetails && camera.details.simDetails.simType) {
                simTypeLabel = camera.details.simDetails.simType
            }
            return simTypeLabel
        },
        simTypeLabel: function (camera) {
            return (camera.status === 'Online') ? this.$t('SIM type') : this.$t('Last known SIM type')
        },
        hasSimDetails (camera) {
            if (camera.details && camera.details.simDetails) {
                return true
            }
            return false
        },
        simStatusClass (camera) {
            let _class = (camera.status === 'Online') ? '' : 'offline '
            if (camera.status === 'Online' && camera.details && camera.details.simDetails) {
                switch (camera.details.simDetails.simState) {
                case 'inserted':
                    _class += 'sim-installed'
                    break
                case 'noSim':
                    _class += 'no-sim-installed'
                    break
                }
            }
            return _class
        },
        setCameraDetailsLoading (loading, cameraId) {
            if (loading) {
                if (this.isCameraDetailsLoading(cameraId)) {
                    console.log('[Warn] Camera details are already loading for cameraId = ', cameraId)
                } else {
                    this.loadingDetails.push(cameraId)
                }
            } else {
                let indexOfCameraId = this.loadingDetails.indexOf(cameraId)
                this.loadingDetails.splice(indexOfCameraId, 1)
            }
        },
        isCameraDetailsLoading (cameraId) {
            let foundCameraId = this.loadingDetails.find(function (element) {
                return element === cameraId
            })
            return foundCameraId
        },
        openDetails (row, index) {
            if (!row.details) {
                let that = this
                that.setCameraDetailsLoading(true, row.cameraId)
                restapi.getAccountMobileSubscriptionsSummary(this.account.resellerId, this.account.accountId)
                    .then(function (mobileSubscriptions) {
                        restapi.getCameraDetails(that.account.resellerId, that.account.accountId, row.cameraId)
                            .then(function (cameraDetailsResult) {
                                let subscriptionFound = mobileSubscriptions.filter(function (cameraItem) {
                                    return cameraItem.cameraId === row.cameraId
                                })
                                if (subscriptionFound && subscriptionFound.length === 1) {
                                    subscriptionFound = subscriptionFound[0]
                                    cameraDetailsResult.simDetails = {
                                        'iccid': subscriptionFound.iccid,
                                        'imei': subscriptionFound.imei,
                                        'imsi': subscriptionFound.imsi,
                                        'simState': subscriptionFound.simState,
                                        'simType': subscriptionFound.simType
                                    }
                                }
                                row.details = cameraDetailsResult
                                let indexOfCameraObj = that.cameras.indexOf(row)
                                that.$set(that.cameras, indexOfCameraObj, row)
                            })
                            .catch(function (error) {
                                console.error(error)
                                that.$store.commit('toastMessage', {
                                    showing: true,
                                    text: that.getMessageFromError(error, 'GET_CAMERA_DETAILS'),
                                    timeout : -1,
                                    color: 'error',
                                    support: true
                                })
                            })
                            .then(function () {
                                that.setCameraDetailsLoading(false, row.cameraId)
                            })
                    })
                    .catch(function (error) {
                        console.error(error)
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.getMessageFromError(error, 'GET_CAMERA_DETAILS'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                    })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/styles/main";

  .grayed-out {
    opacity: .3;
  }

  .table-head {
    border-bottom-color: var(--v-primary-lighten1) !important;
    border-bottom-width: 2px !important;
  }

  .table-row {
    &:nth-child(even) {
      background-color: #f8f8f8;
    }
    &.expanded {
      background-color: #f5f5f5;
    }
    .table-cell {
      padding: 10px;
      vertical-align: middle;
      font-size: 0.9em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: none !important;
      &:hover {
        cursor: pointer;
      }
      &.camera-status {
        text-align: center;
        color: white;
        .online {
          background-color: #207cad;
          padding: 5px;
          border-radius: $border-radius;
        }
        .offline {
          background-color: #d43536;
          padding: 5px;
          border-radius: $border-radius;
        }
      }
    }
  }

  .expanded-area {
    cursor: default;
    .row {
      .col {
        padding: 0;
        span {
          font-size: 12px;
          color: rgb(204, 204, 204);
          img {
            height: 14px;
          }
          &.connectionIcon {
            color: rgb(74, 74, 74);
          }
        }
        p {
          margin-bottom: 5px;
        }
      }
    }
  }


  .iccid-icon, .sim-status-icon {
    width: 20px;
    display: inline-block;
    margin-right: 5px;
  }

  .sim-status-icon {
    &.offline {
      path {
        fill: $color-gray;
      }
    }
    &.sim-installed {
      path {
        fill: $primary;
      }
    }
    &.no-sim-installed {
      path {
        fill: $color-gray;
      }
    }
  }

  .iccid-label, .sim-status-label {
    display: inline-block;
  }

  .iccid-block, .sim-status-block {
    padding-top: 5px;
  }

  .is-divider {
    height: 2px;
    background-color: #dfdfdf73;
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 1.75rem;
  }

  div.spinner-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    padding-top: 40px;
    opacity: .5;
  }

  #cameras-table ::v-deep {
    table {
      &.table {
        tbody > tr {
          box-shadow: none;
          cursor: default;
          td {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &.chevron-cell {
              max-width: 45px;
              width: 45px;
            }
            &.status {
              max-width: 45px;
              width: 45px;
            }
            &.name {
              max-width: 300px;
              width: 300px;
            }
            &.zone {
              max-width: 200px;
              width: 200px;
            }
            &.model {
              max-width: 200px;
              width: 200px;
            }
            &.mac-address {
              max-width: 250px;
              width: 250px;
            }
            > span {
              font-size: 12px;
            }
          }
        }
        thead th {
          border-bottom: 2px solid $border-blue-light;
          padding: 6px 4px!important;
          .th-wrap {
            color: $primary;
            text-transform: uppercase;
            font-size: 12px;
          }
          &.is-current-sort {
            border-bottom-color: $border-blue;
            span.icon {
              i.fa.fa-arrow-up {
                &:before {
                  content: "\f0d8";
                }
              }
            }
          }
        }
        tbody td {
          border: none;
          .camera-status {
            color: white;
            padding: 5px;
            border-radius: $border-radius;
            &.online {
              background-color: $primary;
            }
            &.offline {
              background-color: $color-error-dark;
            }
          }
          .connectionIcon {
            display: contents;
            width: 18px;
            vertical-align: top;
            padding-top: 15px;
            img {
              height: 13px;
              margin: 0 5px 0 0;
              vertical-align: top;
            }
          }
        }
      }
      &.is-striped {
        tbody > tr {
          &.detail {
            &:not(.is-selected) {
              &:nth-child(even) {
                background-color: #FFFFFF;
              }
            }
            .detail-container {
              margin: 0 0 0 30px;
              background-color: #FFFFFF;
              .field {
                margin: 0;
                position: relative;
                .has-float-label {
                  p {
                    &.content {
                      font-size: 12px;
                      padding: 5px 0;
                      margin: 0;
                      height: 22px;
                    }
                  }
                  span {
                    position: absolute;
                    top: -10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
