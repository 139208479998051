import Vue from 'vue'
import Vuex from 'vuex'

import coreData from './modules/coreData'
import componentEvents from './modules/componentEvents'
import authStatus from './modules/authStatus'
import sessionData from './modules/sessionData'
import browser from './modules/browser'
import partners from './modules/partners'
import navigation from './modules/navigation'
import accounts from './modules/accounts'

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        coreData,
        componentEvents,
        authStatus,
        sessionData,
        browser,
        partners,
        navigation,
        accounts
    }
})
