<template>
  <div id="reset-password-block">
    <h2 class="component-title">
      {{ $t('Password') }}
    </h2>
    <p class="component-description">
      {{ $t('Password reset description') }}
    </p>
    <v-tooltip
      color="primary"
      max-width="400px"
      top
      :disabled="!exclusiveThirdPartyAuthentication"
    >
      <template #activator="{ on }">
        <div v-on="on">
          <div
            class="button-wrapper"
            v-on="on"
          >
            <v-btn
              large
              color="primary"
              class="mb-6"
              min-width="200"
              :disabled="exclusiveThirdPartyAuthentication"
              @click="showConfirmPopup()"
            >
              {{ $t('Send reset link') }}
            </v-btn>
          </div>
          <div
            class="button-wrapper"
            v-on="on"
          >
            <v-btn
              v-if="!showPasswordField"
              large
              color="primary"
              min-width="200"
              :disabled="exclusiveThirdPartyAuthentication"
              @click="showPasswordField = true, generatePassword()"
            >
              {{ $t('Reset password') }}
            </v-btn>
          </div>
          <div v-if="showPasswordField">
            <v-text-field 
              v-model="newPassword"
              error-messages
              :rules="passwordRules"
              autocomplete="no"
            />
            <v-btn @click="showPasswordField = false">
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              class="ml-5"
              color="primary"
              @click="resetPassword ()"
            >
              {{ $t('Reset') }}
            </v-btn>
          </div>
        </div>
      </template>
      <span>{{ $t('Password for this user is managed by a third party.') }}</span>
    </v-tooltip>
    <v-dialog
      id="reset-password-confirm-modal"
      v-model="isConfirmModalActive"
      class="support-element-popup"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          
          {{ $t('Reset password') }}
        
        </v-card-title>
        <v-card-text>{{ $t('Are you sure you want to send a password reset link to') }} {{  email  }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            outlined
            class="mr-2"
            color="primary"
            type="button"
            @click="confirmPopupClosed()"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            large
            color="primary"
            @click="sendResetLink()"
          >
            {{ $t('Send email') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      id="reset-password-new-modal"
      v-model="isNewPasswordModalActive"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('Reset password') }}
        </v-card-title>
        <v-card-text>{{ $t('The password for this customer is set to', { password: newPassword }) }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            color="primary"
            @click="newPasswordPopupClosed()"
          >
            {{ $t('OK') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getMessageFromError } from '@eencloud/core-components/src/service/errors'
const restapi = require('@/service/restapi')

export default {
    name: 'ResetPassword',
    props: ['account', 'exclusiveThirdPartyAuthentication'],
    data () {
        return {
            isConfirmModalActive: false,
            isNewPasswordModalActive: false,
            showPasswordField: false,
            newPassword: '',
            passwordRules: [val => (val || '').length > 7 || 'Password should be 8-32 characters'],
        }
    },
    computed: {
        email () {
            return this.account ? this.account.contactEmail : null
        }
    },
    methods: {
        showConfirmPopup () {
            if (!this.exclusiveThirdPartyAuthentication) {
                this.isConfirmModalActive = true
            }
        },
        async sendResetLink() {
            try {
                await restapi.requestEndUserPasswordResetLink(this.email)
                this.isConfirmModalActive = false
                this.$store.commit('toastMessage', {
                    showing: true,
                    text: this.$t('Password reset link sent'),
                    timeout : 4000,
                    color: 'primary'
                })
            } catch (error) {
                this.isConfirmModalActive = false
                this.$store.commit('toastMessage', {
                    showing: true,
                    text: getMessageFromError(error, 'REQUEST_PASSWORD_RESET'),
                    timeout : -1,
                    color: 'error',
                    support: true
                })
            }
        },
        resetPassword () {
            const that = this
            that.isLoading(true)
            restapi.setAccountUserPassword(this.account.resellerId, this.account.accountOwnerId, this.account.accountId, this.newPassword)
                .then(function (result) {
                    that.isLoading(false)
                    that.showNewPasswordPopup()
                })
                .catch(function (error) {
                    that.isLoading(false)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: getMessageFromError(error, 'SET_ACCOUNT_USER_PASSWORD'),
                        timeout : -1,
                        color: 'error',
                        support: true
                    })
                    console.error(error)
                })
            this.showPasswordField = false
            this.confirmPopupClosed()
        },
        confirmPopupClosed () {
            this.isConfirmModalActive = false
        },
        showNewPasswordPopup () {
            this.isNewPasswordModalActive = true
        },
        newPasswordPopupClosed () {
            this.isNewPasswordModalActive = false
            this.newPassword = null
        },
        generatePassword () {
            let pass = ''
            const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz0123456789@#$'
            for (let i = 1; i <= 10; i++) {
                let char = Math.floor(Math.random()* str.length + 1)
                pass += str.charAt(char)
            } 
            this.newPassword = pass
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/custom';

  #reset-password-block {
    padding: 20px 0;
  }
</style>
