<template>
  <v-app>
    <div class="global-container">
      <div
        id="app"
        class="main-content"
        :class="isEEN ? 'margin-top-75' : 'margin-top-64'"
      >
        <navigation :logo="logo" />
        <div class="root">
          <router-view />
        </div>
        <app-footer
          :title="title"
          :version="version"
          :build-number="buildNumber"
          :logo="logo"
        />
      </div>
    </div>
    <een-snack-bar
      :showing="$store.state.coreData.snackBar.showing"
      :timeout="$store.state.coreData.snackBar.timeout"
      :color="$store.state.coreData.snackBar.color"
      :text="$store.state.coreData.snackBar.text"
      :confirmation="$store.state.coreData.snackBar.confirmation"
      :support="$store.state.coreData.snackBar.support"
      @closeSnackBar="$store.state.coreData.snackBar.showing = false"
      @openChat="openChat"
    />
    <v-progress-circular
      v-show="isLoading"
      indeterminate
      color="primary"
      class="loader"
      size="150"
      width="12"
    />
  </v-app>
</template>

<script>
import { getImgUrl } from '@/service/main'
import Navigation from '@/components/Navigation'
import AppFooter from '@/components/AppFooter'
import WalkThrough from '@/components/WalkThrough'
import Theme from '@/service/themes'
import { changeFavicon } from './service/main'
import restapi  from '@/service/restapi'

const auth = require('@/service/auth')

export default {
    name: 'PartnerPortal',
    components: {
        Navigation,
        AppFooter,
    },
    data() {
        return {
            logo: '',
            auth: null,
        }
    },
    computed: {
        isEEN() {
            return this.logo.includes('een')
        },
        configuration() {
            return this.$store.getters.configuration
        },
        title() {
            if (this.configuration) return this.configuration.title
            return null
        },
        version() {
            if (this.configuration) return this.configuration.version
            return null
        },
        buildNumber() {
            if (this.configuration) return this.configuration.buildNumber
            return null
        },
        isLoggedIn() {
            return this.$store.getters.isAuthorized
        },
        isBrowserIE() {
            return this.$store.getters.isIE
        },
        canShowAddNewWizardButton() {
            return this.isLoggedIn && this.$store.getters.acceptedTerms.state
        },
        showWalkThrough() {
            return this.$store.getters.showWalkThrough
        },
        isLoading: {
            get() {
                return this.$store.getters.isLoading
            },
            set(value) {
                this.$store.commit('isLoading', value)
            },
        },
    },
    watch: {
        '$route.query'() {
            if (this.$route.query.hasOwnProperty('access_token')) {
                delete this.$route.query.a
                delete this.$route.query.access_token
                delete this.$route.query.token_type
                delete this.$route.query.state
                delete this.$route.query.expires_in
                this.$router.replace({ path: this.$route.path, query: this.$route.query })
            }
        },
        showWalkThrough: function (newVal, oldVal) {
            if (newVal) {
                this.$modal.open({
                    parent: this,
                    component: WalkThrough,
                    hasModalCard: false,
                    canCancel: ['x'],
                    active: this.showWalkThrough,
                })
            }
        },
    },
    beforeMount() {
        this.auth = auth
        const data = auth.getSessionData()
        this.$store.commit('session', data)
    },
    mounted() {
        const themeName = config.hasOwnProperty('theme') ? config.theme : 'Cameramanager'
        const theme = new Theme(themeName)
        const logo = theme.logo()
        if (logo) {
            if (logo.endsWith('.svg')) {
                this.logo = require(`@/assets/images/${logo}`)
            } else {
                this.logo = this.getImgUrl(theme.logo())
            }
        }
        const favicon = theme.favicon()
        if (favicon) {
            changeFavicon(favicon)
        }
        let isIE = !!document.documentMode
        this.$store.commit('isIE', isIE)
        let lang = this.$cookies.get('lang') || 'en'
        if (!this.configuration.crowdinIntegration && lang === 'aa') lang = 'en'
        this.$locale.setLanguage(lang)
        this.fetchCoreData()
    },
    methods: {
        getImgUrl: getImgUrl,
        feedback() {
            /* eslint-disable no-undef */
            zsLoadAutoSuggestions()
        },
        async fetchCoreData() {
            try {
                const promises = [
                    restapi.getCountries(),
                    restapi.getLanguages(),
                    restapi.getTimeZones()
                ]
                const [ countries, languages, timeZones ] = (await Promise.allSettled(promises)).map(element => element.status === 'fulfilled' ? element.value : null)
                if (countries) this.$store.dispatch('setCountries', countries)
                if (languages) this.$store.dispatch('setLanguages', languages)
                if (timeZones) this.$store.dispatch('setTimeZones', timeZones)

            } catch (error) {
                console.log(error)
            }
        },
        openChat() {
            $zoho.salesiq.visitor.name(
                this.$store.getters.account ? this.$store.getters.username : null
            )
            $zoho.salesiq.visitor.email(this.$store.getters.account ? this.$store.getters.account.email : null)
            const errorCode = this.$store.state.coreData.snackBar.text && this.$store.state.coreData.snackBar.text.split('errorCode: ')[1]
            if (errorCode) $zoho.salesiq.visitor.info({'Last seen error code' : errorCode })
            $zoho.salesiq.floatwindow.open()
        }
    },
}
</script>

<style lang="scss">
// Our styles
@import "assets/styles/main";

// Customization
@import "assets/styles/custom";
@import "assets/styles/vuetify-custom";
@import "assets/styles/forms";


.loader {
  position: fixed;
  top: 50%;
  left: 50%;
}
</style>
