<template>
  <v-footer
    color="text"
    padless
  >
    <v-row
      style="width:100%"
      class="is-marginless"
    >
      <v-col class="has-logo">
        <v-row class="is-mobile">
          <v-col
            class="is-hidden-touch text-center has-logo"
            :class="{ 'is-centered': isAuthorized }"
          >
            <v-img
              id="eagle-eye-networks-logo"
              :src="logo"
              class="img-responsive block logo"
              alt="Eagle Eye Networks logo"
            />
            <div
              v-if="links.phone"
              class="phone"
            >
              <v-icon
                size="1em"
                color="secondary"
              >
                fa fa-phone-alt
              </v-icon> <a :href="`tel:${links.phone}`">{{ links.phone }}</a>
            </div>
          </v-col>
          <v-col>
            <ul
              v-if="isAuthorized"
              class="footer-menu"
            >
              <li class="menu-title">
                {{ $t('Management') }}
              </li>
              <li
                v-if="isAdmin()"
                class="menu-item"
              >
                <a
                  class="my-distributors-link"
                  href="#/admin/distributors"
                >{{ $t('My distributors') }}</a>
              </li>
              <li
                v-if="isDistributor() && !isAdmin()"
                class="menu-item"
              >
                <a
                  class="my-resellers-link"
                  :href="'#/distributor/' + partnerId + '/resellers'"
                >{{ $t('My resellers') }}</a>
              </li>
              <li
                v-if="isDistributor() && !isAdmin()"
                class="menu-item"
              >
                <a
                  class="add-new-reseller-link"
                  :href="'#/distributor/' + partnerId + '/add-reseller'"
                >{{ $t('Add new reseller') }}</a>
              </li>
              <li
                v-if="isReseller()"
                class="menu-item"
              >
                <a
                  class="my-customers-link"
                  :href="'#/reseller/' + partnerId + '/customers'"
                >{{ $t('My customers') }}</a>
              </li>
              <li
                v-if="isReseller()"
                class="menu-item"
              >
                <a
                  class="add-new-customer-link"
                  :href="'#/reseller/' + partnerId + '/add-customer'"
                >{{ $t('Add new customer') }}</a>
              </li>
            </ul>
          </v-col>
          <v-col>
            <ul
              v-if="isAuthorized && hasInsightsRights()"
              class="footer-menu"
            >
              <li class="menu-title">
                {{ $t('Insights') }}
              </li>
              <li class="menu-item">
                <a
                  class="insights-reports-link"
                  href="#/insights/reports"
                >{{ $t('Reports') }}</a>
              </li>
            </ul>
          </v-col>
          <v-col>
            <ul
              class="footer-menu"
            >
              <li class="menu-title">
                {{ $t('Resources') }}
              </li>
              <li class="menu-item">
                <a
                  class="insights-reports-link"
                  href="https://developer.cameramanager.com"
                  target="_blank"
                >{{ $t('Developer Portal') }}</a>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>      
      <v-col>
        <v-row
          v-if="isAuthorized"
          class="is-mobile"
        >
          <v-col v-if="hasPartnerRights()">
            <ul class="footer-menu">
              <li class="menu-title">
                {{ $t('Partner account') }}
              </li>
              <li
                v-if="hasPermission('AccountEdit')"
                class="menu-item"
              >
                <a
                  class="my-details-link"
                  href="#/my-account/my-details"
                >{{ $t('My details') }}</a>
              </li>
              <li
                v-if="hasPermission('AccountLogins')"
                class="menu-item"
              >
                <a
                  class="admins-link"
                  href="#/my-account/partner-admins"
                >{{ $t('Operators') }}</a>
              </li>
              <li
                v-if="hasPermission('AccountProfiles')"
                class="menu-item"
              >
                <a
                  class="roles-link"
                  href="#/my-account/partner-roles"
                >{{ $t('Roles') }}</a>
              </li>
              <li
                v-if="hasPermission('ManageOauthClient')"
                class="menu-item"
              >
                <a
                  class="api-keys-link"
                  href="#/my-account/api-keys"
                >{{ $t('Api keys') }}</a>
              </li>
            </ul>
          </v-col>
          <v-col class="text-left">
            <ul class="footer-menu">
              <li class="menu-title">
                {{ $t('Language') }}
              </li>
              <language-selection position="is-top-left" />
            </ul>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      style="width:100%"
      class="is-marginless"
    >
      <v-divider />
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="6"
        class="text-center"
      >
        <v-row
          class="pt-4"
          no-gutters
        >
          <v-col
            v-if="links.privacyPolicy !== ''"
            class="pl-5"
            md="auto"
          >
            <a
              id="footer-privacy-policy-link"
              :target="(''+links.privacyPolicy).includes('/#/') ? '' : '_blank'"
              class="button is-link"
              :href="links.privacyPolicy"
            >{{ $t('Privacy Policy') }}</a>
          </v-col>
          <v-col
            v-if="links.cookies !== ''"
            class="pl-5"
            md="auto"
          >
            <a
              id="footer-cookies-link"
              :target="(''+links.cookies).includes('/#/') ? '' : '_blank'"
              class="button is-link"
              :href="links.cookies"
            >{{ $t('Cookies') }}</a>
          </v-col>
          <v-col
            v-if="links.terms !== ''"
            class="pl-5"
            md="auto"
          >
            <a
              id="footer-terms-conditions-link"
              :target="(''+links.terms).includes('/#/') ? '' : '_blank'"
              class="button is-link"
              :href="links.terms"
            >{{ $t('Terms & Conditions') }}</a>
          </v-col>
          <v-col
            v-if="links.apiterms !== '' && hasPermission('ManageOauthClient')"
            class="pl-5"
            md="auto"
          >
            <a
              id="apifooter-terms-conditions-link"
              :target="(''+links.apiterms).includes('/#/') ? '' : '_blank'"
              class="button is-link"
              :href="links.apiterms"
            >{{ $t('API Terms & Conditions') }}</a>
          </v-col>
          <v-col
            v-if="links.newsletter && !isMobile.any()"
            class="pl-5"
            md="auto"
          >
            <v-dialog
              v-model="dialog"
              max-width="640"
            >
              <template #activator="{ on }">
                <a
                  id="signup-form"
                  class="button is-link"
                  v-on="on"
                >{{ $t('Dealer Communication') }}</a>
              </template>
              <v-card>
                <mail-chimp :close-dialog="() => dialog = false" />
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <p
          class="copyright"
          :title="versionNumber"
          @dblclick.prevent="showVersion"
        >
          <v-icon
            size="1em"
            color="secondary"
          >
            far fa-copyright
          </v-icon> <span v-if="links.copyright">Eagle Eye CameraManager</span> {{ year }}
        </p>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { getImgUrl } from '@/service/main'
import LanguageSelection from '@/components/base/LanguageSelection'
import MailChimp from '@/components/widget/MailChimp'
import Theme from '@/service/themes'

const auth = require('@/service/auth')

export default {
    name: 'AppFooter',
    components: {
        LanguageSelection,
        MailChimp
    },
    props: ['title', 'version', 'buildNumber', 'logo'],
    data () {
        return {
            year: new Date().getFullYear(),
            dialog: false,
            links: {}
        }
    },
    computed: {
        versionNumber () {
            if (this.buildNumber !== null && this.buildNumber.length > 0) {
                return `${this.version} (build ${this.buildNumber})`
            }
            return this.version
        },
        partnerId () {
            return this.$store.getters.myAccount ? this.$store.getters.myAccount.accountId : null
        },
        showSignup: {
            get () {
                return this.$store.getters.showSignup
            },
            set (value) {
                this.$store.commit('showSignup', !!value)
            }
        },
        isAuthorized () {
            return this.$store.getters.isAuthorized
        }
    },
    mounted () {
        const themeName = config.hasOwnProperty('theme') ? config.theme : 'Cameramanager'
        const theme = new Theme(themeName)
        this.links = theme.links()

    },
    methods: {
        hasPartnerRights () {
            return this.hasPermission('AccountEdit') || this.hasPermission('AccountLogins') || this.hasPermission('AccountProfiles')
        },
        isAdmin () {
            return auth.isAdmin()
        },
        isDistributor () {
            return auth.isDistributor()
        },
        isReseller () {
            return auth.isReseller()
        },
        hasInsightsRights () {
            return auth.isReseller() || this.hasPermission('InsightsReports')
        },
        showVersion () {
            this.$store.commit('toastMessage', {
                showing: true,
                text: `Version ${this.versionNumber}`,
                timeout: 5000,
                color: 'primary'
            })
        }
    },
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";

  a {
    text-decoration: none;
  }

  ul {
    list-style-type: none;
  }

  .is-text-right {
    text-align: right;
  }

  .v-divider {
    margin: 0;
  }

  .v-container {
    width: 100%;
  }

  .v-footer {
    background-color: var(--v-text-base);
    color: #ffffff;
    z-index: 1;
    .columns {
      .column {
        &.is-centered {
          justify-content: center;
          text-align: center;
        }
        &.has-logo {
          margin-top: -10px;
        }
      }
    }
    .v-image {
      margin: 0 auto;
      max-height: unset;
      height: auto;
      max-width: 150px;
    }
    .phone {
      padding: 10px 0;
      font-weight: 600;
      a {
        color: inherit;
      }
    }
    ul {
      &.footer-menu {
        margin-bottom: 10px;
        li {
          text-transform: uppercase;
          color: #FFFFFF;
          &.menu-title {
            font-size: 1em;
            font-weight: 600;
            margin: 10px 0;
            a {
              color: inherit;
            }
          }
          &.menu-item {
            font-size: 0.7em;
            margin: 5px 0;
            a {
              color: inherit;
            }
          }
        }
      }
    }
    .divider {
      border-color: #0F0F0F;
      margin-top: 20px;
    }
  }

  .v-footer .button.is-link {
    color: white !important;
    text-decoration: none;
    font-weight: normal !important;
    text-transform: capitalize !important;
    border: none;
    user-select: none;
    box-shadow: none;
  }

  .v-footer .button.is-link.is-active, .button.is-link.is-hovered, .button.is-link:active, .button.is-link:hover {
    background-color: transparent !important;
    color: var(--v-primary-lighten3) !important;
    font-weight: normal !important;
  }

  .v-footer .button.is-link.is-focused, .button.is-link:focus {
    background-color: transparent !important;
    font-weight: normal !important;
  }

  p.copyright {
    color: $secondary !important;
    user-select: none;
    margin-bottom: 0;
    @media screen and (min-width: 769px) {
      line-height: 3.5;
      padding-right: 170px;
      text-align: right;
    }
  }

  .button:active, .button.is-active {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  @media (max-width: 992px) {
    p.copyright {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    .v-footer .column {
      padding: 0;
      text-align: center;
    }

    .v-footer .button.is-link {
      height: auto !important;
    }
  }

</style>
