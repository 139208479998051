<template>
  <v-card height="200">
    <v-form @submit.prevent="addProfile">
      <v-card-title class="headline">
        <span>{{ $t('New role') }}</span>
      </v-card-title>
      <v-card-text class="dialog-card-background">
        <div class="field">
          <v-text-field
            v-model="name"
            :label="$t('Name')"
            type="text"
            dense
            autocomplete="no"
            @input="$v.name.$touch()"
          />
          <p
            v-if="$v.name.$error && !$v.name.required"
            class="error-message pt-2"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="$v.name.$error && !$v.name.validName"
            class="error-message pt-2"
          >
            {{ $t('Please enter a valid name') }}
          </p>
          <p
            v-else-if="$v.name.$error && !$v.name.doesNotExist"
            class="error-message pt-2"
          >
            {{ $t('This role already exists') }}
          </p>
        </div>
        <v-btn
          large
          class="float-right"
          color="primary"
          type="submit"
        >
          {{ $t('Add role') }}
        </v-btn>
        <v-btn
          large
          class="float-right mr-2"
          outlined
          color="primary"
          type="button"
          @click="closeDialog"
        >
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validName } from '@/service/main'

const restapi = require('@/service/restapi')

function doesNotExist (value) {
    let names = []
    if (this.invalidNames && Array.isArray(this.invalidNames)) {
        this.invalidNames.forEach(name => { names.push(name.toLowerCase()) })
    }
    return !names.includes(value.toLowerCase())
}

export default {
    name: 'NewPartnerProfile',
    props: ['partnerId', 'invalidNames'],
    data () {
        return {
            name: null
        }
    },
    methods: {
        addProfile () {
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }
            let that = this
            this.$v.$reset()
            restapi.createAccountProfile(this.partnerId, { name: this.name })
                .then(function () {
                    that.name = null
                    that.$emit('onCreated')
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        doesNotExist: function (name) {
            let existing = this.invalidNames || []
            if (!Array.isArray(existing)) existing = [existing]
            existing.forEach(exist => {
                if (exist.toLowerCase() === name.toLowerCase()) return false
            })
            return true
        },
        closeDialog: function(){
            this.$emit('close-dialog')
        },
    },
    validations: {
        name: {
            required,
            validName,
            doesNotExist
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';
  .dialog-card-background{
    height:100px
  }
 </style>
