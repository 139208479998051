<template>
  <div class="view-mobile-subscriptions">
    <div
      v-if="hasSubscriptions"
      class="subscriptions-list"
    >
      <div class="table-top-actions-block">
        <div class="search-block">
          <v-text-field
            v-model="filter"
            class="partner-search"
            type="text"
            placeholder="Search"
          >
            <template #prepend>
              <v-icon
                size="1rem"
                class="pt-1"
              >
                fa fa-search
              </v-icon>
            </template>
          </v-text-field>
        </div>
        <div class="fill-remaining-space" />
        <div class="add-new-button-block">
          <v-btn
            large
            color="primary"
            :disabled="!hasPermission('CustomerAccount')"
            @click="addNewSubscription()"
          >
            {{ $t("Add new") }}
          </v-btn>
        </div>
      </div>
      <mobile-subscriptions-table
        :account="account"
        :subscriptions="subscriptions"
        :filter="filter"
        @onEditSubscription="editSubscription"
        @onRemoveSubscription="removeSubscription"
      />
    </div>
    <div
      v-else
      class="empty-subscriptions-list"
    >
      <div class="columns is-dashed px-10 pa-5">
        <v-row>
          <v-col cols="auto">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="sim-card"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              class="svg-inline--fa fa-sim-card fa-w-12 fa-3x sim-card-image"
            >
              <path
                fill="currentColor"
                d="M0 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V128L256 0H64C28.7 0 0 28.7 0 64zm224 192h-64v-64h64v64zm96 0h-64v-64h32c17.7 0 32 14.3 32 32v32zm-64 128h64v32c0 17.7-14.3 32-32 32h-32v-64zm-96 0h64v64h-64v-64zm-96 0h64v64H96c-17.7 0-32-14.3-32-32v-32zm0-96h256v64H64v-64zm0-64c0-17.7 14.3-32 32-32h32v64H64v-32z"
              />
            </svg>
          </v-col>
          <v-col>
            <p class="contact-dealer">
              {{ $t('Click ADD NEW to add a mobile subscription to this customer account') }}
            </p>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-btn
          large
          color="primary"
          :disabled="!(hasPermission('CustomerAccount') && isUserActive)"
          @click="addNewSubscription()"
        >
          {{ $t("Add new") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
import MobileSubscriptionsTable from '@/components/account/subscription/mobile/MobileSubscriptionsTable'

export default {
    name: 'ViewMobileSubscriptions',
    components: {
        'mobile-subscriptions-table': MobileSubscriptionsTable
    },
    props: ['account', 'subscriptions'],
    data () {
        return {
            filter: null
        }
    },
    computed: {
        hasSubscriptions () {
            return this.subscriptions.length > 0
        },
        resellerId () {
            return this.$route.params.resellerid
        },
        accountId () {
            return this.$route.params.accountid
        },
        userId () {
            return this.$route.params.userid
        },
        isUserActive () {
            return this.account ? this.account.active : false
        },
    },
    methods: {
        addNewSubscription () {
            this.$emit('onAddNewSubscription')
        },
        editSubscription (subscription) {
            this.$emit('onEditSubscription', subscription)
        },
        removeSubscription (subscription) {
            this.$emit('onRemoveSubscription', subscription)
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../../../assets/styles/forms';
  @import '../../../../assets/styles/custom';

  .empty-subscriptions-list {
    padding: 5px 0;
  }

  .content-block {
    margin-top: 30px;
  }

  .columns {
    margin-top: 20px;
    padding: 5px 0;
    &.is-dashed {
      background-color: transparent;
      border: dashed 2px $color-title-light;
      border-radius: 4px;
      padding: 0 0 10px 20px;
      margin-left: 0;
      margin-bottom: 20px;
      .column {
        margin: 20px 0 10px 0;
        padding: 0 0 0 10px;
      }
      .contact-dealer {
        padding-top: 10px;
        padding-left: 10px;
        font-size: 13px;
      }
    }
  }

  .sim-card-image {
    width: 30px;
    path {
      fill: $color-gray;
    }
  }

  .columns.title-block {
    background-color: transparent;
    p {
      font-size: 12px;
    }
  }

  .field {
    &.search {
      min-width: 50%;
      display: inline-block;
      margin: 20px 0 30px;
      max-height: 28px;
      p.control.has-icons-left {
        border: none;
        .icon {
          height: 40px;
        }
        input.input {
          margin: 0 0 0 30px;
          padding: 0;
          height: 40px;
          border: none;
          border-bottom: 2px solid #FFFFFF;
          + .icon {
            border-bottom: 2px solid #FFFFFF;
          }
          &:focus {
            border-bottom-color: $primary;
            + .icon {
              color: $primary;
              border-bottom-color: $primary;
            }
          }
        }
      }
    }
  }

  .table-top-actions-block {
    display: flex;
    overflow: hidden;
    margin: 0;
    align-items: center;
  }

  .fill-remaining-space {
    flex: auto;
  }

  .search-block {
  }

  .add-new-button-block {
  }

</style>
