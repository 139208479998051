<template>
  <div
    id="partner-accounts"
    class="content-wrapper"
  >
    <side-bar>
      <side-bar-item
        name="my-details"
        :text="$t('My details')"
        icon="fa fa-id-card"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="hasPermission('AccountLogins')"
        name="partner-admins"
        :text="$t('Operators')"
        icon="fa fa-user-plus"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="hasPermission('AccountProfiles')"
        name="partner-roles"
        :text="$t('Roles')"
        icon="fa fa-align-left"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
      <side-bar-item
        v-if="hasPermission('ManageOauthClient')"
        name="api-keys"
        :text="$t('Api keys')"
        icon="fa fa-key"
        :selected-menu="selectedMenu"
        :select-menu="selectMenu"
      />
    </side-bar>
    <div class="content">
      <div
        class="container"
        :class="selectedMenu"
      >
        <partner-details
          v-if="menuSelected('my-details')"
          id="distributor-details"
          :partner="partner"
          :partner-type="partnerType"
          :title="$t('My contact info')"
        />
        <partner-logins
          v-if="hasPermission('AccountLogins') && menuSelected('partner-admins')"
          id="partner-logins"
          :partner-id="partnerId"
        />
        <partner-profiles
          v-if="hasPermission('AccountProfiles') && menuSelected('partner-roles')"
          id="partner-profiles"
          :partner-id="partnerId"
        />
        <api-keys
          v-if="hasPermission('ManageOauthClient') && menuSelected('api-keys')"
          id="api-keys"
          :partner-id="partnerId"
        />
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
import PartnerDetails from '@/components/partner/PartnerDetails'
import PartnerLogins from '@/components/partner/PartnerLogins'
import PartnerProfiles from '@/components/partner/PartnerProfiles'
import ApiKeys from '@/components/partner/ApiKeys'
import SideBar from '@/components/base/SideBar'
import SideBarItem from '@/components/base/SideBarItem'

const auth = require('@/service/auth')
const restapi = require('@/service/restapi')

export default {
    name: 'MyAccount',
    components: {
        PartnerDetails,
        PartnerLogins,
        PartnerProfiles,
        ApiKeys,
        SideBar,
        SideBarItem
    },
    data () {
        return {
            rest: true,
            selectedMenu: null,
            defaultMenu: 'my-details',
            customersCount: null,
            partner: {}
        }
    },
    computed: {
        partnerId () {
            return this.$store.getters.myAccount.accountId
        },
        partnerDetails () {
            return this.$store.getters.myPartner
        },
        partnerType () {
            return this.partnerDetails.hasOwnProperty('resellerId') ? 'Reseller' : 'Distributor'
        },
    },
    watch: {
        partnerId () {
            this.loadPartnerDetails()
        },
        '$route' (to, from) {
            this.selectedMenu = this.$route.params.menu || this.defaultMenu
            this.$store.commit('selectedSubmenu', this.selectedMenu)
        },
        selectedMenu () {
            if (this.partnerId !== null) {
                this.loadPartnerDetails()
            }
        }
    },
    mounted () {
        if (!this.$route.params.menu) {
            this.$router.replace({path: '/my-account/' + this.defaultMenu})
        } else {
            this.selectedMenu = this.$route.params.menu
            this.$store.commit('selectedSubmenu', this.selectedMenu)
        }
    },
    beforeDestroy () {
        this.$store.commit('selectedSubmenu', null)
    },
    methods: {
        selectMenu (item) {
            this.$router.push({path: '/my-account/' + item})
        },
        menuSelected (item) {
            return item === this.selectedMenu
        },
        loadPartnerDetails () {
            if (typeof this.partnerId === 'number') {
                const that = this
                console.log('Retrieving partner details', this.partnerId, this.partnerType)
                restapi.getPartnerDetails(this.partnerId, this.partnerType)
                    .then(function (result) {
                        that.partner = result
                    })
                    .catch(function (error) {
                        console.error(error)
                        if (error.response) {
                            that.$store.commit('toastMessage', {
                                showing: true,
                                text: error.response.data && error.response.data.title ? error.response.data.title : that.$t('Could not load data'),
                                timeout : -1,
                                color: 'error',
                                support: true
                            })
                        }
                    })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";

  .content-wrapper {
    .content {
      width: 100%;
      background-color: $color-shadow-light;
      .container {
        position: relative;
        padding-top: 40px;
        padding-bottom: 20px;
        width: 90%;
        min-width: 90%;
        @media screen and (min-width: 769px) {
          &.my-details {
            width: 900px;
            min-width: 900px;
          }
          &.partner-admins {
            width: 90%;
            min-width: 90%;
          }
          &.partner-roles {
            width: 90%;
            min-width: 90%;
          }
        }
      }
    }
  }
</style>
