<template>
  <div
    v-if="hasPermission('AccountEdit')"
    id="partner-activate"
    class="partner-activate"
    :data-is-active="isActive"
  >
    <h2 class="component-title">
      {{ deactivatePartnerLabel }}<span v-if="isInActive"> - {{ $t('Deactivated') }}</span>
    </h2>
    <p class="component-description">
      {{ deactivatePartnerDescription }}
    </p>

    <v-tooltip
      v-if="isActive"
      top
      :disabled="canDeactivate"
    >
      <template #activator="{ on }">
        <span
          class="py-3"
          v-on="on"
        >
          <v-btn
            large
            color="primary"
            :disabled="!canDeactivate"
            @click="deactivateConfirm"
          >{{ deactivatePartnerLabel }}</v-btn>
        </span>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>

    <v-tooltip
      v-if="isInActive"
      top
      :disabled="canReactivate"
    >
      <template #activator="{ on }">
        <span
          class="py-3"
          v-on="on"
        >
          <v-btn
            large
            color="primary"
            :disabled="!canReactivate"
            @click="reactivateConfirm"
          >{{ reactivatePartnerLabel }}</v-btn>
        </span>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>

    <div class="text-center">
      <v-dialog
        v-model="showDeactivateDialog"
        width="500"
      >
        <v-card>
          <v-card-title
            class="headline lighten-2"
            primary-title
          >
            {{ deactivatePartnerLabel }}
          </v-card-title>

          <v-card-text v-html="deactivateMessage" />

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              large
              @click="showDeactivateDialog = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn

              large
              color="error"
              @click="deactivatePartner"
            >
              {{ $t('Deactivate') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog
        v-model="showReactivateDialog"
        width="500"
      >
        <v-card>
          <v-card-title
            class="headline lighten-2"
            primary-title
          >
            {{ reactivatePartnerLabel }}
          </v-card-title>

          <v-card-text v-html="reactivateMessage" />

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              large
              outlined
              @click="showReactivateDialog = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              large
              color="error"
              @click="reactivatePartner"
            >
              {{ $t('Reactivate') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
const restapi = require('@/service/restapi')

export default {
    name: 'PartnerActivate',
    props: ['partner', 'partnerType', 'resellerCount', 'customerCount'],
    data () {
        return {
            isActivateAccountPopupShown: false,
            isDeactivateAccountPopupShown: false,
            showDeactivateDialog: false,
            showReactivateDialog: false
        }
    },
    computed: {
        partnerId () {
            if (this.partner) {
                return this.partnerType === 'Reseller' ? this.partner.resellerId : this.partner.distributorId
            }
            return null
        },
        tooltipText () {
            if (this.partnerType === 'Distributor') return this.$t('deactivate_distributor_text')
            else return this.$t('deactivate_reseller_text')
        },
        reactivatedMessage () {
            if (this.partnerType === 'Distributor') return this.$t('activated_distributor_message')
            else return this.$t('activated_reseller_message')
        },
        deactivatedMessage () {
            if (this.partnerType === 'Distributor') return this.$t('deactivated_distributor_message')
            else return this.$t('deactivated_reseller_message')
        },
        reactivatePartnerLabel () {
            if (this.partnerType === 'Distributor') return this.$t('Reactivate distributor')
            else return this.$t('Reactivate reseller')
        },
        deactivatePartnerLabel () {
            if (this.partnerType === 'Distributor') return this.$t('Deactivate distributor')
            else return this.$t('Deactivate reseller')
        },
        deactivatePartnerDescription () {
            if (this.partnerType === 'Distributor') return this.$t('Deactivate distributor description')
            else return this.$t('Deactivate reseller description')
        },
        deactivateMessage () {
            if (this.partnerType === 'Distributor') return this.$t('Are you sure you want to deactivate distributor', { company: this.partner.companyName })
            else return this.$t('Are you sure you want to deactivate reseller', { company: this.partner.companyName })
        },
        reactivateMessage () {
            if (this.partnerType === 'Distributor') return this.$t('Are you sure you want to reactivate distributor', { company: this.partner.companyName })
            else return this.$t('Are you sure you want to reactivate reseller', { company: this.partner.companyName })
        },
        isActive () {
            if (this.partner) {
                return this.partner.active
            }
            return null
        },
        isInActive () {
            if (this.partner) {
                return !this.partner.active
            }
            return null
        },
        partnerDetails () {
            return this.$store.getters.partnerDetails || {}
        },
        canDeactivate () {
            if (!this.partnerType) return null
            if (this.partnerType === 'Distributor') {
                return !this.hasResellers
            } else if (this.partnerType === 'Reseller') {
                return !this.hasCustomers
            }
            return false
        },
        canReactivate () {
            return (this.$store.getters.myAccount.accountId !== this.partnerId)
        },
        hasCustomers () {
            return this.customerCount && this.customerCount > 0
        },
        hasResellers () {
            return this.resellerCount && this.resellerCount > 0
        }
    },
    methods: {
        deactivatePartner () {
            const that = this
            that.isLoading(true)
            restapi.setPartner(this.partnerId, this.partnerType, { active: false })
                .then(function () {
                    that.isLoading(false)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.deactivatedMessage,
                        timeout : 4000,
                        color: 'primary'
                    })
                    that.$emit('deactivated')
                    that.partner.active = false
                    that.partnerDetails.IsActive = 'no'
                    that.showDeactivateDialog = false
                })
                .catch(function (error) {
                    console.error(error)
                    that.isLoading(false)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.getMessageFromError(error, (that.partnerType === 'Distributor') ? 'SET_DISTRIBUTOR' : 'SET_RESELLER'),
                        timeout : -1,
                        color: 'error',
                        support: true
                    })
                })
        },
        reactivatePartner () {
            const that = this
            that.isLoading(true)
            restapi.setPartner(this.partnerId, this.partnerType, { active: true })
                .then(function (result) {
                    that.isLoading(false)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.reactivatedMessage,
                        timeout : 4000,
                        color: 'primary'
                    })
                    that.$emit('reactivated')
                    that.partner.active = true
                    that.partnerDetails.IsActive = 'yes'
                    that.showReactivateDialog = false
                })
                .catch(function (error) {
                    console.error(error)
                    that.isLoading(false)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.getMessageFromError(error, (that.partnerType === 'Distributor') ? 'SET_DISTRIBUTOR' : 'SET_RESELLER'),
                        timeout : -1,
                        color: 'error',
                        support: true
                    })
                })
        },
        deactivateConfirm () {
            if (this.canDeactivate) {
                this.showDeactivateDialog = true
            }
        },
        reactivateConfirm () {
            if (this.canReactivate) {
                this.showReactivateDialog = true
            }
        },
        deactivatePopupClosed () {
            this.isDeactivateAccountPopupShown = false
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/custom';

  .partner-activate {
    padding: 20px 0;
  }

  .tooltip.is-top.is-primary:before {
    border-top: 5px solid rgba(0,0,0,0.7);
  }

  .tooltip.is-primary:after {
    background-color: rgba(0,0,0,0.7);
    color: #fff;
  }

  #deactivate-account-modal {
    .icon {
      color: $color-error-dark;
    }
    button.is-primary {
      background-color: $color-error-dark;
    }
    ::v-deep .b-checkbox.checkbox input[type=checkbox]:checked + .check {
      background-color: $color-error-dark;
      border-color: $color-error-dark;
    }
    ::v-deep label.b-checkbox.checkbox:hover .check {
      border-color: $color-error-dark;
    }
    ::v-deep .b-checkbox.checkbox .check:hover {
      border-color: $color-error-dark;
    }
    ::v-deep .b-checkbox.checkbox .control-label {
      font-size: 14px;
    }
    ::v-deep .b-checkbox.checkbox {
      margin-bottom: 10px;
      display: flex;
    }
    ::v-deep .b-checkbox.checkbox + .checkbox {
      margin-left: 0;
    }
    ::v-deep .b-checkbox.checkbox:focus input[type=checkbox] + .check {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    ::v-deep .b-checkbox.checkbox input[type=checkbox] + .check {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .cannot-be-prolonged {
    padding: 15px 0 0 0;
  }

  .scheduled-deactivation {
    padding: 15px 0;
  }

  #deactivate-date-modal {
    ::v-deep .b-radio.radio {
      margin-bottom: 10px;
      display: flex;
    }
    ::v-deep .b-radio.radio + .radio {
      margin-left: 0;
    }
    .icon {
      color: $color-error-dark;
    }
    button.is-primary {
      background-color: $color-error-dark;
    }
    ::v-deep .b-radio.radio input[type=radio]:checked + .check {
      border-color: $color-error-dark;
    }
    ::v-deep .b-radio.radio input[type=radio]:checked + .check:before {
      background-color: $color-error-dark;
    }
    ::v-deep .b-radio.radio input[type=radio] + .check:before {
      background-color: $color-error-dark;
    }
    ::v-deep label.b-radio.radio:hover .check {
      border-color: $color-error-dark;
    }
    ::v-deep .b-radio.radio .check:hover {
      border-color: $color-error-dark;
    }
    ::v-deep .b-radio.radio:focus input[type=radio] + .check {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    ::v-deep .b-radio.radio input[type=radio] + .check {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  #activate-subscription-modal .modal-card-body {
    padding: 0;
  }

  .component-title span {
    color: $color-error-dark;
  }
</style>
