<template>
  <div
    v-if="hasPermission('CustomerAccount')"
    id="account-activate"
    class="account-activate"
    :data-is-active="active"
  >
    <h2 class="component-title">
      {{ $t('Deactivate account') }}<span v-if="!active"> - {{ $t('Deactivated') }}</span>
    </h2>
    <p class="component-description">
      {{ $t('Deactivate account description') }}
    </p>
    <v-tooltip
      v-if="!active"
      top
      nudge-bottom="10"
      :disabled="!(!hasPermission('CustomerAccount') || !hasAvailableSubscriptions)"
    >
      <template #activator="{ on }">
        <span v-on="on">
          <v-btn
            large
            color="primary"
            :disabled="!isPartnerActive || !hasAvailableSubscriptions"
            @click="showActivateAccountPopup()"
          >{{ $t('Activate account') }}
          </v-btn>
        </span>
      </template>
      <span>{{ activateTooltip }}</span>
    </v-tooltip>
    <v-tooltip
      v-if="active"
      top
      nudge-bottom="10"
      :disabled="!(!hasPermission('CustomerDeactivate') || !isActive)"
    >
      <template #activator="{ on }">
        <span v-on="on">
          <v-btn
            large
            color="primary"
            :disabled="!hasPermission('CustomerDeactivate') || !isActive"
            @click="confirmDeactivateAccount()"
          >{{ $t('Deactivate account') }}
          </v-btn>
        </span>
      </template>
      <span>{{ deactivateTooltip }}</span>
    </v-tooltip>
    <v-dialog
      id="deactivate-account-modal"
      v-model="isDeactivateAccountPopupShown"
      width="600px"
      :active.sync="isDeactivateAccountPopupShown"
      @close="deactivatePopupClosed()"
    >
      <v-card>
        <v-form @submit.prevent="deactivate">
          <v-card-title>
            {{ $t('Deactivate Account') }}
          </v-card-title>
          <v-card-text>
            <p>{{ $t('Are you sure you want to deactivate user', { email: email }) }}</p>
            <p>{{ $t('Please confirm all below') }}</p>
            <v-container fluid>
              <v-checkbox
                v-model="acceptDisableLogin"
                class="mt-0 pt-0 mb-0"
                dense
                :label="$t('User cannot login anymore at', {currentDate: getCurrentDate()})"
              />
              <v-checkbox
                v-model="acceptCancelSubscription"
                class="mt-0 pt-0 mb-0"
                dense
                :label="$t('Subscription will be cancelled at', {subscriptionEnd: getCurrentDate()})"
              />
              <v-checkbox
                v-model="acceptStopRecording"
                class="mt-0 pt-0 mb-0"
                dense
                :label="$t('All cameras will stop recording at', {currentDate: getCurrentDate()})"
              />
              <v-checkbox
                v-model="acceptCamerasRemoved"
                class="mt-0 pt-0 mb-0"
                dense
                :label="$t('All cameras will be removed from account at', {camerasRemovalDate: standardDate(camerasRemovalDate)})"
              />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              large
              outlined
              class="mr-2"
              color="primary"
              type="button"
              @click="deactivatePopupClosed()"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              large
              color="error"
              type="submit"
              :disabled="!canDeactivate || deactivating"
            >
              {{ $t('Deactivate') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      id="activate-account-modal"
      v-model="isActivateAccountPopupShown"
      width="600px"
      :active.sync="isActivateAccountPopupShown"
      @close="activatePopupClosed()"
    >
      <v-card>
        <v-form @submit.prevent="showChooseSubscriptionPopup">
          <v-card-title>
            {{ $t('Activate Account') }}
          </v-card-title>
          <v-card-text>
            <p>{{ $t('Are you sure you want to activate this user') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              large
              outlined
              class="mr-2"
              color="primary"
              type="button"
              @click="activatePopupClosed()"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              large
              color="primary"
              type="submit"
            >
              {{ $t('Next') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      id="activate-subscription-modal"
      v-model="isActivateSubscriptionPopupShown"
      width="600px"
      :active.sync="isActivateSubscriptionPopupShown"
      @close="activateSubscriptionPopupClosed()"
    >
      <v-card>
        <v-card-title>
          {{ $t('Activate Account') }} - {{ $t('Choose subscription') }}
        </v-card-title>
        <v-card-text>
          <choose-subscription
            :subscriptions="subscriptions"
            @subscriptionSelected="onSubscriptionSelected($event)"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            outlined
            class="mr-2"
            color="primary"
            type="button"
            @click="activateSubscriptionPopupClosed()"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            large
            color="primary"
            type="button"
            :disabled="!canActivate || !hasPermission('CustomerAccount')"
            @click="activate"
          >
            {{ $t('Activate account') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ChooseSubscription from '@/components/account/ChooseSubscription'

const restapi = require('@/service/restapi')
const guiUtils = require('@/service/guiUtils')

export default {
    name: 'AccountActivate',
    components: {
        ChooseSubscription
    },
    props: ['account', 'subscription', 'reseller', 'subscriptions'],
    data () {
        return {
            acceptDisableLogin: false,
            acceptCancelSubscription: false,
            acceptStopRecording: false,
            acceptCamerasRemoved: false,
            confirmActivate: false,
            selectedSubscription: null,
            isActivateAccountPopupShown: false,
            isActivateSubscriptionPopupShown: false,
            isDeactivateAccountPopupShown: false,
            deactivating: false
        }
    },
    computed: {
        hasSubscriptions () {
            return this.subscriptions ? this.subscriptions.length > 0 : null
        },
        hasAvailableSubscriptions () {
            return this.subscriptions ? this.subscriptions.filter(function (s) { return s.available }).length > 0 : null
        },
        isPartnerActive () {
            return this.reseller ? this.reseller.active : null
        },
        active () {
            return this.account ? this.account.active : null
        },
        email () {
            return this.account ? this.account.contactEmail : null
        },
        canDeactivate () {
            return this.acceptDisableLogin && this.acceptCancelSubscription && this.acceptStopRecording && this.acceptCamerasRemoved
        },
        canActivate () {
            return !!this.selectedSubscription
        },
        camerasRemovalDate () {
            const d = new Date()
            d.setDate(d.getDate() + 14)
            return d
        },
        subscriptionEndDate () {
            if (this.subscription) {
                return this.subscription.endDate
            }
            return null
        },
        isActive () {
            const now = new Date().getTime()
            const endDate = new Date(Date.parse(this.subscriptionEndDate)).getTime()
            return endDate >= now
        },
        activateTooltip () {
            if (!this.hasPermission('CustomerAccount')) {
                return this.$t('You don\'t have permission to activate this account.')
            } else if (!this.hasAvailableSubscriptions) {
                return this.$t('To activate this account it needs to be given a subscription, but there are no available subscriptions.')
            } else {
                return null
            }
        },
        deactivateTooltip () {
            if (!this.hasPermission('CustomerDeactivate')) {
                return this.$t('You don\'t have permission to deactivate this customer.')
            } else if (!this.isActive) {
                return this.$t('This account is already deactivated or scheduled to be deactivated.')
            } else {
                return null
            }
        }
    },
    methods: {
        getCurrentDate () {
            return guiUtils.getCurrentDate()
        },
        showActivateAccountPopup () {
            if (this.isPartnerActive && this.hasAvailableSubscriptions) {
                this.isActivateAccountPopupShown = true
            }
        },
        activatePopupClosed () {
            this.isActivateAccountPopupShown = false
        },
        showChooseSubscriptionPopup () {
            this.isActivateAccountPopupShown = false
            this.isActivateSubscriptionPopupShown = true
        },
        activateSubscriptionPopupClosed () {
            this.isActivateSubscriptionPopupShown = false
        },
        confirmDeactivateAccount () {
            if (this.hasPermission('CustomerDeactivate') && this.isActive) {
                this.isDeactivateAccountPopupShown = true
            }
        },
        deactivatePopupClosed () {
            this.initValues()
            this.isDeactivateAccountPopupShown = false
        },
        onSubscriptionSelected (subscription) {
            this.selectedSubscription = subscription
        },
        initValues () {
            this.acceptDisableLogin = false
            this.acceptCancelSubscription = false
            this.acceptStopRecording = false
            this.acceptCamerasRemoved = false
        },
        activate () {
            if (this.canActivate) {
                const that = this
                const userId = this.account.accountOwnerId
                this.state = 'activating'
                that.isLoading(true)
                restapi.updateAccountSubscription(this.account.resellerId, userId, this.selectedSubscription.subscriptionId)
                    .then(function (result) {
                        console.log('Reactivated user')
                        that.isLoading(false)
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.$t('Account successfully reactivated'),
                            timeout : 4000,
                            color: 'primary'
                        })
                        that.activateSubscriptionPopupClosed()
                        that.$emit('reactivated', userId)
                    })
                    .catch(function (error) {
                        console.error(error)
                        that.isLoading(false)
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.getMessageFromError(error, 'UPDATE_ACCOUNT_SUBSCRIPTION'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                    })
            }
        },
        deactivate () {
            if (this.canDeactivate && !this.deactivating) {
                const that = this
                that.deactivating = true
                that.isLoading(true)
                const userId = this.account.accountOwnerId
                restapi.cancelAccountSubscription(this.account.resellerId, userId, true)
                    .then(function (result) {
                        console.log('Deactivated user')
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.$t('The account will be inactive from tomorrow'),
                            timeout : 4000,
                            color: 'primary'
                        })
                        that.deactivatePopupClosed()
                        that.$emit('deactivated', userId, 'tomorrow')
                    })
                    .catch(function (error) {
                        console.error(error)

                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.getMessageFromError(error, 'CANCEL_ACCOUNT_SUBSCRIPTION'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                    })
                    .then(function () {
                        that.deactivating = false
                        that.isLoading(false)
                    })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/custom';

  .account-activate {
    padding: 20px 0;
    .button {
      .fa {
        &.fa-spin {
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }
  }

  .tooltip.is-top.is-primary:before {
    border-top: 5px solid rgba(0,0,0,0.7);
  }

  .tooltip.is-primary:after {
    background-color: rgba(0,0,0,0.7);
    color: #fff;
  }

  #deactivate-account-modal {
    .icon {
      color: $color-error-dark;
    }
    button.is-primary {
      background-color: $color-error-dark;
    }
    .modal-card-body {
      p:not(:first-child) {
        margin-bottom: 5px;
      }
      p:last-child {
        margin-top: 20px;
      }
    }
    ::v-deep .b-checkbox.checkbox input[type=checkbox]:checked + .check {
      background-color: $color-error-dark;
      border-color: $color-error-dark;
    }
    ::v-deep label.b-checkbox.checkbox:hover .check {
      border-color: $color-general-text;
    }
    ::v-deep .b-checkbox.checkbox .check:hover {
      border-color: $color-general-text;
    }
    ::v-deep .b-checkbox.checkbox .control-label {
      font-size: 14px;
    }
    ::v-deep .b-checkbox.checkbox {
      margin-bottom: 10px;
      display: flex;
    }
    ::v-deep .b-checkbox.checkbox + .checkbox {
      margin-left: 0;
    }
    ::v-deep .b-checkbox.checkbox:focus input[type=checkbox] + .check {
      box-shadow: none;
    }
    ::v-deep .b-checkbox.checkbox input[type=checkbox] + .check {
      box-shadow: none;
    }
    ::v-deep .b-checkbox.checkbox.has-text-danger input[type=checkbox] + .check {
      border-color: $color-error-dark;
    }
  }

  .cannot-be-prolonged {
    padding: 15px 0px 0px 0;
  }

  .scheduled-deactivation {
    padding: 15px 0;
  }

  #deactivate-date-modal {
    ::v-deep .b-radio.radio {
      margin-bottom: 10px;
      display: flex;
    }
    ::v-deep .b-radio.radio + .radio {
      margin-left: 0;
    }
    .icon {
      color: $color-error-dark;
    }
    button.is-primary {
      background-color: $color-error-dark;
    }
    ::v-deep .b-radio.radio input[type=radio]:checked + .check {
      border-color: $color-error-dark;
    }
    ::v-deep .b-radio.radio input[type=radio]:checked + .check:before {
      background-color: $color-error-dark;
    }
    ::v-deep .b-radio.radio input[type=radio] + .check:before {
      background-color: $color-error-dark;
    }
    ::v-deep label.b-radio.radio:hover .check {
      border-color: $color-error-dark;
    }
    ::v-deep .b-radio.radio .check:hover {
      border-color: $color-error-dark;
    }
    ::v-deep .b-radio.radio:focus input[type=radio] + .check {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    ::v-deep .b-radio.radio input[type=radio] + .check {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  #activate-subscription-modal .modal-card-body {
    padding: 0;
  }

  .component-title span {
    color: $color-error-dark;
  }
</style>
