<template>
  <div class="partner-login">
    <v-card>
      <v-card-title class="primary--text">
        <span v-if="!isActive">{{ $t('Inactive') }}</span>
        <span v-else>{{ profileName }}</span>
      </v-card-title>
      <v-card-subtitle class="pa-5">
        <div
          :class="{ 'inactive': !isActive, 'edit-mode': editMode}"
          :data-id="account.userId"
          :data-login="account.email"
        >
          <div
            v-if="!editMode"
            ref="dropDownEditMenu"
            :class="{ 'is-active': showDropdown, 'is-me': isMe }"
            class="dropdown"
          >
            <v-menu
              offset-y
              auto
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon
                    size="1em"
                    color="primary"
                  >
                    fa fa-ellipsis-h
                  </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-if="isActive"
                  @click="edit"
                >
                  <v-list-item-title class="pr-5">
                    {{ $t('Edit') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!isActive && !isMe && !lastAdmin"
                  @click="toggleActiveState"
                >
                  <v-list-item-title class="pr-5">
                    {{ toggleActiveStateText }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!isMe && !lastAdmin"
                  @click="deleteConfirm"
                >
                  <v-list-item-title class="warning--text pr-5">
                    {{ $t('Delete') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="control-buttons">
            <div
              v-if="editMode"
              class="is-pulled-right"
            >
              <v-btn
                class="mx-2"
                fab
                small
                color="primary"
                @click="cancelEditAccount"
              >
                <v-icon dark>
                  fas fa-times
                </v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                small
                color="primary"
                :disabled="!hasChanged"
                @click="saveAccount"
              >
                <v-icon dark>
                  fas fa-save
                </v-icon>
              </v-btn>
            </div>
          </div>
          <v-text-field
            v-if="editMode"
            id="add-user-company-input"
            v-model="changedAccount.email"
            :label="$t('Email address')"
            dense
            :disabled="!isActive"
            autocomplete="no"
            @input="$v.changedAccount.email.$touch()"
          />
          <p
            v-if="!editMode"
            class="overline mb-0"
          >
            {{ $t('Email address') }}
          </p>
          <p v-if="!editMode">
            {{ changedAccount.email }}
          </p>
          <p
            v-if="editMode && hasChanged && $v.changedAccount.email.$error && !$v.changedAccount.email.required"
            class="error-message"
          >
            {{ $t('This is a required field') }}
          </p>
          <p
            v-else-if="editMode && hasChanged && $v.changedAccount.email.$error && !$v.changedAccount.email.email"
            class="error-message"
          >
            {{ $t('Please enter a valid email address') }}
          </p>
          <v-text-field
            v-if="editMode"
            v-model="changedAccount.password"
            :label="$t('Password')"
            dense
            :disabled="!isActive"
            autocomplete="no"
            @input="$v.changedAccount.password.$touch()"
          />
          <p
            v-if="hasChanged && $v.changedAccount.password.$error && !$v.changedAccount.password.minLength"
            class="error-message"
          >
            {{ $t('Please enter a minimum of X characters', { min: $v.changedAccount.password.$params.minLength.min }) }}
          </p>
          <p
            v-else-if="hasChanged && $v.changedAccount.password.$error && !$v.changedAccount.password.maxLength"
            class="error-message"
          >
            {{ $t('Please enter a maximum of X characters', { max: $v.changedAccount.password.$params.maxLength.max }) }}
          </p>
          <v-select
            v-if="editMode"
            v-model="changedAccount.roleId"
            :disabled="isMe || lastAdmin || !isActive"
            :label="$t('Role')"
            :items="profiles"
            item-text="name"
            item-value="profileId"
            dense
          />
        </div>
      </v-card-subtitle>
    </v-card>
    <div class="text-center">
      <v-dialog
        v-model="showDeleteDialog"
        width="500"
      >
        <v-card>
          <v-card-title
            class="headline lighten-2"
            primary-title
          />

          <v-card-text v-html="$t('Are you sure you want to delete operator \'EMAIL\'? This action cannot be undone.', {email: account.email})" />

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              @click="showDeleteDialog = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              color="error"
              @click="deleteAccount"
            >
              {{ $t('Delete') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
// Validator for testing password strength
// import { goodPassword } from '@/service/main'
import zxcvbn from 'zxcvbn'

const restapi = require('@/service/restapi')

export default {
    name: 'PartnerLogin',
    props: ['partnerId', 'account', 'adminProfileInfo', 'profiles'],
    data () {
        return {
            partnerLogin: {},
            showDropdown: false,
            editMode: false,
            changedAccount: {
                userId: null,
                email: null,
                password: null,
                roleId: null
            },
            showDeleteDialog: false,
            lastAdmin: false
        }
    },
    computed: {
        profileName: {
            get: function () {
                let roleId = this.account.roleId
                const profile = this.profiles.find(function (p) { return p.profileId === roleId }) || {name: 'Unknown'}
                return profile.name
            },
            set: function (name) {
                const profile = this.profiles.find(function (p) { return p.name === name })
                this.changedAcount.roleId = profile.profileId
            }
        },
        dropdownMenuId () {
            return 'dropdown-menu-' + this.account.userId
        },
        isActive () {
            return this.account.active
        },
        hasChanged () {
            return (this.changedAccount.password && this.changedAccount.password.length > 0) ||
                this.changedAccount.email !== this.account.email ||
                this.changedAccount.roleId !== this.account.roleId
        },
        isMe () {
            return this.changedAccount.userId === this.$store.getters.myAccount.userId
        },
        toggleActiveStateText () {
            if (this.account.active) {
                return this.$t('Deactivate')
            } else {
                return this.$t('Activate')
            }
        },
        passwordStrength () {
            return this.changedAccount.password ? zxcvbn(this.changedAccount.password).score : null
        },
        passwordStrengthClass () {
            const scores = { 0: 'worst', 1: 'bad', 2: 'weak', 3: 'good', 4: 'strong' }
            const score = this.changedAccount.password ? this.passwordStrength : -1
            return scores[score]
        }
    },
    created () {
        this.changedAccount = {
            userId: this.account.userId,
            email: this.account.email,
            password: null,
            roleId: this.account.roleId,
            active: this.account.active
        }
        document.addEventListener('click', this.documentClick)
    },
    destroyed () {
        document.removeEventListener('click', this.documentClick)
    },
    methods: {
        toggleDropdown () {
            this.showDropdown = !this.showDropdown
            if (!this.showDropdown) this.editMode = false
        },
        edit () {
            this.lastAdmin = this.changedAccount.roleId === this.adminProfileInfo.profileId && this.adminProfileInfo.count === 1
            this.editMode = true
            this.showDropdown = false
            this.$emit('edit', this.account.userId)
        },
        saveAccount () {
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }
            let that = this
            this.$v.$reset()
            this.isLoading(true)
            let account = {
                userId: that.changedAccount.userId
            }
            if (this.account.email !== this.changedAccount.email) {
                account.email = this.changedAccount.email
            }
            if (this.account.roleId !== this.changedAccount.roleId) {
                account.roleId = this.changedAccount.roleId
            }
            if (this.changedAccount.password) {
                account.password = this.changedAccount.password
            }
            restapi.updatePartnerAccount(this.partnerId, this.changedAccount.userId, account)
                .then(function () {
                    that.account.userId = that.changedAccount.userId
                    that.account.email = that.changedAccount.email
                    that.account.roleId = that.changedAccount.roleId
                    that.changedAccount.password = null
                    that.editMode = false
                    that.$emit('change', that.account.userId)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.$t('Operator successfully saved.'),
                        timeout : 4000,
                        color: 'primary'
                    })
                })
                .catch(function (error) {
                    console.error(error)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.getMessageFromError(error, 'UPDATE_PARTNER_ACCOUNT'),
                        timeout : -1,
                        color: 'error',
                        support: true
                    })
                })
                .then(function () {
                    that.isLoading(false)
                })
        },
        cancelEditAccount () {
            this.changedAccount = {
                userId: this.account.userId,
                email: this.account.email,
                password: null,
                roleId: this.account.roleId
            }
            this.editMode = false
            this.$emit('cancel')
        },
        toggleActiveState () {
            const that = this
            const state = !this.account.active
            const parms = {
                userId: this.account.userId,
                email: this.account.email,
                roleId: that.account.roleId,
                active: state
            }
            restapi.updatePartnerAccount(this.partnerId, parms)
                .then(function () {
                    that.account.active = state
                    that.$emit('change')
                })
                .catch(function (error) {
                    console.log(error)
                })
            this.showDropdown = false
        },
        deleteAccount () {
            let that = this
            restapi.deletePartnerAccount(this.partnerId, this.account.userId)
                .then(function () {
                    console.log('Partner account deleted')
                    that.$emit('remove', that.account.userId)
                    // that.$store.commit('showNotification', {type: 'info', message: that.$t('The login has been removed.')})
                })
                .catch(function (error) {
                    console.error(error)
                })
            this.showDropdown = false
        },
        deleteConfirm () {
            this.showDropdown = false
            this.showDeleteDialog = true
        },
        documentClick (e) {
            let el = this.$refs.dropDownEditMenu
            let target = e.target
            if (el && el !== target && !el.contains(target)) {
                this.showDropdown = false
            }
        }
    },
    validations: {
        changedAccount: {
            email: {
                required,
                email
            },
            password: {
                required: false,
                // goodPassword,
                minLength: minLength(8),
                maxLength: maxLength(32)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';

  .partner-login {
    max-width: 320px;
    width: 320px;
    margin: 10px;
    .edit-icons {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .edit-icon {
      cursor: pointer;
      color: $primary;
      font-size: large;
    }
    .card {
      height: 135px;
      padding: 10px 10px 30px 10px;
      border-radius: $border-radius;
      border: none;
      text-align: left;
      .columns {
        margin: 0 0 0 -0.7em;
      }
      &.has-dropdown {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &.edit-mode {
        height: 100%;
        .columns {
          .column {
            padding: 0 20px;
            margin: 40px 0;
            width: 90%;
            @media screen and (max-width: 767px) {
              border-left: 8px solid $primary;
            }
            @media screen and (min-width: 768px) {
              &:first-child {
                border-left: 8px solid $primary;
              }
            }
            .field {
              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }
      .field {
        p {
          &.email {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .control {
          border: none;
        }
        .select {
          border: none;
        }
      }
      .menu-overlay-background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(10, 10, 10, 0.75);
      }
      .card-title {
        color: $primary;
        font-weight: bold;
        text-transform: uppercase;
        border-top: none;
        text-align: left;
      }
      &.inactive {
        background-color: $secondary;
        opacity: 0.75;
        .card-title {
          color: $color-error-dark;
        }
        .columns {
          background-color: $secondary;
          opacity: 0.75;
        }
      }
      .select {
        border: none;
      }
    }
    .dropdown {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 20;
      .dropdown-trigger {
        color: $primary;
        font-weight: bold;
        position: absolute;
        top: 0;
        right: 10px;
        cursor: pointer;
        span {
          font-weight: 600;
          font-size: 18px;
        }
      }
      .dropdown-menu {
        position: relative;
        margin-top: 20px;
        .dropdown-content {
          border-radius: $border-radius;
          .dropdown-item {
            text-align: center;
            padding: 0.375rem 1rem;
            text-transform: uppercase;
            color: $primary;
            border-bottom: 1px solid #CCC;
            &.is-danger {
              color: $color-error-dark;
            }
            &:last-of-type {
              border-bottom: none;
            }
          }
          .dropdown hr {
            height: 0px;
          }
        }
      }
    }
    .control-buttons {
      padding: 0;
      width: 50px;
      display: inline-block;
      position: absolute;
      right: -40px;
      top: 15px;
      button {
        float: right;
        margin-bottom: 10px;
        font-size: 18px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        vertical-align: middle;
        &.cancel-button {
          color: $primary;
          background-color: #FFFFFF;
          border: 2px solid $primary;
        }
        &.save-button {
          color: #FFFFFF;
          background-color: $primary;
        }
      }
    }
    p.error-message {
      bottom: 0;
    }
    .password-strength {
      height: 3px;
      &.worst {
        background: darkred;
        width: 20%;
      }
      &.bad {
        background: orangered;
        width: 40%;
      }
      &.weak {
        background: orange;
        width: 60%;
      }
      &.good {
        background: yellowgreen;
        width: 80%;
      }
      &.strong {
        background: green;
        width: 100%;
      }
    }
    .child.dialog.modal {
      position: absolute;
      .modal-content {
        width: 90%;
        height: 90%;
        margin: 0;
        background-color: #ffffff;
        padding: 20px;
        border-radius: $border-radius;
        p {
          text-align: center;
          &.title {
            font-size: large!important;
          }
        }
        .button {
          text-transform: none!important;
          &.cancel {
            position: absolute;
            bottom: 10px;
            left: 10px;
            background-color: $color-shadow-light;
          }
          &.confirm {
            position: absolute;
            bottom: 10px;
            right: 10px;
          }
        }
      }
    }
  }
  .partner-login ::v-deep input, .partner-login ::v-deep .input, .partner-login ::v-deep .control {
    height: 25px;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: $color-general-text;
    border-bottom: solid 1px $secondary;
  }
  .partner-login ::v-deep input {
    font-size: 14px;
    border-bottom: 1px solid $secondary;
  }
  .partner-login ::v-deep input:focus, .partner-login ::v-deep .input:focus {
    border-bottom: 1px solid $color-action;
  }
  .partner-login ::v-deep .select select {
    color: $color-general-text;
    font-size: 14px;
    background-color: transparent;
  }
</style>
