<template>
  <div class="support-section">
    <h2 class="component-title">
      {{ $t("Support") }}
    </h2>
    <p class="component-description">
      {{ $t("Support description") }}
    </p>
    <v-container class="support-block">
      <v-row>
        <v-col>
          <helpdesk
            v-if="hasPermission('CustomerHelpdesk')"
            :account="account"
            :user="user"
            :enable-access-token-generation="enableAccessTokenGeneration"
          />
        </v-col>
        <v-col>
          <reset-password
            v-if="hasPermission('CustomerPassword')"
            :account="account"
            :exclusive-third-party-authentication="exclusiveThirdPartyAuthentication"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script charset="utf-8">
import Helpdesk from '@/components/account/Helpdesk'
import ResetPassword from '@/components/account/ResetPassword'

export default {
    name: 'Support',
    components: {
        Helpdesk,
        ResetPassword,
    },
    props: [
        'user',
        'account',
        'enableAccessTokenGeneration',
        'exclusiveThirdPartyAuthentication',
    ],
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/custom";

.support-section {
  @media screen and (min-device-width: 460px) {
    margin: 50px 20px;
  }
  padding-bottom: 40px;
}

.support-block {
  padding: 0;
}
</style>
