const state = {
    session: null,
    crumbs: []
}

const getters = {
    session: state => {
        if (state.session === null) return null
        return state.session
    },
    sessionId: state => {
        if (state.session === null) return null
        return state.session.SessionId
    },
    partnerId: state => {
        if (state.session === null) return null
        return state.session.PartnerId
    },
    accountId: state => {
        if (state.session === null) return null
        return state.session.Account
    },
    companyName: state => {
        if (state.session === null) return null
        return state.session.Name
    },
    crumbs: state => {
        return state.crumbs
    }
}

const mutations = {
    session: (state, value) => {
        state.session = value
    },
    crumbs: (state, value) => {
        state.crumbs = value
    }
}

export default {
    state,
    getters,
    mutations
}
