<template>
  <div class="number-spinner-root field is-grouped">
    <div class="field is-grouped">
      <v-btn
        color="primary"
        outlined
        class="mt-1"
        @click="decrement()"
      >
        <v-icon>fas fa-minus</v-icon>
      </v-btn>
      <input
        v-model="value"
        type="text"
        class="number-spinner"
        :initValue="value"
        :min="min"
        :max="max"
        :step="step"
        @keydown.up="increment"
        @keydown.down="decrement"
        @wheel="onWheel"
      >
      <v-btn
        color="primary"
        outlined
        class="mt-1"
        @click="increment()"
      >
        <v-icon>fas fa-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
    name: 'NumberSpinner',
    props: {
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 99999
        },
        step: {
            type: Number,
            default: 1
        },
        initValue: {
            type: [String, Number],
            default: 0
        }
    },
    data () {
        return {
            value: this.initValue ? parseInt(this.initValue) : 0
        }
    },
    watch: {
        initValue () {
            if (this.initValue) {
                this.value = parseInt(this.initValue)
            }
        },
        value: function (newVal, oldVal) {
            if (newVal < this.min) {
                this.value = this.min
            }
            if (newVal > this.max) {
                this.value = this.max
            }
            this.$emit('value-changed', newVal)
        },
        max: function () {
            if (this.value > this.max) {
                this.value = this.max
            }
        }
    },
    methods: {
        increment: function () {
            if (this.value < this.max) {
                this.value = parseInt(this.value) + this.step
            }
        },
        decrement: function () {
            if (this.value > this.min) {
                this.value = parseInt(this.value) - this.step
            }
        },
        onWheel: function (e) {
            e.deltaY < 0 ? this.increment() : this.decrement()
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/main';

  .number-spinner-root {
    justify-content: center;
    button {
      color: var(--v-primary-base) !important;
      border: none;
      font-size: 20px!important;
      min-width: 30px!important;;
    }
    input {
      color: var(--v-primary-base) !important;
      border: 1px solid black !important;
      height: 48px;
      padding: 10px;
      border-radius: 4px;
      width: 50px;
      background-color: white;
      text-align: center;
    }
  }
</style>
