<template>
  <div class="columns content-block has-shadow has-rounded-corners">
    <div class="column">
      <v-data-table
        :headers="headers"
        :items="filteredAddonSubscriptions"
        item-key="sku"
        hide-default-header
        :footer-props="{ 'items-per-page-text': '', 'items-per-page-options': []}"
        dense
      >
        <template #header="{ props, on }">
          <thead>
            <tr>
              <th
                v-for="(header, index) in props.headers"
                :key="index"
                class="primary--text table-head"
              >
                <a @click="on.sort(header.value)">
                  {{ $t(header.text) }}
                </a>
              </th>
            </tr>
          </thead>
        </template>
        <template #item="{ item }">
          <tr :key="item.subscriptionId" class="table-row">
            <td class="table-cell">
              <span class="d-block">{{ addonSubscriptionName(item) }}</span>
              <span class="grey--text">{{ sku(item) }}</span>
            </td>
            <td class="table-cell">
              {{ cameraName(item) }}
            </td>
            <td class="table-cell">
              {{ cameraId(item) }}
            </td>
            <td class="table-cell">
              <v-menu
                v-if="hasPermission('CustomerAccount')"
                offset-y
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    @click.stop="on.click"
                  >
                    <v-icon
                      size="1em"
                      color="primary"
                    >
                      fa fa-ellipsis-h
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="assingCamera(item)" v-if="!hasCameraAssigned(item)">
                    <v-list-item-title>{{ $t('Assign camera') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="unassignCamera(item)" v-if="hasCameraAssigned(item)">
                    <v-list-item-title>{{ $t('Unassign camera') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="red--text" @click="removeAddonSubscription(item)" v-if="!hasCameraAssigned(item)">
                    <v-list-item-title>{{ $t('Remove add-on subscription') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script charset="utf-8">

const restapi = require('@/service/restapi')

export default {
    name: 'AddonSubscriptionsTable',
    props: ['addonSubscriptions', 'filter', 'cameras'],
    data () {
        return {
            loadingDetails: [],
            headers: [
                { text: 'Add-on subscription', value: 'sku'},
                { text: 'Camera name', value: 'cameraName' },
                { text: 'Camera id', value: 'cameraId' },
                { text: '', value: '' },
            ],
        }
    },
    computed: {
        filteredAddonSubscriptions () {
            let subscriptions = this.addonSubscriptions || []
            if (this.filter) {
                subscriptions = subscriptions.filter(item => {
                    const filterLower = this.filter.toLowerCase()
                    return (item.sku && item.sku.toLowerCase().includes(filterLower)) ||
                        (item.cameraId && item.cameraId.toString().toLowerCase().includes(filterLower)) ||
                        (item.cameraName && item.cameraName.toString().toLowerCase().includes(filterLower))
                })
            }
            return subscriptions
        },
        resellerId () {
            return this.$route.params.resellerid
        },
        accountId () {
            return this.$route.params.accountid
        },
        userId () {
            return this.$route.params.userid
        }
    },
    methods: {
        async assingCamera (subscription) {
            this.$emit('onAssignCamera', subscription) 
        },
        async unassignCamera (subscription) {  
            try {
                const payload = { 
                    cameraId: null,
                }
                await restapi.updateAccountAddonSubscription(this.resellerId, this.accountId, subscription.subscriptionId, payload) 
                this.$emit('onCameraUnassigned', subscription) 
            } catch (error) {
                console.error(error)
                this.$store.commit('toastMessage', {
                    showing: true,
                    text: this.getMessageFromError(error, 'UPDATE_ADDON_SUBSCRIPTION'),
                    timeout : -1,
                    color: 'error',
                    support: true
                })
            }
        },
        async removeAddonSubscription (subscription) {
            try {
                await restapi.deleteAccountSubscription(this.resellerId, this.accountId, subscription.subscriptionId) 
                this.$emit('onAddonSubscriptionRemoved', subscription) 
            } catch (error) {
                console.error(error)
                this.$store.commit('toastMessage', {
                    showing: true,
                    text: this.getMessageFromError(error, 'DELETE_ACCOUNT_SUBSCRIPTION'),
                    timeout : -1,
                    color: 'error',
                    support: true
                })
            }
        },
        cameraName (subscription) {
            return subscription.cameraName ? subscription.cameraName : '-'
        },
        cameraId (subscription) {
            return subscription.cameraId ? subscription.cameraId : '-'
        },
        sku (subscription) {
            return subscription.sku ? subscription.sku : '-'
        },
        hasCameraAssigned (subscription) {
            return subscription.cameraId
        },
        addonSubscriptionName(subscription) {
            return subscription.name ? subscription.name : '-'
        } 
    }
}
</script>

<style lang="scss" scoped>
  @import '../../../../assets/styles/forms';
  @import '../../../../assets/styles/custom';

  .table-head {
    border-bottom-color: var(--v-primary-lighten1) !important;
    border-bottom-width: 2px !important;
  }

  .table-row {
    &:nth-child(even) {
      background-color: #f8f8f8;
    }
    .table-cell {
      padding: 10px;
      vertical-align: middle;
      font-size: 0.9em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: none !important;
      &:hover {
        cursor: pointer;
      }
    }
  }

  #addon-subscriptions-table ::v-deep {
      table {
        &.table {
          tbody > tr {
            box-shadow: none;
            cursor: default;
            td {
              vertical-align: inherit;
              a.dropdown-item {
                padding: 0.75rem 1rem;
                text-align: center;
              }
              a.dropdown-item.dropdown-remove-subscription {
                color: $color-warning;
              }
            }
          }
          td {
            border: none;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          thead th {
            border-bottom: 2px solid $border-blue-light;
            padding: 6px 4px!important;
            .th-wrap {
              color: $primary;
              text-transform: uppercase;
            }
            &.is-current-sort {
              border-bottom-color: $border-blue;
              span.icon {
                i.fa.fa-arrow-up {
                  &:before {
                    content: "\f0d8";
                  }
                }
              }
            }
          }
          th.is-sortable:hover {
            border-color: $primary;
          }

          tbody td {
            border: none;
          }
        }
        &.is-striped {
          tbody > tr {
            &.detail {
              .detail-container {
                margin: 0 0 0 30px;
                .field {
                  margin: 0;
                  position: relative;
                  .has-float-label {
                    p {
                      &.content {
                        font-size: 12px;
                        padding: 5px 0;
                        margin: 0;
                        height: 22px;
                      }
                    }
                    span {
                      position: absolute;
                      top: -10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
</style>
