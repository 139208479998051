<template>
  <section>
    <div v-if="enabled">
      <v-form
        id="mc-embedded-subscribe-form"
        :action="action"
        method="post"
        name="mc-embedded-subscribe-form"
        class="mc-subscribe-form validate"
        target="_blank"
        novalidate
      >
        <v-card>
          <v-list-item class="primary">
            <v-list-item-content>
              <v-list-item-title class="headline white--text">
                {{ $t("Subscribe for newsletter") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <p>{{ $t('Provide the best experience for your customers and stay up to date with our Dealer Dashboard newsletter. We’ll update you about new releases and maintenance.') }}</p>
            <v-text-field
              id="mce-EMAIL"
              required
              :value="email"
              :label="$t('Email address')"
              name="EMAIL"
              class="required email"
            />
            <v-text-field
              id="mce-FNAME"
              required
              value=""
              :label="$t('First name')"
              name="FNAME"
              class="required email"
            />
            <v-text-field
              id="mce-LNAME"
              required
              value=""
              :label="$t('Last name')"
              name="LNAME"
              class="required email"
            />
            <div class="mc-field-group input-group">
              <strong>{{ $t("Receive messages about the following topics:") }} </strong>
              <div
                v-for="(group, index) in groups"
                :key="`g-${index}`"
                class="field"
              >
                <v-row dense>
                  <v-col cols="auto">
                    <v-checkbox
                      :id="groupCheckboxId(index)"
                      :value="group.value"
                      :name="groupCheckboxName(group.value)"
                      :label="$t(group.name)"
                    />
                  </v-col>
                  <v-col cols="auto">
                    <v-tooltip
                      color="primary"
                      max-width="200px"
                      top
                    >
                      <template #activator="{ on }">
                        <v-icon
                          size="1em"
                          color="primary"
                          v-on="on"
                        >
                          fa fa-info-circle
                        </v-icon>
                      </template>
                      <span>{{ $t(group.description) }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div
              id="mce-responses"
              class="clear"
            >
              <div
                id="mce-error-response"
                class="response"
                style="display:none"
              />
              <div
                id="mce-success-response"
                class="response"
                style="display:none"
              />
            </div>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div
              style="position: absolute; left: -5000px;"
              aria-hidden="true"
            >
              <input
                type="text"
                :name="botFieldName"
                tabindex="-1"
                value=""
              >
            </div>
          </v-card-text>
          <v-footer class="align-right">
            <v-card
              class="d-flex ml-auto ma-2"
              flat
              tile
            >
              <v-btn
                large
                outlined
                class="mr-2"
                color="primary"
                type="button"
                @click="closeDialog()"
              >
                {{ $t("Close") }}
              </v-btn>
              <v-btn
                id="mc-embedded-subscribe"
                large
                color="primary"
                :value="$t('Subscribe me now')"
                type="submit"
                name="subscribe"
              >
                {{ $t('Subscribe me now') }}
              </v-btn>
            </v-card>
          </v-footer>
        </v-card>
      </v-form>
    </div>
    <div v-else>
      <v-card>
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="headline white--text">
              {{ $t("Subscribe for newsletter") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <p>{{ $t('Provide the best experience for your customers and stay up to date with our Dealer Dashboard newsletter. We’ll update you about new releases and maintenance.') }}</p>
        </v-card-text>
        <v-footer class="align-right">
          <v-card
            class="d-flex ml-auto ma-2"
            flat
            tile
          >
            <v-btn
              large
              outlined
              class="mr-2"
              color="primary"
              type="button"
              @click="closeDialog()"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              large
              color="primary"
              @click="openSignupScreen"
            >
              {{ $t('Go to signup form') }}
            </v-btn>
          </v-card>
        </v-footer>
      </v-card>
    </div>
  </section>
</template>

<script>
// import axios from 'axios'

export default {
    name: 'MailChimp',
    props: [ 'closeDialog' ],
    data () {
        return {
            enabled: false,
            baseUrl: '//eagleeyenetworks.us18.list-manage.com',
            formUrl: '//eepurl.com/dvJ441',
            user: 'fd2fd96806767fa0be833b703',
            id: '96c1f6505c',
            groupId: 509,
            groups: [
                {
                    value: 1,
                    name: 'Release Notes',
                    description: 'Release Notes description'
                },
                {
                    value: 2,
                    name: 'Maintenance',
                    description: 'Maintenance description'
                }
            ]
        }
    },
    computed: {
        email () {
            return this.$store.getters.myAccount.email
        },
        action () {
            return `${this.baseUrl}/subscribe/post?u=${this.user}&amp;id=${this.id}`
        },
        botFieldName () {
            return `b_${this.user}_${this.id}`
        }
    },
    created () {
        const that = this
        let mcIcon = document.createElement('img')
        document.body.appendChild(mcIcon)
        mcIcon.setAttribute('id', 'mcIcon')
        mcIcon.setAttribute('src', `${this.baseUrl}/favicon.ico`)
        mcIcon.style.cssText = 'display: none;'
        mcIcon.onload = mcIcon.onreadystatechange = function () {
            const icon = document.getElementById('mcIcon')
            that.enabled = icon.width > 1
            if (that.enabled) {
                let mcValidateScript = document.createElement('script')
                mcValidateScript.setAttribute('src', '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js')
                document.head.appendChild(mcValidateScript)
                window.fnames = ['EMAIL', 'FNAME', 'LNAME']
                window.ftypes = ['email', 'text', 'text']
            }
        }
    },
    methods: {
        groupCheckboxName (value) {
            return `group[${this.groupId}][${value}]`
        },
        groupCheckboxId (index) {
            return `mce-group[${this.groupId}]-${this.groupId}-${index}`
        },
        openSignupScreen () {
            window.open(this.formUrl, '_blank')
            this.$parent.close()
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';

  .field {
    i {
      vertical-align: -1.5em;
    }
  }

  .mc-subscribe-form {
    .control {
      border-bottom: none;
    }
    .checkbox, .radio, .response {
      color: $color-general-text;
      .fa.fa-info-circle {
        color: $primary;
        margin-left: 20px;
      }
    }
    #mce-error-response.response {
      color: $color-error-dark;
      font-size: large;
      font-weight: 600;
    }
    #mce-success-response.response {
      color: $color-general-text;
      font-size: large;
      font-weight: 600;
    }
  }
</style>
