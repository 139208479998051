<template>
  <div class="partner-modules">
    <h2>{{ $t("Partner modules") }}</h2>
    <p>{{ $t("To add or remove a module for this partner use the corresponding switch.") }}</p>
    <v-btn
      v-if="!editMode"
      large
      color="primary"
      @click="editMode = true"
    >
      {{ $t("Edit partner modules") }}
    </v-btn>
    <div v-else>
      <v-card>
        <v-row>
          <v-col>
            <partner-module
              v-for="(module, index) in modules"
              v-show="isVisible(module)"
              :key="module.name"
              :module="module"
              :index="index"
              @change="toggleModule(module)"
            />
          </v-col>
        </v-row>
        <div class="control-buttons">
          <v-btn
            id="cancel-partner-button"
            fab
            small
            color="primary"
            @click="cancelEditPartnerModules"
          >
            <v-icon dark>
              fas fa-times
            </v-icon>
          </v-btn>
          <v-btn
            id="save-partner-button"
            fab
            small
            color="primary"
            :disabled="!isDirty"
            @click="savePartnerModules"
          >
            <v-icon dark>
              fas fa-save
            </v-icon>
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import PartnerModule from '@/components/partner/PartnerModule'

const restapi = require('@/service/restapi')
const hash = require('object-hash')

export default {
    name: 'PartnerModules',
    components: {
        PartnerModule,
    },
    props: ['partner'],
    data() {
        return {
            isActive: false,
            editMode: false,
            modules: [],
            modulesHash: null,
            updateCount: 0,
            toActivate: [],
            toDeactivate: [],
            initValues: [],
        }
    },
    computed: {
        isDirty() {
            return hash(this.modules) !== this.modulesHash
        },
        partnerId() {
            if (this.partner) {
                return this.partnerType === 'Reseller' ? this.partner.resellerId : this.partner.distributorId
            }
            return null
        },
        partnerType() {
            if (this.partner) {
                return this.partner.hasOwnProperty('resellerId') ? 'Reseller' : 'Distributor'
            }
            return null
        },
        isReseller() {
            return this.partnerType === 'Reseller'
        },
    },
    watch: {
        partner: function (newVal) {
            if (newVal) {
                this.loadPartnerModules()
            }
        },
        editMode(newVal) {
            if (newVal) this.initValues = JSON.parse(JSON.stringify(this.modules))
        },
    },
    mounted() {
        if (this.partner) {
            this.loadPartnerModules()
        }
    },
    methods: {
        toggleModule(module) {
            module.active = !module.active
            if (module.active) {
                const i = this.toDeactivate.indexOf(module.name)
                if (i === -1) {
                    this.toActivate.push(module.name)
                } else {
                    this.toDectivate.splice(i, 1)
                }
            } else {
                const i = this.toActivate.indexOf(module.name)
                if (i === -1) {
                    this.toDeactivate.push(module.name)
                } else {
                    this.toActivate.splice(i, 1)
                }
            }
        },
        cancelEditPartnerModules() {
            this.editMode = false
            this.$emit('cancel')
            this.loadPartnerModules()
        },
        async savePartnerModules() {
            if (this.isDirty) {
                this.updateCount = this.toActivate.length + this.toDeactivate.length
                this.isLoading(true)
                let permissionUpdateObject = {}
                for (let i = 0; i < this.toActivate.length; i++) {
                    permissionUpdateObject[this.toActivate[i]] = true
                }
                for (let i = 0; i < this.toDeactivate.length; i++) {
                    permissionUpdateObject[this.toDeactivate[i]] = false
                }
                this.toActivate = []
                this.toDeactivate = []
                try {
                    await restapi.updateProfileProfile(this.partnerId, permissionUpdateObject)
                    this.isLoading(false)
                    this.editMode = false
                    this.$store.commit('toastMessage', {
                        showing: true,
                        text: this.$t('Updated partner modules'),
                        timeout: 4000,
                        color: 'primary',
                    })
                    this.loadPartnerModules()
                    this.$emit('updated')
                } catch (error) {
                    this.isLoading(false)
                    this.editMode = false
                    this.$store.commit('toastMessage', {
                        showing: true,
                        text: this.$t('Something went wrong while updating your partner modules'),
                        timeout: -1,
                        color: 'error',
                        support: true,
                    })
                    this.modules = this.initValues
                }
            } else {
                this.cancelEditPartnerModules()
            }
        },
        loadPartnerModules() {
            const that = this
            restapi
                .getResellerModules(this.partnerId)
                .then(function (result) {
                    that.modules = that.transformData(result)
                    that.modulesHash = hash(that.modules)
                    that.updateCount = 0
                    that.toActivate = []
                    that.toDeactivate = []
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        transformData(data) {
            let transformed = []
            for (let module in data) {
                transformed.push({ name: module, active: data[module] })
            }
            return transformed
        },
        isVisible(module) {
            return this.editMode && this.$t(module.name) !== 'DELETE'
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/forms";

.partner-modules {
  width: 100%;
  margin: 20px 0;
  padding-bottom: 50px;
  h2 {
    color: $primary;
    text-transform: uppercase;
    font-size: 14px;
    margin: 50px 0 calc(20px + 0.75rem) 0;
  }
  .columns {
    &.title-block {
      padding: 20px 20px 0 20px;
      background-color: transparent;
      p {
        font-size: 12px;
      }
    }
    &.module-label {
      padding: 0 20px;
      p {
        color: $secondary;
        font-size: 12px;
        text-transform: uppercase;
      }
      .column {
        padding-bottom: 0;
      }
    }
    &.modules {
      padding: 20px 20px 20px 0;
      .column {
        @media screen and (max-width: 767px) {
          border-left: 8px solid $primary;
        }
        @media screen and (min-width: 768px) {
          &:first-child {
            border-left: 8px solid $primary;
          }
        }
        .right {
          &:not(:last-child) {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid $secondary;
          }
        }
      }
    }
  }
  &.edit-mode {
    position: relative;
    padding-top: 60px;
    padding-bottom: 40px;
    max-width: 900px;
    width: 900px;
    margin: 0 auto;
  }
  .edit-icons {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .edit-icon {
    cursor: pointer;
    color: $primary;
    font-size: large;
  }
  .card {
    height: 135px;
    padding: 10px 10px 30px 10px;
    border-radius: $border-radius;
    border: none;
    &.has-dropdown {
      background-color: rgba(0, 0, 0, 0.2);
    }
    &.edit-mode {
      height: auto;
      width: 900px;
      max-width: 900px;
    }
    .field {
      .control {
        border: none;
      }
      .select {
        border: none;
      }
    }
    .menu-overlay-background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(10, 10, 10, 0.75);
    }
    .card-title {
      color: $primary;
      font-weight: bold;
      text-transform: uppercase;
      border-top: none;
      text-align: left;
    }
    .card-content {
      font-size: x-large;
    }
    .rights {
      margin: 20px;
      h3 {
        color: $primary;
        text-transform: uppercase;
        font-size: 1.2em;
        font-weight: bold;
      }
    }
    &.inactive {
      background-color: $secondary;
      opacity: 0.75;
      .card-title {
        color: $color-error-dark;
      }
    }
    .select {
      border: none;
    }
  }
  .dropdown {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    .dropdown-trigger {
      color: $primary;
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 10px;
      cursor: pointer;
      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
    .dropdown-menu {
      position: relative;
      margin-top: 20px;
      .dropdown-content {
        border-radius: $border-radius;
        .dropdown-item {
          text-align: center;
          padding: 0.375rem 1rem;
          text-transform: uppercase;
          color: $primary;
          &.is-danger {
            color: $color-error-dark;
          }
        }
        .dropdown hr {
          height: 1px;
        }
      }
    }
  }
  .control-buttons {
    padding: 0;
    width: 50px;
    display: inline-block;
    position: absolute;
    right: -30px;
    top: 50px;
    button {
      float: right;
      margin-bottom: 10px;
      font-size: 18px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      vertical-align: middle;
      &.cancel-button {
        color: $primary;
        background-color: #ffffff;
        border: 2px solid $primary;
      }
      &.save-button {
        color: #ffffff;
        background-color: $primary;
      }
    }
  }
  p.error-message {
    bottom: 0;
  }
  .password-strength {
    height: 3px;
    &.worst {
      background: darkred;
      width: 20%;
    }
    &.bad {
      background: orangered;
      width: 40%;
    }
    &.weak {
      background: orange;
      width: 60%;
    }
    &.good {
      background: yellowgreen;
      width: 80%;
    }
    &.strong {
      background: green;
      width: 100%;
    }
  }
  .child.dialog.modal {
    .modal-content {
      width: 90%;
      height: 90%;
      margin: 0;
      background-color: #ffffff;
      padding: 20px;
      border-radius: $border-radius;
      p {
        text-align: center;
        &.title {
          font-size: large !important;
        }
      }
      .button {
        text-transform: none !important;
        &.cancel {
          position: absolute;
          bottom: 10px;
          left: 10px;
          background-color: $color-shadow-light;
        }
        &.confirm {
          position: absolute;
          bottom: 10px;
          right: 10px;
        }
      }
    }
  }
}
.partner-login ::v-deep input,
.partner-login ::v-deep .input,
.partner-login ::v-deep .control {
  height: 25px;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: $color-general-text;
  border-bottom: solid 1px $secondary;
}
.partner-login ::v-deep input {
  font-size: 14px;
  border-bottom: 1px solid $secondary;
}
.partner-login ::v-deep input:focus,
.partner-login ::v-deep .input:focus {
  border-bottom: 1px solid $color-action;
}
.partner-login ::v-deep .select select {
  color: $color-general-text;
  font-size: 14px;
  background-color: transparent;
}
</style>
