<template>
  <div
    class="user-info-tab-bar"
    :class="{'user-blocked': isUserBlocked}"
  >
    <div class="columns">
      <div class="column">
        <div class="menu-title">
          {{ menuTitle }}
        </div>
      </div>
      <div class="column is-narrow">
        <div
          v-if="isUserBlocked"
          class="user-blocked-label uppercase"
        >
          {{ $t("Blocked") }}
        </div>
        <div class="full-name">
          {{ getUserFullName }}
        </div>
        <div class="company">
          {{ getCompanyName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
export default {
    name: 'UserInfoTopBar',
    props: ['customer', 'menuTitle'],
    data () {
        return {
        }
    },
    computed: {
        getUserFullName () {
            let fullname = ''
            if (this.customer) {
                if (this.customer.FirstName) fullname += this.customer.FirstName + ' '
                if (this.customer.LastName) fullname += this.customer.LastName
            }
            return fullname
        },
        getCompanyName () {
            let companyName = ''
            if (this.customer && this.customer.Company) {
                companyName = this.customer.Company
            }
            return companyName
        },
        isUserBlocked () {
            return this.customer && this.customer.IsBlocked === 'yes'
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/main';

  .menu-title {
    color: $primary;
    font-size: 20px;
    font-weight: bold;
    line-height: 23px;
  }

  .full-name {
    color: $primary;
    font-size: 36px;
    line-height: 42px;
  }

  .company {
    color: $secondary;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
  }

  .user-info-tab-bar {
    padding: 25px;
  }

  .user-blocked .full-name, .user-blocked-label {
    color: $color-error-dark;
    text-align: center;
  }
</style>
