<template>
  <div class="zoho-create-view-section columns">
    <div class="column is-half is-offset-one-quarter">
      <header>
        <div class="distributor-select">
          <h1>{{ getTitleName }}</h1>
          <div>
            <div
              v-if="showDistributorSelect"
              class="distributor-select"
            >
              <v-select
                v-model="selectedDistributor"
                :items="selectedDistributors" 
                :label="$t('Select a distributor')"
                return-object
                @change="onSelectDistributor"
              />
            </div>
            <div
              v-if="showResellerSelect"
              class="reseller-select"
            >
              <v-select
                v-model="selectedReseller"
                :items="selectedResellers" 
                :label="$t('Select a reseller')"
                return-object
                @select="onSelectReseller"
              />
            </div>
          </div>
          <div>
            <v-btn
              large
              color="primary"
              :disabled="!isButtonEnabled"
              @click="onButtonClick"
            >
              {{ $t('Continue') }}
            </v-btn>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
import { getConfiguration } from '@/service/main'

const restapi = require('@/service/restapi')

export default {
    name: 'Zoho',
    data () {
        return {
            entityType: null,
            newEntity: {},
            entity: {},
            showSelectDistributor: false,
            showSelectReseller: false,
            selectedDistributor: { value: -1, text: null },
            selectedReseller: { value: -1, text: null },
            distributors: [],
            resellers: []
        }
    },
    computed: {
        isButtonEnabled () {
            let enabled = false
            if (this.showSelectDistributor) {
                if (this.showSelectReseller) {
                    enabled = this.selectedReseller.value !== -1
                } else {
                    enabled = this.selectedDistributor.value !== -1
                }
            }
            return enabled
        },
        getTitleName () {
            return this.showSelectReseller ? this.$t('Select a distributor and a reseller') : this.$t('Select a distributor')
        },
        showDistributorSelect () {
            return this.showSelectDistributor
        },
        showResellerSelect () {
            return this.showSelectReseller
        },
        id () {
            return this.$route.params.id
        },
        route () {
            let path = this.$route.path.split('/')
            path.shift()
            return path.length > 0 ? path[0] : 'Unknown'
        },
        validAction () {
            return this.route === 'create' || this.route === 'view'
        },
        title () {
            const route = this.route.charAt(0).toUpperCase() + this.route.slice(1)
            return this.entityType ? this.$t(route) + ' ' + this.$t(this.entityType) : ''
        },
        selectedDistributors () {
            let distributors = []
            for (let i = 0; i < this.distributors.length; i++) {
                const distributor = this.distributors[i]
                if (distributor.crmId === null) {
                    distributors.push({
                        value: distributor.distributorId,
                        text: distributor.companyName
                    })
                }
            }
            return distributors.sort(function (a, b) { return (a.text > b.text) ? 1 : ((a.text < b.text) ? -1 : 0) })
        },
        selectedResellers () {
            let resellers = []
            for (let i = 0; i < this.resellers.length; i++) {
                const reseller = this.resellers[i]
                resellers.push({
                    value: reseller.resellerId,
                    text: reseller.companyName
                })
            }
            return resellers.sort(function (a, b) { return (a.text > b.text) ? 1 : ((a.text < b.text) ? -1 : 0) })
        }
    },
    watch: {
        '$route' (to, from) {
            this.viewOrCreateEntity()
        },
        selectedDistributor: function (newVal, oldVal) {
            if (newVal) {
                const config = getConfiguration()
                this.selectedReseller = { value: -1, text: null }
                this.resellers = []
                const that = this
                restapi.getResellers(this.selectedDistributor.value)
                    .then(function (resellers) {
                        that.resellers = resellers.filter(function (p) {
                            if (that.selectedDistributor.value === config.eenEmeaDistributorId) {
                                return (p.resellerId === config.eenEmeaDirectResellerId || p.resellerId === config.eenEmeaEmployeeResellerId)
                            } else if (that.selectedDistributor.value === config.eenUsaDistributorId) {
                                return p.resellerId === config.eenUsaDirectResellerId || p.resellerId === config.eenUsaEmployeeResellerId
                            } else if (that.selectedDistributor.value === config.eenIndiaDistributorId) {
                                return p.resellerId === config.eenIndiaDirectResellerId || p.resellerId === config.eenIndiaEmployeeResellerId
                            } else if (that.selectedDistributor.value === config.eenTestDistributorId) {
                                return p.resellerId === config.eenEmeaEmployeeResellerId
                            } else {
                                return false
                            }
                        })
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
            }
        }
    },
    created () {
        this.viewOrCreateEntity()
    },
    methods: {
        onDistributorType (id) {
            this.$router.push({path: `/admin/add-distributor/${id}`})
        },
        onResellerType () {
            this.showSelectDistributor = true
            this.fetchDistributors()
        },
        onEndUserType () {
            this.showSelectDistributor = true
            this.showSelectReseller = true
            this.fetchDistributors()
        },
        fetchDistributors () {
            const config = getConfiguration()
            const that = this
            restapi.getDistributors()
                .then(function (distributors) {
                    if (that.showSelectReseller) {
                        that.distributors = distributors.filter(function (p) {
                            return p.distributorId === config.eenEmeaDistributorId || p.distributorId === config.eenUsaDistributorId || p.distributorId === config.eenIndiaDistributorId  || p.distributorId === config.eenTestDistributorId
                        })
                    } else {
                        that.distributors = distributors.filter(function (p) {
                            return p.distributorId === config.eenEmeaDistributorId || p.distributorId === config.eenUsaDistributorId || p.distributorId === config.eenIndiaDistributorId
                        })
                    }
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        getEntity () {
            const that = this
            restapi.getEntityByZohoId(this.id)
                .then(function (result) {
                    that.entity = result
                    if (result.entityType === 'reseller' || result.entityType === 'distributor') {
                        that.$router.push({path: `/${that.entity.entityType.toLowerCase()}/${that.entity.entityId}`})
                    } else if (result.entityType === 'customer') {
                        restapi.searchUser(that.entity.entityId)
                            .then(function (result) {
                                let endUser = result.find(function (u) { return u.userId === that.entity.entityId })
                                if (endUser) {
                                    that.$router.push({path: `/user/${endUser.resellerId}/${endUser.accountId}/${endUser.userId}/customer-details`})
                                } else {
                                    that.$store.commit('toastMessage', {
                                        showing: true,
                                        text: that.$t('Could not find a customer with the given id.'),
                                        timeout : -1,
                                        color: 'error',
                                        support: true
                                    })
                                }
                            })
                            .catch(function (error) {
                                console.error(error)
                                throw error
                            })
                    }
                })
                .catch(function (error) {
                    console.error(error)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.$t('Could not find a distributor, reseller or customer with the given id.'),
                        timeout : -1,
                        color: 'error',
                        support: true
                    })
                })
        },
        createEntity () {
            const that = this
            restapi.getZohoAccountById(this.id)
                .then(function (result) {
                    if (result.type === 'Distributor') {
                        that.onDistributorType(that.id)
                    } else if (result.type === 'Reseller') {
                        that.onResellerType()
                    } else if (result.type === 'End User') {
                        that.onEndUserType()
                    }
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        viewOrCreateEntity () {
            if (this.id && this.route === 'view') {
                this.getEntity()
            } else if (this.id && this.route === 'create') {
                this.createEntity()
            }
        },
        onSelectDistributor (distributor) {
            this.selectedDistributor = distributor
        },
        onSelectReseller (reseller) {
            this.selectedReseller = reseller
        },
        addReseller () {
            if (this.selectedDistributor) {
                this.$router.push({path: `/distributor/${this.selectedDistributor.value}/add-reseller/${this.id}`})
            }
        },
        addEndUser () {
            if (this.selectedReseller) {
                this.$router.push({path: `/reseller/${this.selectedReseller.value}/add-customer/${this.id}`})
            }
        },
        onButtonClick () {
            if (this.showSelectDistributor) {
                if (this.showSelectReseller) {
                    this.addEndUser()
                } else {
                    this.addReseller()
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";

  .distributor-select {
    padding: 20px 0 30px;
  }

  .reseller-select {
    padding-bottom: 30px;
  }

  .zoho-create-view-section {
    width: 90%;
    margin: 20px auto;
    header {
      text-align: center;
      h1 {
        color: $primary;
        text-transform: uppercase;
        font-size: 18px;
      }
    }
    .modal {
      z-index: 1000;
    }
    .add-entity-wizard ::v-deep {
      .wizard {
        width: 90%;
        margin: 20px 0;
        .wizard__steps, .wizard__arrow {
          display: none !important;
        }
        .wizard__body {
          border: none!important;
        }
        .wizard__body__actions {
          border-top: none !important;
          background-color: #ffffff !important;
          .wizard__next, .wizard__back {
            border-radius: $border-radius;
            text-transform: uppercase;
            background-color: $primary;
            transition: none;
            img {
              display: none !important;
            }
          }
        }
        .wizard__body__step {
          padding: 2px 8px;
          .step-title {
            color: $primary;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
        .wizard-validation-error {
          .wizard__next {
            opacity: 0.5;
          }
        }
      }
      .final-step {
        background-color: $color-action!important;
      }
      .user-type-selection {
        margin-top: 70px;
        text-align: center;
        h2 {
          margin-bottom: 70px;
        }
        button {
          margin-right: 25px;
        }
      }
      .add-user-type-button {
        position: relative;
        background-color: #FFFFFF;
        border: 1px solid $color-action;
        border-radius: 4px;
        color: $color-action;
        font-weight: bold;
        min-width: 85px;
        height: 50px;
        padding: 5px 14px;
      }
      .add-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: fixed;
        top: 40px;
        left: 50%;
        z-index: 1000;
        transform: translateX(-50%);
      }
      .add-new-button {
        position: relative;
        background-color: #FFFFFF;
        border: 2px solid $color-action;
        top: 0;
        color: $color-action;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        &:focus {
          outline: none;
        }
        .icon {
          width: auto;
          height: auto;
          color: $color-action;
          i {
            color: $color-action;
            font-size: 26px;
          }
        }
      }
    }
    .add-new.modal-card {
      width: 1100px;
      height: 900px;
      .modal-card-head {
        background-color: white;
      }
      .modal-card-title {
        text-align: center;
      }
      .modal-card-body {
        border-radius: 0 0 $border-radius $border-radius;
      }
    }
  }
</style>
