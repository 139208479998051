<template>
  <v-card
    width="300px"
    height="255px"
  >
    <v-card-title class="report-header subtitle-1">
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ description }}
      <v-row
        v-if="currentReport"
        class="reports-date"
      >
        <v-col>
          {{ $t('Latest generated') }}
        </v-col>
        <v-col class="text-right font-weight-bold">
          {{ latestGeneratedReportInfo }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        large
        block
        tile
        class="reports-button"
        color="primary"
        :disabled="!latestGeneratedReportDate"
        @click="downloadReport"
      >
        {{ buttonLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script charset="utf-8">
import { getTimeStamp } from '@eencloud/core-components/src/service/time'
const auth = require('@/service/auth')
const restapi = require('@/service/restapi')

export default {
    name: 'Report',
    props: ['resellerId', 'reportType', 'title', 'description', 'buttonLabel', 'downloadName'],
    data () {
        return {
            latestGeneratedReportDate: null,
            currentReport : false
        }
    },
    computed: {
        latestGeneratedReportInfo () {
            return this.latestGeneratedReportDate ? this.latestGeneratedReportDate : this.$t('Report not available')
        },
        partners () {
            return this.$store.getters.allResellers
        }
    },
    watch: {
        resellerId () {
            this.fetchLatestGeneratedReportInfo()
        }
    },
    mounted () {
        this.fetchLatestGeneratedReportInfo()
    },
    methods: {
        isReseller () {
            return auth.isReseller()
        },
        fetchLatestGeneratedReportInfo () {
            this.latestGeneratedReportDate = null
            const that = this
            restapi.getReportInfo(this.resellerId, this.reportType)
                .then(function (result) {
                    if(result.generationEndTime == null){
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.$t('Report for "today" is being generated. Old report is available for download.'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                        that.latestGeneratedReportDate = that.$t('Archive')
                    }
                    else
                    {
                        that.latestGeneratedReportDate = 
                        getTimeStamp(that.$store.getters.myPartner.timeZoneOffset, new Date(result.generationEndTime)).slice(0,10)
                        that.currentReport = true
                    }
                })
                .catch(function (error) {
                    if (!error.response || error.response.status !== 404) {
                        console.error(error)
                    }
                })
        },
        resellerName (partnerId) {
            let r
            if (auth.getPartnerId().toString() === partnerId.toString()) {
                r = this.$store.getters.myPartnerDetails
            } else {
                r = this.partners.find(function (p) { return p.resellerId  == partnerId })
            }
            if (r) return r.companyName
            return '-'
        },
        downloadReport () {
            console.log('Download report...')
            if (this.latestGeneratedReportDate) {
                this.isLoading(true)
                let that = this
                restapi.downloadReport(this.resellerId, this.reportType, this.resellerName(this.resellerId), this.latestGeneratedReportDate, this.downloadName)
                    .then(function () {
                        that.isLoading(false)
                    })
                    .catch(function (error) {
                        console.log(error)
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.getMessageFromError(error, 'ADD_ACCOUNT'),
                            timeout: -1,
                            color: 'error',
                            support: true
                        })
                        that.isLoading(false)
                    })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/main';

.reports-button{
  position: absolute;
  bottom:0;
  left:0
}

 .reports-date{
   position: absolute;
   bottom:35px;
   width: 100%;
   background: #f6f6f6;
   margin-left: -16px;
 }

  .report-header{
    color: var(--v-primary-base)
  }

</style>
