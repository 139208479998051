<template>
  <v-row>
    <v-col class="justify-centered">
      <p
        class="label"
        :class="[{'selected' : isSelected}]"
      >
        {{ leftLabel }}
      </p>
    </v-col>
    <v-col class="justify-centered">
      <v-switch
        v-model="value"
        inset
        class="center-align"
        dense
      />
    </v-col>
    <v-col class="justify-centered">
      <p
        class="label"
        :class="[{'selected' : !isSelected}]"
      >
        {{ rightLabel }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
    name: 'TwoSidesSwitch',
    props: {
        leftLabel: {
            type: String,
            default: 'Left side'
        },
        rightLabel: {
            type: String,
            default: 'Right side'
        },
        initValue: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            value: this.initValue
        }
    },
    computed: {
        isSelected () {
            return this.value
        }
    },
    watch: {
        value (value) {
            this.$emit('switch-changed', value)
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/main';

  p.label {
    display: table-cell;
    vertical-align: middle;
    padding: 16px 5px 5px 5px;
    text-transform: uppercase;
     font-weight: bold;
  }

  p.label.selected {
    color: lightgray;
    font-weight: bold;
  }

  .switch-element-column {
    text-align: center;
  }

</style>
