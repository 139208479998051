<template>
  <div
    id="partner-details"
    class="partner-details"
  >
    <contact-information
      v-if="!transferReseller"
      id="contact-information"
      :partner="partner"
      :partner-type="partnerType"
      :title="title"
    />
    <distributor-details
      v-if="!isMine && isReseller && isAdmin"
      :partner="partner"
      :transfer-reseller="transferReseller"
      @cancel="transferReseller=false"
      @transfered="resellerTransfered"
    />
    <v-btn
      v-if="!isMine && isReseller &&
        isAdmin && hasPermission('PartnerManage') && !transferReseller"
      large
      color="primary"
      @click="transferReseller = true"
    >
      {{ $t('Transfer to other partner') }}
    </v-btn>

    <div
      v-if="!isMine && !transferReseller"
      class="columns"
    >
      <div class="column">
        <partner-activate
          v-if="!isMine"
          :partner="partner"
          :partner-type="partnerType"
          :reseller-count="resellerCount"
          :customer-count="customerCount"
          @deactivated="partnerDeactivated"
          @reactivated="partnerReactivated"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContactInformation from '@/components/partner/ContactInformation'
import DistributorDetails from '@/components/partner/DistributorDetails'
import PartnerActivate from '@/components/partner/PartnerActivate'

const auth = require('@/service/auth')

export default {
    name: 'PartnerDetails',
    components: {
        ContactInformation,
        DistributorDetails,
        PartnerActivate
    },
    props: ['partner', 'partnerType', 'resellerCount', 'customerCount', 'title'],
    data () {
        return {
            transferReseller: false
        }
    },
    computed: {
        isMine () {
            return typeof this.partnerId !== 'undefined' && this.partnerId !== null && this.partnerId === this.$store.getters.myAccount.accountId
        },
        partnerId () {
            if (this.partner) {
                return this.partnerType === 'Reseller' ? this.partner.resellerId : this.partner.distributorId
            }
            return null
        },
        isReseller () {
            return this.partner && this.partnerType === 'Reseller'
        },
        isAdmin () {
            return auth.isAdmin()
        }
    },
    methods: {
        partnerDeactivated () {
            this.$emit('partnerDeactivated')
        },
        partnerReactivated () {
            this.$emit('partnerReactivated')
        },
        resellerTransfered (distributorId) {
            this.transferReseller = false
            this.$emit('transfered', distributorId)
            const resellerId = this.partnerId
            let reseller = this.$store.getters.allResellers.find(p => { return parseInt(p.resellerId) === parseInt(resellerId) })
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';

  .partner-details {
    position: relative;
    padding-bottom: 40px;
    .columns {
      padding: 20px 0 80px 0;
      background-color: transparent;
    }
  }

</style>
