<template>
  <div class="user-subusers-list">
    <v-row>
      <v-col cols="3">
        <h2>{{ $t('Subusers') }}</h2>
        <v-text-field
          v-model="filter"
          :append-icon-cb="() => {}"
          :placeholder="$t('Search')"
          dense
          single-line
          hide-details
          class="partner-search"
        >
          <v-icon
            slot="prepend-inner"
            color="grey"
            size="0.9rem"
            class="pt-1"
          >
            fa fa-search
          </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            v-if="subusers.length > 0"
            :headers="headers"
            :items="filteredSubusers"
            :items-per-page="10"
            :footer-props="{ 'items-per-page-text': '', 'items-per-page-options': []}"
            hide-default-header
            calculate-widths
            dense
          >
            <template #header="{ props }">
              <thead>
                <tr>
                  <th
                    v-for="(header, index) in props.headers"
                    :key="index"
                    class="primary--text table-head"
                  >
                    {{ $t(header.text) }}
                  </th>
                </tr>
              </thead>
            </template>
            <template #body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.email"
                  class="table-row"
                >
                  <td class="table-cell email">
                    {{ item.email }}
                  </td>
                  <td class="table-cell">
                    {{ item.firstName }}
                  </td>
                  <td class="table-cell">
                    {{ item.lastName }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <div v-else>
            <p
              class="py-12"
              align="center"
            >
              {{ $t('No subusers found') }}
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script charset="utf-8">
export default {
    name: 'SubUsers',
    props: ['account', 'users'],
    data () {
        return {
            filter: null,
            headers: [
                { text: 'Email', value: 'email', width: '200px', fixed: true },
                { text: 'First Name', value: 'firstName', width: '200px', fixed: true },
                { text: 'Last Name', value: 'lastName', width: '200px', fixed: true },
            ],
        }
    },
    computed: {
        userId () {
            return this.account ? this.account.accountOwnerId : null
        },
        partnerId () {
            return this.account ? this.account.resellerId : null
        },
        subusers () {
            return this.users ? this.users.filter(function (u) { return u.role === 'user' }) : null
        },
        filteredSubusers () {
            let subusers = this.subusers || []
            if (this.filter) {
                let _this = this
                subusers = subusers.filter(function (u) {
                    return (u.email && u.email.toLowerCase().indexOf(_this.filter.toLowerCase()) !== -1) ||
              (u.firstName && u.firstName.toLowerCase().indexOf(_this.filter.toLowerCase()) !== -1) ||
              (u.lastName && u.lastName.toLowerCase().indexOf(_this.filter.toLowerCase()) !== -1)
                })
            }
            return subusers
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/main';

  .table-head {
        border-bottom-color: var(--v-primary-lighten1) !important;
        border-bottom-width: 2px !important;
    }

    .table-row {
        &:nth-child(even) {
            background-color: #f8f8f8;
        }
        &.expanded {
            background-color: #f5f5f5;
        }
        .table-cell {
            &:nth-child(1) {
               padding-left: 20px;
            }
            padding: 10px;
            vertical-align: middle;
            font-size: 0.9em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border: none !important;
        }
    }

  .user-subusers-list {
    margin: 20px;
    padding-bottom: 40px;
    h2 {
      color: $primary;
      text-transform: uppercase;
      margin: 50px 0 calc(20px + 0.75rem) 0;
      font-size: 14px;
    }
    .field {
      &.search {
        min-width: 50%;
        display: inline-block;
        margin: 20px 0 30px;
        max-height: 28px;
        p.control.has-icons-left {
          border: none;
          .icon {
            height: 40px;
          }
          input.input {
            margin: 0 0 0 30px;
            padding: 0;
            height: 40px;
            border: none;
            border-bottom: 2px solid #FFFFFF;
            + .icon {
              border-bottom: 2px solid #FFFFFF;
            }
            &:focus {
              border-bottom-color: $primary;
              + .icon {
                color: $primary;
                border-bottom-color: $primary;
              }
            }
          }
        }
      }
    }
    .subusers-block {
      background-color: white;
      padding: 20px;
      .subuser-filter {
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
        input {
          width: 50%;
          border: 1px solid $secondary;
          border-radius: $border-radius;
          text-align: left;
          padding: 0 10px;
        }
      }
    }
  }
</style>
