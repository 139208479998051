<template>
  <div class="user-camera-list">
    <v-row>
      <v-col cols="3">
        <h2>{{ $t('Cameras') }}</h2>
        <v-text-field
          v-model="filter"
          :append-icon-cb="() => {}"
          :placeholder="$t('Search')"
          dense
          single-line
          hide-details
          class="partner-search"
        >
          <v-icon
            slot="prepend-inner"
            color="grey"
            size="0.9rem"
            class="pt-1"
          >
            fa fa-search
          </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <div class="cameras-block">
            <div class="camera-status-buttons">
              <button class="camera-status-button">
                <span>{{ $t('Offline') }}</span>
                <p class="offline">
                  {{ offlineCameras }}
                </p>
              </button>
              <button class="camera-status-button">
                <span>{{ $t('Online') }}</span>
                <p class="online">
                  {{ onlineCameras }}
                </p>
              </button>
            </div>
            <camera-list
              :table-select="tableSelect"
              :cameras="cameras"
              :filter="filter"
              :account="account"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script charset="utf-8">
import UserInfoTopBar from '@/components/account/UserInfoTopBar'
import CameraList from '../base/CameraList'

const restapi = require('@/service/restapi')

export default {
    name: 'Cameras',
    components: {
        'camera-list': CameraList
    },
    props: ['account', 'cameraId'],
    data () {
        return {
            tableSelect: true,
            filter: null,
            cameras: []
        }
    },
    computed: {
        onlineCameras () {
            let count = 0
            if (this.camerasNotEmpty) {
                count = this.cameras.filter(function (c) { return c.status === 'Online' }).length
            }
            return count
        },
        offlineCameras () {
            let count = 0
            if (this.camerasNotEmpty) {
                count = this.cameras.filter(function (c) { return c.status === 'Offline' }).length
            }
            return count
        }
    },
    watch: {
        account: function (newVal, oldVal) {
            if (newVal) {
                this.loadCameras()
            }
        }
    },
    mounted () {
        if (this.account) {
            this.loadCameras()
        }
        if (this.cameraId) {
            this.filter = this.cameraId
        }
    },
    methods: {
        camerasNotEmpty () {
            return Array.isArray(this.cameras) && this.cameras.length > 0
        },
        loadCameras () {
            const that = this
            restapi.getCamerasSummary(this.account.resellerId, this.account.accountId)
                .then(function (result) {
                    that.cameras = result || []
                })
                .catch(function (error) {
                    console.error(error)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.getMessageFromError(error, 'GET_ENDUSER_CAMERAS'),
                        timeout : -1,
                        color: 'error',
                        support: true
                    })
                })
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/main';

  .user-camera-list {
    padding-bottom: 40px;
    h2 {
      color: $primary;
      text-transform: uppercase;
      margin: 50px 0 calc(20px + 0.75rem) 0;
      font-size: 14px;
    }
    .field {
      &.search {
        min-width: 50%;
        display: inline-block;
        margin: 20px 0 30px;
        max-height: 28px;
        p.control.has-icons-left {
          border: none;
          .icon {
            height: 40px;
          }
          input.input {
            margin: 0 0 0 30px;
            padding: 0;
            height: 40px;
            border: none;
            border-bottom: 2px solid #FFFFFF;
            + .icon {
              border-bottom: 2px solid #FFFFFF;
            }
            &:focus {
              border-bottom-color: $primary;
              + .icon {
                color: $primary;
                border-bottom-color: $primary;
              }
            }
          }
        }
      }
    }
    .cameras-block {
      background-color: white;
      padding-top: 20px;
      .camera-status-buttons {
        text-align: center;
        margin-bottom: 20px;
        button {
          border: none;
          margin: 0 30px;
          &.camera-status-button {
            background-color: #FFFFFF;
            span {
              color: $secondary;
              text-transform: uppercase;
              font-size: 10px;
            }
            p {
              font-size: 32px;
              &.online {
                color: #207cad;
              }
              &.offline {
                color: #d43536;
              }
            }
          }
        }
      }
      .camera-filter {
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
        input {
          width: 50%;
          border: 1px solid $secondary;
          border-radius: $border-radius;
          text-align: left;
          padding: 5px;
        }
      }
    }
  }
</style>
