<template>
  <div class="partner-details">
    <h2>{{ $t('Partner details') }}</h2>
    <p class="component-description">
      {{ $t('Partner details description') }}
    </p>
    <v-card v-if="!editMode">
      <v-card-subtitle>
        <div
          v-if="distributor"
          class="field"
        >
          <label class="has-float-label">
            <p
              id="customer-distributor-input"
              class="content"
            >{{ distributorName }}</p>
            <span class="move-up">{{ $t("Distributor") }}</span>
          </label>
        </div>
        <div
          v-if="reseller"
          class="field"
        >
          <label class="has-float-label">
            <p
              id="customer-partner-input"
              class="content"
            >{{ resellerName }}</p>
            <span class="move-up">{{ $t("Reseller") }}</span>
          </label>
        </div>
        <p v-else>
          {{ $t('Unable to load reseller data') }}
        </p>
      </v-card-subtitle>
    </v-card>
    <div
      v-if="!editMode && reseller && hasPermission('CustomerEdit') && hasPermission('AdminPartner')"
      ref="dropDownEditMenu"
      :class="{ 'is-active': isEditActive }"
      class="dropdown edit-menu"
    >
      <v-menu
        offset-y
        auto
      >
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon
              size="1em"
              color="primary"
            >
              fa fa-ellipsis-h
            </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="edit">
            <v-list-item-title class="pr-5">
              {{ $t('Edit') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <change-reseller-wizard
      v-if="editMode"
      :is-admin="isAdmin"
      :is-distributor="isDistributor"
      :account="account"
      :distributor="distributor"
      :reseller="reseller"
      :subscriptions="subscriptions"
      @onCancel="onMoveUserWizardCancel()"
      @transfered="isTransfered"
    />
  </div>
</template>

<script charset="utf-8">
import ChangeResellerWizard from '@/components/account/ChangeResellerWizard'

const auth = require('@/service/auth')

export default {
    name: 'ResellerDetails',
    components: {
        ChangeResellerWizard
    },
    props: ['account', 'distributor', 'reseller', 'subscriptions'],
    data () {
        return {
            isEditActive: false,
            editMode: false,
            hash: null
        }
    },
    computed: {
        active () {
            return !!(this.customer && this.customer.UserId !== this.customer.Email)
        },
        partnerId () {
            return this.customer ? this.customer.PartnerId : null
        },
        resellerName () {
            return this.reseller ? this.reseller.companyName : null
        },
        distributorName () {
            return this.distributor ? this.distributor.companyName : null
        },
        id () {
            return this.customer ? this.customer.UserId : null
        },
        isBrowserIE () {
            return this.$store.getters.isIE
        },
        showCloseButton () {
            return !this.hideCloseButton
        },
        isAdmin () {
            return auth.isAdmin()
        },
        isDistributor () {
            return auth.isDistributor()
        },
        isBlocked () {
            return !!(this.customer && this.customer.IsBlocked === 'yes')
        }
    },
    watch: {
        customer () {
            this.editMode = false
        },
        editMode () {
            this.$emit('transfering', this.editMode)
        }
    },
    created () {
        document.addEventListener('click', this.documentClick)
    },
    destroyed () {
        document.removeEventListener('click', this.documentClick)
    },
    methods: {
        edit () {
            this.editMode = true
            this.isEditActive = false
        },
        onMoveUserWizardCancel () {
            this.editMode = false
        },
        documentClick (e) {
            let el = this.$refs.dropDownEditMenu
            let target = e.target
            if (el && el !== target && !el.contains(target)) {
                this.isEditActive = false
            }
        },
        isTransfered (resellerId) {
            this.editMode = false
            this.$emit('transfered', resellerId)
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';

  .partner-details {
    position: relative;
    padding-bottom: 40px;
    h2 {
      color: $primary;
      text-transform: uppercase;
      margin: 10px 0 0.5714em 0;
      font-size: 14px;
    }
    p {
      margin-bottom: 20px;
    }
    .columns {
      margin: 0;
      padding: 5px 0;
      .column {
        padding: 0 40px;
        margin: 40px 0;
        .field {
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
          .has-float-label {
            p {
              + span {
                top: -60px;
              }
            }
          }
        }
      }
      &.edit-mode {
        .column {
          @media screen and (max-width: 767px) {
            border-left: 8px solid $primary;
          }
          @media screen and (min-width: 768px) {
            &:first-child {
              border-left: 8px solid $primary;
            }
          }
        }
      }
    }
    .edit-button {
      color: $primary;
      background-color: transparent;
      margin-top: -30px;
      font-size: 18px;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 60px;
      right: 0;
    }
    .dropdown {
      &.edit-menu {
        position: absolute;
        top: 70px;
        right: 10px;
        z-index: 20;
        .dropdown-trigger {
          color: $primary;
          font-weight: bold;
          position: absolute;
          top: 0;
          right: 10px;
          cursor: pointer;
          span {
            font-weight: 600;
            font-size: 18px;
          }
        }
        .dropdown-menu {
          position: relative;
          margin-top: 25px;
          .dropdown-content {
            border-radius: $border-radius;
            .dropdown-item {
              text-align: center;
              padding: 0.375rem 1rem;
              text-transform: uppercase;
              color: $primary;
              &.is-danger {
                color: $color-error-dark;
              }
            }
            hr {
              height: 1px !important;
            }
          }
        }
      }
    }
    .control-buttons {
      padding: 0;
      width: 50px;
      display: inline-block;
      position: absolute;
      right: -35px;
      top: 45px;
      button {
        float: right;
        margin-bottom: 10px;
        font-size: 18px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        vertical-align: middle;
        &.cancel-button {
          color: $primary;
          background-color: #FFFFFF;
          border: 2px solid $primary;
        }
        &.save-button {
          color: #FFFFFF;
          background-color: $primary;
        }
        &.deactivate-button {
          color: #FFFFFF;
          background-color: $primary;
        }
        &.activate-button {
          color: #FFFFFF;
          background-color: $primary;
        }
      }
    }
  }
  .select-element.fix-first-select-location {
    margin-top: 12px;
  }
</style>
