<template>
  <div>
    <v-dialog
      id="delete-mobile-subscription-modal"
      v-model="isModalActive"
      width="600"
      @close="onPopupClosed()"
    >
      <v-card>
        <v-form @submit.prevent="deleteSubscription">
          <v-card-title>
            {{ $t('Remove mobile subscription') }}
          </v-card-title>
          <v-card-text>
            <v-checkbox
              v-model="checkboxSimInactive"
              class="mt-0 pt-0 mb-0"
              dense
              :label="$t('The SIM will be inactive and cannot be used for mobile usage')"
            />
            <v-checkbox
              v-model="checkboxCellularNetworksWillGoOffline"
              class="mt-0 pt-0 mb-0"
              dense
              :label="$t('If connected to a device with only cellular network it will go offline')"
            />
            <v-checkbox
              v-model="checkboxUseSimAgain"
              class="mt-0 pt-0 mb-0"
              dense
              :label="$t('To use this SIM again you need to add it to a new mobile subscription')"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              large
              outlined
              class="mr-2"
              color="primary"
              type="button"
              @click="onPopupClosed()"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              large
              color="error"
              type="submit"
              :disabled="!(checkboxSimInactive && checkboxCellularNetworksWillGoOffline && checkboxUseSimAgain)"
            >
              {{ $t('Remove') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script charset="utf-8">

const restapi = require('@/service/restapi')

export default {
    name: 'DeleteMobileSubscription',
    props: ['subscription', 'isModalActive'],
    data () {
        return {
            checkboxSimInactive: false,
            checkboxCellularNetworksWillGoOffline: false,
            checkboxUseSimAgain: false
        }
    },
    computed: {
        resellerId () {
            return this.$route.params.resellerid
        },
        accountId () {
            return this.$route.params.accountid
        },
        userId () {
            return this.$route.params.userid
        }
    },
    methods: {
        onPopupClosed () {
            this.$emit('onCancel')
        },
        deleteSubscription () {
            const that = this
            that.isLoading(true)
            restapi.deleteAccountSubscription(this.resellerId, this.accountId, this.subscription.subscriptionId)
                .then(function (result) {
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.$t('Mobile subscription has been deleted'),
                        timeout : 4000,
                        color: 'primary'
                    })
                    that.$emit('onDeleted')
                })
                .catch(function (error) {
                    console.error(error)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.getMessageFromError(error, 'DELETE_ACCOUNT_SUBSCRIPTION'),
                        timeout : -1,
                        color: 'error',
                        support: true
                    })
                    that.$emit('onCancel')
                })
                .then(function () {
                    that.isLoading(false)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../../../assets/styles/forms';
</style>
