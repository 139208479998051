<template>
  <v-list-item
    class="side-menu mb-0 pa-0"
    tile
    :class="{'side-menu-selected-item': selectedMenu === name }"
    :ripple="false"
    @click="selectMenu(name)"
  >
    <v-list-item-icon class="item-icon">
      <v-icon
        :size="iconSize?iconSize:14"
        class="primary--text"
        v-html="icon"
      />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title
        class="primary--text menu-text text-uppercase"
        :class="{'primary--text': selectedMenu === name }"
      >
        {{ $t(text) }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
    name: 'SideBar',
    props: {
        name: {
            type: String,
        },
        text: {
            type: String,
        },
        icon: {
            type: String,
        },
        iconSize:{
            type: String,
        },
        selectedMenu: {
            type: String,
        },
        click: {
            type: Function,
        },
        largerFont:{
            type: Boolean
        },
        selectMenu: {
            type: Function,
        },
    }
}
</script>

<style lang="scss" scoped>
    .menu-text {
        letter-spacing: 1px !important;
        font-size: 14px !important;
    }
    .item-icon {
        width: 50px;
        margin: auto !important;
        i {
            margin: auto;
        }
    }
    .side-menu-selected-item{
      background-color: #f6f6f6;
      font-weight: 600;
      border-radius: 0;
      width:102%;
   }
  
    .side-menu:hover {
         width: 102%;
         border-radius: 0 !important
 }
    .side-menu:focus {
         background-color:transparent;
         width: 102%;
         border-radius: 0 !important
 }

</style>
