<template>
  <div class="announcement_popup_container">
    <div class="logo_display">
      <div class="logoLayer">
        <span class="logos_container">
          <div class="vertAlign">
            <span class="spaneagleEye"><img :src="eenLogoImage"></span>
          </div>
        </span>
        <img :src="backgroundImage">
      </div>
    </div>
    <div class="content_display">
      <span class="okIcon">
        <img :src="okHandImage">
      </span>
      <p class="walkthrough-title">
        {{ $t("Subscribe for newsletter") }}
      </p>
      <p class="walkthrough-content">
        {{ $t('Provide the best experience for your customers and stay up to date with our Dealer Dashboard newsletter. We’ll update you about new releases and maintenance.') }}
      </p>
      <div class="container_buttons">
        <v-btn
          large
          outlined
          class="mr-2"
          color="primary"
          type="button"
          @click="closeMe(false)"
        >
          {{ $t("No thanks") }}
        </v-btn>
        <v-btn
          large
          color="primary"
          @click="closeMe(true)"
        >
          {{ $t('Subscribe me now') }}
        </v-btn>
      </div>
      <p class="original-layout-info">
        {{ $t('You can always change your settings with the \'Dealer Communication\' link in the footer.') }}
      </p>
    </div>
  </div>
</template>

<script>
import { getImgUrl } from '@/service/main'

const restapi = require('@/service/restapi')

export default {
    name: 'Walkthrough',
    data () {
        return {
            backgroundImage: getImgUrl('background-logos.png'),
            eenLogoImage: getImgUrl('eagleEyeLogo.png'),
            okHandImage: getImgUrl('hand-ok.png')
        }
    },
    methods: {
        closeMe (subscribe) {
            const that = this
            this.$store.commit('showWalkThrough', false)
            restapi.setWalkthroughStatus(true)
                .then(function (result) {
                    that.$store.commit('showSignup', subscribe)
                })
                .catch(function (error) {
                    console.error(error)
                    that.$store.commit('showSignup', false)
                })
                .then(function () {
                    that.$parent.close()
                })
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";

  .walkthrough-title {
    color: $color-title;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: bold;
  }

  .walkthrough-content {
    color: $color-general-text;
    max-width: 500px;
    margin: 20px auto;
    display: inline-block;
  }

  .announcement_popup_container {
    display: table;
    margin: 0 auto;
    max-width: 1020px;
    background-color: white;
    border: 6px solid $primary;

    -webkit-border-radius: 4px;
    border-radius: 4px;
  }

  .announcement_popup_container > div {
    display: table-cell;
    vertical-align: middle;
  }

  .logo_display {
    width: 28%;
    position: relative;
  }

  .logoLayer {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .logoLayer > img {
    width: 110%;
    top: 15%;
    position: relative;
    z-index: 6;
  }

  .logos_container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: table;
    background-color: $primary;
    opacity: 0.9;
    z-index: 10;
  }

  .logos_container img {
    display: block;
    margin: auto;
  }

  .content_display, .container_buttons {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .container_buttons {
    margin-bottom: 35px;
  }

  .okIcon {
    display: inline-block;
    margin: 20px auto;
    width: 70px;
    position: relative;
    margin-top: 35px;
  }

  .okIcon img {
    width: 100%;
    margin-left: 10px;
  }

  #button-go-to-website {
    padding: 0 16px;
    font-size: 11px;
    letter-spacing: 1.4px;
    line-height: 40px;
    margin: 15px;
    font-weight: 700;
  }

  span {
    display: block;
    margin: 0 auto;
  }

  span img {
    width: 100%;
  }

  .spaneagleEye {
    width: 125px;
    position: relative;
    top: 210px;
  }

  #features-list {
    padding: 0 30px;
  }

  #features-list li {
    text-align: left;
    list-style-type: none;
    &:before {
      font-family: fontawesome;
      font-size: 10px;
      content: "\f111";
      margin: 0px 10px 0px -18px;
    }
  }

  .original-layout-info {
    font-size: 75%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
</style>
