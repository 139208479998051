<template>
  <div
    id="change-partner-wizard"
    class="change-partner-wizard has-shadow has-rounded-corners"
  >
    <wizard
      ref="wizard"
      :steps="steps"
      :on-next="nextClicked"
      :on-back="backClicked"
      :back-label="$t('Back')"
      :next-label="$t('Next')"
      :final-step-label="$t('Transfer')"
      :class="{ 'wizard-final-step': finalStep, 'wizard-validation-error': wizardValidationError }"
      :state="state"
    >
      <div slot="page1">
        <div class="columns">
          <div class="column">
            <h2 class="step-title">
              {{ steps[0].label }}
            </h2>
          </div>
        </div>
        <div class="control-buttons">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            @click="cancelMoveUserWizard()"
          >
            <v-icon dark>
              fas fa-times
            </v-icon>
          </v-btn>
        </div>
        <select-partner-page
          :current-reseller="reseller"
          :distributors="distributors"
          :distributor="partner.distributor"
          :is-admin="isAdmin"
          :is-distributor="isDistributor"
          :show-distributor-select="isAdmin"
          @distributorSelected="onDistributorSelected"
          @resellerSelected="onResellerSelected"
        />
      </div>
      <div slot="page2">
        <div class="columns">
          <div class="column">
            <h2 class="step-title">
              {{ steps[1].label }}
            </h2>
          </div>
        </div>
        <div class="control-buttons">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            @click="cancelMoveUserWizard()"
          >
            <v-icon dark>
              fas fa-times
            </v-icon>
          </v-btn>
        </div>
        <div
          v-show="hasDemoOrTrial"
          class="columns"
        >
          <div class="column is-half">
            <div class="field select-element">
              <div class="has-float-label">
                <v-select
                  id="add-user-account-type"
                  v-model="accountType"
                  :label="$t('User type')"
                  :items="accountTypes"
                  return-object
                  dense
                  @change="onSelectAccountType"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="page3">
        <div class="columns">
          <div class="column">
            <h2 class="step-title">
              {{ steps[2].label }}
            </h2>
          </div>
        </div>
        <div class="control-buttons">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            @click="cancelMoveUserWizard()"
          >
            <v-icon dark>
              fas fa-times
            </v-icon>
          </v-btn>
        </div>
        <choose-subscription
          :subscription="currentSubscription"
          :subscriptions="resellerSubscriptions"
          @subscriptionSelected="onSubscriptionSelected($event)"
        />
      </div>
      <div slot="page4">
        <div class="columns">
          <div class="column">
            <h2 class="step-title">
              {{ steps[3].label }}
            </h2>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="question">
              {{ $t('Are you sure you want to transfer this user', [getDistributorName, getResellerName]) }}
            </div>
            <div class="explanation">
              {{ $t("The customer will be moved to the new partner and he will be billed for its subscription") }}
            </div>
          </div>
        </div>
        <div class="control-buttons">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            @click="cancelMoveUserWizard()"
          >
            <v-icon dark>
              fas fa-times
            </v-icon>
          </v-btn>
        </div>
      </div>
    </wizard>
  </div>
</template>

<script>
import SelectPartnerPage from '@/components/partner/SelectPartnerPage'
import ChooseSubscription from '@/components/account/ChooseSubscription'

const restapi = require('@/service/restapi')

export default {
    name: 'ChangeResellerWizard',
    components: {
        SelectPartnerPage,
        ChooseSubscription
    },
    props: ['isAdmin', 'isDistributor', 'account', 'distributor', 'reseller', 'subscriptions'],
    data () {
        return {
            distributors: [],
            resellers: [],
            steps: [
                {
                    label: this.$t('Transfer to other partner'),
                    slot: 'page1'
                },
                {
                    label: this.$t('Transfer to other partner'),
                    slot: 'page2'
                },
                {
                    label: this.$t('Transfer to other partner'),
                    slot: 'page3'
                },
                {
                    label: this.$t('Transfer to other partner'),
                    slot: 'page4'
                }
            ],
            finalStep: false,
            wizardValidationError: false,
            partner: {
                distributor: null,
                reseller: null
            },
            subscription: null,
            currentPage: 0,
            resellerSubscriptions: null,
            accountType: 'regular',
            state: 'working'
        }
    },
    computed: {
        partnerId () {
            return this.account ? this.account.resellerId : null
        },
        accountId () {
            return this.account ? this.account.accountId : null
        },
        userId () {
            return this.account ? this.account.accountOwnerId : null
        },
        getCustomerName () {
            return this.account ? `${this.account.firstName} ${this.account.lastName}` : null
        },
        getDistributorName () {
            if (this.partner.distributor) {
                return this.partner.distributor.companyName
            }
            return ''
        },
        getResellerName () {
            if (this.partner.reseller) {
                return this.partner.reseller.companyName
            }
            return ''
        },
        getResellerId () {
            if (this.partner.reseller) {
                return this.partner.reseller.resellerId
            }
            return ''
        },
        users: {
            get: function () {
                return this.$store.getters.users || []
            },
            set: function (users) {
                this.$store.commit('users', users)
            }
        },
        currentSubscription () {
            if (this.account && this.subscriptions) {
                const subscriptionId = this.account.subscriptionId
                return this.subscriptions.find(function (s) { return s.subscriptionId === subscriptionId })
            }
            return null
        },
        selectedAccountType () {
            let currentAccountType = this.accountType
            return this.accountTypes.find(function (t) { return t.value === currentAccountType })
        },
        trialAccountType () {
            if (this.availableResellerSubscriptions) {
                return this.availableResellerSubscriptions.find(function (s) { return s.name.toLowerCase().indexOf('trial') !== -1 })
            }
            return null
        },
        demoAccountType () {
            if (this.availableResellerSubscriptions) {
                return this.availableResellerSubscriptions.find(function (s) { return s.name.toLowerCase().indexOf('demo') !== -1 })
            }
            return null
        },
        hasDemoOrTrial () {
            return !!this.demoAccountType || !!this.trialAccountType
        },
        accountTypes () {
            let types = [
                {
                    value: 'regular',
                    text: this.$t('Regular')
                }
            ]
            if (this.trialAccountType) {
                types.push({
                    value: 'trial',
                    text: this.$t('Trial')
                })
            }
            if (this.demoAccountType) {
                types.push({
                    value: 'demo',
                    text: this.$t('Demo')
                })
            }
            return types
        },
        isBrowserIE () {
            return this.$store.getters.isIE
        },
        availableResellerSubscriptions () {
            return this.resellerSubscriptions ? this.resellerSubscriptions.filter(s => { return s.available }) : null
        }
    },
    mounted () {
        if (this.isAdmin) {
            const that = this
            restapi.getDistributors()
                .then(function (distributors) {
                    that.distributors = distributors
                })
                .catch(function (error) {
                    console.error(error)
                })
        } else if (this.isDistributor) {
            this.distributors = [this.distributor]
            this.partner.distributor = this.distributor
        }
    },
    methods: {
        onSelectAccountType (t) {
            this.accountType = t.value
            if (this.accountType === 'demo') {
                this.subscription = this.demoAccountType
            } else if (this.accountType === 'trial') {
                this.subscription = this.trialAccountType
            } else {
                this.subscription = null
            }
        },
        onSubscriptionSelected (subscription) {
            this.subscription = subscription
        },
        hasAccountSKUProperty (account) {
            return (account.hasOwnProperty('SKU') && account.SKU !== '')
        },
        onDistributorSelected (distributor) {
            console.log('onDistributorSelected', distributor)
            this.partner.distributor = distributor
            this.partner.reseller = null
        },
        onResellerSelected (reseller) {
            console.log('onResellerSelected', reseller)
            this.partner.reseller = reseller
            this.resellerSubscriptions = null
            const that = this
            restapi.getResellerSubscriptions(this.partner.reseller.resellerId)
                .then(function (result) {
                    that.resellerSubscriptions = result
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        cancelMoveUserWizard () {
            this.partner.distributor = null
            this.partner.reseller = null
            this.subscription = null
            this.currentPage = 0
            this.$emit('onCancel')
        },
        nextClicked (currentPage) {
            this.finalStep = this.steps.length === (currentPage + 1)
            if (currentPage === 0) {
                if (!(this.partner && this.partner.reseller)) return false
                else if (!this.hasDemoOrTrial) this.$refs.wizard.goTo(1)
                return true
            } else if (currentPage === 1) {
                if (this.subscription) this.$refs.wizard.goTo(2)
                return true
            } else if (currentPage === 2) {
                return !!this.subscription
            } else if (this.finalStep) {
                let resellerId = this.partner.reseller.resellerId
                let accountId = this.accountId
                if (this.subscription) {
                    accountId = this.subscription.subscriptionId
                }
                let that = this
                this.isLoading(true)
                restapi.moveAccountToReseller(this.partnerId, this.userId, resellerId, accountId)
                    .then(function (result) {
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.$t('Customer has been successfully transferred', [that.getCustomerName, that.getDistributorName, that.getResellerName]),
                            timeout : 4000,
                            color: 'primary'
                        })
                        that.$emit('transfered', resellerId)
                    })
                    .catch(function (error) {
                        console.error(error)
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.getMessageFromError(error, 'CHANGE_ACCOUNT_RESELLER'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                    })
                    .then(function () {
                        that.isLoading(false)
                    })
                return false
            }
        },
        backClicked (currentPage) {
            return true
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/styles/main";

  .question {
    color: $primary;
    padding: 30px 10px;
    font-weight: bold;
  }
  .explanation {
    color: $color-general-text;
    padding: 10px;
    font-weight: bold;
  }
  #change-partner-wizard ::v-deep {
    .wizard__steps, .wizard__arrow {
      display: none !important;
    }
    .wizard__body {
      border: none!important;
      margin: 0;
      padding-bottom: 0;
    }
    .wizard__body__actions {
      border-top: none !important;
      background-color: #ffffff !important;
      bottom: 20px;
      padding: 0 45px;
    }
    .wizard__body__step {
      padding: 2px 8px;
    }
    .wizard__next, .wizard__back {
      border: none;
      color: #FFFFFF;
      background-color: $primary;
      border-radius: $border-radius;
      text-transform: uppercase;
      img {
        display: none !important;
      }
    }
    .wizard-validation-error {
      .wizard__next {
        opacity: 0.5;
      }
    }
    .step-title {
      text-align: left;
      margin-bottom: 20px;
    }
  }

  .change-partner-wizard {
    .columns {
      margin: 0;
      .column {
        padding: 0 40px;
        margin: 40px 0;
        h2 {
          color: $primary;
          text-transform: uppercase;
          margin: 20px 0;
          font-size: 14px;
        }
        .field {
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &.edit-mode {
        .column {
          @media screen and (max-width: 767px) {
            border-left: 8px solid $primary;
          }
          @media screen and (min-width: 768px) {
            &:first-child {
              border-left: 8px solid $primary;
            }
          }
        }
      }
    }
    .edit-button {
      color: $primary;
      background-color: transparent;
      margin-top: -30px;
      font-size: 18px;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50px;
      right: 0;
    }
    .control-buttons {
      padding: 0;
      width: 50px;
      display: inline-block;
      position: absolute;
      right: -30px;
      top: 15px;
      button {
        float: right;
        margin-bottom: 10px;
        font-size: 18px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        vertical-align: middle;
        &.cancel-button {
          color: $primary;
          background-color: #FFFFFF;
          border: 2px solid $primary;
        }
      }
    }
  }
</style>
