<template>
  <div class="insights content-wrapper">
    <div class="content">
      <div
        class="container"
        :class="selectedMenu"
      >
        <reports
          v-if="menuSelected('reports') && hasInsightsRights()"
          id="reports-insights"
        />
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
import Reports from '@/components/insights/Reports'

const auth = require('@/service/auth')

export default {
    name: 'Insights',
    components: {
        Reports
    },
    data () {
        return {
            selectedMenu: 'reports',
            defaultMenu: 'reports'
        }
    },
    methods: {
        hasInsightsRights () {
            return auth.isReseller() || this.hasPermission('InsightsReports')
        },
        menuSelected (item) {
            return item === this.selectedMenu
        },
        isReseller () {
            return auth.isReseller()
        },
    },
    // mounted () {
    //     if (!this.$route.params.menu) {
    //         this.$router.replace({path: '/insights/' + this.defaultMenu})
    //     } else {
    //         this.selectedMenu = this.$route.params.menu
    //         this.$store.commit('selectedSubmenu', this.selectedMenu)
    //     }
    // },
    // beforeDestroy () {
    //     this.$store.commit('selectedSubmenu', null)
    // },
    // watch: {
    //     '$route' (to, from) {
    //         this.selectedMenu = this.$route.params.menu || this.defaultMenu
    //         this.$store.commit('selectedSubmenu', this.selectedMenu)
    //     }
    // }
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";

  .insights {
    &.content-wrapper {
      .content {
        width: 100%;
        background-color: $color-shadow-light;
        .container {
          position: relative;
          padding-bottom: 20px;
          width: 90%;
          min-width: 90%;
          &.reports {
            width: 90%;
            min-width: 90%;
          }
        }
      }
    }
  }
</style>
