const state = {
    loggedIn: false
}

const getters = {
    isLoggedIn: state => {
        return state.loggedIn
    }
}

const mutations = {
    loggedIn: (state, loggedIn) => {
        state.loggedIn = loggedIn
    }
}

export default {
    state,
    getters,
    mutations
}
