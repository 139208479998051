<template>
  <div class="create-mobile-subscription">
    <mobile-subscription-wizard
      :available-subscriptions="subscriptions"
      @onCancel="cancelWizard"
      @onDone="createAccountSubscription"
    />
  </div>
</template>

<script charset="utf-8">
import MobileSubscriptionWizard from '@/components/account/subscription/mobile/MobileSubscriptionWizard'

const restapi = require('@/service/restapi')

export default {
    name: 'CreateMobileSubscription',
    components: {MobileSubscriptionWizard},
    props: ['availableSubscriptions'],
    data () {
        return {
        }
    },
    computed: {
        isBrowserIE () {
            return this.$store.getters.isIE
        },
        resellerId () {
            return this.$route.params.resellerid
        },
        accountId () {
            return this.$route.params.accountid
        },
        userId () {
            return this.$route.params.userid
        },
        subscriptions () {
            let subscriptions = []
            if (this.availableSubscriptions) {
                const nofSubscriptions = this.availableSubscriptions.length
                for (let i = 0; i < nofSubscriptions; i++) {
                    subscriptions.push({
                        sku: this.availableSubscriptions[i].sku,
                        dataCap: this.availableSubscriptions[i].subscriptionTypeDetails ? this.availableSubscriptions[i].subscriptionTypeDetails.dataCap : null
                    })
                }
            }
            return subscriptions
        }
    },
    methods: {
        cancelWizard () {
            this.$emit('onCancel')
        },
        createAccountSubscription (subscription) {
            let that = this
            this.isLoading(true)
            restapi.createAccountSubscription(this.resellerId, this.accountId, subscription)
                .then(function (result) {
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.$t('Mobile subscription has been successfully created'),
                        timeout : 4000,
                        color: 'primary'
                    })
                    that.$emit('onCreated')
                })
                .catch(function (error) {
                    console.error(error)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.getMessageFromError(error, 'ADD_ACCOUNT_SUBSCRIPTION'),
                        timeout : -1,
                        color: 'error',
                        support: true
                    })
                    that.$emit('onCancel')
                })
                .then(function () {
                    that.isLoading(false)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
