const state = {
    configuration: null,
    countries: [],
    languages: [],
    timeZones: [],
    acceptedTerms: {
        state: true,
        version: null
    },
    showWalkThrough: false,
    isLoading: false,
    burgerMenuActive: false,
    showSignup: false,
    snackBar: {},
    isAuthorized: false
}

const getters = {
    configuration: state => {
        return state.configuration
    },
    countries: state => {
        return state.countries
    },
    languages: state => {
        return state.languages
    },
    timeZones: state => {
        return state.timeZones
    },
    acceptedTerms: state => {
        return state.acceptedTerms
    },
    showWalkThrough: state => {
        return state.showWalkThrough
    },
    isLoading: state => {
        return state.isLoading
    },
    burgerMenuActive: state => {
        return state.burgerMenuActive
    },
    showSignup: state => {
        return state.showSignup
    },
    isAuthorized: state => {
        return state.isAuthorized
    }
}

const mutations = {
    configuration: (state, configuration) => {
        state.configuration = configuration
    },
    countries: (state, countries) => {
        state.countries = countries
    },
    languages: (state, languages) => {
        state.languages = languages
    },
    timeZones: (state, timeZones) => {
        state.timeZones = timeZones
    },
    acceptedTerms: (state, acceptedTerms) => {
        state.acceptedTerms = acceptedTerms
    },
    acceptedTermsVersion: (state, acceptedTerms) => {
        state.acceptedTerms.version = acceptedTerms
    },
    acceptedTermsState: (state, acceptedTerms) => {
        state.acceptedTerms.state = acceptedTerms
    },
    showWalkThrough: (state, show) => {
        state.showWalkThrough = show
    },
    isLoading: (state, isLoading) => {
        state.isLoading = isLoading
    },
    burgerMenuActive: (state, isActive) => {
        state.burgerMenuActive = isActive
    },
    showSignup: (state, bool) => {
        state.showSignup = bool
    },
    toastMessage:(state, toastMessage) => {
        state.snackBar = toastMessage
    },
    SET_AUTHORIZED: (state, isAuthorized) => {
        state.isAuthorized = isAuthorized
    },
    CALL_TOAST: (state, toastMessage) => {
        state.snackBar = toastMessage
    },
}

const actions = {
    setAuthorized({ commit }, data) {
        commit('SET_AUTHORIZED', data)
    },
    toastMessage({ commit }, message) {
        commit('CALL_TOAST', message)
    },
    setLanguages({commit}, data) {
        commit('languages', data)
    },
    setCountries({commit}, data) {
        commit('countries', data)
    },
    setTimeZones({commit}, data) {
        commit('timeZones', data)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
