<template>
  <div
    v-if="hasPermission('CustomerHelpdesk')"
    id="helpdesk-component"
    class="helpdesk-block"
  >
    <h2 class="component-title">
      {{ $t("Support access") }}
    </h2>
    <p class="component-description">
      {{ $t("Helpdesk description") }}
    </p>
    <div
      v-if="hasPermission('OverwriteHelpdesk') && hasPermission('CustomerEdit')"
      class="my-8"
    >
      <div class="my-8">
        <een-support-access 
          :dealer-dashboard="true"
          :account-status="{ allowsHelpdesk: isSupportAccessEnabled }"
        />
      </div>
    </div>
    <!-- Disabled the desktop client because it will be phased out -->
    <!-- <div>
      <v-tooltip color="primary" max-width="400px" top :disabled="enabledHelpdesk">
        <template v-slot:activator="{ on }">
          <div v-on="on" class="button-wrapper">
            <v-btn
              large
              class="open-helpdesk-button"
              color="primary"
              id="open-helpdesk-button-client"
              v-on:click="openHelpDesk()"
              :disabled="!enabledHelpdesk"
              >{{ $t("Start customers client") }}</v-btn
            >
          </div>
        </template>
        <span>{{
          $t(
            "This customer has disabled Support access. Support access can be enabled from within the end user application."
          )
        }}</span>
      </v-tooltip>
    </div> -->
    <div v-if="showWebappSupportAccess">
      <v-tooltip
        color="primary"
        max-width="400px"
        top
        :disabled="enabledHelpdesk"
      >
        <template #activator="{ on }">
          <div
            class="button-wrapper"
            v-on="on"
          >
            <v-btn
              id="open-helpdesk-button-webapp"
              large
              class="open-helpdesk-button"
              color="primary"
              :disabled="!enabledHelpdesk"
              target="_blank"
              v-on="on"
              @click="openWebappUrl()"
            >
              {{ $t("Start customers webapp") }}
            </v-btn>
          </div>
        </template>
        <span>{{
          $t(
            "This customer has disabled Support access. Support access can be enabled from within the end user application."
          )
        }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import Theme from '@/service/themes'
const restapi = require('@/service/restapi')

export default {
    name: 'Helpdesk',
    props: ['account', 'user', 'enableAccessTokenGeneration'],
    data() {
        return {
            showWebappSupportAccess: false,
            urlForAccess: null,
            webApp: 'Cameramanager',
        }
    },
    computed: {
        enabledHelpdesk() {
            let isOSAllowing = this.isDesktop || this.isMobile.Android() || this.isMobile.iOS()
            let isUserAllowing = this.user && this.user.allowsHelpdesk
            let isResellerAllowing = this.isSupportAccessEnabled
            return isOSAllowing && (isUserAllowing || isResellerAllowing)
        },
        isSupportAccessEnabled() {
            return this.$store.getters.helpdesk
        }
    },
    mounted() {
        const themeName = config.hasOwnProperty('theme') ? config.theme : 'Cameramanager'
        const theme = new Theme(themeName)
        this.showWebappSupportAccess = theme.links().webappSupportAccess
        this.urlForAccess = theme.links().webapp
        this.webApp = theme.application
    },
    methods: {
        openWebappUrl() {
            const that = this
            this.isLoading(true)
            restapi
                .getWebappHelpdeskUrl(this.user.accountId, this.user.userId)
                .then(function (result) {
                    let formattedURL = result.url.split('com')
                    if (that.webApp != 'Cameramanager') {
                        result.url = that.urlForAccess + formattedURL[1]
                    }
                    that.isLoading(false)
                    let url = result.url + '&skip_app_cookie=true'
                    if (that.$store.getters.cameraId) url += `&cameraId=${encodeURIComponent(that.$store.getters.cameraId)}`
                    if (that.$store.getters.device_brand)
                        url += `&device_brand=${encodeURIComponent(that.$store.getters.device_brand)}`
                    if (that.$store.getters.device_model)
                        url += `&device_model=${encodeURIComponent(that.$store.getters.device_model)}`
                    if (that.$store.getters.device_MAC_address)
                        url += `&device_MAC_address=${encodeURIComponent(that.$store.getters.device_MAC_address)}`
                    if (that.$store.getters.device_serial)
                        url += `&device_serial=${encodeURIComponent(that.$store.getters.device_serial)}`
                    that.$store.dispatch('resetCameraDetails')
                    window.open(url, '_blank')
                })
                .catch(function (error) {
                    console.error(error)
                    that.isLoading(false)
                    that.$store.commit('toastMessage', {
                        showing: true,
                        text: that.$t('Unable to open the Webapp'),
                        timeout: -1,
                        color: 'error',
                        support: true
                    })
                })
        },
        openHelpDesk() {
            const that = this
            if (this.enabledHelpdesk) {
                if (this.isDesktop) {
                    this.isLoading(true)
                    restapi
                        .downloadHelpdeskJnlp(this.user.accountId, this.user.userId)
                        .then(function (result) {
                            that.isLoading(false)
                        })
                        .catch(function (error) {
                            console.error(error)
                            that.isLoading(false)
                            that.$store.commit('toastMessage', {
                                showing: true,
                                text: this.getMessageFromError(error, 'DOWNLOAD_HELPDESK_JNLP'),
                                timeout: -1,
                                color: 'error',
                                support: true
                            })
                        })
                } else if (this.isMobile.Android() || this.isMobile.iOS()) {
                    this.isLoading(true)
                    restapi
                        .getMobileHelpdeskUrl(this.user.accountId, this.user.userId)
                        .then(function (result) {
                            that.isLoading(false)
                            window.location.href = result.url
                        })
                        .catch(function (error) {
                            console.error(error)
                            that.isLoading(false)
                            that.$store.commit('toastMessage', {
                                showing: true,
                                text: that.getMessageFromError(error, 'GET_MOBILE_HELPDESK_URL'),
                                timeout: -1,
                                color: 'error',
                                support: true
                            })
                        })
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/custom";

.open-helpdesk-button {
  min-width: 250px !important;
}

.button-wrapper {
  max-width: 250px !important;
}

#open-helpdesk-button-webapp {
  margin-top: 10px;
}

#helpdesk-component {
  padding: 20px 0;
}
</style>
