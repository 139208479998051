<template>
  <v-row
    class="px-10"
    align="center"
  >
    <v-col>
      <p class="primary--text mb-0">
        {{ $t(module.name) }}
      </p>
      <p class="mb-0">
        {{ $t(module.name + 'Description') }}
      </p>
    </v-col>
    <v-col
      class="ma-0 pa-0"
      cols="auto"
    >
      <v-switch
        v-model="active"
        color="primary"
        :name="module.name"
        inset
        dense
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
    name: 'UserModule',
    props: ['module'],
    computed: {
        active: {
            get () {
                return this.module.enabled
            },
            set (value) {
                this.$emit('change')
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
