import Vue from 'vue'

const state = {
    allUsers: {},
    allInactiveUsers: {},
    foundUsers: [],
    partners: [],
    allPartners: [],
    myPartnerDetails: null,
    partnerDetails: {},
    selectedPartner: {},
    selectedPartnerDetails: {},
    partnerDetailsHash: null,
    partnerDetailsInvalid: false,
    selectedCustomer: {},
    selectedCustomerHash: null,
    availableAccounts: null,
    allAccounts: null,
    allPartnerAccounts: {},
    accountStatistics: {},
    partnerAccountStatistics: {},
    loginAccount: {},
    partnerAccounts: {},
    profiles: [],
    permissions: {},
    distributors: {},
    resellers: {},
    zohoMapping: {},
    customersCount: [],
    myEmail: null,
    resellerCount: [],
    distributorCount: [],
    myAccount: null,
    myPartner: null,
    allDistributors: {},
    allResellers: {}
}

const getters = {
    myAccount: state => {
        return state.myAccount
    },
    myPartner: state => {
        return state.myPartner
    },
    myEmail: state => {
        return state.myEmail
    },
    allUsers: state => {
        return state.allUsers
    },
    users: state => {
        let users = state.allUsers
        if (users && users.hasOwnProperty('0')) {
            return users[0]
        }
        return []
    },
    allInactiveUsers: state => {
        return state.allInactiveUsers
    },
    inactiveUsers: state => {
        let users = state.allInactiveUsers
        if (users && users.hasOwnProperty('0')) {
            return users[0]
        }
        return []
    },
    foundUsers: state => {
        return state.foundUsers
    },
    partners: state => {
        return state.partners
    },
    allPartners: state => {
        return state.allPartners
    },
    myPartnerDetails: state => {
        return state.myPartnerDetails
    },
    partnerDetails: state => {
        return state.partnerDetails
    },
    selectedPartner: state => {
        return state.selectedPartner
    },
    selectedPartnerDetails: state => {
        return state.selectedPartnerDetails
    },
    partnerDetailsHash: state => {
        return state.partnerDetailsHash
    },
    partnerDetailsInvalid: state => {
        return state.partnerDetailsInvalid
    },
    partnerDetailsDirty: state => {
        const hash = require('object-hash')
        return (hash(state.partnerDetails) !== state.partnerDetailsHash ||
      (state.zohoMapping && state.zohoMapping.mapping && state.zohoMapping.zohoId && (state.zohoMapping.zohoId !== state.zohoMapping.mapping.zohoId)))
    },
    selectedCustomer: state => {
        return state.selectedCustomer
    },
    selectedCustomerHash: state => {
        return state.selectedCustomerHash
    },
    selectedCustomerDirty: state => {
        const hash = require('object-hash')
        return (hash(state.selectedCustomer) !== state.selectedCustomerHash ||
      (state.zohoMapping && state.zohoMapping.mapping && state.zohoMapping.zohoId && (state.zohoMapping.zohoId !== state.zohoMapping.mapping.zohoId)))
    },
    availableAccounts: state => {
        return state.availableAccounts
    },
    allAccounts: state => {
        return state.allAccounts
    },
    allPartnerAccounts: state => {
        return state.allPartnerAccounts
    },
    accountStatistics: state => {
        return state.accountStatistics
    },
    partnerAccountStatistics: state => {
        return state.partnerAccountStatistics
    },
    loginAccount: state => {
        return state.loginAccount
    },
    partnerAccounts: state => {
        return state.partnerAccounts
    },
    profiles: state => {
        return state.profiles
    },
    permissions: state => {
        return state.permissions
    },
    zohoMapping: state => {
        return state.zohoMapping
    },
    customersCount: state => {
        return state.customersCount
    },
    resellerCount: state => {
        return state.resellerCount
    },
    distributorCount: state => {
        return state.distributorCount
    },
    allDistributors: state => {
        return state.allDistributors
    },
    allResellers: state => {
        return state.allResellers
    }
}

const mutations = {
    users: (state, users) => {
        state.allUsers = users
    },
    inactiveUsers: (state, users) => {
        state.allInactiveUsers = users
    },
    foundUsers: (state, users) => {
        users = Array.isArray(users) ? users : [users]
        state.foundUsers = users
    },
    partners: (state, partners) => {
        state.partners = []
        if (!partners) {
            partners = []
        } else if (!Array.isArray(partners)) {
            partners = [partners]
        }
        for (var i = 0, len = partners.length; i < len; i++) {
            let p = partners[i]
            p.Logo = p.Logo.replace(/['"]+/g, '')
            state.partners.push(p)
        }
    },
    allPartners: (state, partners) => {
        state.allPartners = []
        if (!partners) {
            partners = []
        } else if (!Array.isArray(partners)) {
            partners = [partners]
        }
        for (var i = 0, len = partners.length; i < len; i++) {
            let p = partners[i]
            p.Logo = p.Logo.replace(/['"]+/g, '')
            state.allPartners.push(p)
        }
    },
    allDistributors: (state, distributors) => {
        state.allDistributors = distributors
    },
    allResellers: (state, resellers) => {
        state.allResellers = resellers
    },
    myPartnerDetails: (state, partnerDetails) => {
        state.myPartnerDetails = partnerDetails
    },
    partnerDetails: (state, partnerDetails) => {
        const hash = require('object-hash')
        state.partnerDetails = partnerDetails
        state.partnerDetails.Logo = partnerDetails.Logo.replace(/['"]+/g, '')
        state.partnerDetailsHash = hash(state.partnerDetails)
    },
    selectedPartner: (state, partner) => {
        state.selectedPartner = partner
    },
    selectedPartnerDetails: (state, partnerDetails) => {
        state.selectedPartnerDetails = partnerDetails
    },
    partnerDetailsHash: (state, hash) => {
        state.partnerDetailsHash = hash
    },
    partnerDetailsInvalid: (state, invalid) => {
        state.partnerDetailsInvalid = invalid
    },
    selectedCustomer: (state, customerDetails) => {
        const hash = require('object-hash')
        state.selectedCustomer = customerDetails
        state.selectedCustomerHash = hash(state.selectedCustomer)
    },
    selectedCustomerHash: (state, hash) => {
        state.selectedCustomerHash = hash
    },
    availableAccounts: (state, accounts) => {
        state.availableAccounts = accounts
    },
    allAccounts: (state, accounts) => {
        state.allAccounts = accounts
    },
    allPartnerAccounts: (state, accounts) => {
        state.allPartnerAccounts[accounts.id] = accounts.data
    },
    accountStatistics: (state, stats) => {
        state.accountStatistics[stats.id] = stats.statistics
    },
    partnerAccountStatistics: (state, statistics) => {
        state.partnerAccountStatistics = statistics
    },
    loginAccount: (state, account) => {
        state.loginAccount = account
        if (!state.myEmail) state.myEmail = account.Email
    },
    partnerAccounts: (state, accounts) => {
        state.partnerAccounts[accounts.id] = accounts.accounts
    },
    profiles: (state, profiles) => {
        state.profiles[profiles.id] = profiles.profiles
    },
    permissions: (state, permissions) => {
        Vue.set(state.permissions, permissions.profileId, permissions.permissions)
    },
    zohoMapping: (state, mapping) => {
        state.zohoMapping = mapping
    },
    customersCount: (state, customersCount) => {
        state.customersCount[customersCount.id] = customersCount.count
    },
    myAccount: (state, account) => {
        state.myAccount = account
    // After replacing ZenDesk with Zoho Desk this does not work anymore
    // if (account) {
    //   // // eslint-disable-next-line no-undef
    //   zE(function () { zE.identify({email: state.myAccount.email}) })
    // }
    },
    myPartner: (state, partner) => {
        state.myPartner = partner
    },
    myEmail: (state, email) => {
        state.myEmail = email
    },
    resellerCount: (state, count) => {
    // Cache for 15 minutes
        state.resellerCount[count.id] = {
            expires: Date.now() + 900000,
            count: count.count
        }
    },
    distributorCount: (state, count) => {
    // Cache for 15 minutes
        state.distributorCount[count.id] = {
            expires: Date.now() + 900000,
            count: count.count
        }
    }
}

export default {
    state,
    getters,
    mutations
}
