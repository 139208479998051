<template>
  <div class="partner-logins">
    <div class="head">
      <h2 v-if="!editMode">
        {{ $t('Partner operators') }}
      </h2>
      <div
        v-if="!editMode"
        class="field search"
      >
        <v-text-field
          v-model="filter"
          class="partner-search"
          type="text"
          placeholder="Search"
        >
          <template #prepend>
            <v-icon
              size="1rem"
              class="pt-1"
            >
              fa fa-search
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <v-btn
        v-if="!editMode"
        large
        class="float-right"
        color="primary"
        @click="isNewPartnerLoginActive=true"
      >
        {{ $t('Add new operator') }}
      </v-btn>
    </div>
    <div
      class="partner-login-container"
      :class="{ 'edit-mode': editMode, 'slim-mode': hasFewAccounts }"
    >
      <partner-login
        v-for="(account, index) in filteredPartnerAccounts"
        v-show="isVisible(account)"
        :key="account.userId"
        :partner-id="partnerId"
        :account="account"
        :index="index"
        :admin-profile-info="adminProfileInfo"
        :profiles="profiles"
        @edit="startEditing"
        @cancel="cancelEditing"
        @remove="removeAccount"
        @change="changeAccount"
      />
    </div>

    <v-dialog
      v-model="isNewPartnerLoginActive"
      height="400px"
      width="600px"
      :active.sync="isNewPartnerLoginActive"
      @close="onPopupClosed()"
    >
      <new-partner-login
        :partner-id="partnerId"
        :profiles="profiles"
        @add="addedPartnerLogin"
        @close-dialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import PartnerLogin from '@/components/partner/PartnerLogin'
import NewPartnerLogin from '@/components/partner/NewPartnerLogin'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

const restapi = require('@/service/restapi')

export default {
    name: 'PartnerLogins',
    components: {
        PartnerLogin,
        NewPartnerLogin
    },
    props: ['partnerId'],
    data () {
        return {
            selected: {},
            editMode: false,
            editing: null,
            addingAccount: false,
            perPage: 10,
            checkedRows: [],
            newAccount: {
                active: 'yes',
                email: null,
                pass: null,
                profile_id: null
            },
            profiles: [],
            partnerAccounts: [],
            isNewPartnerLoginActive: false,
            filter: null
        }
    },
    computed: {
        hasFewAccounts () {
            return this.filteredPartnerAccounts.length < 4
        },
        filteredPartnerAccounts () {
            let accounts = this.sortedPartnerAccounts
            if (this.filter && this.filter.length > 0) {
                const filter = this.filter
                accounts = accounts.filter(function (a) {
                    return a.email.toLowerCase().indexOf(filter) !== -1
                })
            }
            return accounts.sort(function (a, b) { return (a.email > b.email) ? 1 : ((a.email < b.email) ? -1 : 0) })
        },
        sortedPartnerAccounts () {
            if (!this.partnerAccounts) return []
            return this.partnerAccounts.slice(0).sort(function (a, b) {
                if (a.active && b.active) return -1
                else if (!a.active && b.active) return 1
                else if (a.userId < b.userId) return -1
                else return 1
            })
        },
        adminProfile () {
            return this.profiles ? this.profiles.find(function (p) { return p.name === 'Admin' }) : null
        },
        adminAccounts () {
            const adminProfile = this.adminProfile
            if (!this.partnerAccounts || !adminProfile) return []
            return this.partnerAccounts.filter(function (p) { return p.roleId === adminProfile.profileId })
        },
        adminProfileInfo () {
            return {
                profileId: this.adminProfile ? this.adminProfile.profileId : null,
                count: this.adminAccounts.length
            }
        }
    },
    created () {
        this.loadProfilesAndLogins()
    },
    methods: {
        profileName (id) {
            if (!this.profiles) return 'Unknown'
            const profile = this.profiles.find(function (p) { return p.profileId === id }) || { name: 'Unknown' }
            return profile.name
        },
        isActive (status) {
            return (status || status === 'yes')
        },
        statusText (status) {
            if (this.isActive(status)) return 'Active'
            return 'Inactive'
        },
        removeAccount (userId) {
            console.log('Removing account with userId: ' + userId)
            const index = this.partnerAccounts.findIndex(function (a) { return a.userId === userId })
            if (index !== -1) this.partnerAccounts.splice(index, 1)
        },
        changeAccount () {
            const that = this
            restapi.getAccountProfiles(that.partnerId)
                .then(function (profiles) {
                    that.profiles = profiles
                })
                .catch(function (error) {
                    console.error(error)
                })
            this.editMode = false
            this.editing = null
        },
        startEditing (id) {
            this.editMode = true
            this.editing = id
        },
        cancelEditing () {
            this.editMode = false
            this.editing = null
        },
        isVisible (account) {
            return !this.editMode || this.editing === account.userId
        },
        loadProfilesAndLogins () {
            const that = this
            restapi.getAccountProfiles(that.partnerId)
                .then(function (profiles) {
                    that.profiles = profiles
                    restapi.getPartnerAccounts(that.partnerId)
                        .then(function (accounts) {
                            that.partnerAccounts = accounts
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        addedPartnerLogin () {
            console.log('Added partner login')
            const that = this
            restapi.getPartnerAccounts(that.partnerId)
                .then(function (accounts) {
                    that.partnerAccounts = accounts
                    that.isNewPartnerLoginActive = false
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        closeDialog(){
            this.isNewPartnerLoginActive = false
        },
    },
    validations: {
        newAccount: {
            email: {
                required,
                email
            },
            pass: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(32)
            },
            profile_id: {
                required
            }
        }
    },
    watch: {
        partnerId () {
            this.loadProfilesAndLogins()
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';

  .partner-logins {
    padding-bottom: 40px;
    .head {
      width: 900px;
      min-width: 900px;
      margin: 0 auto;
      @media screen and (max-width: 767px) {
        width: 90%;
        min-width: 90%;
        margin-bottom: 50px;
      }
      h2 {
        color: $primary;
        text-transform: uppercase;
        margin: 50px 0 calc(20px + 0.75rem) 0;
        font-size: 14px;
      }
      .field {
        &.search {
          min-width: 50%;
          display: inline-block;
          p.control.has-icons-left {
            border: none;
            .icon {
              height: 40px;
            }

          }
        }
      }
    }
    .partner-login-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      &.edit-mode {
        justify-content: center;
        padding-top: 50px;
      }
      &:not(.edit-mode) {
        &.slim-mode {
          width: 900px;
          min-width: 900px;
          margin: 0 auto;
        }
      }
    }
  }

  .modal.is-active {
    z-index: 250;
  }

  #new-partner-login-0 {
    height: 270px;
    padding: 10px 10px 30px 10px;
    .card-title {
      color: $primary;
      font-weight: bold;
    }
    p.error-message {
      bottom: 0;
    }
  }

  .switch input[type=checkbox] + .check {
    border-radius: 4px;
    width: 100px;
    height: 40px;
  }
  .switch input[type=checkbox] + .check:before {
    border-radius: 4px;
    width: 45px;
    height: 32px;
  }
  .switch .control-label {
    font-size: small;
    position: relative;
    left: -55px;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .switch input[type=checkbox]:checked + .check + .control-label {
    left: -100px;
    color: #FFFFFF;
  }
  .switch .control-label.is-large {
    font-size: large;
    font-weight: bold;
  }
  .switch:focus input[type=checkbox]:checked + .check,
  .switch:focus input[type=checkbox] + .check {
    box-shadow: none;
  }
  .add-account {
    font-size: 64px;
    color: $primary;
    text-align: left;
    width: 100%;
    cursor: pointer;
  }
  .edit-icons {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .edit-icon {
    cursor: pointer;
    color: $primary;
    font-size: large;
  }
  .card .field .control {
    border: none;
  }
  .card .field .select {
    border: none;
  }
  .partner-logins ::v-deep .input,
  .partner-logins ::v-deep .select select,
  .partner-logins ::v-deep .select select:hover,
  .partner-logins ::v-deep .select select:focus {
    height: 25px;
    font-size: 14px;
    text-transform: none;
    padding: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
  }
  .partner-logins ::v-deep .field.has-addons {
    display: block;
  }
  .partner-logins ::v-deep .card > .card-title {
    border-top: none;
    text-align: left;
  }
</style>

