<template>
  <div class="partner-profiles">
    <div class="head">
      <h2 v-if="!editMode">
        {{ $t('Partner roles') }}
      </h2>
      <div
        v-if="!editMode"
        class="field search"
      >
        <v-text-field
          v-model="filter"
          class="partner-search"
          type="text"
          placeholder="Search"
        >
          <template #prepend>
            <v-icon
              size="1rem"
              class="pt-1"
            >
              fa fa-search
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <v-btn
        v-if="!editMode && !dialog"
        large
        class="float-right"
        color="primary"
        @click="dialog=true"
      >
        {{ $t('Add new role') }}
      </v-btn>
    </div>
    <div
      class="partner-profile-container"
      :class="{ 'edit-mode': editMode, 'slim-mode': hasFewProfiles }"
    >
      <partner-profile
        v-for="(profile, index) in filteredPartnerProfiles"
        v-show="isVisible(profile)"
        :key="profile.profileId"
        :partner-id="partnerId"
        :profile="profile"
        :related-accounts="relatedAccountsFor(profile.profileId)"
        :index="index"
        @edit="startEditing"
        @cancel="cancelEditing"
        @updated="updatedProfile"
        @remove="removedPartnerProfile()"
      />
    </div>
    <v-dialog
      v-model="dialog"
      width="600px"
      :active.sync="dialog"
      @close="onPopupClosed()"
    >
      <new-partner-profile
        class="partner-profile-dialog"
        :partner-id="partnerId"
        :invalid-names="profileNames"
        @close-dialog="closeDialog"
        @onCreated="onProfileCreated"
      />
    </v-dialog>
  </div>
</template>

<script>
import PartnerProfile from '@/components/partner/PartnerProfile'
import NewPartnerProfile from '@/components/partner/NewPartnerProfile'

const restapi = require('@/service/restapi')

export default {
    name: 'PartnerProfiles',
    components: {
        PartnerProfile,
        NewPartnerProfile
    },
    props: ['partnerId'],
    data () {
        return {
            editMode: false,
            editing: null,
            selectedProfile: null,
            profiles: [],
            partnerAccounts: [],
            filter: null,
            dialog: false
        }
    },
    computed: {
        hasFewProfiles () {
            return this.filteredPartnerProfiles.length < 4
        },
        filteredPartnerProfiles () {
            let profiles = this.sortedProfiles
            if (this.filter && this.filter.length > 0) {
                const filter = this.filter
                profiles = profiles.filter(function (p) {
                    return p.name.toLowerCase().indexOf(filter) !== -1
                })
            }
            return profiles
        },
        permissions () {
            if (this.myAccount && this.myAccount.hasOwnProperty('profile') && this.myAccount.profile.permissions) {
                return this.myAccount.profile.permissions
            } else {
                return []
            }
        },
        hasProfilePermissions () {
            return this.profilePermissions.length && this.profilePermissions.length > 0
        },
        profilePermissions () {
            return this.$store.getters.permissions[this.selectedProfile]
        },
        relatedAccounts () {
            const pId = this.selectedProfile
            return this.partnerAccounts.filter(function (a) { return a.profileId === pId })
        },
        canDelete () {
            const profileId = this.selectedProfile || 0
            const profiles = this.profiles || []
            const profile = profiles.find(function (p) { return p.profileId === profileId }) || {name: 'Admin'}
            return this.selectedProfile && profile.name !== 'Admin'
        },
        hasRelatedAccounts () {
            return this.relatedAccounts.length > 0
        },
        sortedProfiles () {
            if (!this.profiles) return null
            return this.profiles.slice(0).sort(function (a, b) {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            })
        },
        profileNames () {
            let names = []
            this.profiles.forEach(profile => { names.push(profile.name) })
            return names
        }
    },
    watch: {
        partnerId () {
            this.loadProfiles()
        },
        'active': function (newVal) {
            if (newVal) {
                this.selectedProfile = this.profiles[0].profileId
            }
        },
        'profiles': function (newVal) {
            if (newVal && Array.isArray(this.profiles)) {
                this.selectedProfile = this.profiles[0] ? this.profiles[0].profileId : null
            }
        }
    },
    created () {
        this.loadProfiles()
    },
    mounted () {
        if (this.partnerId) {
            this.loadProfiles()
        }
    },
    methods: {
        onPopupClosed () {
            this.dialog = false
        },
        onProfileCreated () {
            console.log('new profile has been created')
            this.dialog = false
            const that = this
            that.isLoading(true)
            restapi.getAccountProfiles(that.partnerId)
                .then(function (profiles) {
                    that.profiles = profiles
                })
                .catch(function (error) {
                    console.error(error)
                })
                .then(function () {
                    that.isLoading(false)
                })
        },
        loadProfiles () {
            const that = this
            that.isLoading(true)
            restapi.getAccountProfiles(that.partnerId)
                .then(function (profiles) {
                    that.profiles = profiles
                    restapi.getPartnerAccounts(that.partnerId)
                        .then(function (accounts) {
                            that.partnerAccounts = accounts
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                        .then(function () {
                            that.isLoading(false)
                        })
                })
                .catch(function (error) {
                    console.error(error)
                    that.isLoading(false)
                })
        },
        startEditing (id) {
            this.editMode = true
            this.editing = id
        },
        cancelEditing () {
            this.editMode = false
            this.editing = null
        },
        updatedProfile () {
            const that = this
            that.isLoading(true)
            restapi.getAccountProfiles(that.partnerId)
                .then(function (profiles) {
                    that.profiles = profiles
                })
                .catch(function (error) {
                    console.error(error)
                })
                .then(function () {
                    that.isLoading(false)
                })
            this.editMode = false
            this.editing = null
        },
        isVisible (profile) {
            return !this.editMode || this.editing === profile.profileId
        },
        relatedAccountsFor (profileId) {
            return this.partnerAccounts.filter(function (a) { return a.roleId === parseInt(profileId) })
        },
        removedPartnerProfile () {
            const that = this
            that.isLoading(true)
            restapi.getAccountProfiles(that.partnerId)
                .then(function (profiles) {
                    that.profiles = profiles
                })
                .catch(function (error) {
                    console.error(error)
                })
                .then(function () {
                    that.isLoading(false)
                })
        },
        closeDialog: function(){
            this.dialog = false
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';

  .partner-profiles {
    padding-bottom: 40px;
    .head {
      width: 900px;
      min-width: 900px;
      margin: 0 auto;
      @media screen and (max-width: 767px) {
        width: 90%;
        min-width: 90%;
        margin-bottom: 50px;
      }
      h2 {
        color: $primary;
        text-transform: uppercase;
        margin: 50px 0 calc(20px + 0.75rem) 0;
        font-size: 14px;
      }
      .field {
        &.search {
          min-width: 50%;
          display: inline-block;
          p.control.has-icons-left {
            border: none;
            .icon {
              height: 40px;
            }
            input.input {
              margin: 0 0 0 30px;
              padding: 0;
              height: 40px;
              border: none;
              border-bottom: 2px solid #FFFFFF;
              + .icon {
                border-bottom: 2px solid #FFFFFF;
              }
              &:focus {
                border-bottom-color: $primary;
                + .icon {
                  color: $primary;
                  border-bottom-color: $primary;
                }
              }
            }
          }
        }
      }
    }
    .partner-profile-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      &.edit-mode {
        justify-content: center;
      }
      &:not(.edit-mode) {
        &.slim-mode {
          width: 900px;
          min-width: 900px;
          margin: 0 auto;
        }
      }
    }
    .profile-and-rights {
      background-color: #FFFFFF;
      .profile {
        padding: 20px;
      }
      .rights {
        padding: 20px;
      }
    }
  }

  .wrapper {
    display: table;
    width: 100%;
    padding: 25px;
  }
  .edit-icons {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    top: auto;
    right: auto;
    min-width: 20px;
    padding-top: 20px;
  }
  .edit-icon {
    cursor: pointer;
    color: #207cad;
    font-size: large;
  }
  .add-profile .field, .choose-profile .field {
    width: 90%;
    .control {
      border: none;
    }
  }
  .profile-management {
    margin-bottom: 10px;
  }
  p.error-message {
    bottom: 0;
  }
  #partner-profiles .button.add-profile {
    width: 150px;
    font-size: 12px;
    height: 36px !important;  }
  #partner-profiles .button.add-profile i.fa {
    margin-right: 10px;
  }
  #partner-profiles .choose-profile, #partner-profiles .add-profile {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
  }
  #partner-profiles .buttons {
    display: table-cell;
    vertical-align: middle;
    padding-top: 20px;
  }
  #partner-profiles .delete-icon {
    cursor: pointer;
    color: $primary;
    font-size: 18px;
  }
  #partner-profiles ::v-deep .select select,
  #partner-profiles ::v-deep .select select:hover,
  #partner-profiles ::v-deep .select select:focus {
    height: 25px;
    font-size: 14px;
    text-transform: none;
    padding: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
  }
  .column.field {
    margin: 20px 0 30px;
  }

  .partner-profile-dialog{
    background: white;
  }

 </style>

