// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
/* eslint-disable no-unused-vars */
import 'babel-polyfill'
import 'es6-promise/auto'
import 'es6-object-assign/auto'
import 'es6-string-polyfills'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Theme from '@/service/themes'
import App from '@/App'
import Gravatar from '@/components/base/Gravatar'
import Breadcrumb from '@/components/base/Breadcrumb'
import Tab from '@/components/base/Tab'
import router from '@/service/router'
import locale from '@/service/locale'
import { store } from '@/store/index'
import Vuelidate from 'vuelidate'
import { BasicSelect } from 'vue-search-select'
import ListSelect from '@/components/base/ListSelect'
import 'vue-search-select/dist/VueSearchSelect.css'
import Wizard from '@/components/base/Wizard'
import { getMessageFromError } from '@eencloud/core-components/src/service/errors'
import { getConfiguration, getUrl } from '@/service/main'
import VueGtm from 'vue-gtm'
import Vuetify from 'vuetify/lib'
import { VueShowdown } from 'vue-showdown' // remember to import the esm build of vue-showdown, i.e. vue-showdown.esm.js
import CoreComponents from '@eencloud/core-components'

const auth = require('@/service/auth')

Vue.use(CoreComponents)

Vue.component('VueShowdown', VueShowdown)

const config = getConfiguration()

/* eslint-disable no-undef */
if (config) {
    document.title = config.title
    store.commit('configuration', config)
}

// account permissions that are shown in the DD frontend
// do not change the order below, its on purpose according to the document:
// https://docs.google.com/spreadsheets/d/1e9RrfpJe-uUZaMvJ17vgLgNTump7GHwouDtaXKQFhx8/edit#gid=0
export const ALLOWED_ACCOUNT_PERMISSIONS = [
    'Prov.CustomerOverview',
    'Prov.CustomerAdd',
    'Prov.CustomerEdit',
    'Prov.CustomerBlock',
    'Prov.CustomerPassword',
    'Prov.CustomerAccount',
    'Prov.CustomerSubusers',
    'Prov.PartnerOverview',
    'Prov.PartnerAdd',
    'Prov.PartnerManage',
    'Prov.AccountEdit',
    'Prov.GenerateAccessToken',
    'Prov.ManageOauthClient',
    'Prov.InsightsReports',
    'Prov.AccountLogins',
    'Prov.AccountProfiles',
    'Prov.AdminPartner',
    'Prov.OverwriteHelpdesk',
    'Prov.CustomerHelpdesk',
    'Prov.CustomerDeactivate',
    'Prov.CustomerModules',
    'Prov.AdminCustomerService',
    'Prov.DCTT'
]

// account permissions that are added to the account on create
export const INITIAL_ACCOUNT_PERMISSIONS = [
    'Prov.AccountEdit',
    'Prov.AccountLogins',
    'Prov.AccountOverview',
    'Prov.AccountProfiles',
    'Prov.CustomerAccess',
    'Prov.CustomerAccount',
    'Prov.CustomerAdd',
    'Prov.CustomerBlock',
    'Prov.CustomerEdit',
    'Prov.CustomerHelpdesk',
    'Prov.CustomerOverview',
    'Prov.CustomerPassword',
    'Prov.ManageOauthClient',
    'Prov.InvoiceOverview',
    'Prov.InvoiceDetails',
    'Prov.ToolsOfflineCams',
    'Prov.ToolsLSUs'
]

// account permissions that are added (right with INITIAL_ACCOUNT_PERMISSIONS)
// to the account on create if account is distributor type
export const DISTRIBUTOR_ONLY_ACCOUNT_PERMISSIONS = [
    'Prov.PartnerAdd',
    'Prov.PartnerManage',
    'Prov.PartnerOverview'
]

// those permissions should be shown only for CM admin account
export const ADMIN_ACCOUNT_PERMISSIONS = [
    'Prov.CustomerDeactivate',
    'Prov.CustomerModules',
    'Prov.AdminCustomerService'
]

const restapi = require('@/service/restapi')

Vue.prototype.getMessageFromError = function (error, component) {
    return getMessageFromError(error, component)
}

Vue.prototype.getConfiguration = function () {
    /* eslint-disable no-undef */
    return config
}

Vue.prototype.hasPermission = function (permission) {
    let allowed = false
    if (this.$store.getters.myAccount && this.$store.getters.myAccount.profile && this.$store.getters.myAccount.profile.permissions) {
        allowed = !!this.$store.getters.myAccount.profile.permissions[`Prov.${permission}`]
    }
    return allowed
}

Vue.prototype.hasPermissions = function () {
    return !!this.$store.getters.myAccount?.profile?.permissions
}

Vue.prototype.standardDate = function (aDate) {
    let dt
    if (!aDate) return null
    if (typeof aDate === 'string' && aDate.length === 10) {
        dt = aDate.split('-')
        return dt[2] + '-' + dt[1] + '-' + dt[0]
    } else if (typeof aDate === 'object') {
        return aDate.toJSON().substr(0, 10)
    } else if (typeof aDate === 'string') {
        dt = new Date(aDate).toISOString()
        return dt.toJSON().substr(0, 10)
    }
    return null
}

Vue.prototype.isLoading = function (aBoolean) {
    store.commit('isLoading', !!aBoolean)
}

Vue.prototype.goBack = function () {
    window.history.length > 1
        ? router.go(-1)
        : router.push('/')
}

Vue.prototype.isEmpty = function (value) {
    return (value == null || value === '')
}

const isMobile = {
    Android: function () {
        return !!navigator.userAgent.match(/Android/i)
    },
    BlackBerry: function () {
        return !!navigator.userAgent.match(/BlackBerry/i)
    },
    iOS: function () {
        return !!navigator.userAgent.match(/iPhone|iPad|iPod/i)
    },
    Opera: function () {
        return !!navigator.userAgent.match(/Opera Mini/i)
    },
    Windows: function () {
        return !!navigator.userAgent.match(/IEMobile/i)
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows())
    }
}

Vue.prototype.isMobile = isMobile
Vue.prototype.isDesktop = !isMobile.any()

Vue.prototype.resetWalkthroughStatus = function () {
    const that = this
    restapi.setWalkthroughStatus(false)
        .then(function (result) {
            console.log(result)
            that.$store.commit('showSignup', false)
        })
        .catch(function (error) {
            console.error(error)
        })
}

Vue.prototype.getUserToken = function (accountId, userId) {
    restapi.getUserToken(accountId, userId)
        .then(function (result) {
            console.log(result)
        })
        .catch(function (error) {
            console.error(error)
        })
}

Vue.prototype.getAccountStatus = function (token) {
    restapi.getAccountStatus(token)
        .then(function (result) {
            console.log(result)
        })
        .catch(function (error) {
            console.error(error)
        })
}

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.config.silent = !Vue.config.devtools

if (Vue.config.silent) {
    let console = {}
    console.log = function () {}
    console.debug = function () {}
    console.info = function () {}
    console.warn = function () {}
    console.error = function () {}
    window.console = console
}

Vue.use(VueCookies)
Vue.use(Vuetify)
const themeName = config.hasOwnProperty('theme') ? config.theme : 'Cameramanager'
const vuetifyOptions = {
    theme: new Theme(themeName).colors(),
    icons: { iconfont: 'fa' }
}

Vue.use(Vuelidate)

Vue.component('VGravatar', Gravatar)
Vue.component('Tab', Tab)
Vue.component('BasicSelect', BasicSelect)
Vue.component('ListSelect', ListSelect)
Vue.component('Wizard', Wizard)
Vue.component('Breadcrumb', Breadcrumb)

if (config['google-tag-manager']['enabled']) {
    Vue.use(VueGtm, {
        id: `${config['google-tag-manager']['tracking-id']}&gtm_auth=${config['google-tag-manager']['gtm_auth']}&gtm_preview=${config['google-tag-manager']['gtm_preview']}&gtm_cookies_win=x`,
        enabled: true,
        vueRouter: router
    })
}

router.beforeEach((to, from, next) => {
    restapi.cancelAll()

    if (location.search) {
        location.replace(getUrl(to.path, to.query))
    }
    if (to.query.hasOwnProperty('access_token') && to.query.hasOwnProperty('expires_in')) {
        auth.login(to.query.access_token, to.query.expires_in)
        restapi.createSession()
            .then(function (sessionData) {
                store.commit('session', sessionData)
                window.$cookies.set('sessionData', sessionData, '24h')
                restapi.getWalkthroughStatus()
                    .then(function (result) {
                        store.commit('showWalkThrough', !result.isShown)
                    })
                    .catch(function (error) {
                        console.error(error)
                        store.commit('showWalkThrough', false)
                    })
            })
            .catch(function (error) {
                console.error(error)
                throw error
            })
    }
    if (to.query.hasOwnProperty('device_brand')) {
        store.dispatch('setDeviceBrand', to.query.device_brand)
    }
    if (to.query.hasOwnProperty('device_model')) {
        store.dispatch('setDeviceModel', to.query.device_model)
    }
    if (to.query.hasOwnProperty('device_serial')) {
        store.dispatch('setDeviceSerial', to.query.device_serial)
    }
    if (to.query.hasOwnProperty('device_MAC_address')) {
        store.dispatch('setDeviceMAC', to.query.device_MAC_address)
    }
    if (to.query.hasOwnProperty('cameraId')) {
        store.dispatch('setCameraId', to.query.cameraId)
    }
    if (Object.entries(to.query).length > 0 && !location.search) {
        router.replace({ path: to.path, query: {} })
    }
    
    store.dispatch('setAuthorized', auth.isAuthorized())

    // Only reroute if URI contains access token data
    if (!store.getters.isAuthorized && to.meta.auth) {
        if (auth.isLoggedIntoOtherApp()) {
            next({path: '/firstlogout'})
        } else {
            auth.resetClient()
            window.location = auth.oauthUri()
        }
    } else if (store.getters.isAuthorized && to.path !== '/logout') {
        auth.getMyAccount()
            .then(function (myAccount) {
                restapi.getPolicyData(myAccount.accountId)
                    .then(function (result) {
                        if (result.length === 0) {
                            store.commit('acceptedTermsState', false)
                            if (to.path !== '/terms') {
                                next('/terms')
                            } else {
                                next({query: to.query, replace: true})
                            }
                        } else {
                            let configVersion = config['policyVersion']
                            let policyFound = null
                            for (let i = 0; i < result.length; i++) {
                                if (result[i].version === configVersion) {
                                    policyFound = result[i]
                                }
                            }
                            if (!policyFound) {
                                store.commit('acceptedTermsState', false)
                                next('/terms')
                            } else if (!policyFound.accepted) {
                                store.commit('acceptedTerms', {
                                    state: policyFound.accepted,
                                    version: policyFound.version
                                })
                                next('/terms')
                            } else {
                                next()
                            }
                        }
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
            })
            .catch(function (error) {
                console.error(error)
            })
    } else {
        next()
    }
})

router.afterEach((to, from) => {
    document.title = config.title
    store.commit('burgerMenuActive', false)
})

const i18n = locale.i18n

export const vm = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    template: '<App/>',
    vuetify: new Vuetify(vuetifyOptions),
    components: { App }
})
