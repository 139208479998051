<template>
  <div
    id="partner-details"
    class="partner-details"
  >
    <h2>{{ title || $t('Contact information') }}</h2>
    <p class="component-description">
      {{ $t('Contact information partner description') }}
    </p>
    <v-card class="pa-5">
      <v-card-text>
        <v-row :class="{'edit-mode': editMode}">
          <v-col class="column">
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="partner-details-company-input"
                  v-model="companyName"
                  :label="$t('Company')+'*'"
                  dense
                  autocomplete="no"
                  @input="$v.companyName.$touch()"
                />
                <p
                  v-else
                  id="partner-details-company-label"
                  class="content"
                >
                  {{ companyName }}
                </p>
                <span v-if="!editMode">{{ $t("Company") }}</span>
                <p
                  v-if="editMode && $v.companyName.$error && !$v.companyName.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
                <p
                  v-else-if="editMode && $v.companyName.$error && !$v.companyName.minLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a minimum of X characters', { min: $v.companyName.$params.minLength.min }) }}
                </p>
                <p
                  v-else-if="editMode && $v.companyName.$error && !$v.companyName.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.companyName.$params.maxLength.max }) }}
                </p>
                <p
                  v-else-if="editMode && $v.companyName.$error && !$v.companyName.validName"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a valid name') }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="partner-details-reference-input"
                  v-model="reference"
                  :label="$t('Reference')+'*'"
                  dense
                  autocomplete="no"
                  @input="$v.reference.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ reference }}
                </p>
                <span v-if="!editMode">{{ $t("Reference") }}*</span>
                <p
                  v-if="editMode && $v.reference.$error && !$v.reference.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
                <p
                  v-else-if="editMode && $v.reference.$error && !$v.reference.minLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a minimum of X characters', { min: $v.reference.$params.minLength.min }) }}
                </p>
                <p
                  v-else-if="editMode && $v.reference.$error && !$v.reference.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.reference.$params.maxLength.max }) }}
                </p>
                <p
                  v-else-if="editMode && $v.reference.$error && !$v.reference.validReference"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a valid value') }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="partner-details-custom-reference-input"
                  v-model="customReference"
                  :label="$t('Custom Reference')"
                  dense
                  autocomplete="no"
                  @input="$v.customReference.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ customReference }}
                </p>
                <span v-if="!editMode">{{ $t("Custom Reference") }}</span>
                <p
                  v-if="editMode && $v.customReference.$error && !$v.customReference.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.customReference.$params.maxLength.max }) }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="partner-details-email-input"
                  v-model="email"
                  :label="$t('Contact email')+'*'"
                  dense
                  autocomplete="no"
                  @input="$v.contactEmail.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ email }}
                </p>
                <span v-if="!editMode">{{ $t("Contact email") }}*</span>
                <p
                  v-if="editMode && $v.email.$error && !$v.email.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
                <p
                  v-else-if="editMode && $v.email.$error && !$v.email.email"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a valid email address') }}
                </p>
              </div>
            </div>
            <div v-if="editMode">
              <v-row>
                <v-col cols="4">
                  <div class="has-float-label">
                    <v-text-field
                      id="partner-details-firstname-input"
                      v-model="firstName"
                      :label="$t('First name')+'*'"
                      dense
                      autocomplete="no"
                      @input="$v.firstName.$touch()"
                    />
                    <span v-if="!editMode">{{ $t("First name") }}*</span>
                    <p
                      v-if="editMode && $v.firstName.$error && !$v.firstName.required"
                      class="error-message pt-1"
                    >
                      {{ $t('This is a required field') }}
                    </p>
                    <p
                      v-else-if="editMode && $v.firstName.$error && !$v.firstName.minLength"
                      class="error-message pt-1"
                    >
                      {{ $t('Please enter a minimum of X characters', { min: $v.firstName.$params.minLength.min }) }}
                    </p>
                    <p
                      v-else-if="editMode && $v.firstName.$error && !$v.firstName.maxLength"
                      class="error-message pt-1"
                    >
                      {{ $t('Please enter a maximum of X characters', { max: $v.firstName.$params.maxLength.max }) }}
                    </p>
                    <p
                      v-else-if="editMode && $v.firstName.$error && !$v.firstName.validName"
                      class="error-message pt-1"
                    >
                      {{ $t('Please enter a valid name') }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="8">
                  <div class="has-float-label">
                    <v-text-field
                      id="partner-details-lastname-input"
                      v-model="lastName"
                      :label="$t('Last name')+'*'"
                      dense
                      autocomplete="no"
                      @input="$v.lastName.$touch()"
                    />
                    <span v-if="!editMode">{{ $t("Last name") }}*</span>
                    <p
                      v-if="editMode && $v.lastName.$error && !$v.lastName.required"
                      class="error-message pt-1"
                    >
                      {{ $t('This is a required field') }}
                    </p>
                    <p
                      v-else-if="editMode && $v.lastName.$error && !$v.lastName.minLength"
                      class="error-message pt-1"
                    >
                      {{ $t('Please enter a minimum of X characters', { min: $v.lastName.$params.minLength.min }) }}
                    </p>
                    <p
                      v-else-if="editMode && $v.lastName.$error && !$v.lastName.maxLength"
                      class="error-message pt-1"
                    >
                      {{ $t('Please enter a maximum of X characters', { max: $v.lastName.$params.maxLength.max }) }}
                    </p>
                    <p
                      v-else-if="editMode && $v.lastName.$error && !$v.lastName.validName"
                      class="error-message pt-1"
                    >
                      {{ $t('Please enter a valid name') }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div
              v-else
              class="field"
            >
              <div class="has-float-label">
                <p class="content">
                  {{ contactPerson }}
                </p>
                <span>{{ $t("Contact person") }}*</span>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="partner-details-phone-input"
                  v-model="phone"
                  :label="$t('Phone')+'*'"
                  dense
                  autocomplete="no"
                  @input="$v.phone.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ phone }}
                </p>
                <span v-if="!editMode">{{ $t("Phone") }}*</span>
                <p
                  v-if="editMode && $v.phone.$error && !$v.phone.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
                <p
                  v-else-if="$v.phone.$error && !$v.phone.phoneNumber"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a valid phone number') }}
                </p>
                <p
                  v-else-if="editMode && $v.phone.$error && !$v.phone.minLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a minimum of X characters', { min: $v.phone.$params.minLength.min }) }}
                </p>
                <p
                  v-else-if="editMode && $v.phone.$error && !$v.phone.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.phone.$params.maxLength.max }) }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="partner-details-website-input"
                  v-model="website"
                  :label="$t('Website')+'*'"
                  dense
                  autocomplete="no"
                  @input="$v.website.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ website }}
                </p>
                <span v-if="!editMode">{{ $t("Website") }}*</span>
                <p
                  v-if="editMode && $v.website.$error && !$v.website.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
                <p
                  v-else-if="editMode && $v.website.$error && !$v.website.url"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a valid URL') }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="partner-details-vatnumber-input"
                  v-model="vatNumber"
                  :label="$t('VAT number')"
                  dense
                  autocomplete="no"
                  @input="$v.vatNumber.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ vatNumber }}
                </p>
                <span v-if="!editMode">{{ $t("VAT number") }}</span>
                <p
                  v-if="editMode && $v.vatNumber.$error && !$v.vatNumber.validVATNumber"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a valid VAT number') }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode && canEditDiscount"
                  id="partner-details-discount-input"
                  v-model="discountLevel"
                  :label="$t('Discount')"
                  dense
                  autocomplete="no"
                  @input="$v.vatNumber.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ discountLevel }}
                </p>
                <span v-if="!editMode">{{ $t("Discount") }}%</span>
                <p
                  v-if="editMode && $v.discountLevel.$error && !$v.discountLevel.numeric"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a numeric value') }}
                </p>
                <p
                  v-else-if="editMode && $v.discountLevel.$error && !$v.discountLevel.between"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a value in range', {low: getMinDiscountValue, high: getMaxDiscountValue}) }}
                </p>
              </div>
            </div>
            <div
              v-if="editMode || (canShowCrmId && isBillableEntity)"
              class="field"
            >
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode && hasPermission('AdminCustomerService')"
                  id="zohoid-input"
                  v-model="crmId"
                  :label="$t('Zoho ID')"
                  dense
                  autocomplete="no"
                  @input="$v.crmId.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ crmId }}
                </p>
                <span v-if="!editMode || !hasPermission('AdminCustomerService')">{{ $t("Zoho ID") }}</span>
                <p
                  v-if="editMode && $v.crmId.$error && !$v.crmId.numeric"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a numeric value') }}
                </p>
              </div>
            </div>
          </v-col>
          <v-col class="column">
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="partner-details-address-input"
                  v-model="address"
                  :label="$t('Address')+'*'"
                  dense
                  autocomplete="no"
                  @input="$v.address.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ address }}
                </p>
                <span v-if="!editMode">{{ $t("Address") }}*</span>
                <p
                  v-if="editMode && $v.address.$error && !$v.address.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
                <p
                  v-else-if="editMode && $v.address.$error && !$v.address.minLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a minimum of X characters', { min: $v.address.$params.minLength.min }) }}
                </p>
                <p
                  v-else-if="editMode && $v.address.$error && !$v.address.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.address.$params.maxLength.max }) }}
                </p>
                <p
                  v-else-if="editMode && $v.address.$error"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter only valid characters') }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="partner-details-zip-code-input"
                  v-model="zipCode"
                  :label="$t('Zip code')+'*'"
                  dense
                  autocomplete="no"
                  @input="$v.zipCode.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ zipCode }}
                </p>
                <span v-if="!editMode">{{ $t("Zip code") }}*</span>
                <p
                  v-if="editMode && $v.zipCode.$error && !$v.zipCode.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
                <p
                  v-else-if="editMode && $v.zipCode.$error && !$v.zipCode.minLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a minimum of X characters', { min: $v.zipCode.$params.minLength.min }) }}
                </p>
                <p
                  v-else-if="editMode && $v.zipCode.$error && !$v.zipCode.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.zipCode.$params.maxLength.max }) }}
                </p>
                <p
                  v-else-if="editMode && $v.zipCode.$error && !$v.zipCode.alphaNumericSpace"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter only valid characters') }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="partner-details-city-input"
                  v-model="city"
                  :label="$t('City')+'*'"
                  dense
                  autocomplete="no"
                  @input="$v.city.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ city }}
                </p>
                <span v-if="!editMode">{{ $t("City") }}*</span>
                <p
                  v-if="editMode && $v.city.$error && !$v.city.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
                <p
                  v-else-if="editMode && $v.city.$error && !$v.city.minLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a minimum of X characters', { min: $v.city.$params.minLength.min }) }}
                </p>
                <p
                  v-else-if="editMode && $v.city.$error && !$v.city.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.city.$params.maxLength.max }) }}
                </p>
                <p
                  v-else-if="editMode && $v.city.$error && !$v.city.alphaNumericSpace"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter only valid characters') }}
                </p>
              </div>
            </div>
            <div
              class="field select-element"
              :class="{ 'fix-first-select-location': editMode }"
            >
              <div class="has-float-label">
                <v-autocomplete
                  v-if="editMode"
                  id="partner-details-country-input"
                  v-model="country"
                  :label="$t('Country')+'*'"
                  :items="countries"
                  dense
                  class="zIndex100"
                  :placeholder="$t('Select country')"
                  @change="onSelectCountry"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ countryFor(partnerDetails) }}
                </p>
                <span v-if="!editMode">{{ $t("Country")+'*' }}</span>
                <p
                  v-if="editMode && $v.country.$error && !$v.country.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
              </div>
            </div>
            <div class="field select-element">
              <div class="has-float-label">
                <v-autocomplete
                  v-if="editMode"
                  v-model="language"
                  :label="$t('Language')+'*'"
                  :selected-option="selectedLanguage"
                  :items="languages"
                  dense
                  class="zIndex100"
                  :placeholder="$t('Select language')"
                  @change="onSelectLanguage"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ getLanguageLabel(language) }}
                </p>
                <span v-if="!editMode">{{ $t("Language") }}*</span>
                <p
                  v-if="editMode && $v.language.$error && !$v.language.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
              </div>
            </div>
            <div class="field select-element">
              <div class="has-float-label">
                <v-autocomplete
                  v-if="editMode"
                  id="partner-details-timezone-input"
                  v-model="timeZone"
                  :label="$t('Timezone')+'*'"
                  :items="timezones"
                  :selected-option="selectedTimezone"
                  dense
                  class="zIndex100"
                  :placeholder="$t('Select timezone')"
                  @change="onSelectTimezone"
                >
                  <template #item="data">
                    {{ data.item + addOffset(data.item) }}
                  </template>
                </v-autocomplete>
                <p
                  v-else
                  class="content"
                >
                  {{ timezoneFor(partnerDetails) }}
                </p>
                <span v-if="!editMode">{{ $t("Timezone") }}*</span>
                <p
                  v-if="editMode && $v.timeZone.$error && !$v.timeZone.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
              </div>
            </div>
            <div class="field select-element">
              <div class="has-float-label">
                <v-autocomplete
                  v-if="editMode"
                  id="partner-details-currency-input"
                  v-model="currency"
                  :label="$t('Currency')+'*'"
                  :items="currencies"
                  :selected-option="selectedCurrency"
                  dense
                  class="zIndex100"
                  :placeholder="$t('Select currency')"
                  @change="onSelectCurrency"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ $t(currency) }}
                </p>
                <span v-if="!editMode">{{ $t("Currency") }}*</span>
                <p
                  v-if="editMode && $v.currency.$error && !$v.currency.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div
      v-if="isActive && !editMode && hasPermission('PartnerManage')"
      ref="dropDownEditMenu"
      :class="{ 'is-active': isEditActive }"
      class="dropdown edit-menu"
    >
      <v-menu
        offset-y
        auto
      >
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon
              size="1em"
              color="primary"
            >
              fa fa-ellipsis-h
            </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="startEdit">
            <v-list-item-title class="pr-5">
              {{ $t('Edit') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div
      v-if="hasPermission('PartnerManage') && editMode"
      class="control-buttons"
    >
      <v-btn
        id="cancel-partner-button"
        class="mx-2"
        fab
        small
        color="primary"
        @click="cancelEdit()"
      >
        <v-icon dark>
          fas fa-times
        </v-icon>
      </v-btn>
      <v-btn
        id="save-partner-button"
        class="mx-2"
        fab
        small
        color="primary"
        :disabled="!isDirty"
        @click="savePartnerDetails()"
      >
        <v-icon dark>
          fas fa-save
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength, email, url, numeric, between } from 'vuelidate/lib/validators'
import { phoneNumber, validName, validReference, alphaNumericSpace, validVATNumber } from '@/service/main'
import { getLanguageLabel } from '@/service/guiUtils'
import { mapGetters } from 'vuex'
import { getTimeStamp } from '@eencloud/core-components/src/service/time'
import { findTimeZone, getUTCOffset } from 'timezone-support'

const hash = require('object-hash')
const auth = require('@/service/auth')
const restapi = require('@/service/restapi')

const DISCOUNT_MIN_VALUE = 0
const DISCOUNT_MAX_VALUE = 100

export default {
    name: 'ContactInformation',
    props: ['partner', 'partnerType', 'title'],
    data () {
        return {
            partnerDetails: null,
            partnerHash: null,
            isEditActive: false,
            editMode: false,
            editCancelled: false,
            zohoId: null,
            currencies: [
                { 'text': this.$t('USD'), 'value': 'USD' },
                { 'text': this.$t('EUR'), 'value': 'EUR' },
                { 'text': this.$t('YEN'), 'value': 'YEN' },
                { 'text': this.$t('GBP'), 'value': 'GBP' }
            ]
        }
    },
    computed: {
        partnerId () {
            return this.partner && this.partnerType ? (this.partnerType.toLowerCase() === 'reseller' ? this.partner.resellerId : this.partner.distributorId) : null
        },
        crmId: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.crmId : null
            },
            set: function (value) {
                this.partnerDetails.crmId = value
            }
        },
        companyName: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.companyName : null
            },
            set: function (value) {
                this.partnerDetails.companyName = value
            }
        },
        reference: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.reference : null
            },
            set: function (value) {
                this.partnerDetails.reference = value
            }
        },
        customReference: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.customReference : null
            },
            set: function (value) {
                this.partnerDetails.customReference = value
            }
        },
        email: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.email : null
            },
            set: function (value) {
                this.partnerDetails.email = value
            }
        },
        firstName: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.firstName : null
            },
            set: function (value) {
                this.partnerDetails.firstName = value
            }
        },
        lastName: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.lastName : null
            },
            set: function (value) {
                this.partnerDetails.lastName = value
            }
        },
        contactPerson () {
            return this.partnerDetails ? `${this.firstName} ${this.lastName}`.trim() || '' : ''
        },
        phone: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.phone : null
            },
            set: function (value) {
                this.partnerDetails.phone = value
            }
        },
        website: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.website : null
            },
            set: function (value) {
                this.partnerDetails.website = value
            }
        },
        address: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.address : null
            },
            set: function (value) {
                this.partnerDetails.address = value
            }
        },
        zipCode: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.zipCode : null
            },
            set: function (value) {
                this.partnerDetails.zipCode = value
            }
        },
        city: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.city : null
            },
            set: function (value) {
                this.partnerDetails.city = value
            }
        },
        country: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.country : null
            },
            set: function (c) {
                this.partnerDetails.country = c
            }
        },
        language: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.language : null
            },
            set: function (l) {
                this.partnerDetails.language = l
            }
        },
        timeZone: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.timeZone : null
            },
            set: function (tz) {
                this.partnerDetails.timeZone = tz
            }
        },
        currency: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.currency : null
            },
            set: function (c) {
                this.partnerDetails.currency = c
            }
        },
        languages () {
            return this.$store.getters.languages
        },
        countries () {
            return this.$store.getters.countries
        },
        timezones () {
            return this.$store.getters.timeZones
        },
        selectedCountry () {
            if (this.partnerDetails && this.partnerDetails.country && this.countries) {
                let currentCountry = this.partnerDetails.country
                return this.countries.find(function (c) { return c.value === currentCountry })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        selectedLanguage () {
            if (this.partnerDetails && this.partnerDetails.language && this.languages) {
                let currentLanguage = this.partnerDetails.language
                return this.languages.find(function (l) { return l.value === currentLanguage })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        selectedTimezone () {
            if (this.partnerDetails && this.partnerDetails.timeZone && this.timezones) {
                let currentZone = this.partnerDetails.timeZone
                return this.timezones.find(function (tz) { return tz.value === currentZone })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        selectedCurrency () {
            if (this.partnerDetails && this.partnerDetails.currency && this.currencies) {
                let currentCurrency = this.partnerDetails.currency
                return this.currencies.find(function (c) { return c.value === currentCurrency })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        discountLevel: {
            get: function () {
                return this.partnerDetails ? this.partnerDetails.discountLevel : null
            },
            set: function (value) {
                this.partnerDetails.discountLevel = value
            }
        },
        canEditDiscount () {
            if (auth.isAdmin()) {
                return true
            } else if (auth.isDistributor()) {
                return this.myAccount.accountId !== this.partnerId
            } else {
                return false
            }
        },
        canShowCrmId () {
            return auth.isAdmin()
        },
        isBillableEntity () {
            return this.partner ? this.partner.crmId !== null : null
        },
        getMinDiscountValue () {
            return DISCOUNT_MIN_VALUE
        },
        getMaxDiscountValue () {
            return DISCOUNT_MAX_VALUE
        },
        vatNumber: {
            get: function () {
                return this.partnerDetails && this.partnerDetails.hasOwnProperty('vatNumber') ? this.partnerDetails.vatNumber : null
            },
            set: function (value) {
                this.partnerDetails.vatNumber = value.toUpperCase()
            }
        },
        isBrowserIE () {
            return this.$store.getters.isIE
        },
        isDirty () {
            return this.partnerHash !== hash(this.partnerDetails)
        },
        isMine () {
            return auth.getPartnerId() === parseInt(this.partnerId)
        },
        resellers () {
            if (this.partners && this.partnerId) {
                const pId = this.partnerId
                // return this.partners.filter(function (p) { return p.SuperPartnerId === pId.toString() || p.PartnerId === pId.toString() })
                let resellers = this.partners.filter(function (p) { return p.SuperPartnerId === pId.toString() })
                let _partnerDetails = {...this.partnerDetails}
                _partnerDetails.CustomersCount = this.customersCount ? this.customersCount.toString() : '0'
                resellers.push(_partnerDetails)
                return resellers
            } else {
                return []
            }
        },
        isActive () {
            return this.partnerDetails ? this.partnerDetails.active : null
        },
        ...mapGetters([
            'myAccount'
        ])
    },
    watch: {
        partner () {
            this.loadPartnerDetails()
        },
        partnerId () {
            this.loadPartnerDetails()
        }
    },
    created () {
        document.addEventListener('click', this.documentClick)
    },
    destroyed () {
        document.removeEventListener('click', this.documentClick)
    },
    mounted () {
        if (this.partnerId)
            this.loadPartnerDetails() 
    },
    methods: {
        startEdit () {
            if (this.hasPermission('PartnerManage')) {
                this.editMode = true
                this.isEditActive = false
            }
        },
        cancelEdit () {
            this.$v.$reset()
            this.editMode = false
            this.loadPartnerDetails()
        },
        savePartnerInfo () {
            this.$emit('save')
        // this.$store.commit('showNotification', {type: 'info', message: 'Information has been saved.'})
        },
        timezoneFor (partner) {
            if (partner && partner.timeZone) return partner.timeZone.replace(/_/g, ' ')
            return null
        },
        countryFor (partner) {
            if (partner && partner.country && this.countries) {
                let currentCountry = this.partnerDetails.country
                const country = this.countries.find(function (c) { return c.value === currentCountry })
                if (country) return country.text
            }
            return null
        },
        updateValidationState () {
            this.$store.commit('partnerDetailsInvalid', this.$v.$invalid)
        },
        onSelectCountry (c) {
            this.country = c
        },
        onSelectLanguage (l) {
            this.language = l
        },
        onSelectTimezone (tz) {
            this.timeZone = tz
        },
        onSelectCurrency (c) {
            this.currency = c
        },
        async savePartnerDetails () {
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }
            this.$v.$reset()
            if (!this.editCancelled && this.isDirty) {
                let savedZohoId = true
                if (this.crmId !== this.zohoId && this.hasPermission('AdminCustomerService')) {
                    await this.saveZohoId().catch( error => {
                        savedZohoId = false
                    })
                }
                if (savedZohoId) {
                    const that = this
                    const partnerData = this.partnerDetails
                    delete this.partnerDetails.crmId
                    restapi.setPartnerDetails(this.partnerId, this.partnerType, this.partnerDetails)
                        .then(function () {
                            that.partnerHash = hash(partnerData)
                            // update crumb name
                            let crumbs = that.$store.getters.crumbs
                            let lastCrumb = crumbs.pop()
                            if (lastCrumb) {
                                lastCrumb.name = that.companyName
                                crumbs.push(lastCrumb)
                                that.$store.commit('crumbs', crumbs)
                            }
                            that.$store.commit('toastMessage', {
                                showing: true,
                                text: that.$t('Information has been saved'),
                                timeout : 4000,
                                color: 'primary'
                            })
                            that.editMode = false
                        })
                        .catch(function (error) {
                            console.error(error)
                            that.editMode = true
                            that.$store.commit('toastMessage', {
                                showing: true,
                                text: that.getMessageFromError(error, 'UPDATE_RESELLER'),
                                timeout : -1,
                                color: 'error',
                                support: true
                            })
                        })
                }
            }
        },
        saveZohoId () {
            if (this.zohoId) {
                return restapi.updateZohoMapping(this.zohoId, this.partnerDetails.crmId, this.partnerId, this.partnerType.toLowerCase())
                    .then(() => {
                        this.zohoId = this.partnerDetails.crmId
                    })
                    .catch( error => {
                        this.$store.commit('toastMessage', {
                            showing: true,
                            text: this.getMessageFromError(error, 'CREATE_ZOHO_MAPPING'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                        throw error
                    })   
            } else {
                return restapi.createZohoMapping(this.partnerDetails.crmId, this.partnerId, this.partnerType.toLowerCase())
                    .then(() => {
                        this.zohoId = this.partnerDetails.crmId
                    })
                    .catch( error => {
                        this.$store.commit('toastMessage', {
                            showing: true,
                            text: this.getMessageFromError(error, 'CREATE_ZOHO_MAPPING'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                        throw error
                    })  
            }
        },
        getLanguageLabel (code) {
            return getLanguageLabel(this.languages, code)
        },
        loadPartnerDetails () {
            const that = this
            that.partnerDetails = null
            restapi.getPartnerDetails(this.partnerId, this.partnerType)
                .then(function (result) {
                    // Todo: Remove this when REST API for distributors GET details includes vatNumber with null value
                    if (!result.hasOwnProperty('vatNumber')) {
                        result.vatNumber = null
                    }
                    that.partnerDetails = result
                    that.zohoId = that.partnerDetails ? that.partnerDetails.crmId : null
                    that.partnerHash = hash(that.partnerDetails)
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        documentClick (e) {
            let el = this.$refs.dropDownEditMenu
            let target = e.target
            if (el && el !== target && !el.contains(target)) {
                this.isEditActive = false
            }
        },
        addOffset(timezone) {
            const offset = this.getOffset(timezone)
            const hours = offset / 60
            const minutes = offset % 60
            if (hours > 0) return ' ( -' + hours + (minutes ? `:${Math.abs(minutes)}` : '') + ' )'
            else return ' ( +' + Math.floor(Math.abs(hours)) + (minutes ? `:${Math.abs(minutes)}` : '') + ' )'
        },
        getOffset(timeZone) {
            const foundTimeZone = findTimeZone(timeZone)
            const date = new Date()
            const timeZoneOffset = getUTCOffset(date, foundTimeZone)
            return timeZoneOffset.offset
        },
    },
    validations: {
        companyName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
            validName
        },
        reference: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(250),
            validReference
        },
        customReference: {
            maxLength: maxLength(255)
        },
        phone: {
            required,
            phoneNumber,
            minLength: minLength(10),
            maxLength: maxLength(20)
        },
        website: {
            required,
            url
        },
        address: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(250),
        },
        zipCode: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(50),
            alphaNumericSpace
        },
        city: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(50),
            alphaNumericSpace
        },
        email: {
            required,
            email,
            minLength: minLength(8),
            maxLength: maxLength(128)
        },
        firstName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(32),
            validName
        },
        lastName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(32),
            validName
        },
        crmId: {
            numeric
        },
        country: {
            required
        },
        language: {
            required
        },
        timeZone: {
            required
        },
        discountLevel: {
            numeric,
            between: between(DISCOUNT_MIN_VALUE, DISCOUNT_MAX_VALUE)
        },
        currency: {
            required
        },
        vatNumber: {
            validVATNumber
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';
  @import '../../assets/styles/custom';


  .zIndex100{
    z-index: 100;
  }

  .partner-details {
    position: relative;
    padding-bottom: 20px;
    h2 {
      color: $primary;
      text-transform: uppercase;
      margin: 50px 0 0.5714em 0;
      font-size: 14px;
    }
    .columns {
      margin: 0;
      padding: 5px 0;
      .column {
        padding: 0 40px;
        margin: 40px 0;
        .field {
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .deactivate-partner-tooltip {
          left: -40px;
        }
      }
      &.edit-mode {
        .column {
          @media screen and (max-width: 767px) {
            border-left: 8px solid $primary;
            padding-left: 32px;
          }
          @media screen and (min-width: 768px) {
            &:first-child {
              border-left: 8px solid $primary;
              padding-left: 32px;
            }
          }
        }
      }
    }
    .edit-button {
      color: $primary;
      background-color: transparent;
      margin-top: -30px;
      font-size: 18px;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 60px;
      right: 0;
    }
    .dropdown {
      &.edit-menu {
        position: absolute;
        top: 70px;
        right: 10px;
        z-index: 20;
        .dropdown-trigger {
          color: $primary;
          font-weight: bold;
          position: absolute;
          top: 0;
          right: 10px;
          cursor: pointer;
          span {
            font-weight: 600;
            font-size: 18px;
          }
        }
        .dropdown-menu {
          position: relative;
          margin-top: 25px;
          .dropdown-content {
            border-radius: $border-radius;
            .dropdown-item {
              text-align: center;
              padding: 0.375rem 1rem;
              text-transform: uppercase;
              color: $primary;
              &.is-danger {
                color: $color-error-dark;
              }
            }
            hr {
              height: 1px !important;
            }
          }
        }
      }
    }
    .control-buttons {
      padding: 0;
      width: 50px;
      display: inline-block;
      position: absolute;
      right: -35px;
      top: 75px;
      button {
        float: right;
        margin-bottom: 10px;
        font-size: 18px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        vertical-align: middle;
        &.cancel-button {
          color: $primary;
          background-color: #FFFFFF;
          border: 2px solid $primary;
        }
        &.save-button {
          color: #FFFFFF;
          background-color: $primary;
        }
        &.deactivate-button {
          color: #FFFFFF;
          background-color: $primary;
        }
        &.activate-button {
          color: #FFFFFF;
          background-color: $primary;
        }
      }
    }
  }

  label {
    &.is-one-quarter {
      flex: none;
      width: 25%;
    }
    &.is-three-quarters {
      flex: none;
      width: 75%;
    }
    &.is-one-third {
      flex: none;
      width: 33.3333%;
    }
    &.is-two-third {
      flex: none;
      width: 66.6666%;
    }
  }

  .is-edit-mode {
    padding-top: 12px;
  }

  .select-element.fix-first-select-location {
    margin-top: -9px;
  }
</style>
