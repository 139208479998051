<template>
  <div
    v-if="hasPermission('PartnerAdd')"
    class="add-distributor"
  >
    <h2>{{ $t('Add new distributor') }}</h2>
    <add-partner
      ref="distributor"
      :partner-id="partnerId"
      :zoho-id="zohoId"
      partner-type="distributor"
      @created="$emit('created')"
    />
  </div>
</template>

<script>
import AddPartner from '@/components/partner/AddPartner'

export default {
    name: 'AddDistributor',
    components: {
        AddPartner
    },
    props: ['partnerId', 'zohoId'],
    data () {
        return {
        }
    },
    computed: {
    }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/styles/forms";

  .add-distributor {
    h2 {
      color: $primary;
      text-transform: uppercase;
      margin: 50px 0 calc(20px + 0.75rem) 0;
      font-size: 14px;
    }
  }
</style>
