<template>
  <div class="edit-mobile-subscription">
    <mobile-subscription-wizard
      :edit-mode="editMode"
      :available-subscriptions="subscriptions"
      :subscription-s-k-u="subscriptionSKU"
      @onCancel="cancelWizard"
      @onDone="tryUpdateAccountSubscription"
    />
    <v-dialog
      v-model="isModalActive"
      width="600px"
      @close="onPopupClosed()"
    >
      <v-card>
        <v-form @submit.prevent="showChooseSubscriptionPopup">
          <v-card-title class="card-title mb-3">
            <v-icon
              x-large
              class="pr-5"
              color="warning"
            >
              fas fa-exclamation-triangle
            </v-icon>
            {{ $t('Are you sure?') }}
          </v-card-title>
          <v-card-text>
            <p class="subtitle-1 mb-0">
              {{ $t('The mobile subscription for this camera will not be billed anymore.') }}
            </p>
            <p class="subtitle-1 pb-0">
              {{ $t('If this camera only has a mobile connection, it will go offline') }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              large
              text
              class="mr-2"
              color="primary"
              type="button"
              @click="onPopupClosed()"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              large
              color="primary"
              type="button"
              @click="updateAccountSubscription()"
            >
              {{ $t('Set to inactive') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script charset="utf-8">
import MobileSubscriptionWizard from '@/components/account/subscription/mobile/MobileSubscriptionWizard'

const restapi = require('@/service/restapi')

export default {
    name: 'EditMobileSubscription',
    components: {MobileSubscriptionWizard},
    props: ['availableSubscriptions', 'subscription'],
    data () {
        return {
            editMode: true,
            isModalActive: false,
            subscriptionToEdit: null
        }
    },
    computed: {
        resellerId () {
            return this.$route.params.resellerid
        },
        accountId () {
            return this.$route.params.accountid
        },
        userId () {
            return this.$route.params.userid
        },
        subscriptions () {
            let subscriptions = []
            if (this.availableSubscriptions) {
                const nofSubscriptions = this.availableSubscriptions.length
                for (let i = 0; i < nofSubscriptions; i++) {
                    subscriptions.push({
                        sku: this.availableSubscriptions[i].sku,
                        dataCap: this.availableSubscriptions[i].subscriptionTypeDetails ? this.availableSubscriptions[i].subscriptionTypeDetails.dataCap : null
                    })
                }
            }
            // add inactive subscription
            subscriptions.push({
                sku: 'Inactive'
            })
            return subscriptions
        },
        subscriptionSKU () {
            return this.subscription ? { sku: this.subscription.sku, dataCap: this.subscription.dataCap } : ''
        }
    },
    methods: {
        cancelWizard () {
            this.subscriptionToEdit = null
            this.$emit('onCancel')
        },
        onPopupClosed () {
            this.isModalActive = false
        },
        tryUpdateAccountSubscription (subscription) {
            this.subscriptionToEdit = subscription
            if (this.subscriptionToEdit.sku === 'Inactive') {
                this.subscriptionToEdit.sku = 'unassigned'
            }
            if (this.shouldWarnForInactive()) {
                this.isModalActive = true
            } else {
                this.updateAccountSubscription()
            }
        },
        shouldWarnForInactive () {
            return this.subscription.cameraName && this.subscriptionToEdit.sku === 'unassigned'
        },
        updateAccountSubscription () {
            if (this.subscriptionToEdit) {
                let that = this
                this.isLoading(true)
                this.isModalActive = false
                restapi.updateAccountMobileSubscription(this.resellerId, this.accountId, this.subscription.subscriptionId, this.subscriptionToEdit)
                    .then(function (result) {
                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.$t('Mobile subscription has been successfully updated'),
                            timeout : 4000,
                            color: 'primary'
                        })
                        that.$emit('onUpdated', that.subscriptionToEdit)
                    })
                    .catch(function (error) {
                        console.error(error)

                        that.$store.commit('toastMessage', {
                            showing: true,
                            text: that.getMessageFromError(error, 'UPDATE_MOBILE_SUBSCRIPTION'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                        that.$emit('onCancel')
                    })
                    .then(function () {
                        that.isLoading(false)
                    })
            } else {
                console.log('[ERROR] Subscription to edit is null!')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../../../assets/styles/forms';
  @import '../../../../assets/styles/custom';

  .exclamation-mark-image {
    width: 40px;
    path {
      fill: $color-warning;
    }
  }

  .card-title {
      background-color: #F6F6F6;
  }
</style>
