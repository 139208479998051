<template>
  <div id="terms-conditions-block">
    <div class="pa-12">
      <h2>Legal notices and conditions</h2>
      <VueShowdown
        flavor="github"
        :markdown="terms"
      />
    </div>
    <div
      v-if="showConfirmationBar"
      class="confirmation-bar"
    >
      <v-btn
        id="decline-policy-button"
        large
        outlined
        color="primary"
        class="mr-2"
        @click="declinePolicy()"
      >
        {{ $t("Decline") }}
      </v-btn>
      <v-btn
        id="accept-policy-button"
        large
        color="primary"
        @click="acceptPolicy()"
      >
        {{ $t("I agree with the terms") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Theme from '@/service/themes'
const auth = require('@/service/auth')
const restapi = require('@/service/restapi')

export default {
    name: 'Terms',
    computed: {
        terms() {
            const themeName = config.hasOwnProperty('theme') ? config.theme : 'Cameramanager'
            const theme = new Theme(themeName)
            const t = theme.terms()
            if (t) {
                return require(`!raw-loader!../assets/terms/${t}`).default
            } else {
                return require('!raw-loader!../assets/terms/een.txt').default
            }
        },
        showConfirmationBar () {
            return !this.$store.getters.acceptedTerms.state
        }
    },
    methods: {
        acceptPolicy () {
            let policyAccepted = true
            let policyVersion = this.$store.getters.configuration.policyVersion
            let partnerId = this.$store.getters.myAccount.accountId
            let that = this

            if (this.$store.getters.acceptedTerms.version) {
                restapi.updatePolicy(partnerId, policyVersion, policyAccepted)
                    .then(function (result) {
                        that.$store.commit('acceptedTermsState', policyAccepted)
                        that.$store.commit('acceptedTermsVersion', policyVersion)
                        that.$router.push('/')
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
            } else {
                restapi.addPolicy(partnerId, policyVersion, policyAccepted)
                    .then(function (result) {
                        that.$store.commit('acceptedTermsState', policyAccepted)
                        that.$store.commit('acceptedTermsVersion', policyVersion)
                        that.$router.push('/')
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
            }
        },
        declinePolicy () {
            if (this.$store.getters.isAuthorized) {
                auth.doLogout(false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";
  @import "../assets/styles/custom";

  h2 {
    margin-bottom: 30px;
    text-align: center;
  }

  p {
    margin-bottom: 5px;
  }

  article {
    margin-bottom: 25px;
  }

  article p:first-child {
    font-weight: bold!important;
  }

  .confirmation-bar {
    position: fixed;
    bottom: 0;
    max-width: $main-frame-max-width;
    width: $main-frame-width;
    height: 70px;
    background-color: white;
    text-align: center;
    -webkit-box-shadow: 0 0 15px 0 #e4e4e4;
    box-shadow: 0 0 15px 0 #e4e4e4;
    z-index: 2;
    padding-top: 10px;
  }

  #terms-conditions-block {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
  }

  #decline-policy-button {
    color: $primary;
    background-color: $color-border-darker;
    border: none;
    margin-right: 20px;
  }

  #accept-policy-button {
    border: none;
  }
</style>
