export function getLanguageLabel (languages, code) {
    var language = languages.find(function (l) { return l.value === code })
    if (language) {
        return language.text
    } else {
        return ''
    }
}

// get current date in a format yyyy-mm-dd
export function getCurrentDate () {
    var today = new Date()
    return formatDate(today)
}

// format date in a format yyyy-mm-dd
export function formatDate (date) {
    var today = date
    var dd = today.getDate()
    var mm = today.getMonth() + 1
    var yyyy = today.getFullYear()

    if (dd < 10) {
        dd = '0' + dd
    }

    if (mm < 10) {
        mm = '0' + mm
    }
    return yyyy + '-' + mm + '-' + dd
}

// format date in a format yyyy-mm-dd_HH:mm
export function formatDateTime (date) {
    var today = date
    var HH = today.getHours()
    var MM = today.getMinutes()
    var dd = today.getDate()
    var mm = today.getMonth() + 1
    var yyyy = today.getFullYear()

    if (HH < 10) {
        HH = '0' + HH
    }

    if (MM < 10) {
        MM = '0' + MM
    }

    if (dd < 10) {
        dd = '0' + dd
    }

    if (mm < 10) {
        mm = '0' + mm
    }
    return yyyy + '-' + mm + '-' + dd + '_' + HH + ':' + MM
}
