import { render, staticRenderFns } from "./PartnerModule.vue?vue&type=template&id=402ec2b5&scoped=true"
import script from "./PartnerModule.vue?vue&type=script&lang=js"
export * from "./PartnerModule.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "402ec2b5",
  null
  
)

export default component.exports