<template>
  <div
    v-if="!isLoggedIn"
    class="content-wrapper"
  >
    <div class="content">
      <div class="container">
        <h2>{{ $t('You are now logged out. To login again click') }} <a href="/">{{ $t('here') }}</a>.</h2>
      </div>
    </div>
  </div>
</template>

<script>
// Because of a Chrome issue we needed to implement this special logout component
const auth = require('@/service/auth')

export default {
    computed: {
        isLoggedIn () {
            return this.$store.getters.isAuthorized
        }
    },
    beforeMount () {
        if (this.isLoggedIn) {
            auth.doLogout(false)
        } else {
            auth.deleteToken()
        }
    }
}
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";

  .content-wrapper {
    .content {
      width: 100%;
      background-color: $color-shadow-light;
      .container {
        position: relative;
        padding-top: 40px;
        padding-bottom: 20px;
        width: 90%;
        min-width: 90%;
      }
    }
  }
</style>
