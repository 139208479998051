<template>
  <div class="account-information">
    <h2>{{ $t('Contact information') }}</h2>
    <p class="component-description">
      {{ $t('Contact information description') }}
    </p>
    <v-card class="pa-5">
      <v-card-subtitle>
        <v-row :class="{ 'edit-mode': editMode }">
          <v-col class="column is-half">
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="account-details-company-input"
                  v-model="companyName"
                  :label="$t('Company')"
                  dense
                  autocomplete="no"
                  @input="$v.companyName.$touch()"
                />
                <p
                  v-else
                  id="account-details-company-label"
                  class="content"
                >
                  {{ companyName }}
                </p>
                <span v-if="!editMode">{{ $t("Company") }}</span>
                <p
                  v-if="editMode && $v.companyName.$error && !$v.companyName.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.companyName.$params.maxLength.max }) }}
                </p>
                <p
                  v-else-if="editMode && $v.companyName.$error && !$v.companyName.validName"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a valid name') }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="add-user-reference-input"
                  v-model="reference"
                  :label="$t('Reference')+'*'"
                  dense
                  autocomplete="no"
                  @input="$v.reference.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ reference }}
                </p>
                <span v-if="!editMode">{{ $t("Customer reference") }}*</span>
                <p
                  v-if="editMode && $v.reference.$error && !$v.reference.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
                <p
                  v-else-if="editMode && $v.reference.$error && !$v.reference.minLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a minimum of X characters', { min: $v.reference.$params.minLength.min }) }}
                </p>
                <p
                  v-else-if="editMode && $v.reference.$error && !$v.reference.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.reference.$params.maxLength.max }) }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="add-user-custom-reference-input"
                  v-model="customReference"
                  :label="$t('Custom Reference')"
                  dense
                  autocomplete="no"
                  @input="$v.customReference.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ customReference }}
                </p>
                <span v-if="!editMode">{{ $t("Custom Reference") }}</span>
                <p
                  v-else-if="editMode && $v.customReference.$error && !$v.customReference.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.customReference.$params.maxLength.max }) }}
                </p>
              </div>
            </div>
            <div v-if="editMode">
              <v-row>
                <v-col cols="4">
                  <div class="has-float-label">
                    <v-text-field
                      id="partner-details-firstname-input"
                      v-model="firstName"
                      :label="$t('First name')+'*'"
                      dense
                      autocomplete="no"
                      @input="$v.firstName.$touch()"
                    />
                    <span v-if="!editMode">{{ $t("First name") }}*</span>
                    <p
                      v-if="editMode && $v.firstName.$error && !$v.firstName.required"
                      class="error-message pt-1"
                    >
                      {{ $t('This is a required field') }}
                    </p>
                    <p
                      v-else-if="editMode && $v.firstName.$error && !$v.firstName.minLength"
                      class="error-message pt-1"
                    >
                      {{ $t('Please enter a minimum of X characters', { min: $v.firstName.$params.minLength.min }) }}
                    </p>
                    <p
                      v-else-if="editMode && $v.firstName.$error && !$v.firstName.maxLength"
                      class="error-message pt-1"
                    >
                      {{ $t('Please enter a maximum of X characters', { max: $v.firstName.$params.maxLength.max }) }}
                    </p>
                    <p
                      v-else-if="editMode && $v.firstName.$error && !$v.firstName.validName"
                      class="error-message pt-1"
                    >
                      {{ $t('Please enter a valid name') }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="8">
                  <div class="has-float-label">
                    <v-text-field
                      id="add-user-last-name-input"
                      v-model="lastName"
                      :label="$t('Last name')+'*'"
                      dense
                      autocomplete="no"
                      @input="$v.lastName.$touch()"
                    />
                    <span v-if="!editMode">{{ $t("Last name") }}*</span>
                    <p
                      v-if="editMode && $v.lastName.$error && !$v.lastName.required"
                      class="error-message pt-1"
                    >
                      {{ $t('This is a required field') }}
                    </p>
                    <p
                      v-else-if="editMode && $v.lastName.$error && !$v.lastName.minLength"
                      class="error-message pt-1"
                    >
                      {{ $t('Please enter a minimum of X characters', { min: $v.lastName.$params.minLength.min }) }}
                    </p>
                    <p
                      v-else-if="editMode && $v.lastName.$error && !$v.lastName.maxLength"
                      class="error-message pt-1"
                    >
                      {{ $t('Please enter a maximum of X characters', { max: $v.lastName.$params.maxLength.max }) }}
                    </p>
                    <p
                      v-else-if="editMode && $v.lastName.$error && !$v.lastName.validName"
                      class="error-message pt-1"
                    >
                      {{ $t('Please enter a valid name') }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div
              v-else
              class="field"
            >
              <div class="has-float-label">
                <p class="content">
                  {{ accountName }}
                </p>
                <span>{{ $t("Customer name") }}*</span>
              </div>
            </div>
            <div class="field">
              <label class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  v-model="phone"
                  :label="$t('Phone')"
                  dense
                  autocomplete="no"
                  @input="$v.phone.$touch()"
                />
                <p
                  v-else
                  class="content"
                >{{ phone }}</p>
                <span v-if="!editMode">{{ $t("Phone") }}</span>
                <p
                  v-if="$v.phone.$error && !$v.phone.phoneNumberCustomer"
                  class="error-message pt-1"
                >{{ $t('Please enter a valid phone number') }}</p>
                <p
                  v-else-if="editMode && $v.phone.$error && !$v.phone.minLength"
                  class="error-message pt-1"
                >{{ $t('Please enter a minimum of X characters', { min: $v.phone.$params.minLength.min }) }}</p>
                <p
                  v-else-if="editMode && $v.phone.$error && !$v.phone.maxLength"
                  class="error-message pt-1"
                >{{ $t('Please enter a maximum of X characters', { max: $v.phone.$params.maxLength.max }) }}</p>
              </label>
            </div>
            <div
              v-if="active"
              class="field"
            >
              <label class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  v-model="contactEmail"
                  :label="$t('Email')+'*'"
                  dense
                  autocomplete="no"
                  @input="$v.contactEmail.$touch()"
                />
                <p
                  v-else
                  class="content"
                >{{ contactEmail }}</p>
                <span v-if="!editMode">  {{ $t("Contact email") }}*</span>
                <p
                  v-if="editMode && $v.contactEmail.$error && !$v.contactEmail.required"
                  class="error-message pt-1"
                >{{ $t('This is a required field') }}</p>
                <p
                  v-else-if="editMode && $v.contactEmail.$error && !$v.contactEmail.email"
                  class="error-message pt-1"
                >{{ $t('Please enter a valid email address') }}</p>
                <p
                  v-else-if="editMode && $v.contactEmail.$error && !$v.contactEmail.minLength"
                  class="error-message pt-1"
                >{{ $t('Please enter a minimum of X characters', { min: $v.contactEmail.$params.minLength.min }) }}</p>
                <p
                  v-else-if="editMode && $v.contactEmail.$error && !$v.contactEmail.maxLength"
                  class="error-message pt-1"
                >{{ $t('Please enter a maximum of X characters', { max: $v.contactEmail.$params.maxLength.max }) }}</p>
              </label>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="partner-details-vatnumber-input"
                  v-model="vatNumber"
                  :label="$t('VAT number')"
                  dense
                  autocomplete="no"
                  @input="$v.vatNumber.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ vatNumber }}
                </p>
                <span v-if="!editMode">{{ $t("VAT number") }}</span>
                <p
                  v-if="editMode && $v.vatNumber.$error && !$v.vatNumber.validVATNumber"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a valid VAT number') }}
                </p>
              </div>
            </div>
            <div
              v-if="editMode || (canShowCrmId && isBillableEntity)"
              class="field"
            >
              <label class="has-float-label">
                <v-text-field
                  v-if="editMode && hasPermission('AdminCustomerService')"
                  id="zohoid-input"
                  v-model="crmId"
                  :label="$t('Zoho ID')"
                  dense
                  autocomplete="no"
                  @input="$v.crmId.$touch()"
                />
                <p
                  v-else
                  class="content"
                >{{ crmId }}</p>
                <span v-if="!editMode || !hasPermission('AdminCustomerService')">{{ $t("Zoho ID") }}</span>
                <p
                  v-if="editMode && $v.crmId.$error && !$v.crmId.numeric"
                  class="error-message pt-1"
                >{{ $t('Please enter a numeric value') }}</p>
              </label>
            </div>
            <v-row
              v-if="!editMode"
              no-gutters
              :class="{ 'edit-mode': editMode }"
            >
              <div>
                <div class="field pl-2 pr-4">
                  <label class="has-float-label">
                    <p class="content">{{ numberOfLogins }}</p>
                    <span>{{ $t("Number of logins") }}</span>
                  </label>
                </div>
              </div>
              <div>
                <div class="field">
                  <label class="has-float-label">
                    <p class="content">{{ lastLogin }}</p>
                    <span>{{ $t("Last login") }}</span>
                  </label>
                </div>
              </div>
            </v-row>
          </v-col>
          <v-col class="column is-half">
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="add-user-address-input"
                  v-model="address"
                  :label="$t('Address')"
                  dense
                  autocomplete="no"
                  @input="$v.address.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ address }}
                </p>
                <span v-if="!editMode">{{ $t("Address") }}</span>
                <p
                  v-if="$v.address.$error && !$v.address.minLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a minimum of X characters', { min: $v.address.$params.minLength.min }) }}
                </p>
                <p
                  v-else-if="$v.address.$error && !$v.address.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.address.$params.maxLength.max }) }}
                </p>
                <p
                  v-else-if="$v.address.$error"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter only valid characters') }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  v-model="zipCode"
                  :label="$t('Zip code')"
                  dense
                  autocomplete="no"
                  @input="$v.zipCode.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ zipCode }}
                </p>
                <span v-if="!editMode">{{ $t("Zip code") }}</span>
                <p
                  v-if="$v.zipCode.$error && !$v.zipCode.minLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a minimum of X characters', { min: $v.zipCode.$params.minLength.min }) }}
                </p>
                <p
                  v-else-if="$v.zipCode.$error && !$v.zipCode.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.zipCode.$params.maxLength.max }) }}
                </p>
                <p
                  v-else-if="$v.zipCode.$error && !$v.zipCode.alphaNumericSpace"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter only valid characters') }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="has-float-label">
                <v-text-field
                  v-if="editMode"
                  id="add-user-city-input"
                  v-model="city"
                  :label="$t('City')"
                  dense
                  autocomplete="no"
                  @input="$v.city.$touch()"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ city }}
                </p>
                <span v-if="!editMode">{{ $t("City") }}</span>
                <p
                  v-if="$v.city.$error && !$v.city.minLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a minimum of X characters', { min: $v.city.$params.minLength.min }) }}
                </p>
                <p
                  v-else-if="$v.city.$error && !$v.city.maxLength"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter a maximum of X characters', { max: $v.city.$params.maxLength.max }) }}
                </p>
                <p
                  v-else-if="$v.city.$error && !$v.city.alphaNumericSpace"
                  class="error-message pt-1"
                >
                  {{ $t('Please enter only valid characters') }}
                </p>
              </div>
            </div>
            <div class="field select-element fix-first-select-location">
              <div class="has-float-label">
                <v-autocomplete
                  v-if="editMode"
                  v-model="country"
                  :label="$t('Country')"
                  :items="countries"
                  dense
                  class="zIndex100"
                  :selected-option="selectedCountry"
                  :placeholder="$t('Select country')"
                  @change="onSelectCountry"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ countryFor(account) }}
                </p>
                <span v-if="!editMode">{{ $t("Country") }}</span>
              </div>
            </div>
            <div class="field select-element">
              <div class="has-float-label">
                <v-autocomplete
                  v-if="editMode"
                  id="languageSelect"
                  v-model="language"
                  :label="$t('Language')+'*'"
                  :items="languages"
                  dense
                  class="zIndex100"
                  :selected-option="selectedLanguage"
                  :placeholder="$t('Select language')"
                  @change="onSelectLanguage"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ getLanguageLabel(language) }}
                </p>
                <span v-if="!editMode">{{ $t("Language") }}*</span>
                <p
                  v-if="$v.language.$error && !$v.language.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
              </div>
            </div>
            <div class="field select-element">
              <div class="has-float-label">
                <v-autocomplete
                  v-if="editMode"
                  id="timezoneSelect"
                  v-model="timeZone"
                  :label="$t('Timezone')+'*'"
                  :items="timezones"
                  :selected-option="selectedTimezone"
                  dense
                  class="zIndex100"
                  :placeholder="$t('Select timezone')"
                  @change="onSelectTimezone"
                >
                  <template #item="data">
                    {{ data.item + addOffset(data.item) }}
                  </template>
                </v-autocomplete>
                <p
                  v-else
                  class="content"
                >
                  {{ timeZone }}
                </p>
                <span v-if="!editMode">{{ $t("Timezone") }}*</span>
                <p
                  v-if="$v.timeZone.$error && !$v.timeZone.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
              </div>
            </div>
            <div class="field select-element">
              <div class="has-float-label">
                <v-autocomplete
                  v-if="editMode"
                  id="add-user-timezone-input"
                  v-model="currency"
                  :label="$t('Currency')+'*'"
                  :items="currencies"
                  :selected-option="selectedCurrency"
                  dense
                  class="zIndex100"
                  :placeholder="$t('Select currency')"
                  @change="onSelectCurrency"
                />
                <p
                  v-else
                  class="content"
                >
                  {{ $t(currency) }}
                </p>
                <span v-if="!editMode">{{ $t("Currency") }}*</span>
                <p
                  v-if="editMode && $v.currency.$error && !$v.currency.required"
                  class="error-message pt-1"
                >
                  {{ $t('This is a required field') }}
                </p>
              </div>
            </div>
            <v-row v-if="!editMode">
              <div>
                <div class="field pl-2 pr-4">
                  <label class="has-float-label">
                    <p class="content">{{ clusterId }}</p>
                    <span>{{ $t("Datacenter ID") }}</span>
                  </label>
                </div>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-card>
    <div
      v-if="!editMode && active && hasPermission('CustomerEdit')"
      ref="dropDownEditMenu"
      :class="{ 'is-active': isEditActive }"
      class="dropdown edit-menu"
    >
      <v-menu
        offset-y
        auto
      >
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon
              size="1em"
              color="primary"
            >
              fa fa-ellipsis-h
            </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="edit">
            <v-list-item-title class="pr-5">
              {{ $t('Edit') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div
      v-if="hasPermission('CustomerEdit')"
      class="control-buttons"
    >
      <div
        v-if="editMode"
        class="is-pulled-right"
      >
        <v-btn
          class="mx-2"
          fab
          small
          color="primary"
          @click="cancelSaveCustomerInfo()"
        >
          <v-icon dark>
            fas fa-times
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          fab
          small
          color="primary"
          :disabled="!isDirty || $v.$invalid"
          @click="saveCustomerInfo()"
        >
          <v-icon dark>
            fas fa-save
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
import { required, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import { getLanguageLabel } from '@/service/guiUtils'
import { getConfiguration, phoneNumberCustomer, alphaNumericSpace, validName, validVATNumber } from '@/service/main'
import { getTimeStamp } from '@eencloud/core-components/src/service/time'
import { findTimeZone, getUTCOffset } from 'timezone-support'

const config = getConfiguration()
const hash = require('object-hash')
const auth = require('@/service/auth')
const restapi = require('@/service/restapi')

export default {
    name: 'ContactInformation',
    props: ['account'],
    data () {
        return {
            isEditActive: false,
            editMode: false,
            hash: null,
            currencies: [
                { 'text': this.$t('USD'), 'value': 'USD' },
                { 'text': this.$t('EUR'), 'value': 'EUR' },
                { 'text': this.$t('YEN'), 'value': 'YEN' },
                { 'text': this.$t('GBP'), 'value': 'GBP' }
            ],
            zohoId: null
        }
    },
    computed: {
        active () {
            if (this.account) {
                return this.account.active
            }
            return null
        },
        accountOwnerId () {
            if (this.account) {
                return this.account.accountOwnerId
            }
            return null
        },
        subscriptionId () {
            if (this.account) {
                return this.account.subscriptionId
            }
            return null
        },
        partnerId () {
            if (this.account) {
                return this.account.resellerId
            }
            return null
        },
        superPartnerId () {
            if (this.selectedPartner) {
                let superPartnerId = this.selectedPartner.SuperPartnerId
                if (superPartnerId === '-1') {
                    superPartnerId = this.partnerId
                }
                return superPartnerId
            }
            return null
        },
        distributor () {
            if (this.partnerId) {
                let _this = this
                let d = this.partners.filter(function (p) {
                    return p.SuperPartnerId === '-1' && p.IsActive === 'yes' && (p.PartnerId === _this.superPartnerId.toString())
                })
                if (d && d.length > 0) {
                    return d[0].Company
                }
            }
            return ''
        },
        reseller () {
            if (this.partnerDetails) {
                return this.partnerDetails.Company
            }
            return ''
        },
        partnerDetails: {
            get: function () {
                return this.$store.getters.partnerDetails
            }
        },
        partners: {
            get: function () {
                return this.$store.getters.partners
            }
        },
        id () {
            if (this.account) {
                return this.account ? this.account.accountId : null
            }
            return null
        },
        companyName: {
            get: function () {
                return this.account ? this.account.companyName : null
            },
            set: function (value) {
                this.account.companyName = value
            }
        },
        reference: {
            get: function () {
                return this.account ? this.account.reference : null
            },
            set: function (value) {
                this.account.reference = value
            }
        },
        customReference: {
            get: function () {
                return this.account ? this.account.customReference : null
            },
            set: function (value) {
                this.account.customReference = value
            }
        },
        contactEmail: {
            get: function () {
                return this.account ? this.account.contactEmail : null
            },
            set: function (value) {
                this.account.contactEmail = value
            }
        },
        phone: {
            get: function () {
                return this.account ? this.account.phone : null
            },
            set: function (value) {
                this.account.phone = value
            }
        },
        accountName () {
            return this.account ? `${this.firstName} ${this.lastName}`.trim() || '' : ''
        },
        firstName: {
            get: function () {
                return this.account ? this.account.firstName : null
            },
            set: function (value) {
                this.account.firstName = value
            }
        },
        lastName: {
            get: function () {
                return this.account ? this.account.lastName : null
            },
            set: function (value) {
                this.account.lastName = value
            }
        },
        address: {
            get: function () {
                return this.account ? this.account.address : null
            },
            set: function (value) {
                this.account.address = value
            }
        },
        zipCode: {
            get: function () {
                // return this.account ? this.account.zipCode : null
                if (!this.account) return null
                return this.account.hasOwnProperty('zipCode') ? this.account.zipCode : null
            },
            set: function (value) {
                this.account.zipCode = value
            }
        },
        city: {
            get: function () {
                // return this.account ? this.account.city : null
                if (!this.account) return null
                return this.account.hasOwnProperty('city') ? this.account.city : null
            },
            set: function (value) {
                this.account.city = value
            }
        },
        country: {
            get: function () {
                return this.account ? this.account.country : null
            },
            set: function (l) {
                this.account.country = l
            }
        },
        language: {
            get: function () {
                return this.account ? this.account.language : null
            },
            set: function (l) {
                this.account.language = l
            }
        },
        lastLogin () {
            return this.account && this.account.lastLogin ? 
                getTimeStamp(this.$store.getters.myPartner.timeZoneOffset, new Date(this.account.lastLogin)).slice(0,17) 
                : '-'
        },
        clusterId () {
            return this.account?.clusterId
               
        },
        vatNumber: {
            get: function () {
                if (!this.account) return null
                return this.account.hasOwnProperty('vatNumber') ? this.account.vatNumber : null
            },
            set: function (value) {
                this.account.vatNumber = value.toUpperCase()
            }
        },
        selectedLanguage () {
            if (this.account && this.account.language && this.languages) {
                let currentLanguage = this.account.language
                return this.languages.find(function (l) { return l.value === currentLanguage })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        selectedCountry () {
            if (this.account && this.account.country && this.countries) {
                let currentCountry = this.account.country
                return this.countries.find(function (l) { return l.value === currentCountry })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        timeZone: {
            get: function () {
                return this.account ? this.account.timeZone : null
            },
            set: function (tz) {
                this.account.timeZone = tz
            }
        },
        currency: {
            get: function () {
                if (!this.account) {
                    return null
                } else {
                    let _account = {...this.account}
                    if (!_account.hasOwnProperty('currency')) {
                        _account.currency = null
                    }
                    return _account.currency
                }
            },
            set: function (c) {
                this.account.currency = c
            }
        },
        crmId: {
            get: function () {
                return this.account ? this.account.crmId : null
            },
            set: function (value) {
                this.account.crmId = value
            }
        },
        numberOfLogins () {
            return this.account ? this.account.numberOfLogins : null
        },
        languages () {
            return this.$store.getters.languages
        },
        countries () {
            return this.$store.getters.countries
        },
        timezones () {
            return this.$store.getters.timeZones
        },
        selectedTimezone () {
            if (this.account && this.account.timeZone && this.timezones) {
                const currentZone = this.account.timeZone
                return this.timezones.find(function (tz) { return tz.value === currentZone })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        selectedCurrency () {
            if (this.account && this.account.currency && this.currencies) {
                let currentCurrency = this.account.currency
                return this.currencies.find(function (c) { return c.value === currentCurrency })
            } else {
                return {
                    value: '',
                    text: ''
                }
            }
        },
        isBrowserIE () {
            return this.$store.getters.isIE
        },
        isDirty () {
            return this.hash !== hash(this.account)
        },
        showCloseButton () {
            return !this.hideCloseButton
        },
        isAdmin () {
            return auth.isAdmin()
        },
        isBlocked () {
            return !!(this.account && this.account.IsBlocked === 'yes')
        },
        selectedPartner: {
            get: function () {
                return this.$store.getters.selectedPartner
            }
        },
        canShowCrmId () {
            return auth.isAdmin()
        },
        isBillableEntity () {
            return this.account ? this.account.crmId !== null : null
        }
    },
    created () {
        document.addEventListener('click', this.documentClick)
    },
    destroyed () {
        document.removeEventListener('click', this.documentClick)
    },
    methods: {
        edit () {
            this.editMode = true
            this.isEditActive = false
            this.hash = hash(this.account)
        },
        async saveCustomerInfo () {
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }
            this.$v.$reset()
            const that = this
            if (!this.editCancelled && this.isDirty) {
                let savedZohoId = true
                if (this.crmId !== this.zohoId && this.hasPermission('AdminCustomerService')) {
                    await this.saveZohoId().catch( error => {
                        savedZohoId = false
                    })
                }
                if (savedZohoId) {
                    let data = {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        contactEmail: this.contactEmail,
                        address: this.address,
                        zipCode: this.zipCode,
                        city: this.city,
                        country: this.country,
                        companyName: this.companyName,
                        reference: this.reference,
                        customReference: this.customReference,
                        phone: this.phone,
                        vatNumber: this.vatNumber,
                        language: this.language,
                        currency: this.currency,
                        timeZone: this.timeZone,
                        accountOwnerId: this.accountOwnerId,
                        active: this.active,
                        subscriptionId: this.subscriptionId,
                        accountId: this.id,
                        resellerId: this.partnerId
                    }

                    restapi.setAccount(this.account.resellerId, this.account.accountId, data)
                        .then(function (result) {
                            console.log('Saved account details')
                            that.editMode = !that.editMode
                            that.$store.commit('selectedCustomerHash', hash(that.account))
                            that.$store.commit('toastMessage', {
                                showing: true,
                                text: that.$t('Information has been saved'),
                                timeout : 4000,
                                color: 'primary'
                            })
                            that.$emit('changed')
                        })
                        .catch(function (error) {
                            that.$emit('editMode', true)
                            that.$store.commit('toastMessage', {
                                showing: true,
                                text: that.getMessageFromError(error, 'SET_ACCOUNT'),
                                timeout : -1,
                                color: 'error',
                                support: true
                            })
                        })
                }
            } else {
                console.log('Customer details not changed!')
            }
        },
        cancelSaveCustomerInfo () {
            this.$v.$reset()
            this.editMode = false
            this.$emit('canceled')
        },
        saveZohoId () {
            if (this.zohoId) {
                return restapi.updateZohoMapping(this.zohoId, this.crmId, this.account.accountId, 'customer')
                    .then(() => {
                        this.zohoId = this.crmId
                    })
                    .catch( error => {
                        this.$store.commit('toastMessage', {
                            showing: true,
                            text: this.getMessageFromError(error, 'CREATE_ZOHO_MAPPING'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                        throw error
                    })
            } else {
                return restapi.createZohoMapping(this.crmId, this.account.accountId, 'customer')
                    .then(() => {
                        this.zohoId = this.crmId
                    })
                    .catch( error => {
                        this.$store.commit('toastMessage', {
                            showing: true,
                            text: this.getMessageFromError(error, 'CREATE_ZOHO_MAPPING'),
                            timeout : -1,
                            color: 'error',
                            support: true
                        })
                        throw error
                    })
            }
        },
        onSelectCountry (c) {
            this.country = c
        },
        onSelectLanguage (l) {
            this.language = l
        },
        onSelectTimezone (tz) {
            this.timeZone = tz
        },
        onSelectCurrency (c) {
            this.currency = c
        },
        getLanguageLabel (code) {
            return getLanguageLabel(this.languages, code)
        },
        countryFor (account) {
            if (account && account.country && this.countries) {
                let currentCountry = this.account.country
                const country = this.countries.find(function (c) { return c.value === currentCountry })
                if (country) return country.text
            }
            return null
        },
        openHelpDesk (disabled) {
            if (disabled) return false
            const querystr = '?partner_id=' + auth.getPartnerId() + '&sub_partner_id=' + this.$store.getters.selectedPartner.PartnerId +
          '&session_id=' + auth.getSessionId + '&user_id=' + this.account.accountOwnerId + '&account_id=' + auth.getAccountId + '&non_active=false&old_client=no'
            window.open(config.apiServer + '/api/partners/portal/openHelpDesk' + querystr)
        },
        documentClick (e) {
            let el = this.$refs.dropDownEditMenu
            let target = e.target
            if (el && el !== target && !el.contains(target)) {
                this.isEditActive = false
            }
        },
        addOffset(timezone) {
            const offset = this.getOffset(timezone)
            const hours = offset / 60
            const minutes = offset % 60
            if (hours > 0) return ' ( -' + hours + (minutes ? `:${Math.abs(minutes)}` : '') + ' )'
            else return ' ( +' + Math.floor(Math.abs(hours)) + (minutes ? `:${Math.abs(minutes)}` : '') + ' )'
        },
        getOffset(timeZone) {
            const foundTimeZone = findTimeZone(timeZone)
            const date = new Date()
            const timeZoneOffset = getUTCOffset(date, foundTimeZone)
            return timeZoneOffset.offset
        }
    },
    validations: {
        companyName: {
            maxLength: maxLength(255),
            validName
        },
        reference: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(32)
        },
        customReference: {
            maxLength: maxLength(255)
        },
        phone: {
            phoneNumberCustomer,
            minLength: minLength(10),
            maxLength: maxLength(20)
        },
        contactEmail: {
            required,
            email,
            minLength: minLength(8),
            maxLength: maxLength(128)
        },
        firstName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(32),
            validName
        },
        lastName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(32),
            validName
        },
        crmId: {
            numeric
        },
        language: {
            required
        },
        timeZone: {
            required
        },
        address: {
            minLength: minLength(2),
            maxLength: maxLength(250),
        },
        zipCode: {
            minLength: minLength(2),
            maxLength: maxLength(16),
            alphaNumericSpace
        },
        city: {
            minLength: minLength(2),
            maxLength: maxLength(50),
            alphaNumericSpace
        },
        currency: {
            required
        },
        vatNumber: {
            validVATNumber
        }
    },
    watch: {
        account () {
            this.$v.$reset()
            this.editMode = false
            this.hash = hash(this.account)
            this.zohoId = this.crmId
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/forms';
  @import '../../assets/styles/custom';

  .account-information {
    position: relative;
    padding-bottom: 20px;
    h2 {
      color: $primary;
      text-transform: uppercase;
      margin: 10px 0 0.5714em 0;
      font-size: 14px;
    }
    p {
      margin-bottom: 20px;
    }
    .columns {
      margin: 0;
      padding: 5px 0;
      .column {
        padding: 0 40px;
        margin: 30px 0 40px 0;
        .field {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &.edit-mode {
        .column {
          @media screen and (max-width: 767px) {
            border-left: 8px solid $primary;
            padding-left: 32px;
          }
          @media screen and (min-width: 768px) {
            &:first-child {
              border-left: 8px solid $primary;
              padding-left: 32px;
            }
          }
        }
      }
    }
    .dropdown {
      &.edit-menu {
        position: absolute;
        top: 70px;
        right: 10px;
        z-index: 20;
        .dropdown-trigger {
          color: $primary;
          font-weight: bold;
          position: absolute;
          top: 0;
          right: 10px;
          cursor: pointer;
          span {
            font-weight: 600;
            font-size: 18px;
          }
        }
        .dropdown-menu {
          position: relative;
          margin-top: 25px;
          .dropdown-content {
            border-radius: $border-radius;
            .dropdown-item {
              text-align: center;
              padding: 0.375rem 1rem;
              text-transform: uppercase;
              color: $primary;
              &.is-danger {
                color: $color-error-dark;
              }
            }
            hr {
              height: 1px !important;
            }
          }
        }
      }
    }
    .control-buttons {
      padding: 0;
      width: 50px;
      display: inline-block;
      position: absolute;
      right: -35px;
      top: 75px;
      button {
        float: right;
        margin-bottom: 10px;
        font-size: 18px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        vertical-align: middle;
        &.deactivate-button {
          color: #FFFFFF;
          background-color: $primary;
        }
        &.activate-button {
          color: #FFFFFF;
          background-color: $primary;
        }
      }
    }
  }
</style>
